
import React from "react";
import logo from "../../assets/Logo/logo.svg";
import {Link} from "react-router-dom";
import LoginForm from "../../app/login/loginForm";
import SignupForm from "../../app/signup/signupForm";
import LanguageSwitcher from "../language-switcher";
import { routes } from "../../Routes";
import { useTranslation } from "react-i18next";


const LoginSignup = ({ welcomeText, subText, login, belowText }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="absolute right-2 top-4 justify-end">
        <LanguageSwitcher />
      </div>
      <div className="flex flex-col items-center justify-center overflow-hidden" >
        <div className="flex flex-col  items-center  mb-2.5" style={{marginTop:"rem"}}>
          <img src={logo} width={120} height={80} alt="myGINNE Logo"  />
          <h1 className="font-semibold text-gray-800  text-2xl " style={{marginTop:"2rem"}}>
          {login == true ? (
            t('Welcome Back') ) : (t('Welcome') )}
          </h1>
          {login == true ? (
          <p className="text-gray-500 mt-1 text-md font-normal">{t("Login to your account")}</p>
          ) :(
            <p className="text-gray-500 mt-1 text-lg font-medium">{t("join_us_note")}</p>
          )}
        </div>
        {login == true ? <LoginForm /> : <SignupForm />}
        <p className="mt-4 text-md">
        {login == true ? t('not_account'):t('exist_account')}{" "}
          <span className="text-primaryBlue font-semibold underline ">
            {login == true ? (
              <Link to={routes.signup}>{t("Register")}</Link>
            ) : (
              <Link to={routes.login}>{t("login")}</Link>
            )}
          </span>
        </p>
        {/* <div className="h-10"></div> */}
      </div>
    </>
  );
};

export default LoginSignup;
