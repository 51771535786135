import React, { useRef, useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import JsBarcode from "jsbarcode";
const CreatePDFInfo = ({ data }) => {
  const barcodeRef = useRef();
  const [barcodeUrl, setBarcodeUrl] = useState("");

  let htmlProductLines = "";

  if (data?.order_line_details) {
    data.order_line_details.forEach((item, index) => {
      htmlProductLines += `
        <tr key=${index}>
          <td style="text-align: center; vertical-align: text-top;">${
            index + 1
          }</td>
          <td style="text-align: left;">${item?.product_name}</td>
          <td style="text-align: end;">${item?.delivered_qty}</td>
          <td style="text-align: end;">${item?.sell_price}</td>
          <td style="text-align: end;">${(item?.sell_price * (item?.delivered_qty?item?.delivered_qty:item?.order_qty))}</td>
        </tr>
      `;
    });
  }
  let order_id = 45345
  let order_idChange = order_id
  let Change = 0;
  let Cash;
  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, order_idChange, {
        format: "CODE128",
        lineColor: "#000",
        width: 2,
        height: 60,
        displayValue: true,
        fontSize: 16,
      });

      // Convert SVG to DataURL
      const svg = barcodeRef.current;
      const xml = new XMLSerializer().serializeToString(svg);
      const svg64 = btoa(xml);
      const b64Start = "data:image/svg+xml;base64,";
      const image64 = b64Start + svg64;
      setBarcodeUrl(image64);
    }
  }, [order_idChange]);
//   console.log("data?.order[0]?.payment_data", data?.order[0]);
//   data?.order[0]?.payment_data.forEach((item) => {
//     if (item?.name == "return") {
//       console.log("Heyyyy");
//       Change = item?.amount;
//     }
//     if (item?.payment_method == "بنك") {
//       Change = 0;
//       Cash = item?.amount;
//     }
//     if (
//       item?.name == false &&
//       (item?.payment_method == "نقد" || item?.payment_method == "نقدي")
//     ) {
//       Cash = item?.amount;
//     }
//   });

  const htmlContent = `
<!DOCTYPE html>
<html>
  <head>
    <title>POS Receipt</title>
    <style>
    body {
      font-family: Arial, sans-serif;
      font-size: 14px;
      margin: 0;
      padding: 10px;
      // color: #616161;
      color:"black"
    }
    .pos-receipt-container {
      margin: 10px;
    }
    .pos-receipt {
      text-align: center;
      margin-bottom: 20px;
    }
    .pos-receipt-contact {
      margin-bottom: 10px;
      margin-top:5px
    }
    .pos-receipt-contact div{
      font-size: 15px;
    }
    .pos-receipt-contact .cashier {
      margin-top: 10px;
      margin-bottom: 10px;
      border-top: 1px dashed black;
    }

    .pos-receipt-contact .cashier div {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .pos-receipt-contact table {
      width: 100%;
      margin-bottom: 10px;
    }

    .pos-receipt-contact table td:first-child {
      width: 50%;
    }

    .receipt-orderdata {
      width: 100%;
      font-size: 15px;
      margin-bottom: 10px;
      margin-top:10px
    }
    .receipt-orderdata th,
    .receipt-orderdata td {
      padding: 6px;
    }
    .receipt-orderdata td {
      font-size: 15px;
    }
    .receipt-orderdata th {
      text-align: center;
      font-weight: bold;
      font-size: 16 px;
    }

    .pos-receipt-right-align {
      float: right;
    }
    .pos-receipt .pos-receipt-right-align {
      float: right;
      font-size: 16px;
    }
    .orderDetail{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .orderDetail div, .cashierDetail{
      font-size: 15px;
    }
    .receiptHead{
      text-align: center;
      text-align: center;
      font-weight: 600;
      border-top: 1px dashed black;
      padding: 11px 0px 0px;
      font-size: 23px;
      margin: 11px 0px;
    }
    .bottomSection{
      border-top: 1px dashed black;
      margin-top:10px;
      font-size:15px;
      padding:10px
    }
    .bottomSection img{
      width:83px;
    }
    .after-footer{
      margin-top:10px;
    }
  </style>
  </head>
  <body>
    <div class="pos-receipt-container">
      <div class="pos-receipt">
        <div style="text-align: center;">
        <img src="data:image/png;base64,${
            JSON.parse(localStorage.getItem("retailer_info"))?.store_logo
        }" alt="My Barcode Image" width="100">
        </div>
        <div class="pos-receipt-contact">
          <div>${JSON.parse(localStorage.getItem("retailer_info"))?.storename}</div>
          <div>${JSON.parse(localStorage.getItem("retailer_info"))?.retailermobile}</div>
        </div>
        <div class="receiptHead">
          ${"إيصال"}
        </div>
        
        <div style="text-align: left;border-top: 1px dashed black;
        border-bottom: 1px dashed black; padding:12px 0px;">
          <div class="orderDetail">
            <div style="margin-right:10px">
              <strong>${"رقم الطلب"}:</strong> ${data?.order_number}
            </div>
            <div>
              <strong>${"تاريخ الطلب"}:</strong> ${data?.order_date}
            </div>
          </div>
          <div style="font-size: 15px; margin-top: 5px;">
            <strong>${"اسم الكاشير"}: </strong>${data?.customer_details?.name}
          </div>
        </div>
        
        <div>
          <table class="receipt-orderdata">
            <colgroup>
              <col width="10%">
              <col width="43%">
              <col width="10%">
              <col width="10%">
              <col width="15%">
            </colgroup>
            <tr>
              <th>${"رقم سري"}.</th>
              <th>${"وصف"}</th>
              <th>${"كمية"}</th>
              <th>${"معدل"}</th>
              <th>${"كميه"}</th>
            </tr>
            ${htmlProductLines}
          </table>
        </div>
        <div class="orderDetail">
          <div><strong>${"مجموع"}</strong></div>
          <div>EGP ${data?.order_total_amt}</div>
        </div>
        <br>
        <div class="orderDetail">
          <div><strong>
          $ {            data?.order[0]?.payment_data[0]?.payment_method          }
          </strong></div>
          <div>EGP ${Cash}</div>
        </div>
        <br>
        <div class="orderDetail">
          <div><strong>${"فكه"}</strong></div>
          <div>EGP ${Change}</div>
        </div>
        <br>
        <div class="after-footer"></div>
        <div style="display:inline;">
        <img src="${barcodeUrl}" alt="Receipt Barcode" width="300" height="80"/>
        </div>
      </div>
      <div class="pos-receipt bottomSection">
        ${"شريكك التكنولوجي"}<br>
        <img src="myginne.webp" alt="myGinne Logo">
      </div>
    </div>
  </body>
</html>
`;
  const receiptRef = useRef();
  const downloadPdfDocument = () => {
    const input = receiptRef.current;
    html2canvas(input, {
      scale: 1,
      useCORS: true, // This will attempt to load images with CORS
      allowTaint: false, // Setting this to true may resolve issues but can create security risks
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const canvasRatio = canvasWidth / canvasHeight;

      const maxPdfWidth = 210;

      const pdfWidth = 95;
      const pdfHeight = pdfWidth / canvasRatio;
      const pdf = new jsPDF({
        orientation: canvasWidth > canvasHeight ? "landscape" : "portrait",
        unit: "mm",
        format: [pdfWidth, pdfHeight],
      });
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("order_receipt.pdf");
    });
  };

  return (
    <div className="container">
      <button className="downloadButton" onClick={downloadPdfDocument}>
        <FontAwesomeIcon icon={faDownload} />
      </button>
      <div ref={receiptRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
      <div style={{ display: "none" }}>
        <svg ref={barcodeRef}></svg>
      </div>
    </div>
  );
};
// window.print
export default CreatePDFInfo;