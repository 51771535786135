import React, { useEffect, useRef, useState } from "react";
import Deliveryboy from "../../components/EordersDeliveryPartners/Deliveryboy";
import { Breadcrumb, DatePicker } from "antd";
import { useFormik } from "formik";
import plus from "../../assets/Images/plus.svg";
import Deliverypartnerstable from "./Deliverypartnerstable";
import Deposited from "./Deposited";
import SearchBox from "../CustomWidgets/SearchBox";
import SelectBox from "../CustomWidgets/SelectBox";
import { Image } from "antd";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import {
  getdataDeliveryPartner,
  searchdeliverypartner,
} from "../../API  Functions/DeliveryPartnerFunctions";
import { DoubleRightOutlined } from "@ant-design/icons";
import "../../styles.css";
import { debounce } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Deliverypartners = () => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state.login?.user?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);

  const [Deliverypartners, setDeliverypartners] = useState([]);
  const [SearchDeliverypartners, setSearchDeliverypartners] = useState([]);
  // This is for use in add user button to open a new component
  const [showComponent, setShowComponent] = useState(false);
  const [activeHeading, setActiveHeading] = useState(1);
  const [headingWidth, setHeadingWidth] = useState(0);
  // State to control the visibility of the Deposited component
  const [showDeposited, setShowDeposited] = useState(false);
  const [tempdeliverypartner, settempdeliverypartner] = useState("");

  const getdata = async () => {
    const response = await getdataDeliveryPartner(user_id, store_id);
    response?.length > 0 && setSearchDeliverypartners(response);
  };

  const searchpartner = debounce(async (query = "", status = "") => {
    console.log("query", query, "status", status);
    if (
      (status == "Select" || status == t("status") || status == "") &&
      query == ""
    ) {
      status = "";
      getdata();
    } else if (
      query !== "" &&
      (status == "Select" || status == t("status") || status == "")
    ) {
      const response = await searchdeliverypartner(
        user_id,
        store_id,
        query,
        ""
      );
      if (response) {
        setSearchDeliverypartners(response?.delivery_partner_details);
        // console.log("datataa-->",response?.delivery_partner_details)
      }
    } else {
      const response = await searchdeliverypartner(
        user_id,
        store_id,
        query,
        status
      );
      if (response) {
        setSearchDeliverypartners(response?.delivery_partner_details);
        // console.log("datataa-->",response?.delivery_partner_details)
      }
    }
  }, 1000);
  // console.log("DeliveryPartner searched",SearchDeliverypartners)

  useEffect(() => {
    getdata();
  }, []);

  const formik = useFormik({
    initialValues: {
      status: t("status"),
      query: "",
    },
  });

  // Function to handle clicking the "Mark as Deposited" button
  const handleMarkAsDeposited = (partnerdata) => {
    settempdeliverypartner(partnerdata);
    setShowDeposited(true);
  };
  // Ref for the active heading element
  const activeHeadingRef = useRef(null);
  const isSmall = Deliverypartners && Deliverypartners?.length < 10;

  const badgeStyle = {
    width: "28px",
    height: "20px",
    borderRadius: "9px",
    marginLeft: "5px",
    textAlign: "center",
    backgroundColor: !isSmall ? "#E7E7E7" : "#FCC71D",
  };
  const underlineStyle = {
    width: `${headingWidth}px`, // Set width dynamically
    height: "6px",
    borderRadius: "10px",
    backgroundColor: "#78CFEC", // Blue color
  };

  const handleClearFilter = () => {
    // Implement your logic to clear the filter here
  };

  const handleHeadingClick = (headingNumber) => {
    setActiveHeading(headingNumber);
  };

  // Function to calculate and set the width of the active heading
  const setDynamicWidth = () => {
    if (activeHeadingRef.current) {
      setHeadingWidth(activeHeadingRef.current.offsetWidth);
    }
  };

  // Update width when activeHeading or data changes
  useEffect(() => {
    setDynamicWidth();
  }, [activeHeading, Deliverypartners]);

  const handleDateChange = (date) => {
    // Update formik values when date changes
    formik.setFieldValue("today", date);
  };

  return (
    <>
      <div className=" bg-[#F6F7F9] min-h-[92vh]">
        <Breadcrumb
          separator={<DoubleRightOutlined />}
          items={[
            // { title: "Online Orders", },
            { title: t("delivery_boy"), href: "/deliverypartner" },
            { title: t("delivery_boy_list") },
            // !showDeposited && !showComponent && { title: "Delivery Boy List" },
            // showDeposited && { title: "Delivery Boy Details" } ,
            // showComponent && { title: `Add Delivery Boy ` },
          ]}
          className="pt-4 px-3 font-extrabold"
        />

        {showDeposited ? (
          <Deposited
            Deliverypartnerdata={tempdeliverypartner}
            onClose={() => {
              setShowDeposited(false);
              getdata();
            }}
          />
        ) : (
          <>
            {showComponent ? (
              <Deliveryboy
                onClose={() => {
                  setShowComponent(false);
                  getdata();
                }}
              />
            ) : (
              <>
                <div className="flex items-end ">
                  <div className=" float-left ml-3 ">
                    <SearchBox
                      searchResult={(val) => {
                        searchpartner(val, formik.values.status);
                        formik.setFieldValue("query", val);
                      }}
                    />
                  </div>
                  <div className="flex items-end gap-x-3 ml-4">
                    {/* <DatePicker
                      defaultValue={formik.values.today}
                      onChange={handleDateChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.today}
                      format="DD/MM/YYYY" // Specify date format
                      className="h-10 mt-[23px] w-52"
                    /> */}
                    <SelectBox
                      // label=t('status')
                      style={{ height: "2.5rem" }}
                      name="status"
                      onChange={(val) => {
                        formik.handleChange(val);
                        searchpartner(formik.values.query, val.target.value);
                      }}
                      options={[
                        { value: "", label: t("status") },
                        { value: "Available", label: t("available") },
                        { value: "Unavailable", label: t("unavailable") },
                      ]}
                      onBlur={formik.handleBlur}
                      value={formik.values.status}
                      error={formik.errors.status}
                      touched={formik.touched.status}
                    />
                  </div>
                  <div className="ml-auto">
                    <button
                      onClick={() => setShowComponent(true)}
                      className="font-semibold mt-6 mr-4 w-48 h-10 border rounded-lg bg-primaryBlue text-white text-base flex justify-center items-center"
                      type="submit"
                    >
                      <img src={plus} alt="Image" />
                      <p className="ml-2">{t("add_delivery_boy")}</p>
                    </button>
                  </div>
                </div>

                <div className="flex gap-7 mt-6 mx-3 font-semibold text-[#999999] relative">
                  <h1
                    ref={activeHeadingRef}
                    className={`flex relative cursor-pointer heading-${activeHeading}`}
                    onClick={() => handleHeadingClick(activeHeading)}
                  >
                    {activeHeading === 1 && (
                      <div
                        style={underlineStyle}
                        className="absolute top-8 left-0"
                      />
                    )}
                    <span
                      style={{
                        color: activeHeading ? "#000" : "#999999",
                      }}
                    >
                      {t("all_delivery_boys")}
                    </span>

                    {/* <div  style={badgeStyle}> */}
                    <div
                      style={{
                        borderRadius: "10px",
                        maxHeight: "23px",
                        marginLeft: "5px",
                        backgroundColor: !isSmall ? "#E7E7E7" : "#FCC71D",
                        padding: "0px 7px",
                      }}
                    >
                      <span style={{ color: "black", marginBottom: 2 }}>
                        {SearchDeliverypartners?.length ??
                          Deliverypartners?.length ??
                          0}
                      </span>
                    </div>
                  </h1>
                  {/* <div className="flex items-center ml-4">
                    <h2 className="text-sm text-gray-600 mr-2">
                      Orders Types:
                    </h2>
                    <div className="flex items-center">
                      <div className="w-4 h-4 bg-[#78CFEC] rounded-full mr-2"></div>
                      <p className="text-sm text-gray-600 mr-4">Regular</p>
                    </div>
                    <div className="flex items-center">
                      <div className="w-4 h-4 bg-[#FFD758] rounded-full mr-2"></div>
                      <p className="text-sm text-gray-600 mr-4">Return</p>
                    </div>
                    <div className="flex items-center">
                      <div className="w-4 h-4 bg-[#FE9B3F] rounded-full mr-2"></div>
                      <p className="text-sm text-gray-600 mr-4">Replacement</p>
                    </div>
                    <div className="flex items-center">
                      <div className="w-4 h-4 bg-[#FF7171] rounded-full mr-2"></div>
                      <p className="text-sm text-gray-600">Cancelled order</p>
                    </div>
                  </div> */}
                  {/* <button
                    onClick={handleClearFilter}
                    className="text-primaryBlue focus:outline-none ml-auto mr-2 font-semibold"
                  >
                    Clear Filter
                  </button> */}
                </div>

                <hr className="w-[1090px] lg:w-[97.6%] relative top-[14px] left-[13px]" />
                <div>
                  {/* {console.log("deliver partner data",Deliverypartners1,typeof(Deliverypartners1))
     }
     {console.log("deliver partner data from api",Deliverypartners,typeof(Deliverypartners))
   } */}
                  {activeHeading === 1 && (
                    <Deliverypartnerstable
                      Deliverypartners={Deliverypartners}
                      SearchDeliverypartners={SearchDeliverypartners}
                      onMarkAsDeposited={handleMarkAsDeposited}
                      pagination={(values) =>
                        getdata(values.limit, values.offset)
                      }
                    />
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Deliverypartners;
