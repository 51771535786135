import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import category from "../../../assets/Inventory/spinner.svg";
import { Image, Select, Breadcrumb } from "antd";
import { DoubleRightOutlined, PlusOutlined } from "@ant-design/icons";
import { Routes } from "react-router-dom";
import HighOnInventoryTable from "../../../components/Inventory/HighOnInventoryTable";
import InventoryOnboard from "../InventoryOnboard/InventoryOnboard";
import SearchBox from "../../../components/CustomWidgets/SearchBox";
import Sidebar from "../../../components/Orders/sidebar";
import Navbar from "../../../components/Orders/Navbar";
import { Link } from "react-router-dom";
import {
  GenerateExcelReport,
  HighonInventorySearch,
  InventoryCategoryFilter,
  getInventoryallcategories,
} from "../../../API  Functions/InventoryFunctions";
import { debounce } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import Breadcrumbs from 'react-router-breadcrumbs-hoc';
// import  {Breadcrumb}  from "../../../modals/Breadcrumbs";

const HighOnInventory = () => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state.login?.user?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);

  const { Option } = Select;
  const [showInventoryOnboard, setShowInventoryOnboard] = useState(false); // State to track showing the InventoryOnboard component
  const [CategoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [Showcategories, setShowcategories] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [SelectedsubcategoryOptions, setSelectedsubcategoryOptions] = useState(
    []
  );
  const [subcategory, setsubcategory] = useState([]);
  const [searchedData, setsearchedData] = useState([]);
  const [searchData, setsearchData] = useState(null); // State to track showing the InventoryOnboard component
  const [query, setquery] = useState("");
  const [call, setcall] = useState(false);

  useEffect(() => {
    // getInventoryKPI();
    getInventoryCategories();
  }, []);
  const getInventoryCategories = () => {
    getInventoryallcategories(user_id, store_id).then((res) => {
      setCategoryData(res);
    });
  };

  const debouncedSearch = debounce((value) => {
    // Call your search function or API endpoint here
    setquery(value);
    if (selectedOptions?.length > 0)
      searchFilter(
        user_id,
        store_id,
        selectedOptions,
        SelectedsubcategoryOptions,
        value
      );
    else {
      HighonInventorySearch(user_id, store_id, value).then((response) => {
        if (response?.message == "Success") {
          setsearchedData(response?.data);
          setcall(false);
        }
        if (response?.message == "Please check your Payload !!") {
                   setcall(true);
        }
      });
    }
  }, 1000);

  const searchFilter = (selectedValues, subcat = [], value = "") => {
    InventoryCategoryFilter(
      user_id,
      store_id,
      selectedValues,
      subcat,
      value,
      5
    ).then((response) => {
      if (response?.message == "Success") {
        setsearchedData(response?.data);
        setcall(false);
      } else if (response?.status == 401) {
        setcall(true);
      }
    });
  };

  const handleOptionChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    setsubcategory([]);
    if (selectedOptions) {
      searchFilter(selectedValues, [], query);
      selectedValues.map((item) => {
        const cat = CategoryData.filter(
          (res) => res.main_category_id === item
        )[0]?.sub_category_details;
        setsubcategory((prevdata) => [...prevdata, ...cat]);
      });
    }
  };

  const handlesubcategorySelection = (selectedValues) => {
    setSelectedsubcategoryOptions(selectedValues);
    if (selectedValues) searchFilter(selectedOptions, selectedValues, query);
  };
  // Function to handle the click event of the "Add Product" button
  const handleAddProductClick = () => {
    setShowInventoryOnboard(true); // Set showInventoryOnboard state to true to display the InventoryOnboard component
  };
  const formik = useFormik({
    initialValues: {
      order: "",
    },
  });

  const handleinventoryreportdownload = () => {
    GenerateExcelReport(user_id, store_id, 5).then((res) => console.log(res));
  };

  return (
    <>
      <div className="w-full ">
        <div className="flex flex-col flex-1 pb-4 ">
          <div>
            <Navbar heading="inventory" />
          </div>
          {showInventoryOnboard ? (
            <InventoryOnboard />
          ) : (
            <>
              <div className="mr-4 ml-3 font-extrabold mt-14 ">
                {/* <Breadcrumbs
                  separator={<span> / </span>}
                  item={Breadcrumb}
                  finalItem={"b"}
                  container={Breadcrumb}
                  finalProps={{ style: { color: "red" } }}
                /> */}
                <Breadcrumb
                  separator={<DoubleRightOutlined />}
                  items={[
                    {
                      title: t("inventory"),
                      href: "/inventory",
                    },
                    { title: t("high_on_inventory") },
                  ]}
                  className="relative top-3"
                />
              </div>
              <div className="flex justify-between items-center mx-3 mt-2">
                {/* Left section with SearchBox and Category Button */}
                <div className="flex gap-3 mt-4 justify-start items-center ">
                  <SearchBox
                    showBarcode={true}
                    searchResult={(val) => debouncedSearch(val)}
                  />
                  {/* <button className="w-32 h-10 flex items-center justify-center border-[#CCCCCC] border text-[#5C5C5C] text-sm font-medium rounded-lg">
                    <Image src={category} className="mr-2"  alt="Image"/>
                    Category
                  </button> */}
                  <Select
                    mode="multiple" // Allow multiple selections
                    className=" overflow-hidden cursor-pointer"
                    style={{
                      width: 180,
                      minWidth: 180,
                      // left: -120,
                      height: 40,
                    }}
                    placeholder={t("select_categories")}
                    value={selectedOptions}
                    onChange={handleOptionChange}
                    maxTagCount={1}
                    // maxTagPlaceholder={(values) => `+${values.length - 2} more`}
                  >
                    {Object.values(CategoryData)?.map((option, index) => (
                      <Option
                        key={option.main_category_id}
                        value={option.main_category_id}
                      >
                        {option.name}
                      </Option>
                    ))}
                  </Select>

                  {selectedOptions?.length !== 0 && (
                    <Select
                      mode="multiple" // Allow multiple selections
                      className=" overflow-hidden cursor-pointer"
                      style={{
                        width: 180,
                        minWidth: 180,
                        height: 40,
                      }}
                      placeholder={t("select_sub_categories")}
                      value={SelectedsubcategoryOptions}
                      onChange={handlesubcategorySelection}
                      maxTagCount={1}
                    >
                      {subcategory?.map((option, index) => (
                        <Option key={index} value={option.id}>
                          {option.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </div>
                {/* Right section with Inventory Report and Add Product Buttons */}
                <div className="flex gap-3 mt-4">
                  <button
                    className="w-24 h-10 border-primaryBlue border text-[#1b4597] text-sm font-semibold rounded-lg"
                    onClick={handleinventoryreportdownload}
                  >
                    {t("report")}
                  </button>
                  <Link to="/inventory/InventoryOnboard">
                  <button
                    //onClick={handleAddProductClick}
                    className="w-36 h-10 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center gap-2"
                  >
                    <PlusOutlined />
                    <span>
                     
                        {t("add_product")}
                      
                    </span>
                  </button>
                  </Link>
                </div>
              </div>

              <div>
                <HighOnInventoryTable
                  searchedData={searchedData}
                  searchData={searchData}
                  user_id={user_id}
                  store_id={store_id}
                  call={call}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HighOnInventory;
