import { combineReducers } from 'redux';
import {userReducer,loginReducer,sessionidentifierReducer,getcategorydataReducer, logoutReducer} from "./index"
import deliveryAreasSlice from '../../components/Redux Slices/deliveryAreasSlice';


const rootReducer = combineReducers({
  user: userReducer,
  login: loginReducer,
  // sessionIdentify:sessionidentifierReducer,
  // productData:getcategorydataReducer,
  logout: logoutReducer,
  // deliveryAreas:deliveryAreasSlice,
});

export default rootReducer;
