import React, { useEffect, useState } from "react";
import  {Image as IMG } from "antd";
import {Image}  from "antd";
import checked from "../assets/Cancel.svg";
import { useSelector } from "react-redux";
import { getProductlog } from "../API  Functions/InventoryFunctions";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "60%",
    height: "90%",
    padding: "20px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "semibold",
    textAlign: "center",
    marginBottom: "20px",
    marginTop: "30px",
  },
  image: {
    height: 180,
    width: 180,
    display: "block",
    left: 0,
    objectFit:"contain"
    
  },
  description: {
    textAlign: "center",
    fontSize: "0.875rem",
    // color: "#5C5C5C",
    marginBottom: "20px",
  },
};

const ProductModal = ({ onClose, message, route, state, data }) => {
  const user_id = useSelector(state=>state.login?.user?.user_id)
  const store_id = useSelector(state=>state.login?.user?.store_id)

  const [logdata,setlogdata]=useState([])

 
const getlog=async()=>{
  const response=await getProductlog(user_id,store_id,data?.store_inventory_id)
  setlogdata(response)
}

  useEffect(() => {
   if(data) getlog();
  }, [data])
  

  return (
    <div>
   
      <div style={styles.overlay}></div>
      <div style={styles.container}>
      <button
            type="button"
            className=" absolute w-8 h-6 bg-[#ccc] rounded-lg font-normal   right-1 top-1"
            // onClick={handleGoToDashboard} // Call handleGoToDashboard onClick
            onClick={() => onClose()}
          >
            {/* <img src={checked} alt="X"/> */}
     x
          </button>
        <div className="flex justify-start gap-7 mt-7 mb-7  border">
          <div className=" flex flex-col items-center mt-4 mx-4">
            {/* <span className="text-start top-0 font-normal  flex flex-row gap-4  justify-between  ">
              Product Image
            </span> */}
            <IMG
              
              src={data.image_url} // Provide the path to your checked IMG
              alt="Checked"
              style={styles.image}
            />
          </div>
          <div className="product-detail flex flex-col items-stretch gap-1 mt-4">
            <span className="font-normal text-sm  flex flex-row gap-4  justify-between  ">
              <p>Product Name:{" "}</p>
              <p className=" font-normal text-xs "style={{color:"gray"}}>{data?.product_name}</p>
            </span>
            <span className="font-normal  text-sm flex flex-row gap-4  justify-between">
             <p> Barcode</p> {" "} <p className=" font-normal text-xs "style={{color:"gray"}}>{data?.barcode}</p>
            </span>
            <span className="font-normal text-sm flex flex-row gap-4  justify-between">
              <p>Cost Price</p>{" "}
              <p className=" font-normal text-xs "style={{color:"gray"}}>EGP {" "}{data?.cost_price}</p>
            </span>
            <span className="font-normal text-sm  flex flex-row gap-4  justify-between">
              <p>Sell Price</p>{" "}
              <p className=" font-normal text-xs " style={{color:"gray"}}>EGP {" "}{data?.sell_price}</p>
            </span>
            <span className="font-normal  text-sm flex flex-row gap-4  justify-between">
              <p>Quantity</p> <p className=" font-normal text-xs "style={{color:"gray"}}>{data?.qty}</p>
            </span>
            <span className="font-normal text-sm flex flex-row gap-4  justify-between">
              <p>Category</p>{" "}
              <p className=" font-normal text-xs "style={{color:"gray"}}>
                {data?.main_category?.[0]?.["name"]}
              </p>
            </span>
          </div>

          
          {/* </Link> */}
        </div>
        <div className=" flex flex-col items-start">
        <div className=" font-normal" style={{color:"gray"}}>Product Logs</div>
        <div className="flex w-full justify-between gap-5 mt-2 mb-7  border">
          <div className=" mx-2 flex flex-col text-xs items-start mt-0 " style={{color:"gray"}}>
          <span className="text-start top-0 font-normal  flex flex-row    ">
              {/* {logdata?.Updatedtime} */}
              <span>
            {logdata?.Updatedtime?.substring(0, 10).replace(/-/g, "/")}{" "}
            {logdata?.Updatedtime?.substring(11, 13) == 12
              ? logdata?.Updatedtime?.substring(11, 16) + " PM"
              : logdata?.Updatedtime?.substring(11, 13) > 12
              ? logdata?.Updatedtime?.substring(11, 13) - 12 + logdata?.Updatedtime?.substring(13, 16) + " PM"
              : logdata?.Updatedtime?.substring(11, 16) + " AM"}
          </span>
            
        </span>
            <span className=" text-start top-0 font-normal mt-2 ">
              Product Updated 
            </span>
            <span className=" text-start top-0 font-normal  mb-1 ">
             {data?.product_name}
            </span>

            
            
            
          </div>
          <div className=" mx-2 flex flex-col text-xs items-center mt-6 mb-1" style={{color:"gray"}}>
            <span className="text-start top-0 font-normal  flex flex-row gap-4  justify-between  ">
              Product Updated By
            </span>
            <span className="text-start top-0 font-normal  flex flex-row gap-4  justify-between  ">
              {logdata?.Updated_by?.[0]
              }
             
        </span>
            
        </div>
          </div>
          </div>
      </div>
    </div>
  );
};

export default ProductModal;
