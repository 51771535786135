import React, { useState, useCallback, useEffect } from "react";
import upload from "../assets/Images/Uploadminimalistic.svg";
import { useDropzone } from "react-dropzone";
import { Image } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";

const UploadMedia = ({
  onfileAdd,
  foldername,
  urlgenerated,
  multiple = false,
  keyname,
  imgurl = "",
  message,
}) => {
  const { t } = useTranslation();
  const [image, setImages] = useState([]);
  const [sizeError, setSizeError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [imageurl, setImageUrl] = useState(""); // State to hold the uploaded image URL
  const [forceUpdate, setForceUpdate] = useState(false); // State to force component update

  useEffect(() => {
    if (imageurl) {
      // Toggle forceUpdate to force re-render of Image component
      setForceUpdate((prev) => !prev);
    }
  }, [imageurl]);

  const handleUpload = useCallback(async (file, imageArray) => {
    try {
      const imageData = file.type.substring(6) === "jpeg"
        ? imageArray[0].substring(23)
        : imageArray[0].substring(22);

      const payload = {
        image_base64: imageData,
        bucket_name: process.env.REACT_APP__IMAGE_BUCKET,
        folder_path: foldername,
        image_name: `${
          localStorage?.getItem("store_id")
            ? localStorage?.getItem("store_id")
            : JSON.parse(localStorage.getItem("retailer_info"))?.id
          }-${onfileAdd}.${file.type.substring(6)}`,
      };

      const location = await axios.post(
        process.env.REACT_APP__IMAGE_UPLOAD,
        payload
      );

      const data = {
        name: onfileAdd,
        url: location.data.image_url+"?"+Date.now(),
      };
      keyname ? urlgenerated(data, keyname) : urlgenerated(data);
      setImageUrl(data?.url); // Update imageurl state with the new URL
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }, [foldername, onfileAdd, urlgenerated, keyname]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.size > 5 * 1024 * 1024) {
          setSizeError(true);
        } else {
          setSizeError(false);
          const imageArray = [reader.result];
          setImages(imageArray);

          if (
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png"
          ) {
            handleUpload(file, imageArray);
            setFileError(false);
          } else {
            message(false);
            setFileError(true);
          }
        }
      };
      reader.onerror = (error) => {
        console.log("Error:", error);
      };
    });
  }, [handleUpload, message]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    multiple,
    onDrop,
  });

  return (
    <div className="upload">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p className="flex flex-col justify-end text-center text-sm text-body">
          <span className="flex flex-row justify-center gap-1 items-center font-semibold text-[#1b4597]">
            {(onfileAdd !== "storeuserurl" || onfileAdd !== "userphoto") && (
              <img
                className=""
                src={upload}
                width={18}
                alt="Upload Icon"
              />
            )}
            {imageurl === "" ? `${" "} ${t('upload')}` : `${" "}${t('change')}`}
          </span>
          {onfileAdd !== "userphoto" && (
            <div className="">
              {imageurl === "" && (
                <>
                  {" "}
                  <span className="text-xs text-body text-[#999999]">
                    png, jpg, jpeg
                  </span>
                  <div className="text-xs text-body text-[#999999]">
                    (max 5mb)
                  </div>
                  {/* <div className="text-xs text-body text-[#a6d88b]">
                    Verified
                  </div> */}
                </>
              )}
            </div>
          )}
        </p>
      </div>
      {sizeError && (
        <p className="text-red text-xs mt-1">File cannot be more than 5 mb</p>
      )}
      {fileError && (
        <p className="text-red text-xs mt-1">Unsupported File Type</p>
      )}
    </div>
  );
};

export default UploadMedia;
