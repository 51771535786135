import React, { useState } from "react";
import { useFormik } from "formik";
import { SignupSchema } from "../../components/validationSchema";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import SelectBox from "../../components/CustomWidgets/SelectBox";
import Button from "../../components/CustomWidgets/Button";
import OTPModal from "../../modals/verifyOTP";
import { useDispatch } from "react-redux";
import { signupActions } from "../../components/Redux Slices/signupSlice";
import { signupUser } from "../../components/Actions/signupActions";
import { Input } from "antd";
import FlagIcon from "../../assets/egypt.svg";
import Notification from "../../modals/Notification";
import LoaderComponent from "../../Loader";
import { Image } from "antd";
import "../../styles.css";
import { InputBox } from "../../components/CustomWidgets/InputBox";
import { useTranslation } from "react-i18next";

const SignupForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [responseData, setresponseData] = useState("");
  const [Loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      businessProfile: "Retailer",
      phoneNumber: "",
      country_code: "",
    },
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      // console.log("values", values);
      let payload = {
        // .substring(3)
        mobile:
          values.phoneNumber?.length == 11
            ? values.phoneNumber?.substring(1)
            : values?.phoneNumber,
        country_code: "+20",
        user_type: values.businessProfile,
      };
      localStorage?.clear();
      try {
        // Dispatch the signup request action
        setLoading(true);
        dispatch(signupActions.signupRequest());

        // Dispatch the signupUser action
        const response = await dispatch(signupUser(payload));

        setresponseData(response);
        // console.log("response",response);
        if (
          response.payload.message === "Retailer Already exist with this mobile"
        ) {
          setShowNotification(true);
          setLoading(false);
          // console.log("called")
        }
        // Check if the signupUser action was fulfilled successfully
        else if (signupUser.fulfilled.match(response)) {
          // Dispatch the signup success action
          dispatch(signupActions.signupSuccess(response.payload));

          // Handle additional logic here

          // Show OTP Modal on successful API call
          setLoading(false);
          setShowOTPModal(true);
        } else {
          // Dispatch the signup failure action with the error message
          dispatch(signupActions.signupFailure(response.payload));
        }
      } catch (error) {
        console.error("API request error:", error);
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Modify the value if needed, e.g., format phone number
    e.target.value < 9999999999 && formik.setFieldValue(name, value);
    setShowNotification(false);
  };
  const handleNumberChange = (e) => {
    setShowOTPModal(false);
  };

  return (
    <div className="w-[100%] md:w-[70%] mx-auto">
      <form onSubmit={formik.handleSubmit}>
        <SelectBox
          // style={{ marginTop: "0vh" }}
          label={t("business_type")}
          // Use defaultValue to set the initial value
          name="businessProfile"
          onChange={formik.handleChange}
          options={[
            { value: "Retailer", label: t("retailer") },
            // { value: "Wholesaler", label: "Wholesaler" },
          ]}
          onBlur={formik.handleBlur}
          value={formik.values.businessProfile} // Use value to keep the selected value updated
          error={formik.errors.businessProfile}
          touched={formik.touched.businessProfile}
          className={`${
            formik.touched.businessProfile && formik.errors.businessProfile
              ? "border-red !important" // Apply red border when touched or notification shown
              : ""
          }`}
        />
        {/* {formik.touched.businessProfile && formik.errors.businessProfile ? (
          <div className="font-medium italic text-red">
            {formik.errors.businessProfile}
          </div>
        ) : null} */}
        <div className="flex flex-col mt-5">
          <label className="py-2 text-md font-medium text-black ">
            {t("phone_number")}
          </label>
          <div className="flex flex-row mt-0">
            <div className="h-10 w-16 text-md text-body outline-none focus:outline-none  rounded-md border p-3 flex items-center justify-center">
              <img src={FlagIcon} height={50} width={50} alt="img" />
            </div>
            <Input
              type="number"
              placeholder={t("phone_number_placeholder")}
              value={formik.values.phoneNumber}
              onChange={handleChange}
              onBlur={formik.handleBlur}
              name="phoneNumber"
              maxLength={11}
              className={`h-10 w-100 ml-2 text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-md border p-3 ${
                (formik.touched.phoneNumber && formik.errors.phoneNumber) ||
                showNotification
                  ? "border-red" // Apply red border when touched or notification shown
                  : ""
              }`}
            />
          </div>
          {/* {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <div className="font-medium italic text-red">
              {formik.errors.phoneNumber}
            </div>
          ) : null} */}
          {showNotification ? (
            <div className=" text-sm  text-red flex justify-start ml-16">
              {t("user_exist")}
            </div>
          ) : null}
        </div>
        <div className="my-4">
          {/* <Button
        
          text={t("signup_button_text")}
          type="submit"
          Loading={Loading}
        /> */}
          <button
            type="submit"
            className="w-full my-4 flex justify-center items-center p-2 bg-primaryBlue text-md text-white rounded-lg font-semibold mt-4 relative"
            // onClick={onClick}
            disabled={Loading}
          >
            {" "}
            {t("signup_button_text")}{" "}
            {Loading && (
              <>
              <div className="ms-4" > 
                <LoaderComponent />
                </div>
              </>
            )}
          </button>
        </div>
      </form>
      {/* <div className=" width-500 flex justify-center mt-9">
      {showNotification && (
        
         <Notification  duration={2000} message={"User already  exist with this phone number"} onClose={()=>setShowNotification(false)}/>
      
       )}
        </div> */}

      {/* {Loading &&(<LoaderComponent/>)} */}

      {showOTPModal && (
        <>
          <OTPModal
            responseData={responseData}
            isOpen={showOTPModal}
            numberChange={handleNumberChange}
            onClose={() => setShowOTPModal(false)}
            // handlecancel={() => setShowOTPModal(false)}
            payload={() => {
              setShowOTPModal(false);
            }}
          />
        </>
      )}
    </div>
  );
};

export default SignupForm;
