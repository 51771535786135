import React from "react";
import { Image } from "antd";
import LogoWhite from "../../assets/Logo/logoWhite.svg";

// importing icons for register
import HomeActive from "../../assets/Register/homeActive.svg";
import Business from "../../assets/Register/business.svg";
import BusinessActive from "../../assets/Register/businessActive.svg";
import Detail from "../../assets/Register/detail.svg";
import DetailActive from "../../assets/Register/detailActive.svg";
import Profile from "../../assets/Register/profile.svg";
import ProfileActive from "../../assets/Register/profileActive.svg";
import "../../styles.css";

const Card = ({ name, icon, isActive }) => {
  return (
    <div
      className={`flex flex-row items-center justify-center w-[95%] p-2 h-14 ps-4 rounded-md ${
        isActive
          ? "bg-white text-primaryBlue"
          : "bg-transparencyBlue text-transparencyWhite"
      }`}
    >
      <div
        className={`me-3 ms-6 bg-primaryBlue p-2 rounded-full ${
          isActive ? "bg-primaryBlue" : "bg-transparentlightBlue"
        }`}
      >
        <img
          src={icon}
          alt={name}
          width={isActive ? 20 : 25}
          height={isActive ? 20 : 25}
        />
      </div>{" "}
      <div
        className={`text-lg justify-center items-center w-40 ${
          isActive ? "font-semibold" : "font-normal"
        }`}
      >
        {name}
      </div>
    </div>
  );
};

function RegistrationSideBar({ currentStep }) {
  return (
    <div className="flex flex-col items-start  custom-scrollbar">
      <div className="mx-14 mt-8">
        <img src={LogoWhite} width={100} height={60} alt="myGINNE Logo" />
      </div>
      <div className="flex flex-col items-start mx-auto mt-8">
        <Card
          name="Shop Location"
          icon={HomeActive}
          isActive={currentStep >= 0}
        />
        <div
          className={`ms-[21%] ${
            currentStep >= 1 ? "bg-primaryBlue" : "bg-white"
          } h-8 w-[2px] border-dashed border-2 border-[#76CFEE]`}
        ></div>
        <Card
          name="Basic Details"
          icon={currentStep >= 1 ? DetailActive : Detail}
          isActive={currentStep >= 1}
        />
        <div
          className={`ms-[21%] ${
            currentStep >= 2 ? "bg-primaryBlue" : "bg-white"
          } h-8 w-[2px] border-dashed border-2 border-[#76CFEE]`}
        ></div>
        {/* <div
          className={`ms-[22%] ${
            currentStep >= 2 ? "bg-primaryBlue" : "bg-white"
          } h-10 w-[2px]`}
        ></div> */}
        <Card
          name="Business Proof"
          icon={currentStep >= 2 ? BusinessActive : Business}
          isActive={currentStep >= 2}
        />
        <div
          className={`ms-[21%] ${
            currentStep >= 3 ? "bg-primaryBlue" : "bg-white"
          } h-8 w-[2px] border-dashed border-2 border-[#76CFEE]`}
        ></div>
        <Card
          name="Profile Creation"
          icon={currentStep >= 3 ? ProfileActive : Profile}
          isActive={currentStep >= 3}
        />
      </div>
    </div>
  );
}

export default RegistrationSideBar;
