import React from "react";

import {Image}  from "antd";
import checked from "../assets/checked.svg";
import { routes } from "../Routes";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    height:"300px",
    padding: "20px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  title: {
    fontSize: "1.6rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "10px",
    marginTop: "10px",
  },
  image: {
    width: "70px",
    display: "block",
    margin: "10px auto",
    marginBottom: "10px",
  },
  description: {
    textAlign: "center",
    fontSize: "0.875rem",
    color: "#5C5C5C",
    marginBottom: "20px",
  },
};

const CompleteProfile = ({ onClose, message }) => {
  const {t,i18n}=useTranslation()
  const navigate=useNavigate();

  const handleGoToDashboards = () => {
    navigate(routes.login)
    onClose();
  };

  return (
    <div>
      <div style={styles.overlay} onClick={onClose}></div>
      <div style={styles.container}>
        
        <img
          src={checked} // Provide the path to your checked image
          alt="Checked"
          style={styles.image}
        />
        <p style={styles.title}>{t('completed')}</p>
        <p style={styles.description}>
          {message == 1 ?t('registrationcomplete_note'):t('uploadcomplete_note')}
        </p>
        <div className="flex justify-center gap-8 mt-10 mb-7 ">
          <button
            type="button"
            className="w-[180px] h-[45px] bg-primaryBlue text-white rounded-lg font-semibold"
            onClick={handleGoToDashboards}
          >
            {t('gotto_login')}
          </button>
          {message == 1 && (
            <button
              type="button"
              className="w-[180px] h-[45px] bg-primaryBlue text-white rounded-lg font-semibold"
              onClick={() => {
                onClose();
              }}
            >
              {t('upload_documents')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
