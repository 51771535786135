import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { InputBox, Password } from "../../components/CustomWidgets/InputBox";
import "react-phone-number-input/style.css";
import SelectBox from "../CustomWidgets/SelectBox";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import UpdatedProfile from "../../modals/UpdatedProfile";
import { Image } from "antd";
import FlagIcon from "../../assets/egypt.svg";
import { Button, Image as Img, Input } from "antd";
import UploadMediaonly from "../upload-imageonly";
import "../../styles.css";
import { useNavigate } from "react-router";
import image from "../../assets/Images/roundimage.png";
import { store } from "../../Redux/store";
import { useTranslation } from "react-i18next";

const defaultImage = "roundimage.png";

const BasicDetails = ({ payload, onDataSubmit, profiledetail }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [data, setdata] = useState("");
  const [showUpdatedProfile, setShowUpdatedProfile] = useState(false); // State to control the visibility of UpdatedProfile modal
  const [restricted, setrestricted] = useState(true);

  const getbasicdetaildata = () => {
    try {
      axiosInstance
        .post(URLS.GET_STORE_BASIC_DETAIL, payload)
        .then((response) => {
          if (response?.message == "Invalid Token") {
            localStorage.clear();
            navigate("/");
          }
          setdata(response?.data?.basic_details?.[0]);
          if (
            response?.data?.basic_details?.[0]?.retailername ||
            response?.data?.basic_details?.[0]?.sotre_profile_url
          ) {
            const profiledata = {
              name: response?.data?.basic_details?.[0]?.retailername
                ? response?.data?.basic_details?.[0]?.retailername
                : undefined,
              image: response?.data?.basic_details?.[0]?.sotre_profile_url
                ? response?.data?.basic_details?.[0]?.sotre_profile_url
                : "",
            };
            profiledetail(profiledata);
          }

          // ownerName(response?.data?.basic_details?.[0]?.retailername);
          //  if(response?.data?.basic_details?.[0]?.sotre_profile_url) profilephoto(response?.data?.basic_details?.[0]?.sotre_profile_url)
          formik.setValues({
            // Use formik.setValues to update form values
            user_id: payload.user_id || "",
            store_id: payload.store_id || "",
            businessProfile: response?.data?.basic_details?.[0]?.profile || "", // Provide default values or empty string if data is null
            business_name: response?.data?.basic_details?.[0]?.storename || "",
            business_name_ar:
              response?.data?.basic_details?.[0]?.storename_ar || "",
            owner_name: response?.data?.basic_details?.[0]?.retailername || "",
            owner_name_ar:
              response?.data?.basic_details?.[0]?.retailername_ar || "",
            phoneNumber:
              response?.data?.basic_details?.[0]?.retailermobile || "",
            email: response?.data?.basic_details?.[0]?.emailid || "",
            // password: response?.data?.basic_details?.[0]?.password || "",
            image_url:
              response?.data?.basic_details?.[0]?.sotre_profile_url || "",
            shopType: response?.data?.basic_details?.[0]?.shop_type || "",

            bdo_name: response?.data?.basic_details?.[0]?.bdo_name || "",
            bdo_phone: response?.data?.basic_details?.[0]?.bdo_phone || "",
          });
          setrestricted(true);
          localStorage.setItem(
            "bdo_name",
            response?.data?.basic_details?.[0]?.bdo_name
          );
          localStorage.setItem(
            "bdo_phone",
            response?.data?.basic_details?.[0]?.bdo_phone
          );
        });
    } catch (error) {}
  };
  useEffect(() => {
    getbasicdetaildata();
  }, []);

  const [file, setFile] = useState(defaultImage);

  const handleurlgenerated = (values) => {
    // console.log("values", values);
    setrestricted(false);
    formik.setFieldValue("image_url", values.url);
  };

  const formik = useFormik({
    initialValues: {
      businessProfile: data?.profile,
      business_name: data?.storename,
      business_name_ar: data?.storename_ar,
      owner_name_ar: data?.retailername_ar,
      owner_name: data?.retailername,
      phoneNumber: data?.retailermobile,
      email: data?.email,
      user_id: payload.user_id,
      store_id: payload.store_id,
      image_url: "",
      shopType: "",
      bdo_name: "",
      bdo_phone: "",
    },
    onSubmit: async (values) => {
      // Using async function for handling asynchronous axios call
      try {
        const apipayload = {
          user_id: payload?.user_id,
          store_id: payload?.store_id,
          business_name: values.business_name,
          business_name_ar: values?.business_name_ar,
          owner_name_ar: values?.owner_name_ar,
          owner_name: values.owner_name,
          email: values.email,
          image_url: values.image_url,
        };
        // console.log("apipayload", apipayload);
        const response = await axiosInstance.post(
          URLS.SET_STORE_BASIC_DETAIL,
          apipayload
        ); // Wait for the response

        if (response?.data.status === 200) {
          setShowUpdatedProfile(true); // Show UpdatedProfile modal
          setrestricted(true);
        }
      } catch (error) {}
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className=" flex items-center gap-4 mx-11 my-6">
          {formik.values?.image_url != "" ? (
            <Img
              className="h-16 w-16 object-cover rounded-full"
              src={formik.values?.image_url}
              width={60}
              height={60}
              alt="current profile photo"
            />
          ) : (
            <img
              className="h-14 w-14 object-cover rounded-full"
              src={image}
              width={50}
              height={50}
              alt="current profile photo"
            />
          )}
          <label
            id="fileinputlabel"
            for="fileinput"
            className="cursor-pointer text-primaryBlue text-center text-base"
          >
            <UploadMediaonly
              foldername={"store_app_UAT"}
              onfileAdd={"userphoto"} // Adjust field name as needed
              urlgenerated={handleurlgenerated}
              imageurl={formik.values.image_url}
            />
          </label>
        </div>
        <div className="font-normal text-sm text-[#5c5c5c]  flex mx-8  mt-5">
          <div className=" w-1/2 px-3"> 
          <div className=" md:w-full leading-[0px] my-4">
            <label>{t("business_name")}</label>

            <InputBox
              name="business_name"
              placeholder="Store Name"
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.business_name}
              error={formik.errors.business_name}
              touched={formik.touched.business_name}
            />
          </div>
          <div className=" md:w-full leading-[0px] my-4">
            <label>{t("business_name_ar")}</label>

            <InputBox
              name="business_name_ar"
              placeholder="Store Name in Arabic"
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.business_name_ar}
              error={formik.errors.business_name_ar}
              touched={formik.touched.business_name_ar}
            />
          </div>
          <div className=" md:w-full leading-[0px] my-4 ">
            <label>{t("business_owner_name")}</label>

            <InputBox
              name="owner_name"
              placeholder="Business Owner Name"
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.owner_name}
              error={formik.errors.owner_name}
              touched={formik.touched.owner_name}
            />
          </div>

          <div className=" md:w-full leading-[0px] my-4 ">
            <label>{t("business_owner_name_ar")}</label>

            <InputBox
              name="owner_name_ar"
              placeholder="Business Owner Name in Arabic"
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.owner_name_ar}
              error={formik.errors.owner_name_ar}
              touched={formik.touched.owner_name_ar}
            />
          </div>
          </div>
          <div className=" w-1/2 px-3"> 
          <div className=" md:w-full leading-[0px] my-4">
            <label>{t("business_type")}</label>
            <Input
              defaultValue="Select profile type"
              name="businessProfile"
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              value={formik.values.businessProfile}
              error={formik.errors.businessProfile}
              touched={formik.touched.businessProfile}
              className={` mt-4 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border  ${
                formik.touched.businessProfile && formik.errors.businessProfile
                  ? "border-red" // Apply red border when touched or there's an error
                  : ""
              }`}
              disabled
            />
            {/* <SelectBox
              className="text-[#929EAE]"
              defaultValue="Select profile type"
              name="businessProfile"
              onChange={formik.handleChange}
              options={[
                { value: "Retailer", label: "Retailer" },
                { value: "Wholesaler", label: "Wholesaler" },
              ]}
              onBlur={formik.handleBlur}
              value={formik.values.businessProfile}
              error={formik.errors.businessProfile}
              touched={formik.touched.businessProfile}
              disabled
            /> */}
          </div>
          <div className=" md:w-full leading-[0px] my-4 ">
            <label>{t("shop_type")}</label>
            <Input
              defaultValue="Select ShopType"
              name="shopType"
              onChange={formik.handleChange}
              value={formik.values.shopType}
              error={formik.errors.shopType}
              touched={formik.touched.shopType}
              className={` mt-4 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border  ${
                formik.touched.shopType && formik.errors.shopType
                  ? "border-red" // Apply red border when touched or there's an error
                  : ""
              }`}
              disabled
            />
            {/* <SelectBox
              className="text-[#929EAE]"
              defaultValue="Select ShopType"
              name="shopType"
              onChange={formik.handleChange}
              options={[
                { value: "Retailer", label: "Retailer" },
                { value: "Wholesaler", label: "Wholesaler" },
              ]}
              onBlur={formik.handleBlur}
              value={formik.values.shopType}
              error={formik.errors.shopType}
              touched={formik.touched.shopType}
              disabled
            /> */}
          </div>
          <div className=" md:w-full leading-[0px] my-4  ">
            <label className=" ">{t("phone_number")}</label>

            <div className="flex items-center">
              <div className="h-10 mt-4 w-16 text-md text-body outline-none focus:outline-none  rounded-lg border p-3 flex items-center justify-center bg-[#EBEBEB]">
                <img src={FlagIcon} alt="img" />
              </div>
              <Input
                international
                defaultCountry="EG"
                value={"0" + formik.values.phoneNumber}
                onChange={(phone) => formik.setFieldValue("phoneNumber", phone)}
                onBlur={formik.handleBlur}
                name="phoneNumber"
                disabled
                className={`ml-2 mt-4 h-10 text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-lg border p-3 ${
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                    ? "border-red" // Apply red border when touched or there's an error
                    : ""
                }`}
              />
            </div>

            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="font-medium italic text-red">
                {formik.errors.phoneNumber}
              </div>
            ) : null}
          </div>
          <div className=" md:w-full leading-[0px] my-4 ">
            <label>{t("email")}</label>

            <Input
              name="email"
              placeholder={t("enter_email")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.errors.email}
              touched={formik.touched.email}
              className={` mt-4 p-3 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border  ${
                formik.touched.businessProfile && formik.errors.businessProfile
                  ? "border-red" // Apply red border when touched or there's an error
                  : ""
              }`}
              disabled
            />
          </div>
          </div>
        </div>

        {/* <div className="font-normal text-sm text-[#5c5c5c] grid grid-col-2 grid-flow-col  gap-5 gap-x-9 mx-3 ml-8 mt-7">
          <div className="w-[300px] md:w-full leading-[0px] my-4 ">
            {" "}
            <label>BDO Assigned:</label>
            <Input
              defaultValue="Basheer"
              name="bdo_name"
              onChange={formik.handleChange}
              value={formik.values.bdo_name}
              error={formik.errors.bdo_name}
              touched={formik.touched.bdo_name}
              className={` mt-3 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border  ${
                formik.touched.bdo_name && formik.errors.bdo_name
                  ? "border-red" // Apply red border when touched or there's an error
                  : ""
              }`}
              disabled
            />
            
          </div>
          <div className="w-[300px] md:w-full leading-[0px] my-4 ">
            {" "}
            <label>BDO Contact:</label>
            <Input
              defaultValue="12xxxxxx68"
              name="bdo_phone"
              onChange={formik.handleChange}
              value={formik.values.bdo_phone}
              error={formik.errors.bdo_phone}
              touched={formik.touched.bdo_phone}
              className={` mt-3 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border  ${
                formik.touched.bdo_phone && formik.errors.bdo_phone
                  ? "border-red" // Apply red border when touched or there's an error
                  : ""
              }`}
              disabled
            />
           
          </div> 


        </div>*/}
        <div className="flex gap-5 mx-11 mt-8  mb-10 ">
          <button
            type="submit"
            className={`w-[180px] h-[45px] ${
              restricted ? "bg-primaryBluedisabled" : "bg-primaryBlue"
            } text-white rounded-lg font-semibold`}
            disabled={restricted}
          >
            {t("update_and_save")}
          </button>

          <Button
            // type="submit"
            className="w-[180px] h-[45px] text-primaryBlue font-semibold rounded-lg  border-primaryBlue"
            onClick={getbasicdetaildata}
          >
            {t("cancel")}
          </Button>
        </div>
      </form>

      {/* Show UpdatedProfile modal when showUpdatedProfile state is true */}
      {showUpdatedProfile && (
        <UpdatedProfile
          onClose={() => {
            setShowUpdatedProfile(false);
            window.location.reload();
          }}
          message={"Basic Details"}
        />
      )}
    </>
  );
};

export default BasicDetails;
