import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "tailwindcss/tailwind.css";
import CustomPagination from "../CustomWidgets/CustomPagination";
import { Empty } from "antd";
import { getDeliveryPartnerOrders } from "../../API  Functions/DeliveryPartnerFunctions";
import Vectorsorting from "../../assets/Vectorsorting.svg";
import arrowright from "../../assets/arrowright.svg"
import {Image}  from "antd";
import "../../styles.css"
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import ShimmerLoader from "../../loading";
import { useTranslation } from "react-i18next";

const Deliverypartnersorderstable = ({ deliverypartnerid }) => {
  const {t,i18n}=useTranslation()
  const navigate=useNavigate()
  const user_id = useSelector(state=>state.login?.user?.user_id)
  const store_id = useSelector(state=>state.login?.user?.store_id)
   const [flag, setflag] = useState(false);



  
  const [data,setdata]=useState([])
  useEffect(() => {
    if (data?.length === 0 && !flag) {
      const timer = setTimeout(() => {
        setflag(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [data, flag]);
 


  const getDeliveryBoyOrders =()=>{
    getDeliveryPartnerOrders(user_id,store_id,deliverypartnerid,pageSize,pageSize*(pageIndex-1)>0?(pageSize*(pageIndex-1)):0).then((res)=>res?.message==="Success" && setdata(res.data))
  }

  const openOrderList = (value) => {
    const senddata = [
      // user_id,
      // store_id,
      // value.substring(0, 10),
      // value.substring(12),
      value
    ];
    console.log("sending value", value);
  navigate(`/Orderlist?orderDetail=${senddata}`)
  };

  // useEffect(() => {
  //   deliverypartnerid && getDeliveryBoyOrders()
  // }, [deliverypartnerid])
  
  const columns = useMemo(
    () => [
      { Header: t('order_no'), accessor: "order_number",Cell: ({ value }) => (
        <>
          {/* {(value.substring(value.lastIndexOf(",") + 1) == "Order Pending" ||
            value.substring(value.lastIndexOf(",") + 1) == "In Processing" ||
            value.substring(value.lastIndexOf(",") + 1) ==
              "Ready For Delivery") && ( */}
          <button
            // className="bg-[#7EC242] text-white w-28 h-10 rounded-lg"
            className=" text-[#1b4497] rounded-lg"
            onClick={() => openOrderList("ordernamehar"+value+",")}
          >
           {value}
          </button>
          {/* )
          } */}
        </>
      ), },
      { Header:t('customer_name'), accessor: "customer_details.name" },
      { Header: t('delivery_schedule'), accessor: "delivery_time" },
      { Header: t('items'), accessor: "order_line_count" },
      { Header: t('order_value'), accessor: "order_total_amt" },
      {Header: t('payment'), accessor: "payment_mode" },
      {
        Header: t("order_status"),
        accessor: "order_stage.name",
        Cell: ({ value }) => {
          let backgroundColor,
            textColor,
            borderRadius,
            paddingTop,
            paddingBottom,
            paddingLeft,
            paddingRight;

          // Conditionally set styles based on order status
          if (value === "Order Pending") {
            backgroundColor = "#999999";
            // textColor = "#3d85c6";
            // borderRadius = "5px"; // Full border radius
          } else if (value === "In Processing") {
            backgroundColor = "#f1c232  ";
            // textColor = "#5c5c57";
            // borderRadius = "5px"; // Full border radius
          } else if (value === "Ready For Delivery") {
            backgroundColor = "#93c47d";
            // textColor = "#00B833";
            // borderRadius = "5px"; // Full border radius
          } else if (value === "On the way") {
            backgroundColor = "#f1c232";
            // textColor = "#E8A700";
            // borderRadius = "5px"; // Full border radius
          } else if (value === "Order Delivered") {
            backgroundColor = "#1b4497";
            // textColor = "#8d5bf1";
            // borderRadius = "5px"; // Full border radius
          } else {
            // Default styles for other statuses
            backgroundColor = "#E74C3C"; // Set default background color
            // textColor = "#A70D2A"; // Set default text color
            // Set default border radius
          }

          // Padding values for top, bottom, left, right
          textColor = "#ffffff";
          paddingTop = "8px";
          paddingBottom = "8px";
          paddingLeft = "1px";
          paddingRight = "1px";
          borderRadius = "10px";

          return (
            <h4
              style={{
                backgroundColor,
                color: textColor,
                borderRadius,
                padding: `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`,
                fontWeight: 600,
                display: "inline-block",
                minWidth: "90px",
                alignItems: "center",
              }}
            >
              {(() => {
                switch (value) {
                  case "Order Pending":
                    return "Pending";
                  case "In Processing":
                    return "Processing";
                  case "Ready For Delivery":
                    return "Ready";
                  case "On the way":
                    return "Out";
                  case "Order Delivered":
                    return "Delivered";

                  default:
                    return "Cancelled";
                }
              })()}
            </h4>
          );
        },
      },
      {
        id: "action",
        // Header: "Action",
        accessor: (row) =>
                      `${row.order_number}`,
        Cell: ({ value }) => (
          <>
             <button
                // className="bg-[#7EC242] text-white w-28 h-10 rounded-lg"
                className=" text-primaryBlue rounded-lg"
                onClick={() => openOrderList(value)}
              >
                <img src={arrowright} alt="arrowright" className={`${i18n.language=="en"?"":" rotate-180"}`} height={3} width={20}/>
                {/* Open Order {">>"} */}
              </button>
          </>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );
  const [pageIndex, setpageIndex] = useState(1);
  useEffect(() => {
    console.log("useEffect", pageIndex, pageSize, previousPage, nextPage);
    const pageDetails = {
      limit: pageSize,
      offset: pageSize * (pageIndex - 1) < 0 ? 0 : pageSize * (pageIndex - 1),
    };
     getDeliveryBoyOrders();
  }, [pageIndex, pageSize]);

  return (
    <div className="bg-[#F6F7F9] flex items-center justify-center">
      {data?.length == 0 && flag == false ? (
          <>
            <ShimmerLoader />{" "}
            {/* {setTimeout(() => {
              setflag(true);
            }, 3000)} */}
          </>
        ) : (
      <table
        {...getTableProps()}
        className="table-auto mt-4 font-normal w-[1100px] sm:w-[99.8%] md:w-[99.8%] lg:w-[99.8%] xl:w-[99.8%] shadow-xl rounded-lg overflow-hidden"
      >
        <thead>
        {headerGroups.map((headerGroup, headerIndex) => {
              const { key: headerKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr
                  key={headerKey}
                  {...headerGroupProps}
                  className="bg-[#78CFEC66] text-sm font-semibold"
                >
                  {headerGroup.headers.map((column, colIndex) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        key={columnKey}
                        {...columnProps}
                        className="py-3 px-2 whitespace-nowrap "
                      >
                        <div className="flex items-center justify-center">
                          {column.render("Header")}
                          <span className="">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img src={Vectorsorting} style={{minHeight:10,minWidth:10}} />
                              ) : (
                                <img
                                  className="rotate-180"
                                  src={Vectorsorting}
                                  style={{minHeight:10,minWidth:10}}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-[#F7F9FD] text-center">
          {page?.length === 0 ? (
            <tr>
              <td colSpan={columns?.length} className="py-4">
                <Empty description="No data available" />
              </td>
            </tr>
          ) : (
            page?.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="relative hover:bg-white transition duration-300 border-b border-gray-300 fixed-row-height "
                >
                  {row.cells.map((cell, index) => (
                    <td
                      {...cell.getCellProps()}
                      className={`p-2 text-[#272728] text-sm font-normal ${
                        index < row.cells.length - 1 ? "relative" : ""
                      }`}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
          {/* page.length !== 0 && */}
          { (
            <tr>
              <td className="" colSpan={columns.length}>
              <CustomPagination
                pageIndex={pageIndex}
                pageCount={page?.length}
                canPreviousPage={pageIndex != 1 ? true : false}
                canNextPage={data?.length >= 10 ? true : false}
                gotoPage={(page) => {
                  nextPage(page);
                }}
                previousPage={() => {
                  setpageIndex(pageIndex - 1);
                }}
                nextPage={() => {
                  setpageIndex(pageIndex + 1);
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                  // nextPage(0);
                  setPageSize(pageSize);
                }}
              />
              </td>
            </tr>
          )}
        </tbody>
      </table>)}
    </div>
  );
};

export default Deliverypartnersorderstable;