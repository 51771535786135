import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import { Input } from "antd";
import "../../styles.css";
import { useNavigate } from "react-router";
import { store } from "../../Redux/store";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const BDODetails = () => {
  const {t,i18n}=useTranslation();
  const navigate=useNavigate()
  const user_id = useSelector(state=>state.login?.user?.user_id)
  const store_id = useSelector(state=>state.login?.user?.store_id)

  const [bdo_name, setbdo_name] = useState("User");
  const [bdo_phone, setbdo_phone] = useState("12xxxxxx09");
  const getBDODetail = async () => {
    try {
      const response = await axiosInstance.post(URLS.GET_BDO_DETAILS, {
        user_id:user_id,
        store_id:store_id,
      });

      if (response?.message == "Invalid Token") {
        localStorage.clear();
        navigate("/");
      }
      setbdo_name(response?.data?.bdo_name || "User")
      
        setbdo_phone(
          response?.data?.bdo_phone || "12xxxxxx09"
        );
    } catch (error) {}
  };

  useEffect(() => {
    getBDODetail();
  }, []);

  return (
    <>
      <form>
        {/* <div className="font-medium text-xl mx-3 mt-12">BDO Details</div> */}

        <div className="font-normal text-sm text-[#5c5c5c] !bg-[#ffffff] p-3 rounded-lg mx-3  flex justify-between  gap-4 gap-x-9   mt-7">
          <div className="w-[363px] md:w-full leading-[0px] mt-2">
            <label>{t('bdo_assigned')}</label>
            <Input
              defaultValue="Basheer"
              name="bdo_name"
              className={` mt-3 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border`}
              value={bdo_name}
              readOnly

              //disabled
            />
          </div>

          <div className="w-[363px] md:w-full mt-2 leading-[0px]">
            <label>{t('bdo_contact')}</label>
            <Input
              defaultValue="12xxxxxx68"
              name="bdo_phone"
              className={` mt-3 h-10 text-[#929EAE] text-md text-body outline-none focus:outline-none ltr:pl-4 rtl:pr-5 rounded-lg border`}
              value={bdo_phone?.substring(1, 12)}
              readOnly
              //   disabled
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default BDODetails;
