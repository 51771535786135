import { URLS } from "../API/API endpoints";
import axiosInstance from "../AxiosInstance";
import { isInteger } from "formik";
import { store } from "../Redux/store";

export const getUOM = async () => {
  try {
    const response = await axiosInstance.get(URLS.GET_UOM);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getInventoryallcategories = async (user_id, store_id) => {
  try {
    const payload = {
      user_id: user_id,
      store_id: store_id,
    };
    const response = await axiosInstance.post(
      URLS.GET_RETAILER_PRODUCT_CATEGORY,
      payload
    );

    // console.log("responsse data", response.data);

    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getInventoryallproducts = async (
  user_id,
  store_id,
  limit = 10,
  offset = 0
) => {
  try {
    const payload = {
      user_id: user_id,
      store_id: store_id,
      limit: limit,
      offset: offset,
    };
    const response = await axiosInstance.post(URLS.GET_ALL_PRODUCTS, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getInventoryincompleteproducts = async (
  user_id,
  store_id,
  limit = 10,
  offset = 0
) => {
  try {
    const payload = {
      user_id: user_id,
      store_id: store_id,
      limit: limit,
      offset: offset,
    };
    const response = await axiosInstance.post(
      URLS.GET_INCOMPLETE_PRODUCTS,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// export const getInventoryallproducts = async (limit = 10, offset = 0) => {
//
// try {
//       const payload = {
//         user_id:login.user?.userDetails?.user_id,
//        store_id:login.user?.RetailerDetails?.[0]?.id,
//         limit: limit,
//         offset: offset,
//       };
//       const response = await axiosInstance.post(
//         URLS.GET_ALL_PRODUCTS,
//         payload
//       );
//       ;

//       return response.data;
//     } catch (error) {
//       return error;
//     }
//   };

export const InventorySearch = async (
  user_id,
  store_id,
  value,
  limit = 10,
  offset = 0
) => {
  try {
    // user_id:login.user?.userDetails?.user_id,
    const payload = {
      store_id: store_id,
      query: value ? value : "",
      limit: limit,
      offset: offset,
    };
    const response = await axiosInstance.post(URLS.SEARCH_INVENTORY, payload);

    return response.data;
  } catch (error) {
    return error;
  }
};
export const StockoutSearch = async (
  user_id,
  store_id,
  value,
  limit = 10,
  offset = 0
) => {
  try {
    // user_id:login.user?.userDetails?.user_id,
    const payload = {
      store_id: store_id,
      query: value ? value : "",
      limit: limit,
      offset: offset,
    };
    const response = await axiosInstance.post(
      URLS.STOCKOUT_SEARCH_INVENTORY,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const HighonInventorySearch = async (
  user_id,
  store_id,
  value,
  limit = 10,
  offset = 0
) => {
  try {
    // user_id:login.user?.userDetails?.user_id,
    const payload = {
      store_id: store_id,
      query: value ? value : "",
      limit: limit,
      offset: offset,
    };
    const response = await axiosInstance.post(
      URLS.HIGHON_SEARCH_INVENTORY,
      payload
    );

    return response.data;
  } catch (error) {
    return error;
  }
};
export const LowonInventorySearch = async (
  user_id,
  store_id,
  value,
  limit = 10,
  offset = 0
) => {
  try {
    // user_id:login.user?.userDetails?.user_id,
    const payload = {
      store_id: store_id,
      query: value ? value : "",
      limit: limit,
      offset: offset,
    };
    const response = await axiosInstance.post(
      URLS.LOWON_SEARCH_INVENTORY,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const InventorySearchonboard = async (
  user_id,
  store_id,
  name,
  barcode = ""
) => {
  try {
    if (isInteger(name)) {
      barcode = name;
      name = "";
    }
    // user_id:login.user?.userDetails?.user_id,
    const payload = {
      user_id: user_id,
      store_id: store_id,
      name: name,
      barcode: barcode,

      // query: value ? value : "",
      // limit: limit,
      // offset: offset,
    };
    const response = await axiosInstance.post(
      URLS.SEARCH_INVENTORY_ONBOARD,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const InventoryProductRequest = async (user_id, store_id, values) => {
  try {
    // user_id:login.user?.userDetails?.user_id,
    const payload = {
      user_id: user_id,
      store_id: store_id,

      product_name: values?.name,
      barcode: values?.barcode,
      sale_price: values?.saleprice,
      cost_price: values?.costprice,
      qty: values?.quantity,
      uom: values?.product_uom,
      image_url: values?.image_url,
      is_gs1:values?.is_gs1

    };
    const response = await axiosInstance.post(URLS.PRODUCT_REQUEST, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const InventoryCategoryFilter = async (
  user_id,
  store_id,
  category = [],
  subcategory = [],
  query = "",
  report_for = 1,
  limit = 10,
  offset = 0
) => {
  try {
    // user_id:login.user?.userDetails?.user_id,
    const payload = {
      user_id: user_id,
      store_id: store_id,
      category_id: category,
      sub_category_id: subcategory,
      query: query,
      request_for: report_for,
      limit: limit,
      offset: offset,
    };

    const response = await axiosInstance.post(URLS.CATEGORY_FILTER, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const InventoryAddproduct = async (user_id, store_id, values) => {
  try {
    // user_id:login.user?.userDetails?.user_id,

    const payload = {
      user_id: user_id,
      store_id: store_id,
      product_id: values?.product_id,
      // barcode:values?.barcode,
      cost_price: values?.cost_price,
      sale_price: values?.sale_price,
      qty: values?.qty,
    };

    const response = await axiosInstance.post(URLS.ADD_PRODUCT, payload);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const InventoryUpdateDetails = async (
  user_id,
  store_id,
  product_ids
) => {
  try {
    // user_id:login.user?.userDetails?.user_id,

    const payload = {
      user_id: user_id,
      store_id: store_id,
      product_ids: product_ids,
    };

    const response = await axiosInstance.post(
      URLS.UPDATE_STORE_PRODUCT_DETAIL,
      payload
    );

    // console.log("Update Product Data", response);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const GenerateExcelReport = async (user_id, store_id, val) => {
  try {
    // user_id:login.user?.userDetails?.user_id,

    const payload = {
      user_id: user_id,
      store_id: store_id,
      report_for: val,
    };

    const response = await axiosInstance.post(URLS.INVENTORY_REPORT, payload, {
      responseType: "blob", // Set responseType to 'blob' to receive binary data
    });

    // Create a blob object from the response data
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a downloadable URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a temporary <a> element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = "inventory_report.xlsx"; // Set the desired file name

    // Append the <a> element to the DOM and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the <a> element and revoking the URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    return response;
  } catch (error) {
    return error;
  }
};

export const getProductlog = async (user_id, store_id, product_id) => {
  try {
    const payload = {
      user_id: user_id,
      store_id: store_id,
      product_id: product_id,
    };
    const response = await axiosInstance.post(
      URLS.GET_INVENTORY_PRODUCT_LOGS,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchGS1 = async (user_id, barcode) => {
  try {
    const payload = {
      user_id: user_id,
      gtin: barcode,
    };
    const response = await axiosInstance.post(URLS.FETCH_GS1_DATA, payload);
    return response?.data;
  } catch (error) {
    return error;
  }
};
