import { createSlice } from "@reduxjs/toolkit";
import { fetchDeliveryAreas } from "../Actions/deliveryAreasActions";

const deliveryAreasSlice = createSlice({
  name: "deliveryAreas",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    // Add your reducers here
    // Example:
    updateData: (state, action) => {
      state.data = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeliveryAreas?.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDeliveryAreas?.fulfilled, (state, action) => {
        //console.log("API Request:", action.payload);
        state.data = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchDeliveryAreas?.rejected, (state, action) => {
        state.data = [];
        state.isLoading = false;
        state.error = action.error.message || "Failed to fetch delivery areas";
      });
  },
});

export const { updateData, setError, clearError } = deliveryAreasSlice.actions;

export default deliveryAreasSlice.reducer;
