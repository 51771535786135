import { createAsyncThunk } from "@reduxjs/toolkit";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";

export const recreateUser = createAsyncThunk(
  "forgot-password/recreateUser",
  async (payload, { rejectWithValue }) => {
    try {
      console.log("Payload", payload);
      const response = await axiosInstance.post(URLS.SIGNUP_OTP_API_URL, payload, {});
     

      if (response.status >= 200 && response.status < 300) {
      } else {
        throw new Error("API request failed");
      }
    } catch (error) {
      console.error("API request error:", error);
      return rejectWithValue(error.message);
    }
  }
);
