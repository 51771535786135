import React from "react";
import Deliverypartners from "../../components/EordersDeliveryPartners/Deliverypartners";
import Sidebar from "../../components/Orders/sidebar";
import Navbar from "../../components/Orders/Navbar";


const DeliveryPartner = () => {
  return (
    <div className="flex">
      {/* <Sidebar/> */}
      <div className="flex flex-col flex-1  mt-14">
        <Navbar heading="delivery_boy" />
        <Deliverypartners  />
       
      </div>
    </div>
  );
};

export default DeliveryPartner;
