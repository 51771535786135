import React, { useEffect, useState } from "react";
import Navbar from "../../components/Orders/Navbar";
import { t } from "i18next";
import { getMySubscription, getSubscriptionPlans } from "../../API  Functions/Subscription";
import { useSelector } from "react-redux";

const Mysubscription = () => {
  const store_id = useSelector(state => state.login?.user?.store_id);
  const store_name = useSelector(state => state.login?.user?.store_name);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const subscriptionData = await getMySubscription(store_id);
      setData(subscriptionData || []);
    };

    fetchData();
    getSubscriptionPlans();
  }, [store_id]);

  const activeSubscription = {
    shopName: store_name,
    frequency: data?.[0]?.frequency,
    StartDate: data?.[0]?.start_date?.replace(/-/g, "/"),
    Rate: data?.[0]?.rate,
    FreetrialDate: data?.[0]?.end_date?.replace(/-/g, "/"),
    Status: data?.[0]?.isactive ? "Active" : "Expired",
  };

  const history = [
    {
      shopName: "Nakul Test Shop",
      Plan: "Sub-Myginne-Plan",
      StartDate: "2024-05-01",
      DueDate: "2024-06-12",
      InvoiceDate: "2024-04-30 06:03:57",
      Amount: "200",
      InvoiceNo: "IN01336247554",
      Paymentrefno: "PN018570661",
      Status: "PAID",
      Paymentmode: "Cash",
    },
    {
      shopName: "Nakul Test Shop",
      Plan: "Sub-Myginne-Plan",
      StartDate: "2024-05-01",
      DueDate: "2024-06-12",
      InvoiceDate: "2024-04-30 06:03:57",
      Amount: "200",
      InvoiceNo: "IN01336247554",
      Paymentrefno: "PN018570661",
      Status: "PAID",
      Paymentmode: "Cash",
    },
    {
      shopName: "Nakul Test Shop",
      Plan: "Sub-Myginne-Plan",
      StartDate: "2024-05-01",
      DueDate: "2024-06-12",
      InvoiceDate: "2024-04-30 06:03:57",
      Amount: "200",
      InvoiceNo: "IN01336247554",
      Paymentrefno: "PN018570661",
      Status: "PAID",
      Paymentmode: "Cash",
    }
  ];

  return (
    <div className="mx-3 flex flex-col justify-center">
      <div className="mt-14">
        <Navbar heading={"my_subscription"} />
      </div>
      <h3 className="mt-3 font-bold">{t('active_subscription')}</h3>
      <div className="active-subscription rounded-md bg-white w-1/2 flex flex-row items-center justify-start gap-6 border text-[#000000] p-4">
        <div className="w-1/2">
          <div className="flex flex-row justify-between my-1">
            <p className="font-normal text-xs">{t('shop_name')}</p>
            <p className="font-semibold text-xs text-black mx-2">{activeSubscription?.shopName}</p>
          </div>
          <div className="flex flex-row justify-between my-1">
            <p className="font-normal text-xs">{t('frequency')}</p>
            <p className="font-semibold text-xs text-black mx-2">{activeSubscription?.frequency === "mth" ? "Monthly" : "Annually"}</p>
          </div>
          <div className="flex flex-row justify-between my-1">
            <p className="font-normal text-xs">{t('start_date')}</p>
            <p className="font-semibold text-xs text-black mx-2">{activeSubscription?.StartDate}</p>
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex flex-row justify-between my-1">
            <p className="font-normal text-xs">{t('rate')}</p>
            <p className="font-semibold text-xs text-black mx-2">{activeSubscription?.Rate}</p>
          </div>
          <div className="flex flex-row justify-between my-1">
            <p className="font-normal text-xs">{t('free_trial_end_date')}</p>
            <p className="font-semibold text-xs text-black mx-2">{activeSubscription?.FreetrialDate}</p>
          </div>
          <div className="flex flex-row justify-between my-1 ">
            <p className="font-normal text-xs">{t('status')}</p>
            <p className={`font-semibold text-xs ${activeSubscription?.Status === "Active" ? 'text-emerald-500' : 'text-red-500'} mx-2`}>
              {activeSubscription?.Status}
            </p>
          </div>
        </div>
      </div>

      {history?.length > 0 && (
        <>
          <h3 className="mt-5 font-bold">{t('history')}</h3>
          <div className="history w-full rounded-md flex flex-wrap gap-1 bg-white h-auto border text-center overflow-y-auto">
            {history.map((item, index) => (
              <div key={index} className="mx-auto my-3 rounded-md bg-white border p-4">
                <div className="flex flex-row justify-between my-1">
                  <p className="mx-2 font-normal text-xs">{t('shop_name')}</p>
                  <p className="mx-2 font-semibold text-xs text-black">{item.shopName}</p>
                </div>
                <div className="flex flex-row justify-between my-1">
                  <p className="mx-2 font-normal text-xs">{t('plan')}</p>
                  <p className="mx-2 font-semibold text-xs text-black">{item.Plan}</p>
                </div>
                <div className="flex flex-row justify-between my-1">
                  <p className="mx-2 font-normal text-xs">{t('start_date')}</p>
                  <p className="mx-2 font-semibold text-xs text-black">{item.StartDate}</p>
                </div>
                <div className="flex flex-row justify-between my-1">
                  <p className="mx-2 font-normal text-xs">{t('due_date')}</p>
                  <p className="mx-2 font-semibold text-xs text-black">{item.DueDate}</p>
                </div>
                <div className="flex flex-row justify-between my-1">
                  <p className="mx-2 font-normal text-xs">{t('invoice_date')}</p>
                  <p className="mx-2 font-semibold text-xs text-black">{item.InvoiceDate}</p>
                </div>
                <div className="flex flex-row justify-between my-1">
                  <p className="mx-2 font-normal text-xs">{t('amount')}</p>
                  <p className="mx-2 font-semibold text-xs text-black">{item.Amount}</p>
                </div>
                <div className="flex flex-row justify-between my-1">
                  <p className="mx-2 font-normal text-xs">{t('invoice_number')}</p>
                  <p className="mx-2 font-semibold text-xs text-black">{item.InvoiceNo}</p>
                </div>
                <div className="flex flex-row justify-between my-1">
                  <p className="mx-2 font-normal text-xs">{t('payment_reference_number')}</p>
                  <p className="mx-2 font-semibold text-xs text-black">{item.Paymentrefno}</p>
                </div>
                <div className="flex flex-row justify-between my-1">
                  <p className="mx-2 font-normal text-xs">{t('status')}</p>
                  <p className="mx-2 font-semibold text-xs text-black">{item.Status}</p>
                </div>
                <div className="flex flex-row justify-between my-1">
                  <p className="mx-2 font-normal text-xs">{t('payment_mode')}</p>
                  <p className="mx-2 font-semibold text-xs text-black">{item.Paymentmode}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Mysubscription;
