import React, { useState } from "react";
import Group from "../../assets/Inventory/Group.svg";
import {Image}  from "antd";
import BarcodeScanner from "react-barcode-reader";
import "../../styles.css"
import { useTranslation } from "react-i18next";

function SearchBox({ showBarcode, searchResult }) {
  const {t,i18n}=useTranslation();
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="relative flex items-center">
      <div className="absolute inset-y-0 left-0 flex items-center pl-4 pointer-events-none">
        <svg
          className="w-4 h-4 text-gray-500 dark:text-gray-400"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
      </div>
      <input
        type="search"
        id="default-search"
        className={`block shadow-sm h-10 w-72 p-2 ps-10 text-sm text-gray-950 border border-[#CCCCCC] rounded-md dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
         ${showBarcode ? "pl-20" : "pl-20"
          
         }
        `}
        style={{ outline: "none" }}
        placeholder={t('search_keyword')}
        value={searchTerm}
        onChange={(event) => {
          setSearchTerm(event.target.value);
          if (event.target.value.length >= 3 || event.target.value.length === 0) {
            searchResult(event.target.value);
          }
        }}
         // Call handleSearchChange on input change
        required
      />
      {/* <input
        type="search"
        id="default-search"
        className={`block shadow-sm h-10 w-96 p-2 ps-10 text-sm text-[#929EAE] border border-[#CCCCCC] rounded-lg dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${
          showBarcode ? "pr-12" : ""
        }`}
        style={{ outline: "none" }}
        placeholder="Search Keyword"
        required
      /> */}
      {showBarcode && (<>
        <Image
        src={Group}
        alt="Barcode"
        className="absolute inset-y-0 right-0 mt-2 mr-4 pointer-events-none cursor-pointer"
      />
        <BarcodeScanner
          onScan={(data) =>{ setSearchTerm(data)
            searchResult(data)}}
          onError={(err) => {
            console.error(err);
          }}
          facingMode="environment"
          className="absolute inset-y-0 right-0 mt-2 mr-4 pointer-events-none cursor-pointer z-10"
        />
       </>
       
      )}
    </div>
  );
}

export default SearchBox;
