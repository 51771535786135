import React, { useState } from "react";
import { useFormik } from "formik";
import { LoginSchema } from "../../components/validationSchema";
import { InputBox, Password } from "../../components/CustomWidgets/InputBox";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/CustomWidgets/Button";
import { routes } from "../../Routes";
import { useDispatch, useSelector } from "react-redux";

import {
  setUserDetail,
  setAccessToken,
  setRetailerInfo,
  setAuthenticated,
} from "../../components/Actions/AuthAction";
import ShimmerLoader from "../../loading";
import LoginPopup from "../../Popups/LoginPopup";
import { loginUser } from "../../Redux/Action/loginAction";
import { useTranslation } from "react-i18next";
import LoaderComponent from "../../Loader";

const LoginForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [Loading, setLoading] = useState(false);
  const [error, seterror] = useState(0);
  const [popup, setpopup] = useState(false);

  const formik = useFormik({
    initialValues: {
      emailOrPhone: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const emailOrPhone =
          values?.emailOrPhone.charAt(0) == 0
            ? values?.emailOrPhone?.substring(1, 11)
            : values?.emailOrPhone;
        const userData = await dispatch(
          loginUser(emailOrPhone, values.password)
        );
        console.log(userData);
        setLoading(false);
        // Check if the response contains an error message
        if (
          (userData && userData.error) ||
          userData?.data?.message?.includes("Invalid")
        ) {
          seterror(1);
        } else if (userData?.data?.message === "Login Successfull") {
          if (userData && userData.access_token === userData.user_id) {
            setpopup(true);

            setTimeout(() => {
              {
                navigate("/orders");
              }
            }, 1000);
          }
        }
      } catch (error) {
        console.error("Error occurred during login:", error);
        // Handle other errors, such as network issues
      }
    },
  });

  return (
    <div className="w-[100%] md:w-[70%] mx-auto">
      {/* {Loading ? (
        <ShimmerLoader />
      ) : ( */}
      <form onSubmit={formik.handleSubmit}>
        {error == 1 ? (
          <div className="text-s  text-red">{t("login_error")}</div>
        ) : null}
        <InputBox
          label={t("mobileoremail")}
          name="emailOrPhone"
          placeholder={t("mobile_or_email_place")}
          maxLength={40}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.emailOrPhone}
          error={formik.errors.emailOrPhone}
          touched={formik.touched.emailOrPhone}
        />

        <Password
          show={0}
          label={t("password")}
          type="password"
          name="password"
          placeholder={t("password_place")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={formik.errors.password}
          touched={formik.touched.password}
          // secureTextEntry
        />
        <div className="flex justify-end mt-2">
          <Link
            to={routes.forgotPassword}
            className="text-sm  text-black font-normal  ml-2 mt-0 cursor-pointer"
          >
            {t("forgot_password")}
          </Link>
        </div>
        <button
          type="submit"
          className="w-full my-4 flex justify-center items-center p-2 bg-primaryBlue text-md text-white rounded-lg font-semibold mt-4 relative"
          // onClick={onClick}
          disabled={Loading}
        >
          {" "}
          {t("login")}{" "}
          {Loading && (
            <>
            <div className="ms-4">
            <LoaderComponent />
            </div>
            </>
          )}
        </button>
        {/* <Button text={t('login')} Loading={Loading} /> */}
      </form>
      {/* )} */}
      {popup && <LoginPopup onClose={() => setpopup(false)} />}
    </div>
  );
};

export default LoginForm;
