import React, { useEffect, useRef, useState } from "react";
import ReturnReplacement from "../../components/Orders/ReturnReplacement";
import TableComponent from "../../components/Orders/TableComponent";
import Onthewaytable from "../../components/Orders/Onthewaytable";
import Delivereddata from "../../components/Orders/Dilivereddata";
import FailedCancelledtable from "../../components/Orders/FailedCancelledtable";
import InProcesstable from "../../components/Orders/InProcesstable";
import AllOrders from "./NewOrders";
import Third from "../../components/Orders/Third";
import ReadyforDeliverytable from "./ReadyforDelivery";
import Completedorderdata from "./CompletedOrder";
import { debounce } from "@mui/material";
import {
  getOrderdata,
  getstoreordercount,
  orderSearchFilter,
} from "../../API  Functions/Eorders";
import { useTranslation } from "react-i18next";

const Tableheader = ({ id, user_id, store_id, handleFilter }) => {
  const {t,i18n}=useTranslation();
  const [data, setdata] = useState([{ id: "11" }]);
  const [activeHeading, setActiveHeading] = useState(0);
  const [headingWidth, setHeadingWidth] = useState(0);
  const activeHeadingRef = useRef(null);
  const [componentData, setComponentData] = useState([]);
  const [heading_number, setheading_number] = useState(0);
  const [searched, setsearched] = useState(false);
  const [storecount, setstorecount] = useState([]);
  const [searchpayload, setsearchpayload] = useState({
    searchvalue: "",
    orderStatus: "",
    payment: "",
    today: "",
    deliveryBoy: 0,
  });
  // console.log("data",data)
  // useEffect(() => {
  //   if (!id) {
  //     return;
  //   } else {
  //     // console.log("ids", id);
  //     getData(0);
  //   }
  // }, [id]);
  const storeordercount = async () => {
    const response = await getstoreordercount(user_id, store_id,);
    if (response?.message === "Store Count Data.") {
      setstorecount(response?.data);
    }
  };

  useEffect(() => {
    setDynamicWidth();
    storeordercount();
  }, []);

  //Function to get data
  // const getData = (id, limit = 10, offset = 0) => {
  //   getOrderdata(id, limit, offset).then((res) => {setComponentData(res) });
  // };

  //Heading select function
  // const handleHeadingClick = (headingNumber) => {
  //   // console.log("Clicked heading:", headingNumber);
  //   setActiveHeading(headingNumber);
  //   getData(id[headingNumber - 1]?.id ?? 0);
  // };

  //Debounce search
  const debouncedSearch = debounce((value, limit = 20, offset = 0) => {
    // Call your search function or API endpoint here
    orderSearchFilter(user_id, store_id,
      value.searchvalue,
      value.orderStatus,
      value.payment,
      value.today,
      activeHeading === 1 || activeHeading === 2 ? 0 : value.deliveryBoy,
      limit,
      offset
    ).then(
      (response) => setComponentData(response),
      setheading_number(value.orderStatus!==""? value.orderStatus:0),
      setActiveHeading(value.orderStatus!==""? value.orderStatus:0)
    );
  }, 1000); // Debounce for 500 milliseconds


  const setDynamicWidth = () => {
    if (activeHeadingRef.current) {
      setHeadingWidth(activeHeadingRef.current.offsetWidth +5);
    }
  };


  const underlineStyle = {
    width: `${headingWidth}px`,
    height: "6px",
    borderRadius: "10px",
    backgroundColor: "#78CFEC",
  };

  const rendercomponent = [
    <AllOrders
      tabledata={componentData}
      user_id={user_id}
      store_id={store_id}
      pagination={
        (values) => debouncedSearch(searchpayload, values.limit, values.offset)
        // searched? :
        // getData(activeHeading, values.limit, values.offset)
      }
    />,
    <TableComponent
      tabledata={componentData}
      user_id={user_id}
      store_id={store_id}
      pagination={(values) => {
        debouncedSearch(searchpayload, values.limit, values.offset);
        // searched? :
        // getData(activeHeading, values.limit, values.offset)
      }}
    />,
    <InProcesstable
      inprocess={componentData}
      user_id={user_id}
      store_id={store_id}
      pagination={
        (values) => debouncedSearch(searchpayload, values.limit, values.offset)
        // searched? :
        // getData(activeHeading, values.limit, values.offset)
      }
    />,
    <ReadyforDeliverytable
      tabledata={componentData}
      user_id={user_id}
      store_id={store_id}
      pagination={
        (values) => debouncedSearch(searchpayload, values.limit, values.offset)
        // searched? :
        // getData(activeHeading, values.limit, values.offset)
      }
    />,
    <Onthewaytable
      ontheway={componentData}
      user_id={user_id}
      store_id={store_id}
      pagination={
        (values) => debouncedSearch(searchpayload, values.limit, values.offset)
        // searched? :
        // getData(activeHeading, values.limit, values.offset)
      }
    />,
    <Delivereddata
      delivered={componentData}
      user_id={user_id}
      store_id={store_id}
      pagination={
        (values) => debouncedSearch(searchpayload, values.limit, values.offset)
        // searched? :
        // getData(activeHeading, values.limit, values.offset)
      }
    />,
    <Completedorderdata
      tabledata={componentData}
      user_id={user_id}
      store_id={store_id}
      pagination={
        (values) => debouncedSearch(searchpayload, values.limit, values.offset)
        // searched? :
        // getData(activeHeading, values.limit, values.offset)
      }
    />,
    <FailedCancelledtable
      failedcancelled={componentData}
      user_id={user_id}
      store_id={store_id}
      pagination={
        (values) => debouncedSearch(searchpayload, values.limit, values.offset)
        // searched? :
        // getData(activeHeading, values.limit, values.offset)
      }
    />,
    // <ReturnReplacement returnreplacement={componentData} user_id={user_id} store_id={store_id}/>,
  ]; // console.log("activeHeading", activeHeading, rendercomponent[activeHeading]);
  const headingNumber = 0;
  return (
    <>
      <div className=" mx-3">
        {/* Pass activeHeading prop to Third component */}
        <Third
          activeHeading={activeHeading ? activeHeading : 0}
          storecount={storecount}
          handleFilter={(values) => {
            setsearched(true);
            setsearchpayload(values);
            // setOrderStatus(valu)
            debouncedSearch(values);
          }}
        />
      </div>
      {/* {data.map((val, index) => ( */}
      <div
        // key={val.id}
        className="flex gap-7 mt-5 mx-3 font-semibold text-[#999999] "
      >
        {/* {[0, 1, 2, 3, 4, 5, 6, 7].map((headingNumber) => */}
        {[0].map((val) => {
          const badgeColor =
            heading_number === activeHeading ? "#FCC71D" : "#E7E7E7";
          return (
            <h1
              key={heading_number}
              ref={heading_number === activeHeading ? activeHeadingRef : null}
              className={`flex relative  heading-${headingNumber}`}
              // onClick={() => handleHeadingClick(heading_number)} // Add this line
              style={{
                fontWeight:
                  heading_number === activeHeading ? "600" : "#999999",
              }}
            >
              {heading_number === activeHeading && (
                <div style={underlineStyle} className="absolute top-8 left-0" />
              )}
              <span
                style={{
                  color: heading_number === activeHeading ? "#000" : "#999999",
                }}
              >
                {activeHeading === 0 && t('all_orders')}
                {activeHeading === 1 && t('order_pending')}
                {activeHeading === 2 && t('in_progress')}
                {activeHeading === 3 && t('ready_for_delivery')}
                {activeHeading === 4 && t('on_the_way')}
                {activeHeading === 5 && t('order_delivered')}
                {/* {activeHeading === 6 && "Completed"} */}
                {activeHeading === 7 && t('order_cancelled')}
                {/* {headingNumber === 7 && "Return/Replacement"} */}
              </span>
              <div
                style={{
                  borderRadius: "10px",
                  maxHeight: "23px",
                  marginLeft: "5px",
                  backgroundColor: badgeColor,
                  padding: "0px 7px",
                }}
              >
                <span style={{ color: "#000000" }}>
                {/* data?.find((item) => "COD" in item)?.COD */}
                {console.log("componentData",componentData?.[0]?.total_orders_count)}
                  {componentData?.[0]?.total_orders_count??storecount?.[activeHeading]?.order_count ?? 0}
                </span>
              </div>
            </h1>
          );
        })}
      </div>
      {/* ))} */}
      <hr className="w-full lg:w-[97.6%] relative top-[14px] left-[13px]" />
      <div>
        {/* componentData.length !== 0 && */}

        {rendercomponent[activeHeading ? activeHeading : 0]}
      </div>
    </>
  );
};

export default Tableheader;
