import React, { useMemo, useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "tailwindcss/tailwind.css";
import CustomPagination from "../CustomWidgets/CustomPagination";

import { Empty } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import arrowright from "../../assets/arrowright.svg"
import {Image}  from "antd";
import "../../styles.css"
import { useNavigate } from "react-router";
import ShimmerLoader from "../../loading";
import Vectorsorting from "../../assets/Vectorsorting.svg";
import { useTranslation } from "react-i18next";

const TableComponent = ({ user_id, store_id, tabledata, pagination }) => {
  const {t,i18n}=useTranslation()
  const navigate=useNavigate();
   const [flag, setflag] = useState(false);

useEffect(() => {
    if (tabledata?.length === 0 && !flag) {
      const timer = setTimeout(() => {
        setflag(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [tabledata, flag]);

  const data = useMemo(() => tabledata || [], [tabledata]);

  //To be called when page 
  


  const openOrderList = (value) => {
    // {alert(typeof({value}))}
    const senddata = [
      // user_id,
      // store_id,
      // value.substring(0, 10),
      value.substring(12),
    ];
 navigate(`/Orderlist?orderDetail=${senddata}`)
  };

  const columns = useMemo(
    () => [
      { Header: t('order_no'), accessor: "order_number" ,Cell: ({ value }) => (
        <>
          {/* {(value.substring(value.lastIndexOf(",") + 1) === "Order Pending" ||
            value.substring(value.lastIndexOf(",") + 1) === "In Processing" ||
            value.substring(value.lastIndexOf(",") + 1) ===
              "Ready For Delivery") && ( */}
          <button
            // className="bg-[#7EC242] text-white w-28 h-10 rounded-lg"
            className=" text-[#1b4497] rounded-lg"
            onClick={() => openOrderList("ordernamehar"+value)}
          >
           {value}
          </button>
          {/* )
          } */}
        </>
      ),},
      { Header:t('customer_name'), accessor: "customer_details.name" },
      {
         Header: t('order_date_time'),
        accessor: (row) => `${row.order_date}`,
        Cell: ({ value }) => (
          <span>
            {value?.substring(0, 10).replace(/-/g, "/")}{" "}
            {value.substring(11, 13) === 12
              ? value.substring(11, 16) + " PM"
              : value.substring(11, 13) > 12
              ? value.substring(11, 13) - 12 + value.substring(13, 16) + " PM"
              : value.substring(11, 16) + " AM"}
          </span>
        ),
      },
      { Header: t('delivery_schedule'), accessor: "delivery_time" },
      // { Header: "Payment Mode", accessor: "payment_mode" },
      { Header: t('order_value'), accessor: "order_total_amt" },
      { Header: t('items'), accessor: "order_line_details",Cell: ({ value }) => (
        <>
           {value?.length}
        </>
      ), },
      // {
      //  Header: t('order_status'),
      //   accessor: "order_stage.name",
      //   Cell: ({ value }) => {
      //     let backgroundColor,
      //       textColor,
      //       borderRadius,
      //       paddingTop,
      //       paddingBottom,
      //       paddingLeft,
      //       paddingRight;

      //     // Conditionally set styles based on order status
      //     if (value === "New") {
      //       backgroundColor = "#D1FFDE";
      //       textColor = "#00B833";
      //       borderRadius = "999px"; // Full border radius
      //     } else if (value === "Opened") {
      //       backgroundColor = "#FFEBC5";
      //       textColor = "#E8A700";
      //       borderRadius = "999px"; // Full border radius
      //     } else {
      //       // Default styles for other statuses
      //       backgroundColor = ""; // Set default background color
      //       textColor = ""; // Set default text color
      //       borderRadius = ""; // Set default border radius
      //     }

      //     // Padding values for top, bottom, left, right
      //     paddingTop = "8px";
      //     paddingBottom = "8px";
      //     paddingLeft = "1px";
      //     paddingRight = "1px";

      //     return (
      //       <div
      //         style={{
      //           backgroundColor,
      //           color: textColor,
      //           borderRadius,
      //           padding: `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`,
      //           display: "inline-block",
      //           minWidth: "90px",
      //           alignItems: "center",
      //         }}
      //       >
      //         {value}
      //       </div>
      //     );
      //   },
      // },

      {
        id: "action",
        Header: "",
        accessor: (row) =>
          `${row.order_number}`,
        Cell: ({ value }) => (
          <>
            {/* {(value.substring(value.lastIndexOf(",") + 1) === "Order Pending" ||
              value.substring(value.lastIndexOf(",") + 1) === "In Processing" ||
              value.substring(value.lastIndexOf(",") + 1) ===
                "Ready For Delivery") && ( */}
            <button
              // className="bg-[#7EC242] text-white w-28 h-10 rounded-lg"
              className=" text-[#1b4497] rounded-lg"
              onClick={() => openOrderList("ordernamehar"+value)}
            >
               <img src={arrowright} alt="arrowright" className={`${i18n.language=="en"?"":" rotate-180"}`} height={3} width={20}/>
              {/* Open Order <ArrowRightOutlined /> */}
            </button>
            {/* )
            } */}
          </>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    state: {  pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {  pageSize: 10 },
    },
    useSortBy,
    usePagination
  );
  const [pageIndex,setpageIndex]=useState(1)
  useEffect(() => {
    const pageDetails = {
    
      limit: pageSize,
      offset: pageSize * (pageIndex - 1) <0 ? 0:pageSize * (pageIndex - 1) ,
    };
    pagination(pageDetails);
    
  }, [pageIndex, pageSize,previousPage,nextPage]);

  return (
    <div className="container mx-auto float-left"  style={{ maxWidth: "100%" }}>
      {tabledata?.length == 0 && flag == false ? (
          <>
            <ShimmerLoader />{" "}
            {/* {setTimeout(() => {
              setflag(true);
            }, 3000)} */}
          </>
        ) : (
      <table
        {...getTableProps()}
        className="table-auto mx-2 ml-3 mt-7 font-normal  sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%] min-h-auto shadow-xl rounded-lg overflow-hidden"
      >
        <thead>
        {headerGroups.map((headerGroup, headerIndex) => {
              const { key: headerKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr
                  key={headerKey}
                  {...headerGroupProps}
                  className="bg-[#78CFEC66] text-sm font-semibold"
                >
                  {headerGroup.headers.map((column, colIndex) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        key={columnKey}
                        {...columnProps}
                        className="py-3 px-2 whitespace-nowrap "
                      >
                        <div className="flex items-center justify-center">
                          {column.render("Header")}
                          <span className="">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img src={Vectorsorting} style={{minHeight:10,minWidth:10}} />
                              ) : (
                                <img
                                  className="rotate-180"
                                  src={Vectorsorting}
                                  style={{minHeight:10,minWidth:10}}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-[#F7F9FD] text-center">
          {page.map((row) => {
            prepareRow(row);
            return (
              <React.Fragment key={row.id}>
                <tr
                  {...row.getRowProps()}
                  className="relative hover:bg-white transition duration-300 border-b border-gray-300 fixed-row-height "
                >
                  {row.cells.map((cell, index) => (
                    <td
                      {...cell.getCellProps()}
                      className={`p-2 text-[#272728] h-12 text-sm font-normal ${
                        index < row.cells.length - 1 ? "" : ""
                      }`}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            );
          })}
          {/* Empty state row */}
          {page.length === 0 && (
            <tr>
              <td colSpan={columns.length}>
                <Empty description="No data available" />
              </td>
            </tr>
          )}
          <tr> {
            <td className="" colSpan={columns.length}>
              <CustomPagination
              data={tabledata?.length}
                pageIndex={pageIndex}
                pageCount={page.length}
                canPreviousPage={pageIndex!=1?true:false}
                canNextPage={tabledata?.length>=10?true:false}
                gotoPage={(page) => {
                  nextPage(page);
                }}
                previousPage={()=>{setpageIndex(pageIndex-1)}}
                nextPage={() => {setpageIndex(pageIndex+1)}}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                 // nextPage(0);

                  setPageSize(pageSize)
                
                
                  
                }}
              />
            </td>}
          </tr>
        </tbody>
      </table>)}
    </div>
  );
};

export default TableComponent;
