import React, { useEffect, useMemo, useState, useRef } from "react";
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import "tailwindcss/tailwind.css";
import CustomPagination from "../CustomWidgets/CustomPagination";
import { Image } from "antd";
import oreo from "../../assets/Inventory/oreo.svg";
import { CaretDownOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Button, Dropdown, Empty, InputNumber, Menu } from "antd";
import Edit from "../../assets/Edit.svg";
import {
  InventoryUpdateDetails,
  getInventoryallproducts,
} from "../../API  Functions/InventoryFunctions";
import ShimmerLoader from "../../loading";
import Vectorsorting from "../../assets/Vectorsorting.svg";
import { useTranslation } from "react-i18next";

const HighOnInventoryTable = ({
  call,
  user_id,
  store_id,
  searchedData,
  searchData,
}) => {
  const { t, i18n } = useTranslation();
  const [additionalData, setAdditionalData] = useState([]);
  const [flag, setflag] = useState(false);

  useEffect(() => {
    if (additionalData.length === 0 && !flag) {
      const timer = setTimeout(() => {
        setflag(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [additionalData, flag]);

  const getdata = (limit = 10, offset = 0) => {
    getInventoryallproducts(user_id, store_id, limit, offset).then((res) => {
      setAdditionalData(res?.high_stock);
    });
  };

  const Updatedata = (val) => {
    InventoryUpdateDetails(user_id, store_id, val).then((res) => {
      console.log("res", res);
      res.message == "Update Successfully" && getdata();
    });
  };

  // useEffect(() => {
  //   getdata();
  // }, []);
  useEffect(() => {
    if (call) getdata();
    else if (searchData) setAdditionalData(searchData);
    else if (searchedData) setAdditionalData(searchedData);
    else getdata();
  }, [searchedData, searchData,call]);

  const [editRows, setEditRows] = useState(new Set()); // State to manage edited rows
  const [editValues, setEditValues] = useState({}); // State to manage edited values
  const [editPriceClicked, setEditPriceClicked] = useState(false);
  const [indexarray, setindexarray] = useState([]); // State to track if "Edit price & Qty" button is clicked
  const [showpopup, setshowpopup] = useState(false);
  const [showpopupdata, setshowpopupdata] = useState([]);
  const costPriceInputRefs = useRef({});
  const sellPriceInputRefs = useRef({});
  const qtyInputRefs = useRef({});
  const timeoutRef = useRef(null);

  const data = useMemo(
    () =>
      additionalData?.status
        ? additionalData?.all_product
        : additionalData || [],
    [additionalData]
  );

  // Dropdown data array
  const items = [
    {
      key: "1",
      label: (
        <a
          className="pr-10 text-[#0F1828] font-normal"
          target="_blank"
          rel="noopener noreferrer"
          href="#"
          // onClick={(e) => {
          //   e.preventDefault();
          //   handleEditRow(0); // Edit the first row
          // }}
        >
          Edit price & Qty
        </a>
      ),
    },
    // {
    //   type: "divider",
    // },
    // {
    //   key: "2",
    //   label: (
    //     <a
    //       className=" text-[#0F1828] font-normal"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       href="#"
    //     >
    //       Mark as damaged
    //     </a>
    //   ),
    // },
    // {
    //   type: "divider",
    // },
    // {
    //   key: "3",
    //   label: (
    //     <a
    //       className=" text-[#0F1828] font-normal text-base"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       href="#"
    //     >
    //       Mark as expired
    //     </a>
    //   ),
    // },
  ];

  const columns = useMemo(
    () => [
      {
        Header: t("product"),
        accessor: "product_name",
        Cell: ({ row }) => (
          <div className="flex h-50 w-50 items-center gap-6 justify-start">
            {row.original.image_url || row.original.image ? (
              <img
                style={{
                  maxHeight: 40,
                  maxWidth: 40,
                  minHeight: 40,
                  minWidth: 40,
                  objectFit: "contain",
                }}
                src={row.original.image_url || row.original.image}
                height={60}
                width={30}
                alt="Image"
              />
            ) : (
              <svg
                className=" float-left h-10 w-12 text-gray-300"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                  // clip-rule="evenodd"
                />
              </svg>
            )}
            {/* <img src={row.original.image_url} alt="Product" height={50} width={50} className="mr-2" /> */}
            <div className=" flex justify-start">
              <span>{row.original.product_name}</span>
              {/* <span>1234567890</span> */}
            </div>
          </div>
        ),
        headerClassName: "text-center",
      },
      {
        Header: t("category"),
        accessor: "main_category[0].name",
        headerClassName: "text-center",
      },
      {
        Header: t("cost_price"),
        accessor: "cost_price",
        Cell: ({ row }) =>
          editRows?.has(row.index) ? (
            <InputNumber
              className="w-16"
              ref={(element) =>
                (costPriceInputRefs.current[row.index] = element)
              }
              min={0}
              value={editValues[row.index]?.cost_price ?? row.values.cost_price}
              onChange={(value) => {
                if (value == null) value = 0;
                handleInputChange(row.index, "cost_price", value);
              }}
              // formatter={(value) => `EGP ${value}`} // Add "EGP" text to formatted value
              // parser={(value) => value.replace("EGP ", "")} // Remove "EGP" text from parsed value
            />
          ) : (
            ` ${editValues[row.index]?.cost_price ?? row.values.cost_price}` // Concatenate with "EGP" text when not editing
          ),
      },
      // Similar logic for "Sale Price" and "On Hand Quantity" columns...
      {
        Header: t("sale_price"),
        accessor: "sell_price",
        Cell: ({ row }) => (
          <>
            {editRows?.has(row.index) ? (
              <InputNumber
                className="w-16"
                ref={sellPriceInputRefs.current[row.index]}
                min={editValues[row.index]?.cost_price ?? row.values.cost_price}
                value={
                  editValues[row.index]?.cost_price >
                    editValues[row.index]?.sell_price &&
                  editValues[row.index]?.cost_price > row.values.sell_price
                    ? editValues[row.index]?.cost_price
                    : editValues[row.index]?.sell_price ?? row.values.sell_price
                }
                onChange={(value) => {
                  if (value == null) value = 0;
                  handleInputChange(row.index, "sell_price", value);
                }}
                // formatter={(value) => `EGP ${value}`} // Add "EGP" text to formatted value
                // parser={(value) => value.replace("EGP ", "")} // Remove "EGP" text from parsed value
              />
            ) : (
              `${editValues[row.index]?.sell_price ?? row.values.sell_price}` // Concatenate with "EGP" text when not editing
            )}
          </>
        ),
      },
      {
        Header: t("quantity"),
        accessor: "qty",
        Cell: ({ row }) =>
          editRows.has(row.index) ? (
            <InputNumber
              className="w-16"
              ref={qtyInputRefs.current[row.index]}
              type="number"
              min={0}
              value={editValues[row.index]?.qty ?? row.values.qty}
              onChange={(value) => {
                if (value == null) value = 0;
                handleInputChange(row.index, "qty", value);
              }}
              // formatter={(value) => `${value}handlePriceChange`} // Remove "EGP" prefix
              // parser={(value) => value.replace("EGP ", "")} // Remove "EGP" prefix
            />
          ) : (
            `${editValues[row.index]?.qty ?? row.values.qty} ` // Show only the quantity
          ),
      },
      {
        Header: t("sell_online"),
        accessor: "sellonline",
        Cell: ({ row }) =>
          editRows?.has(row.index) ? (
            <input
              type="checkbox"
              checked={
                editValues[row.index]?.sellonline ?? row?.values?.sellonline
              }
              onChange={(e) =>
                handlecheckChange(row.index, e.target.checked, "sellonline")
              }
            />
          ) : (
            <div className="flex h-50 w-50 items-center justify-center">
              <input
                type="checkbox"
                value={row?.values?.sellonline}
                checked={row?.values?.sellonline}
                disabled // Disable the checkbox when not editing
              />
            </div>
          ),
      },

      {
        Header: t("sell_pos"),
        accessor: "sellpos",
        Cell: ({ row }) =>
          editRows?.has(row.index) ? (
            <input
              type="checkbox"
              checked={editValues[row.index]?.sellpos ?? row?.values?.sellpos}
              onChange={(e) =>
                handlecheckChange(row.index, e.target.checked, "sellpos")
              }
            />
          ) : (
            <div className="flex h-50 w-50 items-center justify-center">
              <input
                type="checkbox"
                value={row?.values?.sellpos}
                checked={row?.values?.sellpos}
                disabled // Disable the checkbox when not editing
              />
            </div>
          ),
      },
      {
        Header: t("out_of_stock"),
        accessor: "isoutofstock",
        Cell: ({ row }) =>
          editRows?.has(row.index) ? (
            <input
              type="checkbox"
              checked={
                editValues[row.index]?.isoutofstock ?? row?.values?.isoutofstock
              }
              onChange={(e) =>
                handlecheckChange(row.index, e.target.checked, "isoutofstock")
              }
              // disabled
            />
          ) : (
            <div className="flex h-50 w-50 items-center justify-center">
              <input
                type="checkbox"
                value={row?.values?.isoutofstock}
                checked={row?.values?.isoutofstock}
                disabled // Disable the checkbox when not editing
              />
            </div>
          ),
      },

      // {
      //   Header: "Status",
      //   accessor: "",
      //   Cell: ({ value }) => {
      //     let icon;
      //     let bgColor = "";
      //     let textColor = "";
      //     switch (value) {
      //       case false:
      //         icon = <CaretDownOutlined />;
      //         bgColor = "bg-[#D1FFDE]";
      //         textColor = "#00B833";
      //         break;
      //       case "Active":
      //         icon = <CaretDownOutlined />;
      //         bgColor = "bg-[#D1FFDE]";
      //         textColor = "#00B833";
      //         break;
      //       case "Inactive":
      //         icon = <CaretDownOutlined />;
      //         bgColor = "bg-[#DCDCDC]";
      //         textColor = "#707070";
      //         break;
      //       case "Sold Out":
      //         icon = <CaretDownOutlined />;
      //         bgColor = "bg-[#FFEAEA]";
      //         textColor = "#FF5151";
      //         break;
      //       case true:
      //         icon = <CaretDownOutlined />;
      //         bgColor = "bg-[#FFEAEA]";
      //         textColor = "#FF5151";
      //         break;
      //       default:
      //         bgColor = "bg-gray-300";
      //         textColor = "#000000";
      //     }
      //     return (
      //       <div
      //         className={`px-3 py-2 flex items-center justify-between rounded-full ${bgColor}`}
      //       >
      //         <span style={{ color: textColor }}>
      //           {value ? "Sold Out" : "Active"}
      //         </span>
      //         {icon}
      //       </div>
      //     );
      //   },
      // },
      {
        Header: "",
        accessor: "dropdown",
        Cell: (row) => (
          <>
            <img
              src={Edit}
              style={{
                cursor: "pointer",
                // transform: "rotateY(180deg)",
                // marginTop: ".15rem",
                height: "0.9rem",
                width: "0.9rem",
              }}
              alt="Edit"
              className={` mt--2 ${i18n.language == "en" ? "mr-2" : "ml-2"}`}
              onClick={(e, index) => {
                e.preventDefault();
                //  const i = row.row.id+1
                // if (
                //   i == 1
                //   //&&
                //   // !indexarray.includes(parseInt(row.row.id))
                // ) {
                setindexarray([...indexarray, parseInt(row.row.id)]);
                // }
                // if (i == 1) {
                handleEditRow(row.row.id);
                // }
              }}
            />
            {/* <Dropdown 
              overlay={
                <Menu>
                  {items.map((item) =>
                    item.type === "divider" ? (
                      <Menu.Divider key={item.key} />
                    ) : (
                      <Menu.Item key={item.key}>
                        <a
                          onClick={(e) => {
                            console.log("item.key", item.key);
                            e.preventDefault();
                            const i = row.row.id;
                            if (
                              item.key == 1
                              //&&
                              // !indexarray.includes(parseInt(row.row.id))
                            ) {
                              setindexarray([
                                ...indexarray,
                                parseInt(row.row.id),
                              ]);
                            }
                            if (item.key == 1) {
                              handleEditRow(row.row.id);
                            }
                          }}
                        >
                          {item.label}
                        </a>
                      </Menu.Item>
                    )
                  )}
                </Menu>
              }
              trigger={["click"]}
              placement="bottomRight"
              arrow
            >
              <EllipsisOutlined className="ml-1 cursor-pointer rotate-90" />
            </Dropdown> */}
          </>
        ),
      },
    ],
    [editRows, editValues] // Dependencies added to re-render when editRows or editValues change
  );
  // Function to handle price changes
  const handlePriceChange = (index, field, value) => {
    setEditValues((prevValues) => ({
      ...prevValues,
      [index]: {
        ...prevValues[index],
        [field]: value,
      },
    }));
  };
  const handleInputChange = (index, field, value) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      handlePriceChange(index, field, value);
    }, 2000);
  };
  const handlecheckChange = (index, checked, name) => {
    if (name == "isoutofstock") {
      setEditValues((prevValues) => ({
        ...prevValues,
        [index]: {
          ...prevValues[index],
          [name]: checked,
        },
      }));
      setEditValues((prevValues) => ({
        ...prevValues,
        [index]: {
          ...prevValues[index],
          ["qty"]: 0,
        },
      }));
    } else {
      setEditValues((prevValues) => ({
        ...prevValues,
        [index]: {
          ...prevValues[index],
          [name]: checked,
        },
      }));
    }
  };

  // Function to handle saving all changes
  const handleSaveAll = () => {
    // Update the additionalData state with the edited values
    let sendData = [];
    console.log("hello called");
    //  const newData = additionalData?.all_product.map((row, index) => {
    const newData = data?.map((row, index) => {
      if (editRows.has(index)) {
        const item = {
          store_inventory_id: row?.store_inventory_id ?? "",
          sale_price: editValues[index]?.sell_price ?? row?.sell_price ?? 0,
          cost_price: editValues[index]?.cost_price ?? row?.cost_price ?? 0,
          qty: editValues[index]?.qty ?? row?.qty ?? 0,
          sellonline: editValues[index]?.sellonline ?? row?.sellonline ?? 0,
          sellpos: editValues[index]?.sellpos ?? row?.sellpos ?? 0,
          // isoutofstock:editValues[index]?.isoutofstock ?? row?.isoutofstock ?? 0,
        };

        sendData.push(item);
        console.log("sendData", sendData);
        return {
          ...row,
          ...editValues[index], // Use the editValues for this row
        };
      }
      return row;
    });
    // editValues

    // Clear the editRows state and reset the editValues state
    setEditRows(new Set());
    setindexarray([]);
    setEditValues({});
    setEditPriceClicked(false); // Reset editPriceClicked to false when saving all changes

    // Update the additionalData state with the new data
    Updatedata(sendData);
  };

  // Function to handle editing a row
  const handleEditRow = (index) => {
    setEditRows(new Set([...indexarray, parseInt(index)])); // Set only the selected row for editing
    setEditPriceClicked(true);

    // Set editPriceClicked to true when "Edit price & Qty" button is clicked
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    state: { pageSize },
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data: additionalData,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination,
    useRowSelect // Include useRowSelect hook for row selection
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: "selection",
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
    //       ),
    //       Cell: ({ row }) => (
    //         <input type="checkbox" {...row.getToggleRowSelectedProps()} />
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
  );

  const [pageIndex, setpageIndex] = useState(1);
  useEffect(() => {
    const pageDetails = {
      limit: pageSize,
      offset: pageSize * (pageIndex - 1) < 0 ? 0 : pageSize * (pageIndex - 1),
    };

    //  pagination(pageDetails);
    getdata(pageDetails.limit, pageDetails.offset);
  }, [pageIndex, pageSize, previousPage, nextPage]);

  return (
    <div
      className="container mx-auto float-left mt-5"
      style={{ maxWidth: "100%" }}
    >
      {/* Additional Header */}
      <div className="flex items-center ml-3 mb-3 relative">
        <h1 className="text-base font-semibold mb-4">{t("all_products")} </h1>
        <div className="flex mb-4">
          {/* {data.map((val) => ( */}
          <div
            // key={val.id}
            style={{
              backgroundColor: "#FCC71D",
              borderRadius: "10px",
              maxHeight: "23px",
              marginLeft: "5px",
              padding: "0px 7px",
            }}
          >
            <span  className=" font-semibold">{additionalData?.length ?? 0}</span>
          </div>
          {/* ))} */}
        </div>
        <div className="absolute bg-[#78CFEC] h-[6px] w-32 mb-1 left-0 bottom-0 rounded-xl"></div>
      </div>
      <hr className=" lg:w-[97.6%] relative top-[-16px] left-[13px]" />

      {editPriceClicked && ( // Render the "Save All" button only when editPriceClicked is true
        <div className="flex justify-end mb-5">
          <button
            onClick={handleSaveAll}
            className="w-28 h-8 mt-1 bg-[#7EC242] text-white text-sm font-semibold rounded-lg flex items-center justify-center mr-5"
          >
            <span>{t("save_all")}</span>
          </button>
          {/* <Button type="primary" onClick={handleSaveAll} className="bg-green-500">
          Save All
        </Button> */}
        </div>
      )}
      {additionalData?.length == 0 && flag == false ? (
        <>
          <ShimmerLoader />{" "}
          {/* {setTimeout(() => {
              setflag(true);
            }, 3000)} */}
        </>
      ) : (
        <table
          {...getTableProps()}
          className=" mx-auto  font-normal max-w-[100%] sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%] min-h-auto h-[100%] shadow-xl rounded-lg overflow-hidden"
        >
          <thead>
            {headerGroups.map((headerGroup, headerIndex) => {
              const { key: headerKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr
                  key={headerKey}
                  {...headerGroupProps}
                  className="bg-[#78CFEC66] text-sm font-semibold"
                >
                  {headerGroup.headers.map((column, colIndex) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        key={columnKey}
                        {...columnProps}
                        className="py-3 px-2 whitespace-nowrap "
                      >
                        <div className="flex items-center justify-center">
                          {column.render("Header")}
                          <span className="">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img
                                  src={Vectorsorting}
                                  style={{ minHeight: 10, minWidth: 10 }}
                                />
                              ) : (
                                <img
                                  className="rotate-180"
                                  src={Vectorsorting}
                                  style={{ minHeight: 10, minWidth: 10 }}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} className="bg-[#F7F9FD] text-center">
            {page.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr
                    {...row.getRowProps()}
                    className="relative hover:bg-white  h-10 transition duration-300 border-b border-gray-300 "
                  >
                    {row.cells.map((cell, index) => (
                      <td
                        {...cell.getCellProps()}
                        className={`p-2 text-[#272728] h-1212 text-sm font-normal ${
                          index < row.cells.length - 1 ? "relative" : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              );
            })}
            {/* Empty state row */}
            {page.length === 0 && (
              <tr>
                <td colSpan={columns.length}>
                  <Empty description="No data available" />
                </td>
              </tr>
            )}
            <tr>
              <td className="" colSpan={"100%"}>
                <CustomPagination
                  data={additionalData?.length}
                  pageIndex={pageIndex}
                  pageCount={page.length}
                  canPreviousPage={pageIndex != 1 ? true : false}
                  canNextPage={additionalData?.length >= 10 ? true : false}
                  gotoPage={(page) => {
                    nextPage(page);
                  }}
                  previousPage={() => {
                    setpageIndex(pageIndex - 1);
                  }}
                  nextPage={() => {
                    setpageIndex(pageIndex + 1);
                  }}
                  pageSize={pageSize}
                  setPageSize={(pageSize) => {
                    // nextPage(0);

                    setPageSize(pageSize);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default HighOnInventoryTable;
