import React, { useState, useEffect } from "react";
import ring from "../../assets/Order/ring.svg";
import dropdown from "../../assets/Order/arrow_drop_down.svg";
import round from "../../assets/Images/roundimage.png";
import { LogoutOutlined } from "@ant-design/icons";

import axios from "axios";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import LogoutPopup from "../../Popups/LogoutPopup";
import Sessionexpired from "../../modals/Sessionexpired";
import { getOrderNotifications } from "../../API  Functions/Eorders";
import "../../styles.css";
import Notification from "../../assets/Notification.svg";
import { Link, useNavigate } from "react-router-dom";
import { store } from "../../Redux/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../styles.css";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../Redux/Action/loginAction";
import LanguageSwitcher from "../language-switcher";

const Navbar = ({ heading, Profile }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const userinfo = useSelector((state) => state?.login?.user);
  // const token=useSelector((state)=>state.auth.accessToken)
  // const userid=useSelector((state)=>state.auth.userDetail)
  const navigate = useNavigate();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isDropdownNotificationVisible, setisDropdownNotificationVisible] =
    useState(false);
  const [Notificationdata, setNotificationdata] = useState([]);
  const [profileName, setprofileName] = useState("Hi User");
  const [profileImage, setprofileImage] = useState("");
  const [storage, setstorage] = useState([]);
  const [popup, setpopup] = useState(false);
  const [sessionexpired, setsessionexpired] = useState(false);

  const handleToggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  const handleLogout = async () => {
    // Implement your logout logic here
    // For now, let's just hide the dropdown
    try {
      let payload = { user_id: userinfo?.user_id, logout_all_device: "false" };
      // +URLS.LOGOUT_API_URL
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}retailer-logout`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userinfo?.access_token}`,
          },
        }
      );
      // const response = axiosInstance.post(URLS.LOGOUT_API_URL,
      //   payload
      // );
      console.log(response);
      if (
        response?.data?.message == "Invalid Token" ||
        response?.data?.message ==
          "provided token is not found in the system against the user id." ||
        response?.data?.status == 203 ||
        response?.data?.status == 202
      ) {
        // localStorage.clear();
        Object.keys(localStorage).forEach((key) => {
          if (key !== "selectedLanguage") {
            localStorage.removeItem(key);
          }
        });
        setsessionexpired(true);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }

      if (response?.data?.message == "Logout Successfully !!") {
        setDropdownVisible(false);

        dispatch(logoutUser());
        //  localStorage.clear();
        // Object.keys(localStorage).forEach((key) => {
        //   if (key !== "selectedLanguage") {
        //     localStorage.removeItem(key);
        //   }
        // });
        setpopup(true);

        // window.location.reload();
        setTimeout(() => {
          navigate("/login");
          setpopup(false);
        }, 300);
      }
    } catch (error) {
      console.log("error", error);
      alert("cant be able to logout");
      // localStorage.clear();

      // router.push("/");
    }
  };

  const getorderNotifications = async () => {
    const response = await getOrderNotifications(
      userinfo?.user_id,
      userinfo?.store_id
    );
    if (response) setNotificationdata(response?.data);
  };

  useEffect(() => {
    //to set the name updated

    console.log("profile", Profile);
    // Check if localStorage is available before using it
    if (typeof window !== "undefined" && window.localStorage) {
      if (Profile) {
        if (
          (Profile?.name !== "Hi User" &&
            Profile?.name !== undefined &&
            Profile?.name !== "") ||
          (localStorage.getItem("username") !== undefined &&
            localStorage.getItem("username") !== null)
        ) {
          if (Profile?.name && Profile?.name !== "") {
            setprofileName(Profile.name);
            localStorage.setItem("username", Profile.name);
          } else if (localStorage.getItem("username")) {
            setprofileName(localStorage.getItem("username"));
          }
        }

        if (Profile?.image !== "" && Profile?.image !== undefined) {
          console.log("i am 2");
          setprofileImage(Profile.image ?? "");
          localStorage.setItem("profileImage", Profile.image);
        } else if (localStorage.getItem("profileImage")) {
          setprofileImage(localStorage.getItem("profileImage"));
        }
      } else {
        setTimeout(() => {
          if (localStorage.getItem("username")) {
            setprofileName(localStorage.getItem("username"));
          } else setprofileName(userinfo?.retailer_name ?? "User");

          if (localStorage.getItem("profileImage")) {
            setprofileImage(localStorage.getItem("profileImage"));
          } else setprofileImage(userinfo?.image_url ?? "");
        }, 1000);
      }
    }
    // setprofileImage
  }, [Profile]);
  // userName,ProfileImage

  return (
    <div className="flex items-center z-10 justify-between dark:bg-black dark:text-white h-14   w-full shadow-md bg-white  fixed top-0 right-0 ">
      <h2
        className={`text-xl font-semibold ${
          i18n.language == "en" ? "ml-[32.5vh]" : "mr-[32.5vh]"
        }`}
      >
        {t(heading)}
      </h2>
      <div className="flex items-center">
        <LanguageSwitcher />
        <img
          className="mr-4"
          src={ring}
          alt=""
          onClick={() => {
            isDropdownNotificationVisible ? (
              setisDropdownNotificationVisible(!isDropdownNotificationVisible)
            ) : (
              <>
                {getorderNotifications()}
                {setisDropdownNotificationVisible(
                  !isDropdownNotificationVisible
                )}
              </>
            );
          }}
        />

        <div className="relative mx-4">
          <button
            className="p-2 pl-1 w-40 h-10 bg-none rounded-3xl border border-[#E3E7EF] cursor-pointer flex items-center"
            onClick={handleToggleDropdown}
          >
            {profileImage != "" ? (
              <>
                <img
                  className="mr-3 h-8 w-8 object-cover rounded-full"
                  src={profileImage}
                  width={30}
                  height={30}
                  alt="o"
                />
              </>
            ) : (
              <img className="w-8 h-8 " src={round} alt="" />
            )}
            <p
              className={`font-medium ${
                i18n.language == "en" ? "ml-1" : "mr-1"
              } `}
            >
              {(profileName.indexOf(" ") != -1 || profileName.indexOf(" ") != 1 ||profileName.indexOf(" ") != 0)
                ? profileName?.substring(0, profileName.indexOf(" "))
                : profileName}
              {/* {profileName} */}
            </p>
            <img
              className={` absolute ${
                i18n.language == "en" ? "right-3" : "left-3"
              }`}
              src={dropdown}
              alt="V"
            />
          </button>
          {isDropdownVisible && (
            <div className="absolute w-full top-full mt-1 dark:bg-black dark:text-white bg-white border rounded-lg shadow ">
              <div className="p-3 cursor-pointer" onClick={handleLogout}>
                <LogoutOutlined style={{ zIndex: 1 }} /> {t("logout")}
              </div>
            </div>
          )}
        </div>
      </div>
      {isDropdownNotificationVisible && (
        <div
          style={{
            width: "30%",
            height: "60vh",
            WebkitScrollSnapType: "block",
          }}
          className={`absolute  ${
            i18n.language == "en"
              ? "float-right right-10"
              : "float-left left-10"
          } custom-scrollbar  top-full  ml-auto dark:bg-black  bg-[#ecebeb] border rounded-lg shadow z-50 overflow-y-scroll overflow-x-hidden`}
        >
          {Notificationdata?.length == 0 ? (
            <div className=" flex h-full items-center justify-center">
              {t("No New Notifications")}
            </div>
          ) : (
            <>
              {Notificationdata?.map((item, index) => (
                // <Link to={`/Orderlist?orderDetail=${item[0]}`}>
                <div
                  key={index}
                  onClick={() => {
                    setisDropdownNotificationVisible(false);
                    navigate(`/Orderlist?orderDetail=${item[0]}`);
                    window.location.reload();
                  }}
                  className={` flex flex-col justify-center items-start h-10 w-80 text-xs rounded-sm mx-2  my-1 ${
                    item[1] ? "bg-[#f1f0f0]" : "bg-[#ffffff]"
                  }  `}
                >
                  <p className=" flex flex-row text-black ml-2">
                    <img
                      src={Notification}
                      alt="arrowright"
                      height={3}
                      width={13}
                      className="mr-2"
                    />{" "}
                    {t("new_order")}
                  </p>
                  <p className="text-[#3d3d3d]  ml-2">
                    {t("new_order_note")} {item[0]}
                  </p>
                </div>
                // </Link>
              ))}
            </>
          )}
        </div>
      )}
      {popup && <LogoutPopup />}
      {sessionexpired && <Sessionexpired />}
    </div>
  );
};

export default Navbar;
