import axios from "axios";
import { URLS } from "../API/API endpoints";

export const getMySubscription = async (store_id) => {
  try {
    const payload = {
      store_id: store_id,
    };
    const response = await axios.post(`${process.env.REACT_APP_API_URL1}${URLS.MY_SUBSCRIPTION}`, payload);
    return response?.data?.response;
  } catch (error) {
    return error;
  }
};

export const getSubscriptionPlans = async () => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL1}${URLS.SUBSCRIPTION_PLANS}`);
    return response?.data?.response;
  } catch (error) {
    return error;
  }
};
