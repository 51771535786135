import React from 'react';
import { css } from '@emotion/react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const ShimmerLoader = ({ loading }) => {
  const override = css`
    display: block;
    margin: auto;
    border-color: red; // Customize the color if needed
  `;

  
  return (
    <Stack spacing={1} className='mx-3'>
      <Skeleton variant="rectangular" width={"100%"} height={20} />
      <Skeleton variant="rectangular" width={"100%"} height={40} />
      <Skeleton variant="rectangular" width={"100%"} height={40} />
      <Skeleton variant="rectangular" width={"100%"} height={40} />
      <Skeleton variant="rectangular" width={"100%"} height={40} />
      <Skeleton variant="rectangular" width={"100%"} height={40} />
      <Skeleton variant="rectangular" width={"100%"} height={40} /> 
      <Skeleton variant="rectangular" width={"100%"} height={40} />
      <Skeleton variant="rectangular" width={"100%"} height={40} />
      <Skeleton variant="rounded" width={"100%"} height={40} />
      {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> */}
      <Skeleton variant="rectangular" width={"100%"} height={40} />
    </Stack>
  );

};

export default ShimmerLoader;
