import * as yup from "yup";
export const LoginSchema = yup.object({
  emailOrPhone: yup
    .string("Enter your email or phone number")
    .required("Required")
    .test(
      "is-email-or-phone",
      "Invalid email or phone number",
      function (value) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const phoneRegex = /^(?!0)\d{10}$|^0\d{10}$/;

        if (emailRegex.test(value)) {
          return true; // Valid email
        } else if (phoneRegex.test(value)) {
          return true; // Valid phone number
        } else {
          return false; // Invalid input
        }
      }
    ),
  // .test("is-email", "Invalid email format", function (value) {
  //   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  //   return emailRegex.test(value);
  // })
  // .test("is-phone", "Invalid phone number format", function (value) {
  //   const phoneRegex = /^(0|[1-9][0-9]{9})$/;
  //   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  //   return !emailRegex.test(value) && phoneRegex.test(value);
  // }),
  password: yup.string("Enter your password").required("Required"),
});

export const SignupSchema = yup.object({
  businessProfile: yup
    .string()
    .notOneOf([""], "Shop type is required")
    .oneOf(["Retailer", "Wholesale"], "Select business profile")
    .required("Business profile is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .matches(
      // /^(?!0\d{9}$)\d{10}$/,
      /^(?!0)\d{10}$|^0\d{10}$/,
      "Enter a valid Phone Number "
    ),
});

export const otpSchema = yup.object().shape({
  otp: yup
    .string()
    .required("OTP is required")
    .matches(/^\d{4}$/, "OTP must be exactly 4 digits"),
});

export const shopLocationSchema = yup.object().shape({
  address1: yup
    .string()
    .required("Address 1 is required")
    .matches(/^[a-zA-Z0-9\s]*$/, "Address 1 cannot contain special characters"),
  // address2: yup.string().matches(
  //   /^[a-zA-Z0-9\s]*$/,
  //   'Address 2 cannot contain special characters'
  // ).notRequired(),
  street: yup
    .string()
    .required("Street is required")
    // .matches(/^[a-zA-Z0-9\s]*$/, "Street cannot contain special characters"),
});

export const basicDetailSchema = yup.object().shape({
  profile: yup
  .number()
  .integer("Select Shop Type")
  .positive("Select Shop Type")
  .required("Select Shop Type")
  .test('not-zero', 'Select Shop Type', value => value !== 0),
  retailername: yup.string().required("Owner Name  is required"),
  storename: yup.string().required("Business name is required"),
  emailid: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      "Password must contain at least 1 uppercase letter, 1 number and 1 special character"
    )
    .matches(
      /^[^0-9!@#$%^&*]/,
      "Password cannot start with a number or special character"
    )
    .required("Password is required"),

    confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

//Business Proof schema
export const BusinessProofSchema = yup.object().shape({
  commercialregistrynumber: yup
    .string()
    .matches(/^\d{14}$/, "Commercial Registry Number must be 14 digits"),
  // .required("Commercial Registry Number is required"),
  taxcardnumber: yup
    .string()
    .matches(/^\d{9}$/, "Tax Card Number must be 9 digits"),
  // .required("Tax Card Number is required"),
  managerid: yup.string().matches(/^\d{14}$/, "Manager ID must be 14 digits"),
  // .required("Manager ID is required"),
  // commercialregistrynumber: yup
  //   .string()
  //   .required("Commercial Registry Number is required"),
  // taxcardnumber: yup.string().required("Tax Card Number is required"),
  // managerid: yup.string().required("Manager ID is required"),
});
export const profileBusinessProofSchema = yup.object().shape({
  commercial_num: yup
    .string()
    .matches(/^\d{14}$/, "Commercial Registry Number must be 14 digits"),
  // .required("Commercial Registry Number is required"),
  tax_card_num: yup
    .string()
    .matches(/^\d{9}$/, "Tax Card Number must be 9 digits"),
  // .required("Tax Card Number is required"),
  national_id: yup.string().matches(/^\d{14}$/, "Manager ID must be 14 digits"),
  // .required("Manager ID is required"),
  // commercialregistrynumber: yup
  //   .string()
  //   .required("Commercial Registry Number is required"),
  // taxcardnumber: yup.string().required("Tax Card Number is required"),
  // managerid: yup.string().required("Manager ID is required"),
});

//ForgetPassword schema
export const ForgetPasswordSchema = yup.object({
  emailOrPhone: yup
    .string("Enter  phone number")
    .required("Required")
    .test("is-email-or-phone", "Invalid email or phone number", (value) => {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      const phoneRegex = /^[0-9]{10,15}$/;
      return emailRegex.test(value) || phoneRegex.test(value);
    }),
});

export const NewPasswordSchema = yup.object({
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      "Password must contain at least 1 uppercase letter, 1 number and 1 special character"
    )
    .matches(
      /^[^0-9!@#$%^&*]/,
      "Password cannot start with a number or special character"
    )
    .required("Password is required"),
  // password: yup
  //   .string()
  //   .min(6, "Password should be at least 6 characters long")
  //   .required("Password is required"),
  confirmPassword: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      "Password must contain at least 1 uppercase letter, 1 number and 1 special character"
    )
    .matches(
      /^[^0-9!@#$%^&*]/,
      "Password cannot start with a number or special character"
    )
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),

  // confirmpassword: yup
  // .string()
  // .min(6, "Password must be at least 6 characters")
  // .matches(
  //   /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
  //   "Password must contain at least 1 uppercase letter, 1 number and 1 special character"
  // )
  // .matches(
  //   /^[^0-9!@#$%^&*]/,
  //   "Password cannot start with a number or special character"
  // )
  // .oneOf([yup.ref("password"), null], "Passwords must match")
  // .required("Please confirm your password"),
});

export const ChangePasswordSchema = yup.object({
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      "Password must contain at least 1 uppercase letter, 1 number and 1 special character"
    )
    .matches(
      /^[^0-9!@#$%^&*]/,
      "Password cannot start with a number or special character"
    )
    .required("Password is required"),
  // password: yup
  //   .string()
  //   .min(6, "Password should be at least 6 characters long")
  //   .required("Password is required"),

  confirmpassword: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      "Password must contain at least 1 uppercase letter, 1 number and 1 special character"
    )
    .matches(
      /^[^0-9!@#$%^&*]/,
      "Password cannot start with a number or special character"
    )
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Please confirm your password"),
});

//in profileprofile

export const ProfileBusinessProofSchema = yup.object().shape({
  commercialregistrynumber: yup
    .string()
    .matches(/^\d{14}$/, "Commercial Registry Number must be 14 digits"),
  // .required("Commercial Registry Number is required"),
  taxcardnumber: yup
    .string()
    .matches(/^\d{9}$/, "Tax Card Number must be 9 digits"),
  // .required("Tax Card Number is required"),
  managerid: yup.string().matches(/^\d{14}$/, "Manager ID must be 14 digits"),
  // .required("Manager ID is required"),
});

export const ProfileShopDetailSchema = yup.object().shape({
  // address_line_1: yup.string().required('Address line 1 is required'),
  // address_line_2: yup.string().required('Address line 2 is required'),
  // zipcode: yup.string().required('ZIP code is required'),
  // street: yup.string().required('Locality is required'),
  opening_time: yup.string().required("Opening time is required"),
  closing_time: yup.string().required("Closing time is required"),
  off_day_ids: yup
    .array()
    .optional(),
    // .min(1, "Please select at least one off day")
    // .required("Off days are required"),
  prepration_time: yup
    .string()
    // .typeError("Preparation time must be a number")
    .required("Preparation time is required"),
    // .min(1, "Preparation time must be at least 1 minute"),
  minimum_order_value: yup
    .number()
    .typeError("Minimum order value must be a number")
    .required("Minimum order value is required")
    .min(0, "Minimum order value cannot be negative"),
  shop_close: yup
    .string()
    .required("Please specify if online sale is enabled or disabled"),
});

export const InventoryOnboardSchema = yup.object().shape({
  product_id: yup.string().required("Product ID is required"),
  barcode: yup.number().optional("Barcode is required"),
  cost_price: yup
    .number()
    .required("Cost Price is required")
    .min(1, "Cost Price must be greater than or equal to 0"),
  sale_price: yup
    .number()
    .required("Sale Price is required")
    .min(1, "Sale Price must be greater than or equal to 0"),
  qty: yup
    .number()
    .required("Quantity is required")
    .min(1, "Quantity must be greater than or equal to 0"),
});

export const productRequestSchema = yup.object({
  name: yup
    .string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters"),
  // barcode: yup
  //   .number()
  //   .optional("Barcode is required")
  //   .positive("Barcode must be a positive number")
  //   // .matches(/^[0-9]+$/, 'Barcode must be a number')
  //   .min(11, "Barcode must be exactly 12 digits"),
  product_uom: yup.number().required("Product UOM is required"),
  costprice: yup
    .number()
    .required("Cost price is required")
    .positive("Cost price must be a positive number"),
  saleprice: yup
    .number()
    .required("Sale price is required")
    .positive("Sale price must be a positive number"),
  quantity: yup
    .number()
    .required("Quantity is required")
    .integer("Quantity must be an integer")
    .min(1, "Quantity must be at least 1"),
  image_url: yup.string()
  .required('Image URL is required'),
});

export const deliveryBoyschema = yup.object({
  image_url:yup.string().required("Image URL is required"), 
  name: yup.string().required("Full Name is required"),
  mobile:yup
  .string()
  .required("Phone number is required")
  .matches(
    // /^(?!0\d{9}$)\d{10}$/,
    /^(?!0)\d{10}$|^0\d{10}$/,
    "Enter a valid Phone Number "
  ),
  email: yup.string().email("Invalid email").required("Email is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      "Password must contain at least 1 uppercase letter, 1 number and 1 special character"
    )
    .matches(
      /^[^0-9!@#$%^&*]/,
      "Password cannot start with a number or special character"
    )
    .required("Password is required")
});

export const addUserSchema = yup.object().shape({
  url: yup.string().required('URL is required'),
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  store_user_type_id: yup.string().required('Permission is required'),
  mobile: yup.string().required('Phone number is required').matches(/^(?!0)\d{10}$|^0\d{10}$/, 'Invalid phone number format'),
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      "Password must contain at least 1 uppercase letter, 1 number and 1 special character"
    )
    .matches(
      /^[^0-9!@#$%^&*]/,
      "Password cannot start with a number or special character"
    )
    .required("Password is required")});
