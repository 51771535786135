import React from "react";
import { useTranslation } from "react-i18next";

const OrdercustomerDetails = ({
  customer,
  deliverydate,
  address_details,
  order_stage,
  Deliveryboy = "",
}) => {
  const {t,i18n}=useTranslation()
  let backgroundColor,
    textColor,
    borderRadius,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight;
  // Conditionally set styles based on order status
  if (order_stage === "Order Pending") {
    backgroundColor = "#999999";
  } else if (order_stage === "In Processing") {
    backgroundColor = "#f1c232  ";
  } else if (order_stage === "Ready For Delivery") {
    backgroundColor = "#93c47d";
  } else if (order_stage === "On the way") {
    backgroundColor = "#f1c232";
  } else if (order_stage === "Order Delivered") {
    backgroundColor = "#1b4497";
  } else {
    backgroundColor = "#E74C3C";
  }
  textColor = "#ffffff";
  paddingTop = "4px";
  paddingBottom = "4px";
  paddingLeft = "10px";
  paddingRight = "10px";
  borderRadius = "5px";

  return (
    <>
      <div className="mr-3">
        <div className="w-full md:w-[350px]  mt-3 rounded-lg overflow-hidden ">
          <div className="bg-[#C9ECF7] font-bold flex flex-row justify-between items-center text-lg px-5 py-2">
            {t('order_info')}{" "}
            <h4
              style={{
                backgroundColor,
                color: textColor,
                textAlign:"center",
                borderRadius,
                padding: `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`,
                fontSize:14,
                fontWeight:400,
                display: "inline-block",
                minWidth: "65px",
                alignItems: "center",
              }}
            >
              {(() => {
                switch (order_stage) {
                  case "Order Pending":
                    return "Pending";
                  case "In Processing":
                    return "Processing";
                  case "Ready For Delivery":
                    return "Ready";
                  case "On the way":
                    return "Out";
                  case "Order Delivered":
                    return "Delivered";

                  default:
                    return "Cancelled";
                }
              })()}
            </h4>
          </div>
          <div className="bg-white ">
            <div className="border-b border-gray-300 px-5 py-2">
              <div className="font-semibold">{t('customer_information')}</div>
              <div style={{ fontSize: "14px", color: "#303030" }}>
                {customer?.name}
              </div>
              <div style={{ fontSize: "14px", color: "#303030" }}>
                {" "}
                {customer?.mobile1}
              </div>
            </div>
            <div className="border-b border-gray-300 px-5 py-2 font">
              <div className="font-semibold">{t('delivery_address')}</div>
              {/* {console.log("addresssss>>>>>>>>>>", address_details)} */}
              <span style={{ fontSize: "14px", color: "#303030" }}>
                {address_details?.street_1 != "" &&
                  address_details?.street_1 + ", "}
                {address_details?.street_2 != "" &&
                  address_details?.street_2 + ", "}
                {address_details?.area != "" &&
                  address_details?.street_area + ", "}
                {address_details?.locality != "" &&
                  address_details?.locality + ", "}
                {address_details?.city != "" && address_details?.city + ", "}
                {address_details?.state != "" && address_details?.state + ", "}
                {address_details?.pincode}
              </span>
              {/* <div>{address_details?.country}</div> */}
            </div>
            <div className="border-b border-gray-300  px-5 py-2">
              <div className="font-semibold">{t('delivery_schedule')}</div>
              <div style={{ fontSize: "14px", color: "#303030" }}>
                {deliverydate}
              </div>
            </div>
            <div className="border-b border-gray-300  px-5 py-2">
              <div className="font-semibold">{t('order_stage')}</div>
              <div style={{ fontSize: "14px", color: "#303030" }}>
                {order_stage}
              </div>
            </div>
            {/* {Deliveryboy!=null && */}
           {Deliveryboy != null && <div className="border-b border-gray-300  px-5 py-2">
              <div className="font-semibold">{t('delivery_boy')}</div>
              <div style={{ fontSize: "14px", color: "#303030" }}>
                {Deliveryboy != null ? Deliveryboy : "Not Selected"}
              </div>
            </div>}
            {/* } */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdercustomerDetails;
