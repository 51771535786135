// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './components/Translation/en.json';
import translationAR from './components/Translation/ar.json';

const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  }
};

const loadLanguage = () => {
  let storedLanguage = null;
  // if (typeof window !== 'undefined') {
    // Check if window (browser environment) is defined
    storedLanguage = localStorage.getItem('selectedLanguage');
  // }
  return storedLanguage 
};


// Function to get language from localStorage or fallback to 'en'
const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: callback => {
     const storedLanguage = loadLanguage();
    const detectedLanguage = storedLanguage || 'en'; // Default to 'en' if not found in localStorage
    callback(detectedLanguage);
  },
  init: () => {},
  cacheUserLanguage: () => {}
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false // React already escapes values to prevent XSS
    }
  });

export default i18n;
