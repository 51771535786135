import React, { useEffect } from "react";
import checked from "../assets/Cancel.svg";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";


const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    padding: "20px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
    marginTop: "30px",
  },
  image: {
    width: "70px",
    display: "block",
    margin: "20px auto",
    marginBottom: "20px",
  },
  description: {
    textAlign: "center",
    fontSize: "0.875rem",
    color: "#5C5C5C",
    marginBottom: "20px",
  },
};

const AlreadyRegisteredModal = ({message}) => {
  const {t,i18n}=useTranslation()
  const navigate=useNavigate();
 

  const handleGoToOrders = () => {
    // Navigate to the dashboard route
    localStorage.clear();
   navigate("/login")
  };
  useEffect(() => {
    setTimeout(() => {
        handleGoToOrders()
    }, 2000);
  }, []);

  return (
    <div>
      <div style={styles.overlay} ></div>
      <div style={styles.container}>
        <img
          src={checked} // Provide the path to your checked image
          alt="Checked"
          style={styles.image}
        />
        <p style={styles.title}>{t('already_registered')}</p>
        <p style={styles.description}>
                 {message}
        </p>
        <div className="flex justify-center gap-5 mt-7 mb-7 ">
          {/* <Link to="/login"> */}
          <button
            type="button"
            className="w-[180px] h-[45px] bg-primaryBlue text-white rounded-lg font-semibold"
             onClick={handleGoToOrders} // Call handleGoToDashboard onClick
            // onClick={onClose}
          >
           Login
          </button>
          
        </div>
      </div>
    </div>
  );
};

export default AlreadyRegisteredModal;
