import React from "react";
import {Image}  from "antd";
import signupImage from "../../assets/signup.svg";
import LoginSignup from "../../components/Login-signup/Frame";

const Signup = () => {
  return (
    <div className="flex flex-row min-h-[100vh] max-h-[100vh] justify-center items-center  md:flex-row overflow-hidden bg-white ">
    <div className="w-1/2 md:w-1/2  overflow-hidden">
     
      
        <img

        // style={{maxHeight:819,minWidth:590}}
          src={signupImage}
          alt="Signup Image"
          layout="responsive"
          //  width={1000}
          //  height={600}
          //objectFit="contain"
          objectPosition="top"
        />
      </div>
      <div className="w-full h-full md:w-1/2 bg-white text-black p-6 flex flex-col justify-center" >
        <LoginSignup
          welcomeText="Welcome"
          subText="Join us and take your store to the next level!"
          login={false}
          belowText="Already have an account?"
        />
      </div>
    </div>
  );
};

export default Signup;
