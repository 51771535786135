import React from "react";
import loginImage from "../../assets/login.svg";
import LoginSignup from "../../components/Login-signup/Frame";

const Login = () => {
  return (
    <div>
      <div className="flex flex-row min-h-[100vh] max-h-[100vh] justify-center items-center   md:flex-row overflow-hidden bg-white ">
      <div className="w-1/2 md:w-1/2 object-contain   overflow-hidden">
       
          
          <img
            src={loginImage}
            alt="Login Image"
            layout="responsive"
            //  width={400}
              height={100}
           objectFit="contain" // Adjusted objectFit to "contain"
            objectPosition="top"
            className=""
          />
        </div>
        <div className="w-full h-full md:w-1/2 bg-white text-black  p-6 flex flex-col justify-center">
          <LoginSignup
            welcomeText="Welcome Back"
            subText="Login to your account"
            login={true}
            belowText="Don’t have an account?"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
