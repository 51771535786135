import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb, Button, Dropdown, Image, Select } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { routes } from "../../../Routes";
import { InputBox } from "../../../components/CustomWidgets/InputBox";
import { useFormik, isInteger } from "formik";
import SelectBox from "../../../components/CustomWidgets/SelectBox";
// import {Image}  from "antd";
import "../../../styles.css";
import styles from "./autocomplete.module.css";
import {
  InventoryAddproduct,
  InventorySearchonboard,
} from "../../../API  Functions/InventoryFunctions";
import { debounce } from "@mui/material";
import { Link } from "react-router-dom";
import Autocomplete from "react-autocomplete";
import { InventoryOnboardSchema } from "../../../components/validationSchema";
import BarcodeScanner from "react-barcode-reader";
import InventoryOnboardPopup from "../../../Popups/InventoryOnboardPopup";
import Navbar from "../../../components/Orders/Navbar";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const InventoryOnboard = () => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state.login?.user?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);

  const [value, setValue] = useState("");
  const [requestProduct, setrequestProduct] = useState(false);
  const [Productoptions, setProductoptions] = useState([]);
  const [ProductImage, setProductImage] = useState("");
  const [popup, setpopup] = useState(false);
  const [message, setmessage] = useState(" ");
  const [scrollPosition, setScrollPosition] = useState(0);
  const autocompleteRef = useRef(null);
  const [quantity, setquantity] = useState(0);

  const searchResult = debounce((value) => {
    InventorySearchonboard(user_id, store_id, value).then((res) => {
      console.log("response in", res);
      if (
        res?.message === "Product is not present in the Myginnee Catalouge."
      ) {
        if (isInteger(value) && value?.length > 10) {
          formik.setFieldValue("barcode", value);
        }
        console.log("called");
        setProductoptions([]);
        setrequestProduct(true);
      } else if (res?.data?.length == 1 && isInteger(value)) {
        setValue(res?.data?.[0]?.product_name);
        setProductImage(res?.data?.[0]?.image);
        setProductoptions(res?.data);
        formik.setFieldValue("barcode", res?.data?.[0]?.barcode);
        setrequestProduct(false);
      } else {
        setProductoptions(res?.data) && setrequestProduct(false);
      }
    });
  }, 500);

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;
    const itemHeight = 60; // Adjust this based on your item height
    const index = Math.floor(scrollTop / itemHeight);
    setScrollPosition(index);
  };

  const formik = useFormik({
    initialValues: {
      // name: "",
      product_id: "",
      barcode: "",
      category: "",
      cost_price: "",
      sale_price: "",
      qty: "",
    },

    validationSchema: InventoryOnboardSchema,
    // validateOnChange: false,
    // validateOnBlur: false,
    onSubmit: (values, { resetForm }) => {
      InventoryAddproduct(user_id, store_id, values).then((res) => {
        if (
          res.message == "Store Inventory Updated Sucessfully !!!" ||
          res.message == "Inventory Update Sucessfully"
        ) {
          setpopup(true);
          resetForm();
          setmessage(
            res.message == "Store Inventory Updated Sucessfully !!!"
              ? "Store Inventory Updated Sucessfully"
              : "Inventory Update Sucessfully"
          );
          setProductImage("");
          setValue("");
        }
      });
    },
  });

  const openMenu = () => {
    if (autocompleteRef.current) {
      autocompleteRef.current.openMenu();
    }
  };

  const handleButtonClick = (event) => {
    console.log("working", event);
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      if (autocompleteRef.current) {
        // Ensure autocompleteRef.current.handleKeyDown exists and is a function
        if (typeof autocompleteRef.current.handleKeyDown === "function") {
          autocompleteRef.current.handleKeyDown(event);
        }
      }
    }
  };

  return (
    <>
      <div>
        <Navbar heading={"inventory_onboard"} />
      </div>
      <div className="bg-[#F6F7F9] min-h-[100vh] pb-6 dark:bg-black ">
        <div className="flex flex-col  mx-auto ">
          <div className="mr-4 ml-3 font-extrabold mt-14">
            <Breadcrumb
              separator={<DoubleRightOutlined />}
              items={[
                { title: t("inventory"), href: "/inventory" },
                { title: t("inventory_onboard") },
              ]}
              className="relative top-3"
            />
            {/* <Breadcrumb separator={<DoubleRightOutlined />}>
              <Breadcrumb.Item>
                <Link to="/inventory">{t("inventory")}</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{t("inventory_onboard")}</Breadcrumb.Item>
            </Breadcrumb> */}
          </div>
          <div className="flex flex-row items-end justify-start">
            <div
              className={` relative bottom-2  ${
                i18n.language == "en" ? "float-left  ml-3" : "float-right  mr-3"
              }`}
            >
              <h1 className="mt-8 font-semibold text-xl">{t("add_product")}</h1>
            </div>
            <div className="flex flex-row justify-end relative bottom-6  w-180 mt-4 mr-4">
              {/* <button
                type="submit"
                className="w-52 h-[45px] border rounded-lg bg-[#ffffff] text-primaryBlue leading-8 mt-8 mr-2 border-primaryBlue"
              >
                Cancel
              </button> */}
              {/* <Link href="/inventory">
                <button className="w-48 h-10 border rounded-lg bg-primaryBlue text-white leading-8 mt-8 mr-2">
                  Finish
                </button>
              </Link> */}
            </div>
            {/* <div className="grid grid-cols-2 gap-x-3 relative bottom-6 float-right w-180 mt-6">
          <Button className="w-52 h-14 border rounded-lg bg-[#ffffff] text-blue leading-8 mt-8 mr-2">
            Cancel
          </Button>
          <Button className="w-52 h-14 border rounded-lg bg-[#1D5C96] text-white leading-8 mt-8 mr-2">
            Finish
          </Button>
        </div> */}
          </div>
          <div className="mx-2 h-10 bg-[#C9ECF7] rounded-t-xl">
            <p className="mt-2 ml-4">{t("product_details")}</p>
          </div>
          <div className="flex flex-row justify-between   bg-white text-black  mx-2 border rounded-b-xl">
            <div className="m-2 w-2/4 p-4 ">
              <span className="relative">
                <label htmlFor="default-search" className="sr-only">
                  {t("product")}
                </label>
                <div
                  onKeyDown={handleButtonClick}
                  className={styles.autocompleteWrapper}
                  //  className={`${"autocompletee"} relative inset-y-0 right-0  flex items-center min-w-100 min-h-20 max-h-96 overflow-y-auto scrollbar-hidden `}
                  style={{
                    border: "1px solid #B2BEB5",
                    borderRadius: 8,
                    minHeight: 40,
                    width: "100%",
                  }}
                >
                  <Autocomplete
                    menuStyle={{ width: "100%" }}
                    className="cursor-pointer"
                    value={value}
                    items={Productoptions || []}
                    renderInput={(props) => (
                      <input {...props} value={value != "" ? value : ""} />
                    )}
                    getItemValue={(item) => item.product_name}
                    renderMenu={(item) => (
                      <div>
                        {item ? (
                          <div
                            className="  border rounded-lg custom-scrollbar "
                            onScroll={handleScroll}
                            style={{
                              maxHeight: 300,
                              overflowY: "scroll",
                              display: "block",
                            }}
                          >
                            {item}{" "}
                          </div>
                        ) : (
                          <div>{t("no_product_found")}</div>
                        )}
                        {/* {item && item.length ? (
        item.map((product) => (
          <div key={product.id}>{product.product_name}</div>
        ))
      ) : (
        <div>No Data Found</div> 
      )} */}
                      </div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div className="  flex flex-row items-center cursor-pointer  gap-2 p-1">
                        {/* {console.log("item rendered", item)} */}
                        {/* {item?.image ? (
                            <Image
                              src={item?.image}
                              style={{
                                maxHeight: 40,
                                maxWidth: 50,
                                minHeight: 30,
                                minWidth: 40,
                                objectFit: "contain",
                              }}
                              height={50}
                              width={30}
                              alt="Image"
                            />
                          ) : (
                            <svg
                              className=" float-left h-10 w-12 text-gray-300"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                                // clip-rule="evenodd"
                              />
                            </svg>
                          )} */}
                        {item.product_name}
                      </div>
                    )}
                    onChange={(event, val) => {
                      setValue(val);
                      setquantity(0);
                      if (val?.length < 3) {
                        setProductoptions([]);
                        setProductImage("");
                        formik.setFieldValue("barcode", "");
                        setrequestProduct(false);
                      } else if (val?.length >= 3) {
                        searchResult(val);
                      } else {
                        setrequestProduct(false);
                        setProductoptions([
                          { id: 0, product_name: "No Data Found" },
                        ]);
                      }
                    }}
                    onSelect={(event, val) => {
                      setValue(event);
                      setProductImage(val.image);
                      formik.setFieldValue("product_id", val.id);
                      formik.setFieldValue("barcode", val.barcode);
                      setquantity(val?.available_qty);
                    }}
                    inputProps={{
                      placeholder: t("search_product"),
                      width: "100%",
                      cursor: "pointer",
                    }}
                  />

                  {/* <div className="absolute inset-y-0 right-0 flex items-right pr-3">
                      <Image
                        src={Group}
                        alt="Barcode"
                        className=" text-gray-500 dark:text-gray-400"
                      />
                    </div> */}
                </div>
              </span>
              <BarcodeScanner
                onScan={(data) => {
                  setValue(data);
                  console.log("barcode called");
                  searchResult(data);
                }}
                onError={(err) => {
                  console.error(err);
                }}
                facingMode="environment"
                className="absolute inset-y-0 right-0 mt-2 mr-4 pointer-events-none cursor-pointer z-10"
              />
              {requestProduct && (
                <Link
                  to={`/inventory/ProductRequest${
                    formik?.values?.barcode?.length > 10
                      ? `?barcode=${formik?.values?.barcode}`
                      : ""
                  }`}
                  style={{ color: "blue", textDecoration: "underline" }}
                  className="flex flex-col items-end mt-2"
                >
                  {t("request_product")}{" "}
                </Link>
              )}
              <form onSubmit={formik.handleSubmit}>
                <span className="mt-5 block leading-[0px]">
                  <label>{t("barcode")}</label>
                  <InputBox
                    type="number"
                    className={`${
                      formik.errors.barcode
                        ? "border-red" // Apply red border when touched or notification shown
                        : ""
                    }`}
                    name="barcode"
                    placeholder={t("barcode")}
                    max={9999999999999}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.barcode}
                    error={formik.errors.barcode}
                    touched={formik.errors.barcode}
                    disabled={formik.values.barcode?.length >= 13}
                  />
                  {/* {formik.errors.barcode && (
                    <div
                      className="text-red-500 mt-5 mb-5"
                      style={{ color: "red" }}
                    >
                      {formik.errors.barcode}
                    </div>
                  )} */}
                </span>
                {/* <span className="mt-5 block leading-[0px]">
                  <label>Category</label>
                  <SelectBox
                    name="category"
                    defaultValue="Select"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.category}
                    options={[
                      { value: "Category", label: "Category" },
                      { value: "Subcategory", label: "Subcategory" },
                    ]}
                    disabled
                  />
                </span> */}
                <div className="flex flex-row gap-5 mt-5 mb-5">
                  <span className="w-full leading-[0px]">
                    <label>{t("quantity")}</label>
                    <InputBox
                      type="number"
                      name="qty"
                      className={`${
                        formik.errors.qty
                          ? "border-red" // Apply red border when touched or notification shown
                          : ""
                      }`}
                      placeholder={t("enter_quantity")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.qty}
                      error={formik.errors.qty}
                      touched={formik.touched.qty}
                    />
                    {/* {formik.errors.qty && (
                    <div
                      className="text-red-500 mt-5 mb-5"
                      style={{ color: "red" }}
                    >
                      {formik.errors.qty}
                    </div>
                  )} */}
                    {quantity != 0 && (
                      <div
                        className="text-green-500 mt-2  text-xs"
                        style={{ color: "green" }}
                      >
                        Available Quantity:{quantity}
                      </div>
                    )}
                  </span>
                  <span className="w-full leading-[0px]">
                    <label>{t("cost_price")}</label>
                    <InputBox
                      type="number"
                      className={`${
                        formik.errors.cost_price
                          ? "border-red" // Apply red border when touched or notification shown
                          : ""
                      }`}
                      name="cost_price"
                      placeholder={t("enter_cost_price")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cost_price}
                      error={formik.errors.cost_price}
                      touched={formik.touched.cost_price}
                    />
                    {/* {formik.errors.cost_price && (
                      <div
                        className="text-red-500 mt-5 mb-5"
                        style={{ color: "red" }}
                      >
                        {formik.errors.cost_price}
                      </div>
                    )} */}
                  </span>
                </div>
                <span className="inline-block w-[47.6%]  leading-[0px]">
                  <label>{t("sale_price")}</label>
                  <InputBox
                    type="number"
                    name="sale_price"
                    className={`${
                      formik.errors.sale_price
                        ? "border-red" // Apply red border when touched or notification shown
                        : ""
                    }`}
                    placeholder={t("enter_sale_price")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sale_price}
                    error={formik.errors.sale_price}
                    touched={formik.touched.sale_price}
                  />
                  {/* {formik.errors.sale_price && (
                      <div
                        className="text-red-500 mt-5 mb-5"
                        style={{ color: "red" }}
                      >
                        {formik.errors.sale_price}
                      </div>
                    )} */}
                </span>
                <div className="flex gap-5 mt-8  ">
                  <button
                    type="submit"
                    className="w-48 h-10 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center gap-2"
                  >
                    <span>{t("save_add")}</span>
                  </button>
                  <Button
                    type="submit"
                    className="w-48 h-10 text-primaryBlue font-semibold rounded-lg  border-primaryBlue"
                    onClick={() => {
                      formik.resetForm();
                      setValue("");
                      setProductImage();
                    }}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </form>
              {/* {Productoptions } */}
            </div>
            <div className="m-2 p-4 ">
              {/* {ProductImage && ( */}
              <span className="flex items-center justify-center w-72 h-72 border rounded-xl  relative ">
                {ProductImage ? (
                  <Image
                    style={{ maxHeight: 280, maxWidth: 280 }}
                    src={ProductImage}
                    height={280}
                    width={280}
                    alt="Image"
                    objectFit="contain"
                    className="rounded-xl"
                  />
                ) : (
                  <svg
                    className=" float-left h-10 w-12 text-gray-300"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                      // clip-rule="evenodd"
                    />
                  </svg>
                )}
              </span>
              {/* )} */}
              <div className="flex gap-3 relative top-10 left-20">
                {/* <span className="w-16 h-16 border rounded-xl"></span> */}
                {/* <span className="w-16 h-16 border rounded-xl"></span>
                <span className="w-16 h-16 border rounded-xl"></span>
                <span className="w-16 h-16 border rounded-xl"></span> */}
              </div>
            </div>
          </div>
        </div>
        {popup && (
          <InventoryOnboardPopup
            message={message}
            onClose={() => setpopup(false)}
          />
        )}
      </div>
    </>
  );
};

export default InventoryOnboard;
