import { createSlice } from "@reduxjs/toolkit";
import { signupUser } from "../Actions/signupActions";

const signupSlice = createSlice({
  name: "signup",
  initialState: {
    user: null,
    isLoading: false,
    error: null,
  },
  reducers: {
    signupRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    signupSuccess: (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    signupFailure: (state, action) => {
      state.user = null;
      state.isLoading = false;
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(signupUser.rejected, (state, action) => {
        console.error('API Request Error:', action.error);
        state.user = null;
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { actions: signupActions } = signupSlice;
export default signupSlice.reducer;
