// CustomPagination.js
import React from "react";

const CustomPagination = ({
  data,
  pageIndex,
  pageCount,
  canPreviousPage,
  canNextPage,
  gotoPage,
  previousPage,
  nextPage,
  pageSize,
  setPageSize,
}) => {
  return (
    <div className=" flex items-center justify-between  mt-4 mr-2 mb-4">
      {/* Page Count */}
      <div className="flex items-center ml-2">
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          className="ml-2 border border-gray-300 bg-[#EEEEEE] rounded-md px-2 py-1"
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
             {pageSize}
            </option>
          ))}
        </select>
      </div>
      {/* Results */}

      {/* Previous and Next buttons */}
      <div className="flex gap-3 ml--8">
        <button
          className="bg-[#EEEEEE] rounded-full cursor-pointer w-6"
          onClick={() => previousPage()}
           disabled={!canPreviousPage}
        >
          {"<"}
        </button>
        {/* <div className="text-center px-8">
          Results {pageIndex * pageSize + 1}-
          {Math.min((pageIndex + 1) * pageSize, pageCount * pageSize)} of{" "}
          {pageCount * pageSize}
        </div> */}
         <div className="text-center px-8">
          {(pageIndex-1)*pageSize}{"-"}{(data+(pageIndex-1)*pageSize)<(pageIndex * pageSize)?(data+(pageIndex-1)*pageSize):(pageIndex * pageSize)} 
         </div>
        <button
          className="bg-[#EEEEEE] rounded-full cursor-pointer w-6"
          onClick={() =>{nextPage()}}
          disabled={!canNextPage}
        >
          {">"}
        </button>
      </div>
      {/* Go to page */}
      <div>
        {/* Go to:
        <input
          type="number"
          defaultValue={pageIndex + 1}
          min="1"
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          className="border border-gray-300 bg-[#EEEEEE] rounded-md px-2 mx-4 py-1 w-12 "
        /> */}
      </div>
    </div>
  );
};

export default CustomPagination;
