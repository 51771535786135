import { URLS } from "../API/API endpoints";
import axiosInstance from "../AxiosInstance";



export const getOrderNotifications = async (user_id, store_id,) => {
 

  try {
    const payload = {
           store_id:store_id,
    };
    const response = await axiosInstance.post(URLS.ORDER_NOTIFICATION,payload);
  
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getStageIds = async (user_id,store_id) => {

  try {
   
    const response = await axiosInstance.get(URLS.GET_STAGE_IDS);
   
    return response.data;
  } catch (error) {
    
    return error;
  }
};
export const getstoreordercount = async (user_id, store_id,) => {
  try {
    const payload = {
           store_id:store_id,
    };
    const response = await axiosInstance.post(URLS.GET_STORE_ORDER_COUNT,payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getKPIs = async (user_id, store_id,) => {
  try {
    const payload = {
      user_id:user_id,
     store_id:store_id,
    };
    
    const response = await axiosInstance.post(URLS.GET_KPI_DETAIL, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getOrderdata = async (user_id, store_id,id, limit, offset) => {
  const input = {
    user_id:user_id,
   store_id:store_id,
    limit: limit,
    offset: offset,
    stage_id: id ? id : 0,
  };

  try {
    // await axiosInstance.post(URLS.GET_ALL_ORDERS, input).then((response) => {
    //   return response.data.data;
    // });
    const response = await axiosInstance.post(URLS.GET_ALL_ORDERS, input);
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const orderSearchFilter = async (user_id, store_id,
  query,
  stage_id,
  payment_method,
  date,
  delivery_partner_id = 0,
  limit = 20,
  offset = 0
) => {
  const input = {
    user_id:user_id,
   store_id:store_id,
    query: query,
    stage_id: stage_id ? stage_id : 0,
    payment_method: payment_method !== "Payment" ? payment_method : "",
    date_from: date?.[0] ?? "",
    date_to: date?.[1] ?? "",
    delivery_partner_id: delivery_partner_id,
    limit: limit,
    offset: offset,
  };

  try {
    const response = await axiosInstance.post(URLS.ORDER_SEARCH_FILTER, input);
    return response?.data?.data;
  } catch (error) {
    console.log(error);
  }
};


