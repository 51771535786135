// Third component
import React, { useState, useEffect } from "react";
import SearchBox from "../CustomWidgets/SearchBox";
import { useFormik } from "formik";
import SelectBox from "../CustomWidgets/SelectBox";
import { DatePicker, Space } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import { getdataDeliveryPartner } from "../../API  Functions/DeliveryPartnerFunctions";
import "../../styles.css";
import Sessionexpired from "../../modals/Sessionexpired";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const Third = ({ activeHeading, handleFilter,storecount }) => {
  const {t,i18n}=useTranslation();
  const user_id = useSelector(state=>state.login?.user?.user_id)
  const store_id = useSelector(state=>state.login?.user?.store_id)

  const [Deliverypartners, setDeliverypartners] = useState([{id:"",name:""}]);
  const [sessionexpired,setsessionexpired]=useState(false)
  const [todayDate, settodayDate] = useState("");
  const options = [
    { value: 0, label: "All Orders" },
    { value: 1, label: "Order Pending" },
    { value: 2, label: "In Progress" },
    { value: 3, label: "Ready For Delivery" },
    { value: 4, label: "On The Way" },
    { value: 5, label: "Delivered" },
    // { value: 6, label: "Completed" },
    { value: 7, label: "Failed/Cancelled" },
  ];
  const today = dayjs().subtract(15, "day").format(dateFormat);
   const fifteenDaysLater =  dayjs().format(dateFormat);

  const formik = useFormik({
    initialValues: {
      orderStatus: 0,
      today: ["",""
        // dayjs(today, dateFormat),
        // dayjs(fifteenDaysLater, dateFormat),
      ],
      payment: "Payment",
      deliveryBoy: 0,
      searchvalue: "",
    },
    onSubmit: (values) => {
      const sendvalues = {
        orderStatus: values.orderStatus,
        today: todayDate == "1970/01/01" ? "" : todayDate,
        payment: values.payment,
        deliveryBoy: values.deliveryBoy,
        searchvalue: values.searchvalue,
      };
      handleFilter(sendvalues);
    },
  });
  const handleDateChange = (date) => {
    formik.setFieldValue("today", date);
   
    const dateObj = new Date(date);

    // Format the date to yyyy/mm/dd format
    // const formattedDate = dateObj
    //   .toLocaleDateString("en-GB", {
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    //   })
    //   .split("/")
    //   .reverse()
    //   .join("/");
    // // Update formik values when date changes
    // console.log("date", formattedDate);
    // settodayDate(formattedDate);

    settodayDate([date?.[0].format(dateFormat),date?.[1].format(dateFormat)])
    formik.handleSubmit();
    // formik.setFieldValue("today", formattedDate);
  };

  useEffect(() => {
    {
      // (activeHeading === 4 || activeHeading === 5 || activeHeading === 6) &&
      getdataDeliveryPartner(user_id,store_id).then((response) => {
        if(response==401) setsessionexpired(true)
        else {
          setDeliverypartners(response);}
      });
    }
  }, [activeHeading]);

  // const handleChange=(e)=>{

  //    formik.setFieldValue("mobile", e.target.value);

  //    changeerror()// Call the parent onChange function to update formik state
  //   };
  // }

  const disabledDate = (current) => {
    // Disable dates before 2019
    return current && current < dayjs("2019-01-01");
  };

  
  return (
    <>
      <div className="flex justify-between items-end ">
        <SearchBox
          searchResult={(values) => {
            formik.setFieldValue("searchvalue", values);
            formik.handleSubmit();
          }}
        />
        <div className="flex  gap-x-1  items-end justify-end ">
          {/* Render Order Status select box */}
          <SelectBox
          style={{ width:177}}
                   // defaultValue={formik.values.orderStatus}
                   width={200}
            defaultValue={options.find(
              (option) => option.value === activeHeading
            )}
            placeholder="Order Status"
            name="orderStatus"
            onChange={(value) => {
              formik.handleChange(value); // Call handleChange with the selected value
              formik.handleSubmit(); // Call handleSubmit after handleChange
            }}
            options={[
              { value: 0, label: `${t('all_orders')} (${storecount?.[0]?.order_count??0})` },
              { value: 1, label: `${t('order_pending')}  (${storecount?.[1]?.order_count??0})` },
              { value: 2, label: `${t('in_progress')}  (${storecount?.[2]?.order_count??0})` },
              { value: 3, label: `${t('ready_for_delivery')}   (${storecount?.[3]?.order_count??0})` },
              { value: 4, label: `${t('on_the_way')}   (${storecount?.[4]?.order_count??0})` },
              { value: 5, label: `${t('order_delivered')}   (${storecount?.[5]?.order_count??0})` },
              // { value: 6, label: "Completed" },
              { value: 7, label: `${t('order_cancelled')}  (${storecount?.[7]?.order_count??0})` },
            ]}
            onBlur={formik.handleBlur}
            value={
              options?.find((option) => option?.value === activeHeading)?.value
            }
            error={formik.errors.orderStatus}
            touched={formik.touched.orderStatus}
          />

          <RangePicker
            className="w-10 h-[2.5rem] range-picker-placeholder"
            style={{height:"2.5rem", minWidth: 220 }}
            // defaultValue={[
            //   dayjs(today, dateFormat),
            //   dayjs(fifteenDaysLater, dateFormat),
            // ]}
            onChange={handleDateChange}
            value={formik.values.today}
            disabled={[false, false]}
            disabledDate={disabledDate}
            placement="bottomLeft" 
          />
          {/* <DatePicker
            // defaultValue={formik.values.today}
            onChange={handleDateChange}
            onBlur={formik.handleBlur}
            value={formik.values.today}
            format="YYYY/MM/DD" // Specify date format
            className="h-10 mt-6 "
            style={{ minWidth: 115 }}
          /> */}
          {/* Conditionally render the Payment select box */}
          {/* {activeHeading === 2 && ( */}
          <SelectBox
          style={{ width:98}}
            defaultValue={formik.values.payment}
            name="payment"
            onChange={(value) => {
              formik.handleChange(value); // Call handleChange with the selected value
              formik.handleSubmit(); // Call handleSubmit after handleChange
            }}
            options={[
              { value: "Payment", label: t('payment') },
              { value: "COD", label: t('cod') },
              { value: "Paid", label: t('paid')},
            ]}
            onBlur={formik.handleBlur}
            value={formik.values.payment}
            error={formik.errors.payment}
            touched={formik.touched.payment}
            width={200}
           
          />
          {/* )} */}
          {/* Conditionally render the Delivery Boy select box */}
          {(activeHeading === 0 ||
            activeHeading === 4 ||
            activeHeading === 5 ||
            activeHeading === 6) && (
            <>
              {/* {DeliveryPartners.map((item) => (
                      <>
                        {console.log("item", item)}
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      </> */}
{Array.isArray(Deliverypartners) ?
      <SelectBox
      
        style={{  width: 125 }}
        defaultValue={formik.values.deliveryBoy}
        name="deliveryBoy"
        placeholder="Delivery Boy"
        onChange={(value) => {
          formik.handleChange(value);
          formik.handleSubmit();
        }}
        options={[
          { value: 0, label: t('delivery_boy') },
          ...Deliverypartners.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        ]}
        onBlur={formik.handleBlur}
        value={formik.values.deliveryBoy}
        error={formik.errors.deliveryBoy}
        touched={formik.touched.deliveryBoy}
        width={230}
      />
      :
  
              <SelectBox
              style={{height:"2.4rem", width:125}}
              
                            defaultValue={formik.values.deliveryBoy}
                name="deliveryBoy"
                placeholder="Delivery Boy"
                onChange={(value) => {
                  formik.handleChange(value); // Call handleChange with the selected value
                  formik.handleSubmit(); // Call handleSubmit after handleChange
                }}
                // options={[
                //   { value: 0, label: "Delivery Boy" },

                //  ...Deliverypartners?.map((item) => ({
                //     value: item.id,
                //     label: item.name,
                //   })),
                // ]}
                options={[
                  { value: "Delivery Boy", label: "Delivery Boy" },
                  { value: "Jhon Doe", label: "Jhon Doe" },
                ]}
                onBlur={formik.handleBlur}
                value={formik.values.deliveryBoy}
                error={formik.errors.deliveryBoy}
                touched={formik.touched.deliveryBoy}
                width={230}
                
              />
}
            </>
          )}
        </div>
      </div>
      {sessionexpired && <Sessionexpired/> }
    </>
  );
};

export default Third;
