export const getdatahtml = (data, store_data) => {
  console.log("store_data", store_data);

  let htmlProductLines = "";
  // let totalValue = products?.reduce((totalQty, item) => totalQty + (item?.price_unit * (item?.count || item?.product_uom_qty)), 0) + data?.order_details?.shipping_charges + (data?.order_details?.offer ? data?.order_details?.offervalue : 0)

  if (data?.order_line_details) {
    data?.order_line_details.forEach((item, index) => {
      htmlProductLines += `
        <tr key=$ {index}>
          
          <td style="text-align: left;">${item?.product_name}</td>
           <td style="text-align: end;">${item?.delivered_qty}</td>
          <td style="text-align: end;">${item?.sell_price}</td>
          <td style="text-align: end;">${
            item?.sell_price *
            (item?.delivered_qty ? item?.delivered_qty : item?.order_qty)
          }</td>
        </tr>
      `;
    });
  }

  //   let offerTab = `
  //   <tr>
  //   <td style="text-align: center; vertical-align: text-top;"></td>
  //   <td style="text-align: left;">${("Ramadan Offer")}</td>
  //   <td style="text-align: end;"></td>

  //   <td style="text-align: end;"></td>
  //   <td style="text-align: end;">$ {data?.order_details?.offervalue?.toFixed(2)}</td>
  // </tr>
  // `
  // let order_id = data?.order_details?.order_no;

  // data?.order[0]?.payment_data.forEach((item) => {
  //   if (item?.name == "return") {
  //     Change = item?.amount;
  //   }
  //   if (item?.payment_method == "Bank") {
  //     Change = 0;
  //     Cash = item?.amount;
  //   }
  //   if (item?.name == false && item?.payment_method == "Cash") {
  //     Cash = item?.amount;
  //   }
  // });

  const htmlContent = `
<!DOCTYPE html>
<html>
  <head>
    <style>
    body {
      font-family: Arial, sans-serif;
       width:"100%";
      color: #000000;
      font-size: 10px;
    }
   
    .pos-receipt {
      text-align: center;
     
    }
   
    // .pos-receipt-contact div{
    //   font-size:12 px;
     margin-top: 5px;
    // }
    .pos-receipt-contact .cashier {
      // margin-top: 10px;
      // margin-bottom: 10px;
      border-top: 1px dashed black;
    }
 
    // .pos-receipt-contact .cashier div {
    //   margin-top: 5px;
    //   margin-bottom: 5px;
    // }
 
    .pos-receipt-contact table {
      width: 100%;
      margin-bottom: 5px;
    }
 
    .pos-receipt-contact table td:first-child {
      width: 100%;
    }
 
    .receipt-orderdata {
      width: 100%;
      font-size:10 px;
      margin-bottom: 5px;
      margin-top:5px
    }
    
    .receipt-orderdata th,
    .receipt-orderdata td {
      padding: 0px;
    }
    .receipt-orderdata td {
     font-size: 10px;
    }
    .receipt-orderdata td {
      text-align: center;
      // font-weight: semi-bold;
     
    }
 
    .pos-receipt-right-align {
      float: right;
    }
    .pos-receipt .pos-receipt-right-align {
      // float: right;
      font-size:10 px;
    }
    .orderDetail{
      display: flex;
      flex-direction:  $ {i18n.language === 'ar' ? 'row-reverse' : 'row'};
      justify-content: space-between;
  
     
    }
    .orderDetail div, .cashierDetail{
      font-size:10px;
    }
    .total{
      border-top: 1px dashed black;
       display: flex;
       flex-direction: row;
      justify-content: space-between;
      padding-top: 10px;
      margin-top: 10px;
    }
    .total div, .cashierDetail{
      font-size:9px;
    }
    .lines{
      // margin-top:5px;
      // margin-bottom:5px;

    }
      .order-delivery{
       margin: 10px 0px;
      }
    .receiptHead{
      text-align: center;
    font-weight: semi-bold;
    // padding: 5px 0px 5px;
    font-size: 14px;
    margin: 10px 0px;
    width:100%
    }
    
    .pos-receipt-bottomSection
    {
      border-top: 1px dashed black;
      margin-top:10px;
      font-size:14px;
     
    }
    .bottomSection img{
      width:100px;
    }
    .after-footer{
      margin-top:0px;
    }
  </style>
    </head>
  <body>
    <div class="pos-receipt-container">
      <div class="pos-receipt">
        <div style="text-align: center;">
        <img style="max-height: 50px; max-width: 40px;" src="${store_data?.image_url}" alt="My Barcode Image" width="100">
        </div>
        <div class="pos-receipt-contact">
          <div class="lines" >${store_data?.store_name}</div>
          <div class="lines">${store_data?.mobile}</div>
          <div class="lines">${store_data?.email}</div>
        </div>
        <div class="receiptHead">
          <strong >${"Receipt"}</strong>
        </div>
       
        <div style="text-align: $ {i18n.language === 'ar' ? 'row-reverse' : 'row'};border-top: 1px dashed black;
        border-bottom: 1px dashed black; padding:5px 0px ;">
         
 
          <div class="orderDetail">
          <div class="lines">
           <strong>${"Order No:"}</strong>
          </div>
          <div class="lines">
          ${data?.order_number}
          </div>
         </div>
 
         <div class="orderDetail">
         <div class="lines">
         <strong>${"Order Date:"}</strong>
         </div>
         <div class="lines">
        
         ${data?.order_date}
         </div>
        </div>
 
        <div class="orderDetail">
        <div class="lines">
        <strong>${"Customer:"}</strong>
        </div>
        <div class="lines">
        ${data?.customer_details?.name}
        </div>
       </div>
 
       <div class="orderDetail">
       <div class="lines">
         <strong>${"Customer Phone:"}</strong>
       </div>
       <div class="lines">
       ${data?.customer_details?.mobile1}
       </div>
      </div>
 
        </div>
       
        <div>
          <table class="receipt-orderdata">
            <colgroup>
              
              <col width="60%">
              <col width="10%">
              <col width="10%">
              <col width="15%">
            </colgroup>
            <tr >
            
              <td style="text-align: left; font-weight: semi-bold; margin-top: 5px"><strong>${"Description"}</strong></td>
              <td style="text-align: end; font-weight: semi-bold; margin-top: 5px"><strong>${"Qty"}</strong></td>
              <td style="text-align: end; font-weight: semi-bold; margin-top: 5px"><strong>${"Rate"}</strong></td>
              <td style="text-align: end; font-weight: semi-bold; margin-top: 5px "><strong>${"Amount"}</strong></td>
            </tr>
            ${htmlProductLines}


            <br/>
            <tr style={margin-top:5px;} class="order-delivery">
         
          <td style="text-align: left;">${"Delivery Fee"}</td>
          <td style="text-align: end;"></td>
         
          <td style="text-align: end;"></td>
          <td style="text-align: end; ">${data?.delivery_charge??0}</td>
        </tr>
        ${data?.discount_amount ?? ""}
       
       
          </table>
        </div>
        <div class="total">
          <div><strong>${"TOTAL"}</strong></div>
          <div><strong>EGP ${data?.order_total_amt}</strong></div>
        </div>
      <div class="pos-receipt-bottomSection"><br>
     ${"Your Technology Partner"}<br>
        <img style="max-height: 50px; max-width: 100px;" src="https://live-production-assets.s3.me-central-1.amazonaws.com/myginne.webp" alt="myGinne Logo">
  </div>
    </div>
  </body>
</html>
`;
  return htmlContent;
};
