export const URLS = {
  //Login,Signup and Logout Api
  LOGIN_API_URL: "retailer-login",
  SIGNUP_OTP_API_URL: "retailer-signup",
  VERIFY_OTP_API_URL: "retailer-signup-verify",
  LOGOUT_API_URL: "retialer-logout",

  //Forgot Password and update Password Api
  FORGOT_PASS_OTP_API_URL: "retailer-forgot-password",
  UPDATE_PASSWORD: "retailer-forgot-password",

  //Orders
  GET_ALL_ORDERS_API_URL: "GetAllOrders", //

  //Inventory
  GET_ALL_inventory_API_URL: "GetAllInventory",

  //Register Api
  USER_SIGNUP_API_URL: "retailer-registration-value-update",

  SHOP_LOCATION_API_URL: "GetDeliveryArea", //

  //profile
  //getapis
  GET_STORE_BASIC_DETAIL: "getstorebasicdetails",
  GET_STORE_SHOP_DETAIL: "getshopdetails",
  GET_STORE_BUSINESSPROOF: "getstorebussinessproof",
  GET_BDO_DETAILS: "getbdodetails",

  //POSTapis
  SET_STORE_BASIC_DETAIL: "updatebasicdetails",
  SET_STORE_SHOP_DETAIL: "updateshopdetails",
  SET_STORE_BUSINESSPROOF: "updatebussinessproof",

  //Delivery Partner
  DELIVERY_PARTNER_CREATE: "retialer-createdeliverypartner", //
  DELIVERY_PARTNER_DETAILS: "retialer-getdeliverypartner", //Get API
  UPDATE_DELIVERY_PARTNER: "retialer-updatedeliverypartner",
  GET_DELIVERY_PARTNER_ORDERS: "delivery_partner_orders",
  SEARCH_DELIVERY_PARTNER: "searchdeliverypartner",

  //Orders Api
  GET_STORE_ORDER_COUNT: "storeordercount",
  GET_KPI_DETAIL: "getordersdetailsamount",
  GET_STAGE_IDS: "getorderstages",
  GET_ALL_ORDERS: "getordersdetails",
  UPDATE_ORDERLINE: "updateorderline",
  SEARCH_ORDER: "searchorder",
  GET_ORDER_CANCEL_REASONS: "getordercancelreason",
  UPDATE_CANCEL_REASON: "updateordercancelreason",
  UPDATE_ORDER_STAGE: "updateorderstages",
  ORDER_SEARCH_FILTER: "searchfilter",
  ORDER_NOTIFICATION: "/ordernotification",

  //Inventory Api
  GET_UOM: "GetUom",
  GET_RETAILER_PRODUCT_CATEGORY: "getretailerproductcategory",
  GET_ALL_PRODUCTS: "getretailerallproduct",
  GET_INVENTORY_PRODUCT_LOGS: "getproductlog",
  UPDATE_STORE_PRODUCT_DETAIL: "updatestoreproductdetails",
  GET_INCOMPLETE_PRODUCTS: "getallincompleteproduct",
  CATEGORY_FILTER: "categoryfilter",
  SEARCH_INVENTORY: "searchinventory",
  STOCKOUT_SEARCH_INVENTORY: "searchoutstockinventory",
  HIGHON_SEARCH_INVENTORY: "searchhighinventory",
  LOWON_SEARCH_INVENTORY: "searclowhinventory",
  PRODUCT_REQUEST: "/productrequest",

  SEARCH_INVENTORY_ONBOARD: "searchinventoryonboard",
  ADD_PRODUCT: "updateinventoryonboard",
  INVENTORY_REPORT: "inventory_report",
  FETCH_GS1_DATA: "fetch_gs1_data",

  //User Management
  CREATE_STORE_USER: "createstoreuser",
  GET_ALL_STORE_USERS: "getallstoreusers",
  GET_USER_TYPES: "getstoreusertype",
  UPDATE_STORE_USER: "UpdateRetailerUser",
  INACTIVE_STORE_USER: "/inactiveretaileruser",

  //subscription
  MY_SUBSCRIPTION: "getSubscriptionstion",
  SUBSCRIPTION_PLANS:"getSubscriptionstionPlans"
};
