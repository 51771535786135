import React, { useEffect } from "react";

import {Image}  from "antd";
import checked from "../assets/checked.svg";
import Cancel from "../assets/Cancel.svg";
import { Link } from "react-router-dom";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    padding: "20px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
    marginTop: "30px",
  },
  image: {
    width: "70px",
    display: "block",
    margin: "20px auto",
    marginBottom: "20px",
  },
  description: {
    textAlign: "center",
    fontSize: "0.875rem",
    color: "#5C5C5C",
    marginBottom: "20px",
  },
};

const ProductrequestModal = ({ onClose,isnew=false}) => {
 

  useEffect(() => {
    setTimeout(() => {
      onClose();
    }, 2000);
  }, []);


  return (
    <div>
      <div style={styles.overlay} ></div>
      <div style={styles.container}>
        <img
          src={isnew ?checked:Cancel} // Provide the path to your checked image
          alt="Checked"
          style={styles.image}
        />
        <p style={styles.title}>{isnew ?"Successful !":"Already Exist !"}</p>
        <p style={styles.description}>
          {isnew ? "You have successfully requested you product.":"This product already exists"} 
        </p>
        
      </div>
    </div>
  );
};

export default ProductrequestModal;
