import React from "react";
import { useFormik } from "formik";
import { InputBox } from "../CustomWidgets/InputBox";
import UploadMedia from "../upload-media";
import Button from "../CustomWidgets/Button";
import { BusinessProofSchema } from "../validationSchema";
import { useTranslation } from "react-i18next";
import { Image } from "antd";

function BusinessProof({ onDataSubmit ,savedata}) {
  const {t,i18n}=useTranslation()
  const formik = useFormik({
    initialValues: {
      commercialregistrynumber: savedata?savedata[0]?.name: "",
      commercialregistrynumberurl: savedata?savedata[0]?.url:"",

      taxcardnumber: savedata?savedata[1]?.name:"",
      taxcardnumberurl: savedata?savedata[1]?.url:"",

      managerid:savedata?savedata[2]?.name:"",
      manageridurl: savedata?savedata[2]?.url:"",
    },
    validationSchema:BusinessProofSchema,
     
    onSubmit: (values) => {
      const api_payload = {
        store_id: localStorage.getItem("store_id"),
        page: "Bussiness Proof",
        bussiness_proof: [
          {
            name: values.commercialregistrynumber,
            url: values.commercialregistrynumberurl,
            doc_type: "registration_number",
          },
          {
            name: values.taxcardnumber,
            url: values.taxcardnumberurl,
            doc_type: "tax_card_number",
          },
          {
            name: values.managerid,
            url: values.manageridurl,
            doc_type: "national_id",
          },
        ],
      };
      onDataSubmit("businessProofData", api_payload);
    },
  });

  const handleurlgenerated = (values) => {
       formik.setFieldValue(values.name, values.url);
  };

  return (
    <>
      <div>
                {/* <div className=""> */}
          <h1 className="text-xl font-bold">Business Proof</h1>
          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-2 mt--4 text-sm gap-x-7 pb-12">
              <div>
                <InputBox
                  label={t('commercial_registry_number')}
                  name="commercialregistrynumber"
                  placeholder={t('enter_commercial_registry_number')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.commercialregistrynumber}
                  error={(formik.values.commercialregistrynumberurl !== "" && formik.values.commercialregistrynumber === "") || formik.errors.commercialregistrynumber}
                  touched={(formik.values.commercialregistrynumberurl !== "" && formik.values.commercialregistrynumber === ""|| formik.errors.commercialregistrynumber)}
                />
                 { formik.errors.commercialregistrynumber && (
                <div className="text-red text-xs">{formik.errors.commercialregistrynumber}</div>
              )}
              <div className="w-[363px] md:w-full mt-2">
              <div className=" rounded-lg border border-dashed border-primaryBlue px-2  py-3">
                <div
                  className="flex flex-row justify-start gap-x-4 "
                  style={{ minHeight: 70, maxHeight: 70 }}
                >
                  {formik.values?.commercialregistrynumberurl != "" ? (
                    <Image
                      src={formik.values?.commercialregistrynumberurl}
                      height={70}
                      width={70}
                      alt="image"
                    />
                  ) : (
                    <svg
                      className=" float-left  h-20 w-24 text-gray-300"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        // clip-rule="evenodd"
                      />
                    </svg>
                  )}

                  <div class="flex flex-row text-sm leading-6 pl-20 text-gray-600">
                    <label
                      for="file-upload"
                      class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                    >
                      {/* <span>
                      <Image src={upload} width={18} />
                    </span>
                    <span className="ml-2">Change </span> */}
                      <UploadMedia
                        foldername={"store_app_UAT"}
                        onfileAdd={"commercialregistrynumberurl"}
                        urlgenerated={handleurlgenerated}
                        // keyname={"tax_card_num_url"}
                        imageurl={formik.values.commercialregistrynumberurl}
                        message={(message)=>!message }

                        // Adjust field name as needed
                      />
                    </label>
                  </div>
                  {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
                </div>
              </div>
            </div>
                {/* <UploadMedia
                  foldername={"legal"}
                  onfileAdd={"commercialregistrynumberurl"} // Adjust field name as needed
                  urlgenerated={handleurlgenerated}
                  imgurl={formik.values.commercialregistrynumberurl}
                  message={(message)=>!message }
                /> */}
                {/* <UploadMedia /> */}
              </div>

              <div>
                <InputBox
                  label={t('taxcard_number')}
                  name="taxcardnumber"
                  placeholder={t('enter_taxcard_number')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.taxcardnumber}
                  error={(formik.values.taxcardnumberurl !== "" && formik.values.taxcardnumber === "") || formik.errors.taxcardnumber}
                  touched={(formik.values.taxcardnumberurl !== "" && formik.values.taxcardnumber === "") || formik.errors.taxcardnumber}
                />
                 { formik.errors.taxcardnumber && (
                <div className="text-red text-xs">{formik.errors.taxcardnumber} </div>
              )}
               <div className="w-[363px] md:w-full mt-2">
              <div className=" rounded-lg border border-dashed border-primaryBlue px-2  py-3">
                <div
                  className="flex flex-row justify-start gap-x-4"
                  style={{ minHeight: 70, maxHeight: 70 }}
                >
                  {formik.values?.taxcardnumberurl != "" ? (
                    <Image
                      src={formik.values?.taxcardnumberurl}
                      height={70}
                      width={70}
                      alt="image"
                    />
                  ) : (
                    <svg
                      className=" float-left  h-20 w-24 text-gray-300"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        // clip-rule="evenodd"
                      />
                    </svg>
                  )}

                  <div class="flex flex-row text-sm leading-6 pl-20 text-gray-600">
                    <label
                      for="file-upload"
                      class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                    >
                      {/* <span>
                      <Image src={upload} width={18} />
                    </span>
                    <span className="ml-2">Change </span> */}
                      <UploadMedia
                        foldername={"store_app_UAT"}
                        onfileAdd={"taxcardnumberurl"}
                        urlgenerated={handleurlgenerated}
                        // keyname={"tax_card_num_url"}
                        imageurl={formik.values.taxcardnumberurl}
                        message={(message)=>!message }

                        // Adjust field name as needed
                      />
                    </label>
                  </div>
                  {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
                </div>
              </div>
            </div>
                {/* <UploadMedia
                  foldername={"legal"}
                  onfileAdd={"taxcardnumberurl"}
                  urlgenerated={handleurlgenerated}
                  imgurl={formik.values.taxcardnumberurl}
                  message={(message)=>!message }
                  // Adjust field name as needed
                /> */}
              </div>
              <div>
                <InputBox
                  label={t('managerid')}
                  name="managerid"
                  placeholder={t('enter_nationalid')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.managerid}
                  error={(formik.values.manageridurl !== "" && formik.values.managerid === "") || formik.errors.managerid}
                  touched={(formik.values.manageridurl !== "" && formik.values.managerid === "") || formik.errors.managerid}
                />
                 { formik.errors.managerid && (
                <div className="text-red text-xs">{formik.errors.managerid}</div>
              )}
               <div className="w-[363px] md:w-full mt-2">
              <div className=" rounded-lg border border-dashed border-primaryBlue px-2  py-3">
                <div
                  className="flex flex-row justify-start gap-x-4"
                  style={{ minHeight: 70, maxHeight: 70 }}
                >
                  {formik.values?.manageridurl != "" ? (
                    <Image
                      src={formik.values?.manageridurl}
                      height={70}
                      width={70}
                      alt="image"
                    />
                  ) : (
                    <svg
                      className=" float-left  h-20 w-24 text-gray-300"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        // clip-rule="evenodd"
                      />
                    </svg>
                  )}

                  <div class="flex flex-row text-sm leading-6 pl-20 text-gray-600">
                    <label
                      for="file-upload"
                      class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                    >
                      {/* <span>
                      <Image src={upload} width={18} />
                    </span>
                    <span className="ml-2">Change </span> */}
                      <UploadMedia
                        foldername={"store_app_UAT"}
                        onfileAdd={"manageridurl"}
                        urlgenerated={handleurlgenerated}
                        // keyname={"tax_card_num_url"}
                        imageurl={formik.values.manageridurl}
                        message={(message)=>!message }

                        // Adjust field name as needed
                      />
                    </label>
                  </div>
                  {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
                </div>
              </div>
            </div>
                {/* <UploadMedia
                  foldername={"legal"}
                  onfileAdd={"manageridurl"}
                  urlgenerated={handleurlgenerated}
                  imgurl={formik.values.manageridurl}
                  message={(message)=>!message }
                  // Adjust field name as needed
                /> */}
              </div>
             
            </div>
            <button
              type="submit"
              className={`absolute right-0 bottom-0 w-[185px] h-[45px] ${(formik.values.manageridurl !== "" && formik.values.managerid === "") || (formik.values.taxcardnumberurl !== "" && formik.values.taxcardnumber === "") || (formik.values.commercialregistrynumberurl !== "" && formik.values.commercialregistrynumber === "" || (formik.errors.managerid || formik.errors.taxcardnumber || formik.errors.commercialregistrynumber)) ? "bg-primaryBluedisabled":"bg-primaryBlue"}
            }  text-white rounded-lg font-semibold float-right `}
              disabled={(formik.values.manageridurl !== "" && formik.values.managerid === "") || (formik.values.taxcardnumberurl !== "" && formik.values.taxcardnumber === "") || (formik.values.commercialregistrynumberurl !== "" && formik.values.commercialregistrynumber === "")  }
            >              {t('next')}
            </button>
           
          </form>
        </div>
      {/* </div> */}
    </>
  );
}

export default BusinessProof;
