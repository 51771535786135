import React, { useState } from "react";
import { useFormik } from "formik";
import { Password } from "../../components/CustomWidgets/InputBox";

import { routes } from "../../Routes";
import { Button } from "antd";
import { forgotPassword } from "../Actions/ForgotPassword";
import { useDispatch } from "react-redux";
import PasswordUpdated from "../../modals/PasswordUpdated";
import { ChangePasswordSchema, NewPasswordSchema } from "../validationSchema";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const ChangePassword = ({ payload }) => {
  const {t,i18n} =useTranslation()
  const navigate=useNavigate();
  const [passwordUpdated, setPasswordUpdated] = useState(false); // State to manage showing PasswordUpdated modal
  const [Error,setError]=useState(null)
 
  const dispatch = useDispatch();

  const forgothandleDataSubmit = async (values) => {
    const input = {
      mobile: "0000000000",
      country_code: "+20",
      otp: "5409",
      user_id: payload.user_id,
      password: values.password,
      old_password: values.old_password,
      page: "update_password",
    };

    try {
      const response = await dispatch(forgotPassword(input));

      if (
        response?.payload &&
        response?.payload.status === 200 &&
        response?.payload.message === "Password Updated successfully."
      ) {
        setTimeout(() => {
          setPasswordUpdated(true); // Set passwordUpdated to true to show the modal
        }, 1000);
      } else if (response?.payload?.message === "Old Password not matched.") {
        setError(true)
      }
      
      else  console.log("Password update failed");
      
    } catch (error) {
      console.error("Error in forgot password dispatch:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      confirmpassword: "",
    },
    validationSchema:ChangePasswordSchema,
    onSubmit: (values) => {
      forgothandleDataSubmit(values);
    },
  });

  return (
    <>
      {passwordUpdated && ( // Conditionally render the PasswordUpdated modal
        <PasswordUpdated onClose={() => setPasswordUpdated(false)} />
      )}
      
      <div className="font-medium text-xl leading-10 ml-7 mt-4 flex flex-row">
       {t('change_password')} </div>
      <div className="font-normal text-lg leading-8 ml-7">
      {t('change_password_note')}
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="font-normal  text-[14px] text-[#5C5C5C] grid mx-7">
          <div>
            <Password
            show={0}
              label= {t('current_password')}
              name="old_password"
              placeholder= {t('enter_current_password')}
              onChange={(val)=>{setError(null)
                formik.handleChange(val)}}
              onBlur={formik.handleBlur}
              value={formik.values.old_password}
              error={formik.errors.old_password}
              touched={formik.touched.old_password}
              secureTextEntry
            />
          </div>
          {Error && (
        <div className=" text-red text-sm h-2 mt-0 ml-1">
         Incorrect Password
        </div>
       )

       }
          <div>
            <Password
              label= {t('new_password')}
              name="password"
              placeholder= {t('new_password')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.errors.password}
              touched={formik.touched.password}
            />
          </div>
          <div>
            <Password
              label= {t('confirm_password')}
              name="confirmpassword"
              placeholder= {t('enter_confirm_password')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmpassword}
              error={formik.errors.confirmpassword}
              touched={formik.touched.confirmpassword}
            />
          </div>
         <p className="flex flex-row mt-3">{t('want_reset')} <p className="text-sm text-primaryBlue  ml-2 cursor-pointer" onClick={() => navigate(routes.forgotPassword)}> {t('click_here')}</p></p>
      
          <div className="flex mt-4 mb-10  gap-5">
            <button
             className="w-[180px] h-[45px] bg-primaryBlue text-white mt-4 font-semibold rounded-lg"
              type="submit"
            >
               {t('confirm')}
            </button>
            {/* <Button
              className=" w-52 h-14 border rounded-lg bg-[#1D5C96] text-white leading-8 mt-8 "
              onClick={() => router.push(routes.forgotPassword)}
            >
              Forgot Password
            </Button> */}
          </div>
        </div>
      </form>
    </>
  );
};

export default ChangePassword;
