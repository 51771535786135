import UserManagement from "../../../components/Setting/UserManagement";
import React, { useState, useEffect } from "react";
import { Image } from "antd";
import menu from "../../../assets/Images/menudots.svg";
import plus from "../../../assets/Images/plus.svg";
import { Dropdown } from "antd";
import AddUser from "../../../components/Setting/AddUser";
import { Breadcrumb } from "antd";
import {
  DoubleRightOutlined,
  DeleteOutlined,
  EditOutlined,
  StopOutlined,
} from "@ant-design/icons";
import Sidebar from "../../../components/Orders/sidebar";
import Navbar from "../../../components/Orders/Navbar";
import { routes } from "../../../Routes";
import {
  blockStoreUser,
  getStoreUsers,
} from "../../../API  Functions/UserMangement";
import { useSelector } from "react-redux";
import Blockuserpopup from "../../../modals/Blockuserpopup";
import { useTranslation } from "react-i18next";
// Dropdown data array

//

const Setting = () => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state.login?.user?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);

  // This is for use in add user button to open a new component
  const [showComponent, setShowComponent] = useState(false);
  const [storeuser, setstoreuser] = useState(null);

  const [popup, setpopup] = useState(false);
  const [blockdata, setblockdata] = useState([]);
  const [status, setstatus] = useState(1);

  // This is for use in users box details
  const [data, setData] = useState([]);
  //

  const getStoreusers = () => {
    getStoreUsers(user_id, store_id).then(
      (res) => res?.message == "sucess" && setData(res?.data)
    );
  };

  useEffect(() => {
    getStoreusers();
  }, []);

  const blockstoreuser = async (val, block) => {
    const response = await blockStoreUser(
      user_id,
      store_id,
      val?.user_id ?? 0,
      val?.user_record_link_id ?? 0,
      block
    );
    if (response?.data?.message === "User Inactive Successfully") {
      console.log("response", response?.data);
      setstatus(block);
      setblockdata(val);
      setpopup(true);
      getStoreusers();
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <>
          <EditOutlined className="pr-2" />
          {t("edit")}
        </>
      ),
    },
    {
      type: "divider",
    },

    {
      key: "2",
      label: (
        <a
          className=" text-[#0F1828] font-normal"
          target="_blank"
          rel="noopener noreferrer"
          href="#"
        >
          <StopOutlined className="pr-2" />
          {t("block")}
        </a>
      ),
    },
    {
      type: "divider",
    },

    // {
    //   key: "3",
    //   label: (
    //     <a
    //       className=" text-[#0F1828] font-normal text-base"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       href="#"
    //     >
    //       <DeleteOutlined className="pr-2" />
    //       Delete
    //     </a>
    //   ),
    // },
  ];

  return (
    <>
      <div className="flex">
        {/* <Sidebar /> */}
        <div className="flex flex-col bg-[#F6F7F9] min-h-[93vh]  flex-1 mt-14">
          <Navbar heading="settings" />
          <div className="mr-4 ml-3 font-extrabold">
            <Breadcrumb
              separator={<DoubleRightOutlined />}
              items={[
                { title: t("settings"),href:routes.settings.userManagement },
                { title: t("user_management") },
              ]}
              className="relative mt-3"
            />
            {showComponent ? (
              <AddUser
                onClose={() => {
                  setShowComponent(false);
                  setstoreuser(null);
                  getStoreusers();
                }}
                isEdit={storeuser}
              />
            ) : (
              <>
                <div className="flex justify-between items-center">
                  <div className="font-semibold text-xl mt-2 ">
                    {t("admin")}
                  </div>
                  <button
                    onClick={() => setShowComponent(true)}
                    className={`font-semibold w-40   h-10 border  rounded-lg bg-primaryBlue text-white text-base   flex justify-center items-center gap-2`}
                    type="submit"
                  >
                    <img src={plus} alt="plus" />
                    <p className="">{t("add_user")}</p>
                  </button>
                </div>
                <div className="bg-[#FFFFFF] shadow-sm rounded-xl grid mt-4 h-32">
                  <UserManagement />
                </div>
                {data?.length > 0 && (
                  <div className="font-semibold text-xl mt-6">
                    {t("allusers")}
                  </div>
                )}
                <div className="grid grid-cols-3 gap-x-8 mt-1 mb-4">
                  {data.map((val, i) => (
                    <div className="w-[345px] md:w-full h-30 shadow-sm hover:shadow-lg rounded-xl bg-[#FFFFFF] mt-4 pb-2">
                      <span className="flex flex-row  justify-between items-center ">
                        <span className="mx-2">
                          {" "}
                          {val?.is_active == true? (
                            <p className=" text-green-500 font-semibold text-3xl">
                              •
                            </p>
                          ) : (
                            <p className=" text-red font-semibold text-3xl">
                              •
                            </p>
                          )}
                        </span>
                        <Dropdown
                          menu={{
                            items: [
                              {
                                key: "1",
                                label: (
                                  <p
                                    onClick={() => {
                                      setstoreuser(val);
                                      setShowComponent(true);
                                    }}
                                  >
                                    <EditOutlined className="pr-2" />
                                    {t("edit")}
                                  </p>
                                ),
                              },
                              {
                                type: "divider",
                              },

                              {
                                key: "2",
                                label: (
                                  <p
                                    onClick={() =>
                                      blockstoreuser(
                                        val,
                                        val?.is_active == true? 0 : 1
                                      )
                                    }
                                  >
                                    <StopOutlined className="pr-2" />
                                    {val?.is_active == true
                                      ? t("block")
                                      : t("unblock")}
                                  </p>
                                ),
                              },
                              {
                                type: "divider",
                              },

                              // {
                              //   key: "3",
                              //   label: (
                              //     <p>
                              //       <DeleteOutlined className="pr-2" />
                              //       Delete
                              //     </p>
                              //   ),
                              // },
                            ],
                          }}
                          trigger={["click"]}
                          placement="bottomRight"
                          arrow
                        >
                          <img
                            src={menu}
                            alt="menu"
                            className="float-right cursor-pointer"
                          />
                        </Dropdown>
                      </span>

                      <div className="flex items-center">
                        {val?.url?.length >= 30 ? (
                          <img
                            className="h-16 w-16 object-cover rounded-full ml-4"
                            src={val.url}
                            alt=""
                          />
                        ) : (
                          <img
                            className="h-16 w-16 object-cover rounded-full ml-4"
                            src="https://www.w3schools.com/images/picture.jpg"
                            alt=""
                          />
                        )}
                        <div className="ml-4">
                          <div className="font-medium text-[#272728]">
                            {val.first_name + " " + val.last_name}
                          </div>
                          <div className="text-[#999999]">{val.user_type}</div>
                        </div>
                      </div>
                      <div className="ml-20 mt-2 flex flex-row justify-between gap-4 text-sm font-normal mr-2">
                        <span>
                          {" "}
                          {t("createdon")} : {val.createddate}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {popup && (
              <Blockuserpopup
                onClose={() => setpopup(false)}
                data={blockdata}
                status={status}
              />
            )}
            {/* <p className='ml-20 mt-20'>Yet to be integrated</p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
