import { useSelector } from "react-redux";
import { URLS } from "../API/API endpoints";
import axiosInstance from "../AxiosInstance";




// Assuming URLS is correctly imported and contains the SEARCH_ORDER URL

export const getdata = async (user_id,store_id,searchid, limit = 10, offset = 0) => {
  try {
    const payload = {
      user_id:user_id,
     store_id:store_id,
      query: searchid.lastIndexOf(",")!=-1?searchid.substring(searchid.lastIndexOf(",")+1):searchid,
      limit: limit,
      offset: offset,
    };
    const response = await axiosInstance.post(URLS.SEARCH_ORDER, payload);
    if (response.data.message === "Success") {
      
      return response.data.data[0];
    } else { 
      return response;
    }
  } catch (error) {
    return error;
  }
};

//Function to update the Quantity during order processing
export const quantityUpdate = async (
  user_id,store_id,
  searchid,
  qty,
  order_id,
  order_line_id,
  sell_price,
  product_id = "",
  barcode = ""
) => {
  try {
    const payload = {
      user_id:user_id,
     store_id:store_id,
      qty: qty,
      order_id: order_id,
      order_line_id: order_line_id,
      product_id: product_id,
      barcode: barcode,
      sell_price: sell_price,
    };
    const response = await axiosInstance.post(URLS.UPDATE_ORDERLINE, payload);
  
    if (response.data.message === "Success") {
          return response.data.data[0];
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

//Get cancel reasons
export const getcancelReasons = async () => {
  try {
    const response = await axiosInstance.get(URLS.GET_ORDER_CANCEL_REASONS);
    if (response.data.meessage === "Success") {
      return response.data.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const updateordercancelreason = async (user_id,store_id,order_id, cancel_reason_id) => {
  const payload = {
    user_id:user_id,
   store_id:store_id,
    order_id: order_id,
    cancel_reason_id: cancel_reason_id,
  };
  try {
    const response = await axiosInstance.post(
      URLS.UPDATE_CANCEL_REASON,
      payload
    );
    if (response.data.meessage === "Success") {
      return response.data.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

// Update Order stage

export const updateorderStage = async (
  user_id,store_id,
  order_id,
  stage_id,
  delivery_partner_id = 0,delivery_type_id=0
) => {
  const payload = {
    user_id:user_id,
   store_id:store_id,
    order_id: order_id,
    stage_id: stage_id,
    delivery_partner_id: delivery_partner_id,
    delivery_type_id: delivery_type_id
  };
  try {
    const response = await axiosInstance.post(URLS.UPDATE_ORDER_STAGE, payload);
    if (response?.data?.meessage === "Success" ) {
      
      return response?.data?.data;
    } 
    if(response?.data?.message === "Order update sucessfully")
      {
        return response?.data
      }
    else {
      return response;
    }
  } catch (error) {
    return error;
  }
};
