import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputBox, Password } from "../CustomWidgets/InputBox";
import SelectBox from "../CustomWidgets/SelectBox";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { SignupSchema, basicDetailSchema } from "../validationSchema";
import axios from "axios";
import { Image, Select } from "antd";
import FlagIcon from "../../assets/egypt.svg";
import { Input } from "antd";
import "../../styles.css";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

function BasicDetails({ onDataSubmit, email, mobile, savedata }) {
  const { t, i18n } = useTranslation();
  const [shoptype, setshoptype] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(
        "https://backend-api.myginne.com/V2/19/ShopType",
        { lang: "ar_002" }
      );

      setshoptype(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      mobile:
        typeof window !== "undefined"
          ? localStorage?.getItem("UserPhone")
          : mobile,
      country_code: "+20",
      user_type: savedata?.user_type ?? "Retailer",
      page: "basic_details",
      emailid: savedata?.emailid ?? "",
      storename: savedata?.storename ?? "",
      retailername: savedata?.retailername ?? "",
      profile: savedata?.profile ?? 0,
      password: savedata?.password ?? "",
      confirmPassword: "",
    },
    validationSchema: basicDetailSchema,
    onSubmit: (values) => {
      onDataSubmit("basicDetailsData", values);
    },
  });

  return (
    <div className="container w-full px-4">
      <h1 className="text-xl font-bold mb-2">{t("basic_details")}</h1>
      <form onSubmit={formik.handleSubmit} className="">
        <div className="grid grid-cols-1 ">
          {/* Shop Type */}
          <div className="grid grid-cols-2 gap-x-8">
            <SelectBox
              label={t("shop_type")}
              name="profile"
              onChange={formik.handleChange}
              options={[
                { value: 0, label: t("select_shoptype"), disabled: true },
                ...shoptype?.map((item) => ({
                  value: item?.id,
                  label: item?.shop_type,
                })),
              ]}
              onBlur={formik.handleBlur}
              value={
                shoptype?.find((item) => item?.id == formik.values.profile)
                  ?.id || 0
              }
              error={formik.errors.profile}
              touched={formik.touched.profile}
            />
          </div>

          {/* Business Name and Business Owner Name */}
          <div className="grid grid-cols-2 gap-x-8">
            <div>
              <InputBox
                label={t("business_name")}
                name="storename"
                placeholder={t("enter_business_name")}
                maxLength={20}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.storename}
                error={formik.errors.storename}
                touched={formik.touched.storename}
              />
            </div>
            <div>
              <InputBox
                label={t("business_owner_name")}
                name="retailername"
                placeholder={t("enter_business_ownername")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.retailername}
                error={formik.errors.retailername}
                touched={formik.touched.retailername}
              />
            </div>
          </div>

          {/* Mobile No. and Email */}
          <div className="grid grid-cols-2 gap-x-8">
            <div className="flex flex-col">
              <label className="font-medium text-base mt-4">
                {t("phone_number")}
              </label>
              <div className="flex items-center mt-2">
                <div className="h-10 w-16 text-md text-body outline-none focus:outline-none rounded-lg border p-3 flex items-center justify-center bg-[#EBEBEB]">
                  <Image src={FlagIcon} alt="img" />
                </div>
                <Input
                  international 
                  defaultCountry="EG"
                  label={t("Mobile No.")}
                  value={"0" + formik.values.mobile}
                  placeholder={formik.values.mobile}
                  onBlur={formik.handleBlur}
                  name="mobile"
                  disabled
                  className={`h-10 ml-2 text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-md p-3 ${
                    formik.touched.mobile && formik.errors.mobile
                      ? "border-red" // Apply red border when touched or there's an error
                      : ""
                  }`}
                />
              </div>
              {formik.touched.mobile && formik.errors.mobile && (
                <div className="text-red-500 mt-0">{formik.errors.mobile}</div>
              )}
            </div>
            <div>
              {/* <label className="font-medium text-base">
                {t("phone_number")}
              </label> */}
              <InputBox
                label={t("email")}
                name="emailid"
                placeholder={t("enter_email")}
                maxLength={30}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.emailid}
                error={formik.errors.emailid}
                touched={formik.touched.emailid}
                autoComplete="new-email"
              />
              <div className="text-red text-sm mt-1">
                {email ? t("email_already_registered") : " "}
              </div>
            </div>
          </div>

          {/* Password and Confirm Password */}
          <div className="grid grid-cols-2 gap-x-8">
            <div>
              <Password
                label={t("password")}
                name="password"
                placeholder={t("password_place")}
                maxLength={20}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={formik.errors.password}
                touched={formik.touched.password}
                autoComplete="new-password"
              />
            </div>
            <div>
              <Password
                label={t("Confirm Password")}
                name="confirmPassword"
                placeholder={t("Re-Enter password")}
                maxLength={20}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                error={formik.errors.confirmPassword}
                touched={formik.touched.confirmPassword}
                autoComplete="new-password"
              />
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex float-right mt-8 mb-2">
          <button
            type="submit"
            className={`w-[180px] h-[45px] text-white rounded-lg bg-primaryBlue font-semibold`}
          >
            {t("confirm")}
          </button>
        </div>
      </form>
    </div>
  );
}

export default BasicDetails;

//   return (
//     <div className="container w-full px-4">
//       <h1 className="text-xl font-bold mb-4">{t("basic_details")}</h1>
//       <form onSubmit={formik.handleSubmit} className="space-y-4">
//         <div className="grid grid-cols-2  gap-x-8">
//           <div>
//             <SelectBox
//               // defaultValue="Retailer"
//               label={t("shop_type")}
//               name="profile"
//               onChange={formik.handleChange}
//               options={[
//                 { value: 0, label: t("select_shoptype"), disabled: true },

//                 ...shoptype?.map((item) => ({
//                   value: item?.id,
//                   label: item?.shop_type,
//                 })),
//               ]}
//               onBlur={formik.handleBlur}
//               value={
//                 shoptype?.find((item) => item?.id == formik.values.profile)
//                   ?.id || 0
//               }
//               error={formik.errors.profile}
//               touched={formik.touched.profile}
//             />
//             {/* {formik.touched.profile && formik.errors.profile ? (
//                     <div className=" text-red text-sm h-2 mt-0 ml-1">
//                       {formik.errors.profile}
//                     </div>
//                   ) : null} */}
//           </div>
//           <div className="">
//             <InputBox
//               label={t("business_owner_name")}
//               name="retailername"
//               placeholder={t("enter_business_ownername")}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               value={formik.values.retailername}
//               error={formik.errors.retailername}
//               touched={formik.touched.retailername}
//             />
//           </div>
//           <InputBox
//             label={t("business_name")}
//             name="storename"
//             placeholder={t("enter_business_name")}
//             maxLength={20}
//             onChange={formik.handleChange}
//             onBlur={formik.handleBlur}
//             value={formik.values.storename}
//             error={formik.errors.storename}
//             touched={formik.touched.storename}
//           />
//           <div>
//             <InputBox
//               label={t("email")}
//               name="emailid"
//               placeholder={t("enter_email")}
//               maxLength={30}
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               value={formik.values.emailid}
//               error={formik.errors.emailid}
//               touched={formik.touched.emailid}
//               autoComplete="new-email"
//             />

//             <div className=" text-red text-sm h-2 mt-0 ml-1">
//               {email ? t("email_already_registered") : " "}
//             </div>
//           </div>

//           <div className="col-span-2 gap-7 flex items-center justify-center mt-3">
//             <div className="w-1/2 pb-6 flex flex-col">
//               <label className="font-medium text-base">
//                 {t("phone_number")}
//               </label>
//               <div className="flex items-center">
//                 <div className="h-10 w-16 mt-2 text-md text-body outline-none focus:outline-none  rounded-lg border p-3 flex items-center justify-center bg-[#EBEBEB]">
//                   <Image src={FlagIcon} alt="img" />
//                 </div>
//                 <Input
//                   international
//                   defaultCountry="EG"
//                   value={"0" + formik.values.mobile}
//                   placeholder={formik.values.mobile}
//                   // onChange={(phone) => formik.setFieldValue("mobile", phone)}
//                   onBlur={formik.handleBlur}
//                   name="mobile"
//                   disabled
//                   className={` h-10 ml-2 mt-2 text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-md p-3 ${
//                     formik.touched.phoneNumber && formik.errors.phoneNumber
//                       ? "border-red" // Apply red border when touched or there's an error
//                       : ""
//                   }`}
//                 />
//               </div>
//               {/* <PhoneInput
//                 international
//                 defaultCountry="EG"
//                 value={formik.values.mobile}
//                 placeholder={formik.values.mobile}
//                 // onChange={(phone) => formik.setFieldValue("mobile", phone)}
//                 onBlur={formik.handleBlur}
//                 name="mobile"
//                 className="w-full ml-2 mt-2 text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-xl border p-3"
//                 disabled
//               /> */}
//               {formik.touched.mobile && formik.errors.mobile && (
//                 <div className="text-red-500 mt-0">{formik.errors.mobile}</div>
//               )}
//             </div>
//             <div className="w-1/2 pl-1 mt-[-41px]">
//               <Password
//                 label={t("password")}
//                 name="password"
//                 placeholder={t("password_place")}
//                 maxLength={20}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 value={formik.values.password}
//                 error={formik.errors.password}
//                 touched={formik.touched.password}
//                 autoComplete="new-password"
//               />
//             </div>
//             {/* <div className="w-1/2 pl-1 mt-[-41px]"> */}
//               <Password
//                 label={t("confirm_password")}
//                 name="confirmPassword"
//                 placeholder={t("confirm_password_place")}
//                 maxLength={20}
//                 onChange={formik.handleChange}
//                 onBlur={formik.handleBlur}
//                 value={formik.values.confirmPassword}
//                 error={formik.errors.confirmPassword}
//                 touched={formik.touched.confirmPassword}
//                 autoComplete="new-password"
//               />
//             {/* </div> */}
//           </div>
//         </div>
//         {/* <div className="flex justify-end "> */}
//         {/* <button
//             type="button"
//             className="w-[180px] h-[45px] bg-white text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue mr-2"
//           >
//             Cancel
//           </button> */}

//         {/* ${
//               formik?.values?.profile == 0
//                 ? "bg-primaryBluedisabled"
//                 : "bg-primaryBlue"
//             }  */}
//         <div className="flex float-right mt-6  mb-2">
//           <button
//             type="submit"
//             className={`w-[180px] h-[45px]

//              text-white rounded-lg bg-primaryBlue font-semibold`}
//             // disabled={formik?.values?.profile == 0 ? true : false}
//           >
//             {t("confirm")}
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default BasicDetails;
