import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputBox } from "../../components/CustomWidgets/InputBox";
import upload from "../../assets/Images/Uploadminimalistic.svg";
// import {Image}  from "antd";
import axiosInstance from "../../AxiosInstance";
import Notification from "../../modals/Notification";
import UpdatedProfile from "../../modals/UpdatedProfile"; // Import the UpdatedProfile modal
import UploadMediaonly from "../upload-imageonly";
import { Button, Image } from "antd";
import "../../styles.css";
import {
  BusinessProofSchema,
  profileBusinessProofSchema,
} from "../validationSchema";
import { useNavigate } from "react-router";
import { URLS } from "../../API/API endpoints";
import { useTranslation } from "react-i18next";

const BusinessProof = ({ payload, onDataSubmit }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [notification, setnotification] = useState(false);
  const [showUpdatedProfile, setShowUpdatedProfile] = useState(false); // State to control the visibility of UpdatedProfile modal
  const [restricted, setrestricted] = useState(true);
  //useeffect to get previous information
  useEffect(() => {
    getbusinessproofdata();
  }, [payload]);

  const getbusinessproofdata = async () => {
    try {
      // console.log("payload",payload)
      axiosInstance
        .post(URLS.GET_STORE_BUSINESSPROOF, payload)
        .then((response) => {
          if (response?.data?.message == "Invalid Token") {
            localStorage.clear();
            navigate("/");
          }

          if (response?.data?.message !== "Business Proof Details Not Found")
            formik.setValues({
              // Use formik.setValues to update form values
              commercial_num:
                response.data?.basic_details?.find(
                  (item) => item.type.toLowerCase() === "registration_number"
                ).name || "",
              // response.data?.basic_details[0]?.name || "", // Provide default values or empty string if data is null
              commercial_num_url:
                response.data?.basic_details?.find(
                  (item) => item.type.toLowerCase() === "registration_number"
                ).url || "",
              tax_card_num:
                response.data?.basic_details?.find(
                  (item) => item.type.toLowerCase() === "tax_card_number"
                ).name || "",
              tax_card_num_url:
                response.data?.basic_details?.find(
                  (item) => item.type.toLowerCase() === "tax_card_number"
                ).url || "",
              national_id:
                response.data?.basic_details?.find(
                  (item) => item.type.toLowerCase() === "national_id"
                ).name || "",
              // password: response.data?.basic_details[0]?.password || "",
              national_id_url:
                response.data?.basic_details?.find(
                  (item) => item.type.toLowerCase() === "national_id"
                ).url || "",
            });
          setrestricted(true);
        });
    } catch (error) {}
  };

  //Form values for tho profile form
  const formik = useFormik({
    initialValues: {
      commercial_num: "",
      commercial_num_url: "",
      tax_card_num: "",
      tax_card_num_url: "",
      national_id: "",
      national_id_url: "",
    },
    validationSchema: profileBusinessProofSchema,
    onSubmit: async (values, { resetForm }) => {
      // console.log("values", values);
      const apipayload = {
        user_id:
          payload?.user_id ??
          JSON.parse(localStorage.getItem("user_detail"))?.user_id,
        store_id:
          payload?.store_id ??
          JSON.parse(localStorage.getItem("retailer_info"))?.id,
        commercial_num: values.commercial_num,
        commercial_num_url: values.commercial_num_url,
        tax_card_num: values.tax_card_num,
        tax_card_num_url: values.tax_card_num_url,
        national_id: values.national_id,
        national_id_url: values.national_id_url,
      };

      try {
        const response = await axiosInstance.post(
          URLS.SET_STORE_BUSINESSPROOF,
          apipayload
        );
        if (response.data.status === 200) {
          // setnotification(true);
          setrestricted(true);
          setShowUpdatedProfile(true); // Show UpdatedProfile modal if response status is 200
        }
      } catch (error) {}

      // resetForm();
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    formik.handleSubmit(e); // Call Formik's submit handler
  };
  const handleurlgenerated = (values, keyname) => {
    // console.log("values", values,keyname);
    setrestricted(false);
    formik.setFieldValue(keyname, values.url);
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="font-normal text-sm text-[#5c5c5c] grid grid-rows-2 grid-flow-col  gap-4 gap-x-9 mx-7 mt-6">
          <div className="w-[363px] md:w-full leading-[0px]">
            <label> {t("commercial_registry_number")}</label>
            <InputBox
              name="commercial_num"
              placeholder="000000000"
              maxLength={14}
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.commercial_num}
              error={formik.errors.commercial_num}
              touched={formik.touched.commercial_num}
            />
            {formik.errors.commercial_num && (
              <div className="text-red text-xs">
                {formik.errors.commercial_num}
              </div>
            )}
            {formik.values.commercial_num_url !== "" &&
              formik.values.commercial_num === "" && (
                <div className="text-red text-xs">
                  Please enter Commercial Registry Number{" "}
                </div>
              )}
            <div className="w-[363px] md:w-full  mt-2">
              <div className="flex flex-row rounded-lg border border-inset border-gray-900/25 px-2  py-3">
                <div
                  className="flex flex-row"
                  style={{ minHeight: 70, maxHeight: 70 }}
                >
                  {formik.values?.commercial_num_url != "" ? (
                    <Image
                      src={formik.values?.commercial_num_url}
                      height={70}
                      width={70}
                      alt="image"
                    />
                  ) : (
                    <svg
                      className=" float-left mt-3 h-10 w-12 text-gray-300"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        // clip-rule="evenodd"
                      />
                    </svg>
                  )}
                  <div class="flex flex-row text-sm leading-6 pl-20 ml-10 text-gray-600">
                    <label
                      for="file-upload"
                      class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                    >
                      {/* <span>
                      <Image
                        src={
                          formik.values.commercial_num_url
                            ? formik.values.commercial_num_url
                            : upload
                        }
                        width={40}
                        height={40}
                      />
                    </span> */}
                      {/*}
                    <span className="ml-2">Change </span>
                   <UploadMediaonly
                  onfileAdd={"manageridurl"}
                  urlgenerated={handleurlgenerated}
                  // Adjust field name as needed
                /> */}
                      <UploadMediaonly
                        foldername={"store_app_UAT"}
                        onfileAdd={"commercialregistrynumberurl"} // Adjust field name as needed
                        urlgenerated={handleurlgenerated}
                        keyname={"commercial_num_url"}
                        imageurl={formik.values.commercial_num_url}
                      />
                    </label>
                  </div>
                  {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
                </div>
              </div>
            </div>
          </div>

          <div className="w-[363px] md:w-full leading-[0px] mt-2">
            <label> {t("managerid")}</label>
            <InputBox
              name="national_id"
              placeholder="000000000"
              maxLength={14}
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.national_id}
              error={formik.errors.national_id}
              touched={formik.errors.national_id}
            />
            {formik.errors.national_id && (
              <div className="text-red text-xs">
                {formik.errors.national_id}
              </div>
            )}
            {formik.values.national_id_url !== "" &&
              formik.values.national_id === "" && (
                <div className="text-red text-xs">Please enter National ID </div>
              )}
            <div className="w-[363px] md:w-full mt-2">
              <div className=" rounded-lg border border-inset border-gray-900/25 px-2  py-3">
                <div
                  className="flex flex-row"
                  style={{ minHeight: 70, maxHeight: 70 }}
                >
                  {formik.values?.national_id_url != "" ? (
                    <Image
                      src={formik.values?.national_id_url}
                      height={70}
                      width={70}
                      alt="image"
                    />
                  ) : (
                    <svg
                      className=" float-left mt-3 h-10 w-12 text-gray-300"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        // clip-rule="evenodd"
                      />
                    </svg>
                  )}
                  <div class="flex flex-row text-sm leading-6 pl-20 ml-10 text-gray-600">
                    <label
                      for="file-upload"
                      class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                    >
                      {/* <span>
                      <Image src={upload} width={18} />
                    </span>
                    <span className="ml-2">Change </span> */}
                      <UploadMediaonly
                        foldername={"store_app_UAT"}
                        onfileAdd={"national_id_url"}
                        urlgenerated={handleurlgenerated}
                        keyname={"national_id_url"}
                        imageurl={formik.values.national_id_url}
                        // Adjust field name as needed
                      />
                    </label>
                  </div>
                  {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
                </div>
              </div>
            </div>
          </div>

          <div className="w-[363px] md:w-full leading-[0px]">
            <label> {t("taxcard_number")}</label>
            <InputBox
              name="tax_card_num"
              placeholder="000000000"
              maxLength={9}
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.tax_card_num}
              error={formik.errors.tax_card_num}
              touched={formik.touched.tax_card_num}
            />
            {formik.errors.tax_card_num && (
              <div className="text-red text-xs">
                {formik.errors.tax_card_num}{" "}
              </div>
            )}
            {formik.values.tax_card_num_url !== "" &&
              formik.values.tax_card_num === "" && (
                <div className="text-red text-xs">Please enter Tax card number </div>
              )}
            <div className="w-[363px] md:w-full mt-2">
              <div className=" rounded-lg border border-inset border-gray-900/25 px-2  py-3">
                <div
                  className="flex flex-row "
                  style={{ minHeight: 70, maxHeight: 70 }}
                >
                  {formik.values?.tax_card_num_url != "" ? (
                    <Image
                      src={formik.values?.tax_card_num_url}
                      height={70}
                      width={70}
                      alt="image"
                    />
                  ) : (
                    <svg
                      className=" float-left mt-3 h-10 w-12 text-gray-300"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        // clip-rule="evenodd"
                      />
                    </svg>
                  )}

                  <div class="flex flex-row text-sm leading-6 pl-20 ml-10 text-gray-600">
                    <label
                      for="file-upload"
                      class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                    >
                      {/* <span>
                      <Image src={upload} width={18} />
                    </span>
                    <span className="ml-2">Change </span> */}
                      <UploadMediaonly
                        foldername={"store_app_UAT"}
                        onfileAdd={"taxcardnumberurl"}
                        urlgenerated={handleurlgenerated}
                        keyname={"tax_card_num_url"}
                        imageurl={formik.values.tax_card_num_url}

                        // Adjust field name as needed
                      />
                    </label>
                  </div>
                  {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" flex gap-5 mx-7 mt-6 mb-5">
          <button
            type="submit"
            className={`w-[180px] h-[45px] ${
              restricted ? "bg-primaryBluedisabled" : "bg-primaryBlue"
            } text-white rounded-lg font-semibold `}
            disabled={restricted}
          >
            {t("update_and_save")}
          </button>
          <Button
            className="w-[180px] h-[45px] text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
            onClick={getbusinessproofdata}
          >
            {t("cancel")}
          </Button>
        </div>
      </form>
      {notification && (
        <Notification
          message={"Business Proof  Details Updated Sucessfully"}
          onClose={() => setnotification(false)}
        />
      )}
      {showUpdatedProfile && (
        <UpdatedProfile
          onClose={() => setShowUpdatedProfile(false)}
          message={"Business Proof Details"}
        />
      )}
    </>
  );
};

export default BusinessProof;
