import React, { useEffect, useState } from "react";
import { Breadcrumb, Button } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { routes } from "../../../Routes";
import { InputBox } from "../../../components/CustomWidgets/InputBox";
import { useFormik } from "formik";
import SelectBox from "../../../components/CustomWidgets/SelectBox";
import { Image } from "antd";
// import Group from "../../assets/Inventory/Group.svg";
// import Navbar from "../../components/Orders/Navbar";
import {
  InventoryProductRequest,
  fetchGS1,
  getUOM,
} from "../../../API  Functions/InventoryFunctions";
import "../../../styles.css";
import UploadMediaonly from "../../../components/upload-imageonly";
import ProductrequestModal from "../../../modals/ProductrequestModal";
import { productRequestSchema } from "../../../components/validationSchema";
import Navbar from "../../../components/Orders/Navbar";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ProductRequest = () => {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state.login?.user?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);

  const [UOM, setUOM] = useState([]);
  const [popup, setpopup] = useState(false);
  const [isnew, setisnew] = useState(false);
  const [isGS1, setisGS1] = useState(false);
  const [data, setdata] = useState([]);
  const [searchid, setsearchid] = useState("");

  const get_UOM = async () => {
    const res = await getUOM();
    if (res?.status == "UOM") setUOM(res?.response);
    // formik.setFieldValue("product_uom", res?.response?.[0]?.id);
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("barcode"))
      localStorage.setItem("barcode", params.get("barcode"));
    const searchparam =
      params.get("barcode") || localStorage.getItem("barcode");
    setsearchid(searchparam);
    if (searchparam) {
      getdata(searchparam);
    }
  }, []);

  const ProductRequest = async (val) => {
    const response = await InventoryProductRequest(user_id, store_id, val);
    if (response?.status === 200) {
      setisnew(true);
      setpopup(true);
      return true;
    } else if (response?.status === 409) {
      setisnew(false);
      console.log("Product already exists.");
      setpopup(true);
      return false;
    }
  };
  useEffect(() => {
    UOM?.length == 0 && get_UOM();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      barcode: "",
      product_uom: "",
      costprice: "",
      saleprice: "",
      quantity: "",
      image_url: "",
      is_gs1: false,
    },
    // validateOnChange: false,
    // validateOnBlur: false,

    validationSchema: productRequestSchema,
    onSubmit: async (values, { resetForm }) => {
      const check = await ProductRequest(values);
      
      check == true && resetForm();
    },
  });

  const getdata = (searchid) => {
    fetchGS1(user_id, searchid).then((datagot) => {
      // setloading(false);
      if (datagot?.status != 404) {
        setdata(datagot);
        console.log("datagot", datagot);
        setisGS1(true);
        formik.setValues({
          // Use formik.setValues to update form values
          name:
            i18n.language == "en"
              ? datagot?.brand?.name
              : datagot?.brand?.arabic_name,
          barcode: datagot?.gtin,
          is_gs1: true,
        });
      } else console.log("not found");
    });
  };

  return (
    <>
      <div>
        <Navbar heading="product_request" />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="h-auto pb-6 bg-[#F6F7F9] min-h-[100vh] ">
          <div className="flex flex-col">
            <div className="mr-3 ml-3 font-extrabold  mt-14">
              <Breadcrumb
                separator={<DoubleRightOutlined />}
                items={[
                  {
                    title: t("inventory"),
                    href: routes.inventory.root,
                  },
                  { title: t("product_request") },
                ]}
                className="relative top-3"
              />
            </div>
            <div>
              {/* <div
                className={`grid grid-cols-2 gap-x-3 mt-9 relative bottom-6 ${
                  i18n.language == "en"
                    ? "float-left ml-3"
                    : "float-right mr-3"
                }`}
              >
                <h1 className="mt-2 font-semibold text-xl">
                  {t("product_request")}
                </h1>
              </div> */}
              {/* <div className="grid grid-cols-2 gap-x-3 relative bottom-6 float-right w-180 mt-6 mr-4">
                <button
                  type="submit"
                  className="w-52 h-[45px] border rounded-lg bg-[#ffffff] text-primaryBlue leading-8 mt-8 mr-2 border-primaryBlue"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-52 h-[45px] border rounded-lg bg-primaryBlue text-white leading-8 mt-8 mr-2"
                >
                  Finish
                </button>
              </div> */}
              {/* <div className="grid grid-cols-2 gap-x-3 relative bottom-6 float-right w-180 mt-6">
          <Button className="w-52 h-14 border rounded-lg bg-[#ffffff] text-blue leading-8 mt-8 mr-2">
            Cancel
          </Button>
          <Button className="w-52 h-14 border rounded-lg bg-[#1D5C96] text-white leading-8 mt-8 mr-2">
            Finish
          </Button>
        </div> */}
            </div>
            <div className="mx-2 h-10 bg-[#C9ECF7] rounded-t-xl mt-6 flex items-center">
              <p className=" ml-4">{t("product_details")}</p>
            </div>

            <div className="flex flex-row flex-1  bg-white mx-2 border rounded-b-xl">
              <div className="m-2 w-2/4 p-4 ">
                <span className=" block leading-[0px]">
                  <label>{t("product_name")}</label>
                  <div className="">
                    <InputBox
                      name="name"
                      placeholder={t("enter_product_name")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      error={formik.errors.name}
                      touched={formik.touched.name}
                    />
                    {/* <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <Image
                        src={Group}
                        alt="Barcode"
                        className=" text-gray-500 dark:text-gray-400"
                      />
                    </div>  */}
                  </div>
                </span>

                <span className="mt-5 block leading-[0px]">
                  <label>{t("barcode")}</label>
                  <InputBox
                    name="barcode"
                    placeholder={t("barcode")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.barcode}
                    error={formik.errors.barcode}
                    touched={formik.touched.barcode}
                  />
                </span>
                <div className="flex flex-row gap-5 mt-5">
                  <span className="w-full leading-[0px]">
                    <label>{t("quantity")}</label>
                    <InputBox
                      name="quantity"
                      placeholder={t("enter_quantity")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.quantity}
                      error={formik.errors.name}
                      touched={formik.touched.name}
                    />
                  </span>
                  <span className="w-full leading-[0px] flex flex-col">
                    <label className="pb-2">{t("product_uom")}</label>
                    <SelectBox
                      // style={{ marginTop: ".5rem" }}
                      name="product_uom"
                      defaultValue=""
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.product_uom}
                      options={
                        [
                          { value: "", label: "Select", disabled: true },
                          ...UOM?.map((item) => ({
                            value: item.id,
                            label: item.name,
                          })),
                        ]

                        //   [
                        //   { value: "Units", label: "Units" },
                        //   { value: "Litre", label: "Litre" },
                        //   { value: "KG", label: "KG" },
                        // ]
                      }
                      error={formik.errors.product_uom}
                      touched={formik.touched.product_uom}
                    />
                  </span>
                </div>
                <div className="flex flex-row gap-5 mt-5">
                  <span className="w-full leading-[0px]">
                    <label>{t("cost_price")}</label>
                    <InputBox
                      name="costprice"
                      placeholder={t("enter_cost_price")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.costprice}
                      error={formik.errors.costprice}
                      touched={formik.touched.costprice}
                    />
                  </span>
                  <span className="w-full leading-[0px]">
                    <label>{t("sale_price")}</label>
                    <InputBox
                      name="saleprice"
                      placeholder={t("enter_sale_price")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.saleprice}
                      error={formik.errors.saleprice}
                      touched={formik.touched.saleprice}
                    />
                  </span>
                </div>

                <button
                  type="submit"
                  className="w-48 h-10 mt-8 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center gap-2"
                >
                  <span>{t("save_add")}</span>
                </button>
              </div>
              <div className="m-2 p-4 ">
                <span
                  className={`flex  flex-col w-72 h-72 border rounded-xl relative top-4 ${
                    i18n.language == "en" ? "left-20" : "right-20"
                  } justify-center items-center ${
                    (formik?.errors?.image_url &&formik?.touched?.image_url) ? "border-red" : ""
                  }`}
                >
                  {console.log("imageurl", formik?.values?.image_url)}
                  {formik?.values?.image_url != "" &&
                  formik?.values?.image_url != undefined ? (
                    <img
                      src={formik?.values?.image_url}
                      height={100}
                      width={100}
                      alt="Image"
                      objectFit="contain"
                      style={{
                        minWidth: "17rem",
                        maxWidth: "18rem",
                        maxHeight: "13rem",
                        minHeight: "12rem",
                      }}
                    />
                  ) : (
                    <svg
                      className=" float-left h-10 w-12 text-gray-300"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        // clip-rule="evenodd"
                      />
                    </svg>
                  )}
                  <span className="cursor-pointer">
                    <UploadMediaonly
                      foldername={"store_image"}
                      onfileAdd={"Productrequest"}
                      urlgenerated={(val) =>
                        formik.setFieldValue("image_url", val?.url)
                      }
                      imageurl={
                        formik?.values?.image_url == undefined
                          ? ""
                          : formik?.values?.image_url
                      }
                    />
                  </span>
                </span>

                {/* <div className="flex gap-3 relative top-10 left-20">
                <span className="w-16 h-16 border rounded-xl"></span>
                <span className="w-16 h-16 border rounded-xl"></span>
                <span className="w-16 h-16 border rounded-xl"></span>
                <span className="w-16 h-16 border rounded-xl"></span>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </form>
      {popup && (
        <ProductrequestModal onClose={() => setpopup(false)} isnew={isnew} />
      )}
    </>
  );
};

export default ProductRequest;
