// import Swal from 'sweetalert2';
// import React, { useEffect } from 'react';

// const UnservicableCity = () => {
//   useEffect(() => {
//     Swal.fire({
//       icon: 'error',
//       title: 'Sorry',
//       text: 'Currently, we are only serving in Al Obour. Kindly consider adding the address of Al Obour for a better shopping experience.',
//     })
//   }, []);

//   return <div></div>; // This component doesn't render anything, it just triggers the Swal.fire() on mount
// };


import React from "react";

import {Image}  from "antd";
import checked from "../assets/Cancel.svg";
import { Link } from "react-router-dom";
import { routes } from "../Routes";
import { useNavigate } from "react-router";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    padding: "20px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
    marginTop: "30px",
  },
  image: {
    width: "70px",
    display: "block",
    margin: "20px auto",
    marginBottom: "20px",
  },
  description: {
    textAlign: "center",
    fontSize: "0.875rem",
    color: "#5C5C5C",
    marginBottom: "20px",
  },
};

const UnservicableCity = ({ onClose}) => {
  const navigate=useNavigate()

  const handleGoToOrders = () => {
    // Navigate to the dashboard route
   navigate("/orders")
  };

  return (
    <div>
      <div style={styles.overlay} ></div>
      <div style={styles.container}>
        <img
          src={checked} // Provide the path to your checked image
          alt="Checked"
          style={styles.image}
        />
        <p style={styles.title}>{"Sorry"}</p>
        <p style={styles.description}>
        Currently, we are only serving in Al Obour.<br/><br/> Kindly consider adding the address of Al Obour for a better shopping experience.

        </p>
        <div className="flex justify-center gap-5 mt-7 mb-7 ">
            {/* <Link to={route}> */}
          <button
            type="button"
            className="w-[180px] h-[45px] bg-primaryBlue text-white rounded-lg font-semibold"
            // onClick={handleGoToDashboard} // Call handleGoToDashboard onClick
            onClick={()=>onClose()}
          >
           Ok
          </button>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default UnservicableCity;

// export default UnservicableCity;