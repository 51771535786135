import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URLS} from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance"

// Set the access token in the cookies

export const loginUser = createAsyncThunk(
  "loginUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(URLS.LOGIN_API_URL, payload,
      //   , {
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json; charset=UTF-8",
      //     "Access-Control-Allow-Origin" : "*",
      //     "api-key": "b63bd82b-4a83-4d28-909d-30ce29614589",
      //   },
      // }
      );
     
      if (response.status >= 200 && response.status < 300) {
       
        return response.data;
      } else {
        throw new Error("API request failed");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
