import React, { useState } from "react";
import UploadMedia from "../upload-media";
import Button from "../CustomWidgets/Button";
import CompleteProfile from "../../modals/CompleteProfile";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Image } from "antd";

const ProfileCreation = ({ values, onDataSubmit }) => {
  const { t, i18n } = useTranslation();
  const [showCompleteModal, setShowCompleteModal] = useState(false); // State for modal visibility

  const formik = useFormik({
    initialValues: {
      Shop_logo: "",
      Owner_Photo: "",
      Shop_1: "",
      Shop_2: "",
      Shop_3: "",
    },
    // validationSchema: LoginSchema,
    onSubmit: (values) => {
      const api_payload = {
        store_id: localStorage?.getItem("store_id") ?? 159,
        page: "profile_creation",
        bussiness_proof: [
          {
            name: "shop logo",
            url: values.Shop_logo,
            doc_type: "store_profile",
          },
          {
            name: "Owner Photo",
            url: values.Owner_Photo,
            doc_type: "owner_photo",
          },
          {
            name: "Shop_1",
            url: values.Shop_1,
            doc_type: "shop_photo_1",
          },
          {
            name: "Shop_2",
            url: values.Shop_2,
            doc_type: "shop_photo_2",
          },
          {
            name: "Shop_3",
            url: values.Shop_3,
            doc_type: "shop_photo_3",
          },
        ],
      };
      onDataSubmit("profile_creation_data", api_payload);
    },
  });

  // Function to handle opening the modal
  const handleOpenModal = () => {
    setShowCompleteModal(true);
  };

  const handleurlgenerated = (values) => {
    formik.setFieldValue(values.name, values.url);
  };
  return (
    <>
      <div className="font-normal text-base leading-[0px] w-[95%] ms-4">
        <h1 className="text-2xl font-bold">{t("profile_creation")}</h1>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-2 gap-2 gap-x-10 mt-10">
            <div className="h-10">
              <label className="text-sm">{t("shop_logo")}</label>
              <div className="w-[363px] mt-2  md:w-full">
                <div className=" rounded-lg border border-dashed border-primaryBlue px-2  py-3">
                  <div
                    className="flex flex-row mx-auto  justify-start gap-x-4"
                    style={{ minHeight: 70, maxHeight: 70 }}
                  >
                    {formik.values?.Shop_logo != "" ? (
                      <Image
                        src={formik.values?.Shop_logo}
                        height={70}
                        width={70}
                        alt="image"
                      />
                    ) : (
                      <svg
                        className=" float-left  h-20 w-24 text-gray-300"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                          // clip-rule="evenodd"
                        />
                      </svg>
                    )}

                    <div class="flex flex-row text-sm leading-6 ms-16 text-gray-600">
                      <label
                        for="file-upload"
                        class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                      >
                        {/* <span>
                      <Image src={upload} width={18} />
                    </span>
                    <span className="ml-2">Change </span> */}
                        <UploadMedia
                          foldername={"store_image"}
                          onfileAdd={"Shop_logo"}
                          urlgenerated={handleurlgenerated}
                          // keyname={"tax_card_num_url"}
                          imageurl={formik.values.Shop_logo}
                          message={(message) => !message}

                          // Adjust field name as needed
                        />
                      </label>
                    </div>
                    {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
                  </div>
                </div>
              </div>
              {/* <UploadMedia
                foldername={"store_image"}
                onfileAdd={"Shop_logo"} // Adjust field name as needed
                urlgenerated={handleurlgenerated}
                imgurl={formik.values.Shop_logo}
                message={(message)=>!message && alert("Unsupported File type")}
              /> */}
            </div>
            <div>
              <label className="text-sm">{t("owner_photo")}</label>
              <div className="w-[363px] mt-2  md:w-full">
                <div className=" rounded-lg border border-dashed border-primaryBlue px-2  py-3">
                  <div
                    className="flex flex-row mx-auto  justify-start gap-x-4"
                    style={{ minHeight: 70, maxHeight: 70 }}
                  >
                    {formik.values?.Owner_Photo != "" ? (
                      <Image
                        src={formik.values?.Owner_Photo}
                        height={70}
                        width={70}
                        alt="image"
                      />
                    ) : (
                      <svg
                        className=" float-left  h-20 w-24 text-gray-300"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                          // clip-rule="evenodd"
                        />
                      </svg>
                    )}

                    <div class="flex flex-row text-sm leading-6 ms-16 text-gray-600">
                      <label
                        for="file-upload"
                        class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                      >
                        {/* <span>
                      <Image src={upload} width={18} />
                    </span>
                    <span className="ml-2">Change </span> */}
                        <UploadMedia
                          foldername={"owner"}
                          onfileAdd={"Owner_Photo"}
                          urlgenerated={handleurlgenerated}
                          // keyname={"tax_card_num_url"}
                          imageurl={formik.values.Owner_Photo}
                          message={(message) => !message}

                          // Adjust field name as needed
                        />
                      </label>
                    </div>
                    {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
                  </div>
                </div>
              </div>
              {/* <UploadMedia
                foldername={"owner"}
                onfileAdd={"Owner_Photo"} // Adjust field name as needed
                urlgenerated={handleurlgenerated}
                imgurl={formik.values.Owner_Photo}
                message={(message)=>!message && alert("Unsupported File type")}
              /> */}
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2 gap-x-7 mt-12 pb-8">
            <div>
              <label className="text-sm">{t("shopphoto1")}</label>
              <div className="w-[363px] mt-2  md:w-full">
                <div className=" rounded-lg border border-dashed border-primaryBlue px-2  py-3">
                  <div
                    className="flex flex-row mx-auto  justify-start gap-x-4"
                    style={{ minHeight: 70, maxHeight: 70 }}
                  >
                    {formik.values?.Shop_1 != "" ? (
                      <Image
                        src={formik.values?.Shop_1}
                        height={70}
                        width={70}
                        alt="image"
                      />
                    ) : (
                      <svg
                        className=" float-left  h-20 w-24 text-gray-300"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                          // clip-rule="evenodd"
                        />
                      </svg>
                    )}

                    <div class="flex flex-row text-sm leading-6 ms-4 text-gray-600">
                      <label
                        for="file-upload"
                        class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                      >
                        {/* <span>
                      <Image src={upload} width={18} />
                    </span>
                    <span className="ml-2">Change </span> */}
                        <UploadMedia
                          foldername={"store_app_UAT"}
                          onfileAdd={"Shop_1"}
                          urlgenerated={handleurlgenerated}
                          // keyname={"tax_card_num_url"}
                          imageurl={formik.values.Shop_1}
                          message={(message) => !message}

                          // Adjust field name as needed
                        />
                      </label>
                    </div>
                    {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
                  </div>
                </div>
              </div>
              {/* <UploadMedia
                foldername={"legal"}
                onfileAdd={"Shop_1"} // Adjust field name as needed
                urlgenerated={handleurlgenerated}
                imgurl={formik.values.Shop_1}
                message={(message)=>!message && alert("Unsupported File type")}
              /> */}
            </div>
            <div>
              <label className="text-sm">{t("shopphoto2")}</label>
              <div className="w-[363px] mt-2  md:w-full">
                <div className=" rounded-lg border border-dashed border-primaryBlue px-2  py-3">
                  <div
                    className="flex flex-row mx-auto  justify-start gap-x-4"
                    style={{ minHeight: 70, maxHeight: 70 }}
                  >
                    {formik.values?.Shop_2 != "" ? (
                      <Image
                        src={formik.values?.Shop_2}
                        height={70}
                        width={70}
                        alt="image"
                      />
                    ) : (
                      <svg
                        className=" float-left  h-20 w-24 text-gray-300"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                          // clip-rule="evenodd"
                        />
                      </svg>
                    )}

                    <div class="flex flex-row text-sm leading-6 ms-5 text-gray-600">
                      <label
                        for="file-upload"
                        class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                      >
                        {/* <span>
                      <Image src={upload} width={18} />
                    </span>
                    <span className="ml-2">Change </span> */}
                        <UploadMedia
                          foldername={"store_app_UAT"}
                          onfileAdd={"Shop_2"}
                          urlgenerated={handleurlgenerated}
                          // keyname={"tax_card_num_url"}
                          imageurl={formik.values.Shop_2}
                          message={(message) => !message}

                          // Adjust field name as needed
                        />
                      </label>
                    </div>
                    {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
                  </div>
                </div>
              </div>
              {/* <UploadMedia
                foldername={"store_image"}
                onfileAdd={"Shop_2"} // Adjust field name as needed
                urlgenerated={handleurlgenerated}
                imgurl={formik.values.Shop_2}
                message={(message)=>!message && alert("Unsupported File type")}
              /> */}
            </div>
            <div>
              <label className="text-sm">{t("shopphoto3")}</label>
              <div className="w-[363px] mt-2  md:w-full">
                <div className=" rounded-lg border border-dashed border-primaryBlue px-2  py-3">
                  <div
                    className="flex flex-row mx-auto  justify-start gap-x-4"
                    style={{ minHeight: 70, maxHeight: 70 }}
                  >
                    {formik.values?.Shop_3 != "" ? (
                      <Image
                        src={formik.values?.Shop_3}
                        height={70}
                        width={70}
                        alt="image"
                      />
                    ) : (
                      <svg
                        className=" float-left  h-20 w-24 text-gray-300"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                          // clip-rule="evenodd"
                        />
                      </svg>
                    )}

                    <div class="flex flex-row text-sm leading-6 ms-5 text-gray-600">
                      <label
                        for="file-upload"
                        class="flex items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                      >
                        {/* <span>
                      <Image src={upload} width={18} />
                    </span>
                    <span className="ml-2">Change </span> */}
                        <UploadMedia
                          foldername={"store_app_UAT"}
                          onfileAdd={"Shop_3"}
                          urlgenerated={handleurlgenerated}
                          // keyname={"tax_card_num_url"}
                          imageurl={formik.values.Shop_3}
                          message={(message) => !message}

                          // Adjust field name as needed
                        />
                      </label>
                    </div>
                    {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
                  </div>
                </div>
              </div>
              {/* <UploadMedia
                foldername={"store_image"}
                onfileAdd={"Shop_3"} // Adjust field name as needed
                urlgenerated={handleurlgenerated}
                imgurl={formik.values.Shop_3}
                message={(message)=>!message }
              /> */}
            </div>
          </div>
          <div className="w-[185px] h-[45px] float-right mt-8 ">
            <button
              type="submit"
              className="h-[45px] w-[185px] bg-primaryBlue text-white rounded-lg font-semibold float-right"
              text={t("complete")}
            >
              {t("complete")}
            </button>
          </div>
        </form>
      </div>
      {/* {showCompleteModal && (
        <CompleteProfile
          isOpen={showCompleteModal}
          onClose={() => setShowCompleteModal(false)}
        />
      )} */}
    </>
  );
};

export default ProfileCreation;
