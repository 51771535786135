import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Orders/sidebar";
import Navbar from "../../components/Orders/Navbar";
import { Breadcrumb, Button } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Image } from "antd";
import ProcessDone from "../../components/Orders/ProcessDone";
import CancelOrder from "../../modals/cancelorder";
import {
  getdata,
  quantityUpdate,
  updateorderStage,
} from "../../API  Functions/OrderlistFunction";
import OrdercustomerDetails from "../../components/Orders/OrdercustomerDetails";
import {
  htmlContent,
  getdatahtml,
} from "../../components/orderlist/printorderlist";
import ShimmerLoader from "../../loading";
import "../../styles.css";
import Cancelledpopup from "../../modals/Cancelledpopup";
import PaymentInfo from "../../components/Orders/PaymentInfo";
import Orderdeliveredpopup from "../../modals/Orderdeliveredpopup";
import deleteicon from "../../assets/Order/Delete.svg";
import { searchdeliverypartner } from "../../API  Functions/DeliveryPartnerFunctions";
import DeliveryBoyDetails from "../../modals/DeliveryBoyDetails";
import AssignDeliveryBoypopup from "../../modals/AssignDeliveryBoypopup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Orderlist = () => {
  const { t, i18n } = useTranslation();
  const store_data = useSelector((state) => state.login?.user);
  // console.log("store_data", store_data);
  const user_id = useSelector((state) => state.login?.user?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);

  const [data, setdata] = useState([]);
  const [showComponent, setShowComponent] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isCancelOrderOpen, setCancelOrderOpen] = useState(false);
  const [Quantity, setQuantity] = useState(0);
  const [Error, setError] = useState(false);
  // const [processList, setprocessList] = useState([]);
  const [processButton, setprocessbutton] = useState(false);
  const [acceptbutton, setacceptbutton] = useState(false);
  const [loading, setloading] = useState(true);
  const [cancelledpopup, setcancelledpopup] = useState(false);
  const [deliveredpopup, setdeliveredpopup] = useState(false);
  const [zero, setzero] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false); // State to manage modal visibility
  const [DeliveryPartners, setDeliveryPartners] = useState([]);
  const [popup, setpopup] = useState(false);
  const [searchid, setsearchid] = useState(localStorage.getItem("searchid"));
  const [draftquantites, setdraftquantities] = useState([]);

  // const [list, setList] = useState([

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("orderDetail"))
      localStorage?.setItem("searchid", params.get("orderDetail"));
    const searchparam =
      params.get("orderDetail") || localStorage.getItem("searchid");
    setsearchid(searchparam);
    if (searchparam) {
      calldata(searchparam);
    }
  }, []);

  const calldata = (searchid) => {
    getdata(user_id, store_id, searchid, 10, 0).then((datagot) => {
      setloading(false);
      setdata(datagot);

      let count = 0,
        acceptcount = 0;
      datagot.order_line_details?.forEach((item) => {
        if (item.delivered_qty > 0 && item.delivered_qty != null) count++;
      });
      datagot.order_line_details?.forEach((item) => {
        if (item.available_qty === 0) acceptcount++;
      });

      if (datagot?.order_stage?.id === 3) setShowComponent(true);
      else if (
        datagot?.order_line_details?.length === count &&
        datagot?.order_stage?.id === 2
      )
        setprocessbutton(true);
      else if (
        datagot?.order_line_details?.length === acceptcount ||
        datagot?.order_line_details?.length === 0
      )
        setacceptbutton(true);

      //  setShowComponent(true);
    });
  };

  const handleOpenCancelOrder = () => {
    setCancelOrderOpen(true);
  };

  const handleCloseCancelOrder = () => {
    setCancelOrderOpen(false);
  };
  const [orderedItems, setOrderedItems] = useState([]);

  useEffect(() => {
    const orderedItems =
      data?.order_line_details?.filter((item) => item.delivered_qty > 0) ?? [];
    {
      (data?.order_stage?.id == 2 || data?.order_stage?.id == 3) &&
        setOrderedItems(orderedItems);
      console.log(orderedItems);
    }
  }, [data]);

  const openModal = (index) => {
    setModalOpen(true);
    setActiveItem(index);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleMarkAsDamaged = () => {
    closeModal();
  };

  const handleEnterAndAddNext = (
    order_id,
    id,
    product_id,
    sell_price,
    value
  ) => {
    quantityUpdate(
      user_id,
      store_id,
      searchid,
      value,
      order_id,
      id,
      sell_price,
      product_id
    ).then((response) => {
      if (
        response?.data?.message ==
        "You cannot add more the available qty for this product."
      )
        alert(response?.message);
      setQuantity(value);
      calldata(searchid);
    });

    closeModal();
  };

  //Function to change the quantity of product in processing
  const handleQuantityChange = (
    e,
    index,
    available_qty,
    order_id,
    id,
    product_id,
    sell_price
  ) => {
    setzero(true);
    let newQuantity =
      typeof e === "object" ? parseInt(e.target.value) : parseInt(e);

    // if (e?.target?.value <= available_qty || e<= available_qty) {
    //   setQuantity(parseInt(e.target.value));
    //   setError(false);
    console.log("draftquantites", draftquantites);
    setdraftquantities((draftquantities) => {
      // Find if the product_id already exists in draftquantites
      const existingIndex = draftquantities.findIndex(
        (item) => item.product_id === product_id
      );

      // If product_id exists, update the quantity; otherwise, add a new entry
      if (existingIndex !== -1) {
        // Update existing entry
        draftquantities[existingIndex] = {
          ...draftquantities[existingIndex],
          value: newQuantity,
        };
        return [...draftquantities];
      } else {
        // Add new entry
        return [
          ...draftquantities,
          {
            product_id: product_id,
            sell_price: sell_price,
            value: newQuantity,
          },
        ];
      }
    });

    // handleEnterAndAddNext(
    //   order_id,
    //   id,
    //   product_id,
    //   sell_price,
    //   e.target.value
    // );
    // } else {
    //   setError(true);
    //   console.log("gdbd");
    // }
    // } else {
    //   if (e <= available_qty) {
    //     setQuantity(e)
    //      setError(false);
    //       console.log("draftquantites",draftquantites)
    //      setdraftquantities([...draftquantites,{"product_id":product_id,"sell_price":sell_price,"value":e}])

    //     // handleEnterAndAddNext(order_id, id, product_id, sell_price, e);
    //   } else setError(true);
    // }
  };

  const handlePrintOrderList = () => {
    // console.log("data", data);

    const newWindow = window.open("", "_blank");
    newWindow.document.write(getdatahtml(data, store_data));
    newWindow.document.close();
    setTimeout(() => {
      newWindow.print();
    }, 1000);
  };

  const handleAssignForDelivery = async () => {
    const response = await searchdeliverypartner(
      user_id,
      store_id,
      "",
      "Available"
    );
    if (response) setDeliveryPartners(response?.delivery_partner_details);
    setIsModalVisible(true); // Open modal when "Assign for delivery" button is clicked
  };

  const extractNumericPrice = (priceString) => {
    // Split the string by space and extract the numeric part
    const parts = priceString?.split(" ") ?? "lorem ipsum";
    // Assuming the price is always in the last part
    return parseFloat(parts[parts.length - 1].substring(3)); // Remove "EGP" and parse as float
  };
  // console.log("data", data);
  return (
    <>
      {/* <div className="float-left">
        <Sidebar />
        </div> */}
      <div className="w-full flex flex-col md:h-full pb-2 overflow-y-auto dark:bg-black dark:text-white bg-[#F6F7F9]">
        <div className="md:h-[8vh] flex ">
          <Navbar heading="eorders" />
        </div>
        <div className=" mx-3 mt-5 ">
          {loading ? (
            <ShimmerLoader />
          ) : (
            <>
              <div className="font-extrabold">
                <Breadcrumb
                  separator={<DoubleRightOutlined />}
                  items={[
                    {
                      title: t("all_orders"),
                      href: "/orders",
                    },
                    {
                      title: `${data?.order_number}`,
                    },
                  ]}
                />
              </div>
              {/* {showComponent ? (
            <ProcessDone
              list={orderedItems}
              orderno={data?.order_number}
              customer={data?.customerconsole_details}
              payment={data?.payment_mode}
              orderdate={data?.order_date}
              deliverydate={data?.delivery_time}
              address_details={data?.address_details}
              order_id={data.id}
              orderStatus={data?.order_stage?.name}
              data={data}
              Deliveryboy={data?.delivery_boy}
              delivery_charge={data?.delivery_charge}
              parentClose={() => {
                setShowComponent(false), calldata();
              }}
            />
          ) : ( */}

              <div>
                <div className="flex flex-row items-start justify-between">
                  <div className="flex items-center text-base font-medium  mt-2 ">
                    <span>{data?.customer_details?.name}</span>
                    <span className="w-2 h-2 bg-[#1D5C96] rounded mx-2"></span>
                    <span>
                      Placed on{" "}
                      {data?.order_date?.substring(0, 10).replace(/-/g, "/")}{" "}
                      {data?.order_date?.substring(11, 13) == 12
                        ? data?.order_date?.substring(11, 16) + " PM"
                        : data?.order_date?.substring(11, 13) > 12
                        ? data?.order_date?.substring(11, 13) -
                          12 +
                          data?.order_date?.substring(13, 16) +
                          " PM"
                        : data?.order_date?.substring(11, 16) + " AM"}{" "}
                    </span>
                  </div>
                  {/* <span className="font-semibold text-2xl ">
                    Order No. {data?.order_number}
                  </span>
                  <span className="w-24 h-7 bg-[#78CFEC33] rounded-lg px-5  text-[#1D5C96] text-sm ml-3  ">
                    {data?.payment_mode}
                  </span> */}
                  <div className="flex   float-right ">
                    {(data?.order_stage?.id == 1 ||
                      data?.order_stage?.id == 4) && (
                      <button
                        type="submit"
                        className="w-[160px] h-[45px]  bg-white text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
                        onClick={handleOpenCancelOrder}
                      >
                        {t("cancel")}
                      </button>
                    )}

                    {data?.order_stage?.id === 1 ? (
                      <button
                        type="submit"
                        className={`w-[160px] h-[45px] mx-5 ${
                          acceptbutton
                            ? "bg-primaryBluedisabled"
                            : "bg-primaryBlue"
                        } text-white rounded-lg font-semibold  `}
                        onClick={() => {
                          setloading(true);
                          draftquantites.map((product) => {
                            handleEnterAndAddNext(
                              data?.order_line_details?.[0]?.order_id,
                              data?.order_line_details?.[0]?.id,
                              product?.product_id,
                              product?.sell_price,
                              product?.value
                            );
                          });

                          updateorderStage(user_id, store_id, data.id, 3).then(
                            (response) => {
                              calldata(searchid);
                              setloading(false);
                            }
                          );
                        }}
                        disabled={acceptbutton}
                      >
                        {t("accept")}
                      </button>
                    ) : data?.order_stage?.name == 2 ? (
                      <button
                        type="submit"
                        className="w-[150px] h-[45px] bg-primaryBlue text-white rounded-lg font-semibold  "
                        onClick={() => {
                          setloading(true);
                          updateorderStage(user_id, store_id, data.id, 3).then(
                            (response) => {
                              calldata(searchid);
                              setloading(false);
                            }
                          );
                          // setShowComponent(true);
                        }}
                        disabled={processButton ? false : true}
                      >
                        {t("process_order")}
                      </button>
                    ) : (
                      data?.order_stage?.id == 4 && (
                        <button
                          type="submit"
                          className="w-[150px] h-[45px] mx-5 bg-primaryBlue text-white rounded-lg font-semibold  "
                          onClick={async () => {
                            setloading(true);
                            const response = await updateorderStage(
                              user_id,
                              store_id,
                              data.id,
                              5
                            );
                            {
                              response?.message == "Order update sucessfully" &&
                                calldata(searchid);
                              setloading(false);
                              setdeliveredpopup(true);
                              // setTimeout(() => {
                              //   router.push("/orders");
                              // }, 2000);
                            }

                            // setShowComponent(true);
                          }}
                        >
                          {t("deliver_order")}
                        </button>
                      )
                    )}

                    {data?.order_stage?.id === 3 && (
                      <button
                        type="submit"
                        className="w-[180px] h-[45px] mr-4 bg-primaryBlue text-white rounded-lg font-semibold  "
                        onClick={handleAssignForDelivery} // Call function to open modal
                      >
                        {t("assign_delivery")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full item-center justify-between ">
                <div className=" md:w-full   rounded-xl bg-[#FFFFFF] text-black float-left mr-4 mt-3">
                  <p className="flex justify-between items-center px-6 py-[1.37vh] border-b">
                    <span className="font-semibold text-base">
                      {t("item_list")}
                    </span>
                    {(data?.order_stage?.id == 5 ||
                      data?.order_stage?.id == 3) && (
                      <button
                        type="submit"
                        className=" px-5 h-10 bg-[#7EC242] text-white rounded-lg font-semibold"
                        onClick={handlePrintOrderList}
                      >
                        {data?.order_stage?.id == 3
                          ? t("print_packing_slip")
                          : t("invoice")}
                      </button>
                    )}
                  </p>
                  <div className="overflow-auto h-auto">
                    {data?.order_line_details?.map((val, i) => (
                      <div
                        className={`border-b overflow-hidden mt-2 ${
                          val.delivered_qty != null ? "bg-[#FCC71D1A]" : ""
                        }`}
                        key={i}
                      >
                        {/* {console.log("value", val)} */}
                        <div className="flex my-3 mb-2 mx-6">
                          <span>
                            {" "}
                            <Image
                              src={val.image}
                              // width={50}
                              height={50}
                              alt="P.Image"
                            />
                          </span>
                          <span className="flex flex-col justify-center gap-1">
                            <span className="font-semibold  text-xs ml-3 mr-3">
                              {val?.product_name}
                            </span>

                            {/* <span className=" text-xs ml-3 rounded-xl   flex items-start mr-3 text-[#999999] float-left">
                                {/* bg-[#F7F7F7] */}
                            {/* {val?.weight}  */}
                            {/* </span> */}
                            <span className="ml-3 text-xs font-normal">
                              {/* {val?.delivered_qty > 0
                                  ? val?.delivered_qty
                                  : val?.order_qty} */}
                              {data?.order_stage?.id == 1
                                ? val?.order_qty
                                : val?.delivered_qty}{" "}
                              {t("units")} x EGP {val?.sell_price}{" "}
                              {data?.order_stage?.id == 1 ||
                              data?.order_stage?.id == 2 ? (
                                <span className="text-xs font-semibold flex justify-start mt-1 text-[#5e935e]  ">
                                  {t("available_qty")} {val?.available_qty}
                                </span>
                              ) : null}
                            </span>
                          </span>

                          <span className="ml-auto flex flex-row items-center">
                            {data?.order_stage?.id == 1 ? (
                              // (val?.delivered_qty == val?.order_qty ? (
                              //   // Display quantity and check icon
                              //   <div className="flex items-center font-semibold text-base mt-3">
                              //     <span className="ml-2">
                              //       {val?.delivered_qty > 0
                              //         ? val?.delivered_qty
                              //         : val?.order_qty}{" "}
                              //       Qty
                              //     </span>
                              //     <span className="ml-2">
                              //       <Image src={check} alt="check" />
                              //     </span>
                              //   </div>
                              // ) :
                              <div className="flex flex-row items-center justify-between">
                                <div className="flex flex-row items-center rounded-lg bg-[#61d0f2] justify-between  border">
                                  <button
                                    className="font-extrabold text-lg  text-center p-1.5 px-2"
                                    onClick={() =>
                                      handleQuantityChange(
                                        draftquantites.find(
                                          (item) =>
                                            item?.product_id === val?.product_id
                                        )?.value - 1,
                                        "btn",
                                        val?.available_qty,
                                        val.order_id,
                                        val.id,
                                        val.product_id,
                                        val.sell_price
                                      )
                                    }
                                    disabled={
                                      draftquantites.find(
                                        (item) =>
                                          item?.product_id === val?.product_id
                                      )?.value == 0
                                    }
                                  >
                                    -
                                  </button>
                                  <input
                                    type="number"
                                    className="w-16 h-10 border-x  float-right text-center "
                                    placeholder="Qty"
                                    style={{
                                      outline: "none", // Remove outline on focus
                                    }}
                                    min="0"
                                    max={
                                      val?.available_qty > val?.order_qty
                                        ? val?.order_qty
                                        : val?.available_qty
                                    }
                                    onChange={(e) => {
                                      handleQuantityChange(
                                        e,
                                        i,
                                        val?.available_qty,
                                        val.order_id,
                                        val.id,
                                        val.product_id,
                                        val.sell_price
                                      );
                                    }}
                                    value={
                                      !zero
                                        ? handleQuantityChange(
                                            val?.order_qty >= val?.available_qty
                                              ? val?.available_qty
                                              : val?.order_qty,
                                            "btn",
                                            val?.available_qty,
                                            val.order_id,
                                            val.id,
                                            val.product_id,
                                            val.sell_price
                                          )
                                        : draftquantites.find(
                                            (item) =>
                                              item?.product_id ===
                                              val?.product_id
                                          )?.value ||
                                          (data?.order_stage?.id === 1
                                            ? val.order_qty
                                            : val.delivered_qty) ||
                                          0
                                      // (!zero && Quantity == 0 && val?.available_qty!=0)
                                      //   ? handleQuantityChange(
                                      //       val?.order_qty <= val?.available_qty
                                      //         ? val?.order_qty
                                      //         : val?.available_qty,
                                      //       "btn",
                                      //       val?.available_qty,
                                      //       val.order_id,
                                      //       val.id,
                                      //       val.product_id,
                                      //       val.sell_price
                                      //     )
                                      //   : Quantity
                                    }
                                  />
                                  <button
                                    className="  font-extrabold text-lg text-center p-1.5 px-2"
                                    onClick={() =>
                                      // handleQuantityChange(
                                      //   val?.delivered_qty <
                                      //     val?.available_qty &&
                                      //     val?.delivered_qty < val?.order_qty
                                      //     ? val?.delivered_qty + 1
                                      //     : 0,
                                      //   "btn",
                                      //   val?.available_qty,
                                      //   val.order_id,
                                      //   val.id,
                                      //   val.product_id,
                                      //   val.sell_price
                                      // )

                                      handleQuantityChange(
                                        draftquantites.find(
                                          (item) =>
                                            item?.product_id === val?.product_id
                                        )?.value + 1,
                                        "btn",
                                        val?.available_qty,
                                        val.order_id,
                                        val.id,
                                        val.product_id,
                                        val.sell_price
                                      )
                                    }
                                    disabled={
                                      draftquantites.find(
                                        (item) =>
                                          item?.product_id === val?.product_id
                                      )?.value >= val?.available_qty ||
                                      draftquantites.find(
                                        (item) =>
                                          item?.product_id === val?.product_id
                                      )?.value >= val?.order_qty
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                                {data?.order_stage?.id == 2 && (
                                  <button
                                    className=" rounded-md font-semibold text-gray-100 ml-2"
                                    onClick={() => {
                                      setzero(true);
                                      handleQuantityChange(
                                        0,
                                        "btn",
                                        val?.available_qty,
                                        val.order_id,
                                        val.id,
                                        val.product_id,
                                        val.sell_price
                                      );
                                    }}
                                  >
                                    <Image
                                      className="mx-1"
                                      src={deleteicon}
                                      alt="deleteicon"
                                    />
                                  </button>
                                )}
                              </div>
                            ) : (
                              //  <button
                              //       type="button"
                              //       className="w-24 h-10 bg-white text-primaryBlue text-sm font-semibold rounded-lg flex justify-center items-center border border-[#CDCDCD]"
                              //       onClick={() => openModal(i)}
                              //       //  disabled={isModalOpen && activeItem === i}
                              //     >
                              //       {/* {val?.imagecode} */}
                              //       Add{" "}
                              //       <p className="pl-2 w-8">
                              //         {" "}
                              //         <Image src={code} alt="Code" />
                              //       </p>
                              //     </button>
                              //   ))}
                              // {isModalOpen && activeItem === i && (
                              //   <div className="modal absolute shadow-md rounded-md z-10 right-[30.5%] mt-2">
                              //     <button
                              //       className="absolute top-1 right-1  text-gray-500 hover:text-gray-800"
                              //       onClick={closeModal}
                              //     >
                              //       <svg
                              //         xmlns="http://www.w3.org/2000/svg"
                              //         className="h-4 w-4"
                              //         viewBox="0 0 20 20"
                              //         fill="currentColor"
                              //       >
                              //         <path
                              //           fillRule="evenodd"
                              //           d="M15.293 5.293a1 1 0 011.414 1.414L11.414 12l5.293 5.293a1 1 0 01-1.414 1.414L10 13.414l-5.293 5.293a1 1 0 01-1.414-1.414L8.586 12 3.293 6.707a1 1 0 111.414-1.414L10 10.586l5.293-5.293a1 1 0 011.414 0z"
                              //           clipRule="evenodd"
                              //         />
                              //       </svg>
                              //     </button>
                              //     <div className="bg-white px-5 py-5 w-[33.4rem] rounded-lg border border-[#E3E7EF]">
                              //       <div className="mb-4">
                              //         <input
                              //           type="text"
                              //           className="w-full h-10 border border-[#CDCDCD] rounded-lg px-4"
                              //           placeholder="Search..."
                              //         />
                              //       </div>

                              //       <div className="mb-4 flex items-center border-b pb-5">
                              //         {/* <span className="font-normal text-sm ">
                              //           <Image
                              //             src={val.image}
                              //             width={50}
                              //             height={50}
                              //             alt="P.Image"
                              //           />
                              //         </span> */}
                              //         <span className="flex items-center min-w-full justify-between">
                              //           <span className="font-normal text-sm ">
                              //             <Image
                              //               src={val.image}
                              //               width={50}
                              //               height={50}
                              //               alt="P.Image"
                              //             />
                              //           </span>
                              //           <span className="text-md font-semibold ml-1">
                              //             {val.product_name?.substring(0, 24)}
                              //           </span>
                              //           <span className="text-sm font-semibold ml-20">
                              //             EGP {val?.sell_price}
                              //           </span>
                              //           <input
                              //             type="number"
                              //             className="w-24 h-10 border border-[#CDCDCD] rounded-lg px-4 float-right "
                              //             placeholder="Qty"
                              //             min="0"
                              //             max={
                              //               val?.available_qty > val?.order_qty
                              //                 ? val?.order_qty
                              //                 : val?.available_qty
                              //             }
                              //             onChange={(e) => {
                              //               handleQuantityChange(
                              //                 e,
                              //                 i,
                              //                 val?.available_qty
                              //               );
                              //             }}
                              //           />
                              //         </span>
                              //       </div>
                              //       {Error ? (
                              //         <div className="text-xs italic flex justify-end mr-8 text-red">
                              //           Max Quantity can't exceed{" "}
                              //           {val?.available_qty}
                              //         </div>
                              //       ) : (
                              //         <div className="text-xs  italic flex justify-end mr-8 text-red">
                              //           Available Quantity {val?.available_qty}{" "}
                              //         </div>
                              //         )
                              // }

                              //         <div className="flex justify-end mt-2">
                              //           {/* <button
                              //             className="w-44 h-10 border-primaryBlue border text-primaryBlue text-sm font-semibold rounded-lg mr-4"
                              //             onClick={handleMarkAsDamaged}
                              //             disabled
                              //           >
                              //             Mark as Damaged
                              //           </button> */}
                              //           <button
                              //             className={`w-44 h-10 ${
                              //               Error || Quantity == 0
                              //                 ? "bg-primaryBluedisabled"
                              //                 : "bg-primaryBlue"
                              //             } text-white text-sm font-semibold rounded-lg`}
                              //             onClick={() => {
                              //               handleEnterAndAddNext(
                              //                 val.order_id,
                              //                 val.id,
                              //                 val.product_id,
                              //                 val.sell_price
                              //               );
                              //             }}
                              //             disabled={
                              //               Error || Quantity == 0 || Quantity > val?.available_qty ? true : false
                              //             }
                              //           >
                              //             Enter & Add Next
                              //           </button>
                              //         </div>
                              //       </div>
                              //     </div>

                              <></>
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* (data?.order_stage?.id==4 || data?.order_stage?.id==5 )  &&  */}
                  {
                    <PaymentInfo
                      totalPrice={data?.order_total_amt}
                      payment={data?.payment_mode}
                      delivery_charge={data?.delivery_charge}
                    />
                  }
                </div>
                {/* {data?.order_stage?.name != "In Processing" ? ( */}
                <OrdercustomerDetails
                  customer={data?.customer_details}
                  deliverydate={data?.delivery_time}
                  address_details={data?.address_details}
                  order_stage={data?.order_stage?.name}
                  Deliveryboy={data?.delivery_boy}
                />
                {/* ) : (
                  <div className="w-1/2 mt-3 shadow-lg mr-1 h-[300px]">
                    <div className="bg-[#C9ECF7] p-4 font-bold text-lg rounded-t-lg">
                      Billing Details
                    </div>
                    <div className="flex flex-col p-4 bg-white h-auto min-h-[20rem] rounded-b-lg">
                      {/* {orderedItems.map((item, index) => (
                        <div key={index} className="mb-3 border-b pb-2">
                          <span className="float-left">
                            <Image
                              src={item.image}
                              width={70}
                              height={100}
                              alt="P.Image"
                            />
                          </span>
                          <span className=" text-sm text-start ">
                            {item.product_name}
                          </span>
                          <br />
                          <span className="text-sm">
                            {" "}
                            EGP {item.sell_price} x {item.delivered_qty}
                            {" Units"}
                          </span>
                          <span className=" text-md float-right ">
                            EGP{" "}
                            {/* {item.delivered_qty * extractNumericPrice(item.sell_price)} */}
                {/* {item.delivered_qty * item.sell_price}
                          </span>
                        </div>
                      ))} */}
                {/* <div className="flex flex-col items-center justify-center">
                        <Image
                          className="w-20 h-20 mb-4 "
                          src={mark}
                          style={{
                            display:
                              orderedItems.length === 0 ? "block" : "none",
                          }}
                          alt="mark"
                        />
                        {orderedItems.length === 0 && (
                          <p className="text-[#272728] font-normal">
                            No Products added yet
                          </p>
                        )}
                      </div> */}
                {/* <div className="flex flex-col w-full ">
                        {orderedItems.length > 0 && (
                          <div className="text-sm flex flex-col w-full border-b-2">
                            <div className=" text-xs flex justify-between  pt-4">
                              Total Line:
                              <p>{orderedItems.length}</p>
                            </div>
                            <div className="text-xs flex justify-between pb-3">
                              Total Qty:
                              <p>
                                {orderedItems.reduce(
                                  (totalQty, item) =>
                                    totalQty + item.delivered_qty, // Summing up the quantities
                                  0
                                )}
                              </p>
                            </div>
                          </div>
                        )}
                        {orderedItems.length > 0 && (
                          <div className="text-sm  flex justify-between mt-3">
                            Total Value :
                            <p>
                              {orderedItems.reduce(
                                (acc, curr) =>
                                  acc + curr.delivered_qty * curr.sell_price,
                                // curr.count *
                                //   extractNumericPrice(curr.countprice),
                                0
                              )}
                            </p>
                          </div>
                        )}
                        {orderedItems.length > 0 && (
                          <div className="text-sm  flex justify-between">
                            Delivery Charge:
                            <p>{data?.delivery_charge?.toFixed(2)}</p>
                          </div>
                        )}
                        {orderedItems.length > 0 && (
                          <div className="text-sm flex justify-between">
                            Grand Total :
                            <p>
                              {(
                                orderedItems.reduce(
                                  (acc, curr) =>
                                    acc + curr.delivered_qty * curr.sell_price,
                                  // extractNumericPrice(curr.countprice),
                                  0
                                ) *
                                  1.0 +
                                data?.delivery_charge
                              ).toFixed(2)}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )} */}
              </div>
            </>
          )}
        </div>
      </div>
      <DeliveryBoyDetails
        isOpen={isModalVisible} // Pass the state to manage modal visibility
        onClose={() => setIsModalVisible(false)}
        DeliveryPartners={DeliveryPartners} // Close modal when needed
        orderno={data?.order_number}
        order_id={data.id}
        Popup={() => {
          setpopup(true);
        }}
      />
      {/* Render CancelOrder component when isCancelOrderOpen is true */}

      {isCancelOrderOpen && (
        <CancelOrder
          isOpen={isCancelOrderOpen}
          onClose={() => setCancelOrderOpen(false)}
          order_id={data.id}
          popup={() => setcancelledpopup(true)}
        />
      )}
      {cancelledpopup && (
        <Cancelledpopup
          onClose={() => {
            setcancelledpopup(false);
            calldata(searchid);
          }}
        />
      )}
      {deliveredpopup && (
        <Orderdeliveredpopup
          orderno={data?.order_number}
          onClose={() => {
            setdeliveredpopup(false);
            calldata(searchid);
          }}
        />
      )}
      {popup && (
        <AssignDeliveryBoypopup
          message={t("assign_deliveryboy_msg")}
          onClose={() => {
            setpopup(false);
            calldata(searchid);
          }}
        />
      )}
    </>
  );
};

export default Orderlist;
