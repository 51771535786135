import { routes } from "../Routes";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Select } from "antd";

import React, { useState } from "react";
import "../styles.css";
import SuccessModal from "./Sucessmodal";
import AssignDeliveryBoypopup from "./AssignDeliveryBoypopup";
import { updateorderStage } from "../API  Functions/OrderlistFunction";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const styles = {
  selectant: {
    border: "none",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    padding: "30px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "left",
    // marginBottom: "10px",
    // marginTop: "5px",
  },
  cancelIcon: {
    cursor: "pointer",
  },
  description: {
    textAlign: "left",
    fontSize: "16px",
    color: "#272728",
    marginTop: "10px",
  },
  input: {
    width: "100%",
    height: "6.5vh",
    // padding: "12px",
    borderRadius: "5px",
    // border: "1px solid #CCCCCC",
    marginBottom: "20px",
    boxSizing: "border-box",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "180px",
    height: "42px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    marginRight: "10px",
  },
  confirmButton: {
    backgroundColor: "primaryBlue",
    color: "#FFFFFF",
  },
  cancelButton: {
    backgroundColor: "#CCCCCC",
    color: "#000000",
  },
  note: {
    color: "primaryBlue",
    marginTop: "20px",
    textAlign: "left",
  },
};

const DeliveryBoyDetails = ({
  isOpen,
  onClose,
  DeliveryPartners,
  orderno,
  order_id,
  Popup
  
}) => {
  const {t,i18n}=useTranslation()
  const user_id = useSelector(state=>state.login?.user?.user_id)
  const store_id = useSelector(state=>state.login?.user?.store_id)
  
  const { Option } = Select;
 
  const [selectedReason, setSelectedReason] = useState(t('select'));
  const [thirdParty, setthirdParty] = useState(false);
  const [restricted, setrestricted] = useState(true);

  const handleSelectChange = (value) => {
    setSelectedReason(value);
    setrestricted(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, including selectedReason

    thirdParty
      ? updateorderStage(user_id,store_id,order_id, 4, 0, 2).then((response) => {
          ;
           if (response?.data?.message == "Order updated successfully")
            Popup();
          // setTimeout(() => {
            
          // }, 500);
        })
      : updateorderStage(user_id,store_id,order_id, 4, selectedReason, 1).then((response) => {
          if (
            response.data.status ==
            "Order updated successfully / Delivery partner assigned successfully"
          )
          Popup();
          // setTimeout(() => {}, 500);
        });
    // Additional logic like closing the modal
    onClose();
    };

  return (
    <>
      {isOpen && (
        <>
          <div style={styles.overlay} onClick={onClose}></div>
          <div style={styles.container}>
            <div style={styles.header}>
              <p style={styles.title}>{t('delivery_boy_details')}</p>
              {/* <CloseOutlined style={styles.cancelIcon} onClick={onClose} /> */}
            </div>
            {/* <p style={styles.description}>
              Order No {orderno} is to be picked up by Delivery Boy
            </p> */}
            <div className="justify-content-center align-items-center">
              <form onSubmit={handleSubmit}>
                <p className=" mb-2 mt-6">{t('select_delivery_boy')}</p>
                <Select
                  style={styles.input}
                  value={selectedReason}
                  onChange={handleSelectChange}
                  disabled={thirdParty ? true : false}
                >
                  {DeliveryPartners.map((item) => (
                    <>
                      {/* {console.log("item", item)} */}
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    </>
                  ))}
                </Select>
                <div className="flex flex-row items-center justify-center mb-4">
                  <div
                    style={{
                      content: "",
                      border: 1,
                      width: "154px",
                      height: "1px",
                      background: "#3f3f3f",
                    }}
                  ></div>
                  <p className="mx-4">{t('or')}</p>
                  <div
                    style={{
                      content: "",
                      border: 1,
                      width: "154px",
                      height: "1px",
                      background: "#3f3f3f",
                    }}
                  ></div>
                </div>

                <div className="flex flex-row gap-2 cursor-pointer">
                  <input
                    type="checkbox"
                    value="thirdParty"
                    checked={thirdParty}
                    onChange={() => {
                      setthirdParty(!thirdParty)
                       setrestricted(false);
                    }}
                    className="ml-1"
                  />
                  <p
                    onClick={() => {
                      setthirdParty(!thirdParty)
                      setrestricted(false);
                    }}
                  >
                   { t('assign_deliveryboy_third')}{" "}
                  </p>
                </div>
                {/* </div> */}

                {/* <p className="mb-3 mt-6">Delivery Boy’s Name</p>
              <input
                type="text"
                placeholder="Enter Delivery Boy's Name"
                value={deliveryBoyName}
                onChange={(e) => setDeliveryBoyName(e.target.value)}
                style={styles.input}
              /> */}

                <div className="flex justify-center mt-5">
                  <button
                    className="w-52 h-10 border-primaryBlue border text-primaryBlue text-sm font-semibold rounded-lg mr-5"
                    onClick={onClose}
                  >
                    {t('close')}
                  </button>
                  <button
                    type="submit"
                    className={`w-52 h-10 ${
                      selectedReason != t('select') || thirdParty != false
                        ? "bg-primaryBlue"
                        : "bg-primaryBluedisabled"
                    } text-white text-sm font-semibold rounded-lg `}
                    disabled={
                      selectedReason != t('select') || thirdParty != false
                        ? false
                        : true
                    }
                  >
                    {t('save')}
                  </button>
                </div>
              </form>
              {/* <p style={styles.note}>
                Note: By saving this, order will move to “On the way” status.
              </p> */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DeliveryBoyDetails;
