import axiosInstance from "../AxiosInstance";
import { URLS } from "../API/API endpoints";
import { store } from "../Redux/store";
const { login } = store.getState();
const user_id=login.user
const store_id=login.user

// Assuming URLS is correctly imported and contains the SEARCH_ORDER URL

export const getusertypes = async () => {
  try {
    const response = await axiosInstance.get(URLS.GET_USER_TYPES);

    return response;
  } catch (error) {
    return error;
  }
};

export const createStoreUser = async (user_id,store_id,values) => {
  try {
    const payload = {
      user_id:user_id,
     store_id:store_id,
      password: values.password,
      first_name: values.first_name,
      last_name: values.last_name,
      store_user_type_id: values.store_user_type_id,
      mobile: values.mobile,
      email: values.email,
      url: values.url,
    };
    const response = await axiosInstance.post(URLS.CREATE_STORE_USER, payload);
      return response;
  } catch (error) {
    return error;
  }
};

export const updateStoreUser = async (user_id,store_id,values, res_user_id, user_link_id) => {
  try {
      const payload = {
      user_id:user_id,
     store_id:store_id,
      password: values.password,
      first_name: values.first_name,
      last_name: values.last_name,
      store_user_type_id: values.store_user_type_id,
      mobile: values.mobile,
      email: values.email,
      url: values.url,
      res_user_id: res_user_id,
      user_link_id: user_link_id,
    };
    const response = await axiosInstance.post(URLS.UPDATE_STORE_USER, payload);

    return response;
  } catch (error) {
    return error;
  }
};

export const blockStoreUser = async (user_id,store_id, res_user_id, user_link_id,block=0) => {
  try {
        const payload = {
         store_id:store_id,
      user_link_id: user_link_id,
      res_user_id: res_user_id,
      block:block
      
    };
    const response = await axiosInstance.post(URLS.INACTIVE_STORE_USER, payload);

    return response;
  } catch (error) {
    return error;
  }
};

export const getStoreUsers = async (user_id,store_id,) => {
  try {
    const payload = {
      user_id:user_id,
     store_id:store_id,
    };
    const response = await axiosInstance.post(
      URLS.GET_ALL_STORE_USERS,
      payload
    );

    return response.data;
  } catch (error) {
    return error;
  }
};
