import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "tailwindcss/tailwind.css";
import CustomPagination from "../CustomWidgets/CustomPagination";
import { Empty } from "antd";
import {Image}  from "antd";
import arrowright from "../../assets/arrowright.svg";
import "../../styles.css"
import ShimmerLoader from "../../loading";
import Vectorsorting from "../../assets/Vectorsorting.svg";
import { useTranslation } from "react-i18next";

const Deliverypartnerstable = ({
  Deliverypartners,
  onMarkAsDeposited,
  SearchDeliverypartners,
  pagination,
}) => {
  const {t,i18n}=useTranslation()
  const [buttonClicked, setButtonClicked] = useState({});
   const [flag, setflag] = useState(false);

useEffect(() => {
    if ((SearchDeliverypartners ? SearchDeliverypartners : Deliverypartners).length === 0 && !flag) {
      const timer = setTimeout(() => {
        setflag(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [Deliverypartners, SearchDeliverypartners, flag]);


  const Data = useMemo(
    () =>
      SearchDeliverypartners ? SearchDeliverypartners : Deliverypartners || [],
    [Deliverypartners, SearchDeliverypartners]
  );

  const columns = useMemo(
    () => [
      { Header: t('name'), accessor: "name" },
      {
        Header: t('phone_number'),
        accessor: "mobile",
        Cell: ({ value }) => `+20 ${value?.length==11?value?.substring(1):value}`  
      },
      {
        Header: t('joined_on'),
        accessor: (row) => `${row.joined_on}`,
        Cell: ({ value }) => <span>{value?.replace(/-/g, "/")}</span>,
      },
      { Header: t('orders'), accessor: "orders" },
      // , Cell: OrdersCell
      { Header: t('status'), accessor: "status" },
      { Header:t('order_payment'), accessor: "orderpayment" },
      { Header: t('cash_on_custody'), accessor: "cashoncustody" },
      {
        Header: "",
        accessor: "action",
        Cell: ({ row }) => (
          <button
            className=" text-[#1b4497] rounded-lg"
            onClick={() => {
              // Update the separate object without causing a re-render
              setButtonClicked((prev) => ({
                ...prev,
                [row.id]: !prev[row.id],
              }));
              // Call the handler function passed as a prop

              onMarkAsDeposited(row.original);
            }}
          >
            <img src={arrowright} className={`${i18n.language=="en"?"":" rotate-180"}`} alt="arrowright" height={3} width={20} />
            {/* Open Order {">>"} */}
          </button>
        ),
      },
    ],
    []
  );

  function OrdersCell({ value }) {
    // Convert value to array if it's not already an array

    const orders = Array.isArray(value) ? value : [value];

    return (
      <div className="flex items-center">
        {orders.map((status, index) => {
          // Check if status is a string before calling toLowerCase()
          const lowercaseStatus =
            typeof status === "string" ? status.toLowerCase() : "";

          let color;
          switch (lowercaseStatus) {
            case "available":
              color = "#78CFEC";
              break;
            case "paid":
              color = "#FFD758";
              break;
            case "cancelled order":
              color = "#FF7171";
              break;
            default:
              color = "#FE9B3F";
          }
          return (
            <div
              key={index}
              className="rounded-full h-4 w-4 mr-2 flex items-center justify-center"
              style={{ backgroundColor: color }}
            >
              <span className="text-xs text-white">{status}</span>
            </div>
          );
        })}
      </div>
    );
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: Data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  const [pageIndex, setpageIndex] = useState(1);
  useEffect(() => {
    const pageDetails = {
      limit: pageSize,
      offset: pageSize * (pageIndex - 1) < 0 ? 0 : pageSize * (pageIndex - 1),
    };
    pagination(pageDetails);
  }, [pageIndex, pageSize, previousPage, nextPage]);

  return (
    <div className="container mx-auto float-left flex items-center justify-center" style={{ maxWidth: "100%" }}>
      {(SearchDeliverypartners ? SearchDeliverypartners : Deliverypartners)?.length == 0 && flag == false ? (
          <>
            <ShimmerLoader />{" "}
            {/* {setTimeout(() => {
              setflag(true);
            }, 3000)} */}
          </>
        ) : (
      <table
        {...getTableProps()}
        className=" mt-7 font-normal max-w-[100%] sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%] min-h-auto h-[100%] shadow-xl rounded-lg overflow-hidden"
      >
        <thead>
        {headerGroups.map((headerGroup, headerIndex) => {
              const { key: headerKey, ...headerGroupProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr
                  key={headerKey}
                  {...headerGroupProps}
                  className="bg-[#78CFEC66] text-sm font-semibold"
                >
                  {headerGroup.headers.map((column, colIndex) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th
                        key={columnKey}
                        {...columnProps}
                        className="py-3 px-2 whitespace-nowrap "
                      >
                        <div className="flex items-center justify-center">
                          {column.render("Header")}
                          <span className="">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <img src={Vectorsorting} style={{minHeight:10,minWidth:10}} />
                              ) : (
                                <img
                                  className="rotate-180"
                                  src={Vectorsorting}
                                  style={{minHeight:10,minWidth:10}}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-[#F7F9FD] text-center">
          {page.length > 0 ? (
            // Render table rows if there are rows to display
            page.map((row) => {
              prepareRow(row);
              return (
                <React.Fragment key={row.id}>
                  <tr
                    key={row.id}
                    {...row.getRowProps()}
                    className="relative hover:bg-white transition duration-300 border-b border-gray-300 fixed-row-height"
                  >
                    {row?.cells?.map((cell, index) => (
                      <td
                        key={cell.getCellProps().key}
                        {...cell.getCellProps()}
                        className={`p-2 text-[#272728] h-1212 text-sm font-normal ${
                          index < row.cells.length - 1 ? "relative" : ""
                        }`}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                </React.Fragment>
              );
            })
          ) : (
            // Render empty state if there are no rows
            <tr>
              <td colSpan={columns.length}>
                <Empty description="No data available" />
              </td>
            </tr>
          )}
          <tr>
            <td className="" colSpan={columns.length}>
              <CustomPagination
                pageIndex={pageIndex}
                pageCount={page.length}
                canPreviousPage={pageIndex != 1 ? true : false}
                canNextPage={Data?.length >= 10 ? true : false}
                gotoPage={(page) => {
                  nextPage(page);
                }}
                previousPage={() => {
                  setpageIndex(pageIndex - 1);
                }}
                nextPage={() => {
                  setpageIndex(pageIndex + 1);
                }}
                pageSize={pageSize}
                setPageSize={(pageSize) => {
                  // nextPage(0);
                  setPageSize(pageSize);
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>)}
    </div>
  );
};

export default Deliverypartnerstable;
