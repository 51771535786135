// LoaderComponent.js
import React from "react";
import "./Loader.css"; // Import CSS file for styling

const LoaderComponent = () => {
  return (
    <div className="loader"></div>
  );
};

export default LoaderComponent;
