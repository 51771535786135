// NoInternetPopup.js
import React from "react";
import checked from "../../assets/wifi-logo.svg";
import { useTranslation } from "react-i18next";


const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    padding: "20px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "20px",
    marginTop: "30px",
  },
  image: {
    width: "70px",
    display: "block",
    margin: "30px auto",
    marginBottom: "20px",
  },
  description: {
    textAlign: "center",
    fontSize: "0.875rem",
    color: "#5C5C5C",
    marginBottom: "20px",
  },
};

const NoInternetPopup = ({ isVisible }) => {
  const {t,i18n} =useTranslation();
  

  return (
    <div>
      {isVisible ? (
        <div style={styles.overlay}>
          <div style={styles.container}>
            <img
              src={checked} // Provide the path to your checked image
              alt="Checked"
              style={styles.image}
            />
            <p style={styles.title}>{t('No Internet Connection')}</p>
            <p style={styles.description}>{t('No internet connection found.')}
              <br/>
            {t('Check your internet connection and try again.')}
            </p>

            <div
              className={` flex justify-center no-internet-popup${
                isVisible ? "visible" : "hidden"
              }`}
              style={{ zIndex: 999 }}
            >
              <p className="text-red   "></p>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default NoInternetPopup;
