import { URLS } from "../API/API endpoints";
import axiosInstance from "../AxiosInstance";


export const getdataDeliveryPartner = async (user_id,store_id,) => {

  try {
    const payload = {
      user_id:user_id,
     store_id:store_id,
    };
    const response = await axiosInstance.post(
      URLS.DELIVERY_PARTNER_DETAILS,
      payload
    );
    // .then((response) => {
    // ;
    //   return response.data?.delivery_partner_details
    // });
    const result=response.data.status===401?response.data.status:response.data.delivery_partner_details
    
    return result ;
  } catch (error) {
    return error;
  }
};

export const getDeliveryPartnerOrders = async (user_id,store_id,
  delivery_partner_id,
  limit = 10,
  offset = 0
) => {
  try {
    const payload = {
      user_id:user_id,
     store_id:store_id,
      delivery_partner_id: delivery_partner_id,
      limit: limit,
      offset: offset,
    };
    const response = await axiosInstance.post(
      URLS.GET_DELIVERY_PARTNER_ORDERS,
      payload
    );
    // .then((response) => {
    // ;
    //   return response.data?.delivery_partner_details
    // });
    return response.data;
  } catch (error) {
    return error;
  }
};

export const searchdeliverypartner = async (user_id,store_id,query="",status="") => {
  try {
    const payload = {
      user_id:user_id,
     store_id:store_id,
      query: query,
      status: status,
    };
    const response = await axiosInstance.post(
      URLS.SEARCH_DELIVERY_PARTNER,
      payload
    );
    // .then((response) => {
    // console.log("response", response.data);
    //   return response.data?.delivery_partner_details
    // });
    return response.data;
  } catch (error) {
    return error;
  }
};
