import React from "react";
import {Image}  from "antd";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
import logo from "../../assets/Logo/logo.svg";
import LanguageSwitcher from "../../components/language-switcher";
import ForgotOTPModal from "../../modals/verifyOtpForgot";
// import { InputBox } from "../../components/CustomWidgets/InputBox";
import { Link, useNavigate } from "react-router-dom";
import PhoneOrEmail from "./PhoneOrEmail";
import NewPassword from "./NewPassword";
import { useState } from "react";
// import { useModalAction } from "../../modals/modal.context";
import { Button } from "antd";
import { forgotPassword } from "../../components/Actions/ForgotPassword";
import { useDispatch } from "react-redux";
import { loginUser } from "../../components/Actions/loginActions";

import { routes } from "../../Routes";
import Resetpasswordmodal from "../../modals/Resetpasswordmodal";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";


const ForgotPassword = () => {
  const {t,i18n}=useTranslation()
  const { user } = useSelector((state) => state?.login);
  const navigate=useNavigate();
 
  const dispatch = useDispatch();
  const [Loading,setLoading]=useState(false);
  
  // const { openModal, closeModal } = useModalAction();

  //const { openModal,closeModal} = useModalAction();
  const [isOTPModalOpen, setOTPModalOpen] = useState(false);
  const [isEmailRequired, setEmailRequired] = useState(true);
  const [isNewpassword, setisNewpassword] = useState(false);
  const [phoneNumber, setphoneNumber] = useState();
  const [Notification,setNotification]=useState(false)
  const [popup,setpopup]  =useState(false)

  // const loginAfterForgotPassword =async(loginpayload)=>{

  //   setTimeout(async () => {
  //     try {
  //       const userData = await dispatch(loginUser(loginpayload));
  //       console.log("login user", userData);

  //       // Check if the response contains an error message
  //       if (
  //         (userData && userData.error) ||
  //         userData.payload.message.includes("Invalid")
  //       ) {
  //         // Display error message for invalid credentials
  //         alert("Invalid email/phone or password. Please try again.");
  //       } else {
  //         //setUserDetail Check if access token and user ID are the same
  //         if (userData && userData.access_token === userData.user_id) {
  //           // Redirect to the orders page
  //           const userDetail = {
  //             access_token: userData.payload.token,
  //             user_id: userData.payload.userDetails.user_id,
  //           };
  //           console.log("after login", userData);
  //           localStorage.setItem("user_detail", JSON.stringify(userDetail));
  //           localStorage.setItem(
  //             "retailer_info",
  //             JSON.stringify(userData.payload.RetailerDetails[0])
  //           );
  //           localStorage.setItem("isAuthenticated", true);
  //           router.push(routes.Orders);
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error occurred during login:", error);
  //       // Handle other errors, such as network issues
  //     }
  //   }, 2000); // 5000 milliseconds = 5 seconds

  // }

  const handleDataSubmit = (dataType, value) => {
    if (dataType === "Send Otp") {
      const payload = {
        mobile: value?.mobile?.length==10?value?.mobile:value?.mobile?.substring(1),
        country_code: "+20",
        otp: "5409",
        user_id: 8,
        password: "abc123",
        old_password: "abc123",
        page: "send_otp", //send_otp, verify_otp, password
      };
      setLoading(true)
      const response = dispatch(forgotPassword(payload));
      setLoading(false)
      response.then((result) => {
        // console.log("data from api", result);
        if (
          result.payload.status == 200 &&
          result.payload?.message == "Please Provide Registered mobile number."
        ) {
          // alert("Please Provide Registered mobile number.");
          setNotification(true)
        } else if (
          result.payload.status == 200 &&
          result.payload?.response == "OTP send to customer"
        ) {
          setphoneNumber(value?.mobile?.length==10?value?.mobile:value?.mobile?.substring(1));
          // closeModal()
          setEmailRequired(false);
          setOTPModalOpen(true);
        }
      });
    } else if (dataType === "New Password") {
      // console.log(dataType, value);
      const payload = {
        mobile: value.mobile,
        country_code: "+20",
        otp: "5409",
        user_id: 8,
        password: value.password,
        old_password: "abc123",
        page: "password", //send_otp, verify_otp, password
      };
      setLoading(true)
      const response = dispatch(forgotPassword(payload));
      setLoading(false)
      response.then((result) => {
        //console.log("data from api after setting password", result);
        // if (
        //   result.payload.status == 200 &&
        //   result.payload?.message == "Please Provide Registered mobile number."
        // ) {
        //   alert("Please Provide Registered mobile number.");
        // }
        if (
          result.payload.status == 200 &&
          result.payload?.message == "Password Updated successfully."
        ) {
         setpopup(true)
          // let loginpayload={login_id: payload.mobile,
          //   password: payload.password,}
          //   loginAfterForgotPassword(loginpayload)
        }
      });
    }
    // setphoneNumber(value);
  };

  const handleOTPClose = (value) => {
    if (value === "pass") {
      setOTPModalOpen(false);
      setisNewpassword(true);
    } else if (value === "cancel") {
      setEmailRequired(true);
      setOTPModalOpen(false);
    }
  };
  return (
    <div className=" flex items-center justify-center bg-[#F6F7F9] " style={{minHeight:"100vh"}} >
      <div className="absolute right-4 top-4 text-black md:right-5 md:top-5">
        <LanguageSwitcher />
      </div>
      <div className="max-w-md w-[50%] p-6  md:p-6 bg-white rounded-3xl">
        <div className="w-full flex flex-col items-center mt-4 mx-auto justify-center">
          <img
            src={logo}
            alt="Vercel"
            className="mb-4"
            width={120}
            height={24}
            priority
          />
        </div>
        {isEmailRequired && (
          <PhoneOrEmail
            // onclose={handlephoneClose}
            onDatasubmit={handleDataSubmit}
            Loading={Loading}
            showNotification={Notification}
            changeerror={()=>{setNotification(false)}}
          />
        )}
        {isOTPModalOpen && (
          <ForgotOTPModal
          Loading={Loading}
            isOpen={true}
            onClose={() => handleOTPClose("cancel")}
            phone={phoneNumber}
            onDataSubmit={() => handleOTPClose("pass")}
            numberChange={() => {
              setOTPModalOpen(false);
              setEmailRequired(true);
            }}
          />
        )}
        {isNewpassword && (
          <NewPassword phone={phoneNumber} onDataSubmit={handleDataSubmit} 
          Loading={Loading}/>
        )}

        <div className="text-center flex justify-center text-[#5C5C5C] text-sm mt-10 mb-5">
          {t('remember_password')}{" "}
          {console.log("user",user?.access_token)}
          { user?.access_token? (
            <span className="cursor-pointer text-primaryBlue font-semibold">
              <button>
                {" "}
                <Link to="/settings/profile"><p >{" "}{t('backtoprofile')}</p></Link>
              </button>
            </span>
          ) : (
            <span className="cursor-pointer text-primaryBlue font-semibold ps-1 underline">
              <buton>
                {" "}
                <Link to="/login"> <p >{" "}{t('try_login')}</p></Link>
              </buton>
          </span>
          )}
        </div>

        {/* Render the OTP modal component with a condition       */}
      </div>
      {popup && <Resetpasswordmodal onClose={()=>navigate(routes.login)}/>}
    </div>
  );
};

export default ForgotPassword;
