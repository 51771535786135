import React, { useState, useEffect } from "react";
import {Image}  from "antd";
import one from "../../assets/Inventory/Stockout.svg";
import two from "../../assets/Inventory/Low_on_Inventory.svg";
import three from "../../assets/Inventory/High_on_Inventory.svg";
import { getInventoryallproducts } from "../../API  Functions/InventoryFunctions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const InventoryBar = ({ onInventoryBoxClick,user_id, store_id }) => {
  const [kpiData, setkpiData] = useState([]);
  const {t,i18n} =useTranslation();
  const getInventoryKPI = () => {
    getInventoryallproducts(user_id,store_id).then((res) => {
      setkpiData(res);
    });
  };

  useEffect(() => {
    if (store_id) getInventoryKPI();
  }, [store_id]);

  const values = {
    1: {
      img: one,
      heading: t('stockout'),
      productsleft: `${kpiData?.out_stock_len??0} ${t('products')}`,
      bgcolor: "#fbdada",
      textcolor: "#EB3C3C",
      link:"/inventory/Outofstock"
    },
    2: {
      img: two,
      heading: t('low_on_inventory'),
      productsleft: `${kpiData?.low_stock_len??0} ${t('products')}`,
      bgcolor: "#ffe5cd",
      textcolor: "#EB853C",
      link:"/inventory/Lowoninventory"
    },
    3: {
      img: three,
      heading: t('high_on_inventory'),
      productsleft: `${kpiData?.high_stock_len??0} ${t('products')}`,
      bgcolor: "rgb(209,241,252)",
      textcolor: "#059003",
      link:"/inventory/Highoninventory"
    },
  };

  return (
    <div className="flex flex-wrap mt-20">
      {Object.values(values).map((val, index) => (
        
        <div
          key={index}
          className="w-1/3 sm:w-1/3 md:w-1/3 xl:w-1/3 px-3 mb-2 cursor-pointer"
          //onClick={() => onInventoryBoxClick(val.heading)}
        >
          <div
            style={{ backgroundColor: val.bgcolor }}
            className="w-full rounded-lg flex items-center p-2 shadow-sm relative"
          >
            <Link to={val.link} className="w-full rounded-lg flex items-center  relative">
            <div className="mr-4">
              <img src={val.img} alt={val.heading} width={48} height={48}  />
            </div>
            <div>
              <p className="font-normal text-md">{val.heading}</p>
              <p
                style={{ color: val.textcolor }}
                className="font-medium text-xl"
              >
                {val.productsleft}
              </p>
            </div>
            </Link>
          </div>
        </div>
        
      ))}
    </div>
  );
};

export default InventoryBar;
