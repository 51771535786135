import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";

export const verifyOtpUser = createAsyncThunk(
  "verifyOtp/verifyOtpUser",
  async (payload, { rejectWithValue }) => {
    try {
     
      const response = await axiosInstance.post(URLS.VERIFY_OTP_API_URL, payload, {});
     
  //console.log(response)
      if (response.status >= 200 && response.status < 300) {
        // Check if OTP verification is successful and navigate accordingly
        const responseData = response.data;
   
        if (responseData.status=="Otp Verified") {
          // Use your navigation method here, for example, redirect to register page
          // Replace this with your actual navigation logic
          return responseData

        }
        if (responseData.status=="Otp Verified Successfully") {
          // Use your navigation method here, for example, redirect to register page
          // Replace this with your actual navigation logic
          return responseData}
      } else {
        throw new Error("API request failed");
      }
    } catch (error) {
      console.error("API request error:", error);
      return rejectWithValue(error.message);
    }
  }
);
