import React, { useState, useEffect } from 'react';

const Notification = ({ message, duration = 1000, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
      onClose(); // Optionally, call a function when the notification closes
    }, duration);

    return () => clearTimeout(timeout);
  }, [duration, onClose]);

  return (
    <div
    style={{
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#FFFFFF',
      borderRadius: '18px', // Equivalent to rounded-xl in Tailwind CSS
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
      padding: '20px',
      zIndex: 1000, // Ensure the notification appears above other elements
      display: visible ? 'block' : 'none',
    }}
    >
      {message}
    </div>
  );
};

export default Notification;
