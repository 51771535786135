import { useEffect, useState } from "react";
import "./App.css";
import ProjectRoutes from "./Routes";
import "./global.css";
import "./index.css";
import NoInternetPopup from "./Popups/Nointernet popup/NoInternetPopup";

const App = () => {
  const [isOnline, setIsOnline] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null); // Assuming selectedItem is set somewhere

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  // Handle language change effect
  useEffect(() => {
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    setSelectedItem(selectedLanguage ? { value: selectedLanguage } : null);
  }, [localStorage]);

  // Handle language change effect
  useEffect(() => {
    if (selectedItem?.value === "ar") {
      document.body.setAttribute("dir", "rtl"); // Set direction to RTL if Arabic is selected
    } else {
      document.body.setAttribute("dir", "ltr"); // Set direction to LTR for other languages
    }
  }, [selectedItem]);

  const handleLanguageChange = (option) => {
    setSelectedItem(option);
    localStorage.setItem("selectedLanguage", option.value);
  };

  return (
    <div className="App dark:bg-black dark:text-white " >
      {!isOnline && <NoInternetPopup isVisible={!isOnline} />} 
      <ProjectRoutes />
    </div>
  );
};

export default App;
