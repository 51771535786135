import React, { useState, useEffect } from "react";
import Deliverypartners from "../../components/EordersDeliveryPartners/Deliverypartners";
import Navbar from "../../components/Orders/Navbar";
import OrderAmount from "../../components/Orders/OrderAmount";
import Tableheader from "../../components/Orders/Tableheader";
import Sidebar from "../../components/Orders/sidebar";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { getStageIds, getKPIs } from "../../API  Functions/Eorders";
import { routes } from "../../Routes";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const Orderpage = () => {
  const navigate=useNavigate();
  
  const [showDeliveryPartners, setShowDeliveryPartners] = useState(false);
  const [activeHeading, setActiveHeading] = useState(1);
  const [id, setid] = useState([]);
  const [kpidata, setkpidata] = useState([]);

  const user_id = useSelector(state=>state.login?.user?.user_id)
  const store_id = useSelector(state=>state.login?.user?.store_id)

  useEffect(() => {
    //Get ID Details
    if (user_id) {
           getID();
      //Get KPI Details
      getKPI();
    }
  }, [user_id]);

  //Function to get ID DATA
  const getID = () => {
    getStageIds(user_id,store_id)?.then((res) => {
      setid(res);
    });
  };

  //Function to get KPI DATA
  const getKPI = () => {
    getKPIs(user_id, store_id,)?.then((res) => {
      setkpidata(res);
    });
  };

  return (
    <div className="h-full">
      {/* <div className="float-left fixed z-10">
        <Sidebar />
        </div> */}
         <Navbar heading="eorders" />
         <div className="min-h-14"></div>
      <div className=" pb-6   dark:bg-black  dark:text-white">
        <div className="flex flex-col flex-1">
         
          {showDeliveryPartners ? (
            navigate("/deliverypartner")
          ) : (
            <>
             <div className="w-full">
              <span className="">
                <OrderAmount
                  data={kpidata}
                  setShowDeliveryPartners={setShowDeliveryPartners}
                />
                {/* <p className='ml-20 mt-20'>Yet to be integrated</p> */}
              </span>

              <div>
                <Tableheader
                  setActiveHeading={setActiveHeading}
                  id={id?.data}
                  user_id={user_id}
                  store_id={store_id}
                />
              </div>
            </div>
            </>
          )}
        </div>
      </div>
    </div>
    
  );
};

export default Orderpage;
