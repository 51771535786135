import { CloseOutlined } from "@ant-design/icons";
import { Select } from "antd";

import React, { useState, useEffect } from "react";
import { getcancelReasons,updateorderStage,updateordercancelreason } from "../API  Functions/OrderlistFunction";
import { useSelector } from "react-redux";

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "500px",
    padding: "30px",
    borderRadius: "18px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "left",
    marginBottom: "10px",
    // marginTop: "5px",
  },
  cancelIcon: {
    cursor: "pointer",
  },
  description: {
    textAlign: "left",
    fontSize: "16px",
    color: "#272728",
    marginTop: "10px",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "12px",
    border: "1px solid #CCCCCC",
    marginBottom: "20px",
    boxSizing: "border-box",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "180px",
    height: "45px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    marginRight: "10px",
  },
  confirmButton: {
    backgroundColor: "primaryBlue",
    color: "#FFFFFF",
  },
  cancelButton: {
    backgroundColor: "#CCCCCC",
    color: "#000000",
  },
};

const CancelOrder = ({ isOpen, onClose, order_id, popup }) => {
  const user_id = useSelector(state=>state.login?.user?.user_id)
  const store_id = useSelector(state=>state.login?.user?.store_id)

 const {Option}=Select;
  const [reason, setReason] = useState("");
  const [CancelReasons, setCancelReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState("Select");

  const handleCancelOrder = () => {
    // Implement cancel order logic here
    console.log("Order cancelled with reason:", reason);
    onClose();
  };

  const fetchcancelreasons = () => {
    getcancelReasons().then((result) => result && setCancelReasons(result));
  };

  useEffect(() => fetchcancelreasons(), []);

  const handleSelectChange = (value) => {
    setSelectedReason(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, including selectedReason
    updateordercancelreason(user_id,store_id,order_id, selectedReason).then((response) => {
      console.log("cancelled", response);

      if (response?.data?.message == "Order update sucessfully") {
        updateorderStage(user_id,store_id,order_id, 7).then((response) => {
          // if (response?.message == "Order updated successfully") {
                      popup();
            // setTimeout(() => {
            //   router.push(routes.Orders);
            // }, 300);
          // }
        });
      }
    });
    // Additional logic like closing the modal
    onClose();
  };

  return (
    <>
      {isOpen && (
        <>
          <div style={styles.overlay} onClick={onClose}></div>
          <div style={styles.container}>
            <div style={styles.header}>
              <p style={styles.title}>Cancel Order</p>
              <CloseOutlined style={styles.cancelIcon} onClick={onClose} />
            </div>
            <p style={styles.description}>Select Cancel Reason</p>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-row mb-5 mt-2 justify-between">
                {/* <p className="">Reason:</p> */}
                <Select
                  style={{ width: 500, height: 40 }}
                  value={selectedReason}
                  onChange={handleSelectChange}
                >
                  {CancelReasons?.map((item) => (
                    <>
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    </>
                  ))}
                </Select>
              </div>
              <div className="flex justify-center">
                <button
                  className="w-52 h-10 border-primaryBlue border text-primaryBlue  font-semibold rounded-lg mr-5"
                  onClick={() => onClose()}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className={`w-52 h-10 ${
                    selectedReason == "Select"
                      ? "bg-primaryBluedisabled"
                      : "bg-primaryBlue"
                  }  text-white  font-semibold rounded-lg`}
                  disabled={selectedReason == "Select"}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default CancelOrder;
