import React, { useMemo, useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "tailwindcss/tailwind.css";
import CustomPagination from "../CustomWidgets/CustomPagination";
import { Empty } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import arrowright from "../../assets/arrowright.svg";
import Vectorsorting from "../../assets/Vectorsorting.svg";
import { Image } from "antd";
import { useNavigate } from "react-router";
import ShimmerLoader from "../../loading";
import { useTranslation } from "react-i18next";

const AllOrders = ({ user_id, store_id, tabledata, pagination }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [flag, setflag] = useState(false);

  const data = useMemo(() => tabledata || [], [tabledata]);
  useEffect(() => {
    if (tabledata?.length === 0 && !flag) {
      const timer = setTimeout(() => {
        setflag(true);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [tabledata, flag]);

  //To be called when page

  const openOrderList = (value) => {
    // {alert(typeof({value}))}
    const senddata = [
      // user_id,
      // store_id,
      // value.substring(0, 10),
      value.substring(12, value.lastIndexOf(",")),
    ];

    navigate(`/Orderlist?orderDetail=${senddata}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: t("order_no"),
        accessor: "order_number",
        Cell: ({ value }) => (
          <>
            {/* {(value.substring(value.lastIndexOf(",") + 1) == "Order Pending" ||
            value.substring(value.lastIndexOf(",") + 1) == "In Processing" ||
            value.substring(value.lastIndexOf(",") + 1) ==
              "Ready For Delivery") && ( */}
            <button
              // className="bg-[#7EC242] text-white w-28 h-10 rounded-lg"
              className=" text-[#1b4497] rounded-lg"
              onClick={() => openOrderList("ordernamehar" + value + ",")}
            >
              {value}
            </button>
            {/* )
          } */}
          </>
        ),
      },
      { Header: t("customer_name"), accessor: "customer_details.name" },
      {
        Header: t("order_date_time"),
        accessor: (row) => `${row.order_date}`,
        Cell: ({ value }) => (
          <span>
            {value?.substring(0, 10).replace(/-/g, "/")}{" "}
            {value.substring(11, 13) == 12
              ? value.substring(11, 16) + " PM"
              : value.substring(11, 13) > 12
              ? value.substring(11, 13) - 12 + value.substring(13, 16) + " PM"
              : value.substring(11, 16) + " AM"}
          </span>
        ),
      },
      { Header: t("delivery_schedule"), accessor: "delivery_time" },

      // { Header: "Payment Mode", accessor: "payment_mode" },
      { Header: t("order_value"), accessor: "order_total_amt" },
      {
        Header: t("items"),
        accessor: "order_line_details",
        Cell: ({ value }) => <>{value?.length}</>,
      },
      {
        Header: t("order_status"),
        accessor: "order_stage.name",
        Cell: ({ value }) => {
          let backgroundColor,
            textColor,
            borderRadius,
            paddingTop,
            paddingBottom,
            paddingLeft,
            paddingRight;

          // Conditionally set styles based on order status
          if (value === "Order Pending") {
            backgroundColor = "#999999";
            // textColor = "#3d85c6";
            // borderRadius = "5px"; // Full border radius
          } else if (value === "In Processing") {
            backgroundColor = "#f1c232  ";
            // textColor = "#5c5c57";
            // borderRadius = "5px"; // Full border radius
          } else if (value === "Ready For Delivery") {
            backgroundColor = "#93c47d";
            // textColor = "#00B833";
            // borderRadius = "5px"; // Full border radius
          } else if (value === "On the way") {
            backgroundColor = "#f1c232";
            // textColor = "#E8A700";
            // borderRadius = "5px"; // Full border radius
          } else if (value === "Order Delivered") {
            backgroundColor = "#1b4497";
            // textColor = "#8d5bf1";
            // borderRadius = "5px"; // Full border radius
          } else {
            // Default styles for other statuses
            backgroundColor = "#E74C3C"; // Set default background color
            // textColor = "#A70D2A"; // Set default text color
            // Set default border radius
          }

          // Padding values for top, bottom, left, right
          textColor = "#ffffff";
          paddingTop = "8px";
          paddingBottom = "8px";
          paddingLeft = "1px";
          paddingRight = "1px";
          borderRadius = "10px";

          return (
            <h4
              style={{
                backgroundColor,
                color: textColor,
                borderRadius,
                padding: `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`,
                fontWeight: 600,
                display: "inline-block",
                minWidth: "90px",
                alignItems: "center",
              }}
            >
              {(() => {
                switch (value) {
                  case "Order Pending":
                    return "Pending";
                  case "In Processing":
                    return "Processing";
                  case "Ready For Delivery":
                    return "Ready";
                  case "On the way":
                    return "Out";
                  case "Order Delivered":
                    return "Delivered";

                  default:
                    return "Cancelled";
                }
              })()}
            </h4>
          );
        },
      },

      {
        id: "action",
        Header: "",
        accessor: (row) =>
          `${row.customer_details?.mobile1}, ${row?.order_number},${row?.order_stage.name}`,
        Cell: ({ value }) => (
          <>
            {/* {(value.substring(value.lastIndexOf(",") + 1) == "Order Pending" ||
              value.substring(value.lastIndexOf(",") + 1) == "In Processing" ||
              value.substring(value.lastIndexOf(",") + 1) ==
                "Ready For Delivery") && ( */}
            <button
              // className="bg-[#7EC242] text-white w-28 h-10 rounded-lg"
              className=" text-[#1b4497] rounded-lg"
              onClick={() => openOrderList(value)}
            >
              <img
                src={arrowright}
                alt="arrowright"
                className={`${i18n.language == "en" ? "" : " rotate-180"}`}
                height={3}
                width={20}
              />
              {/* Open Order <ArrowRightOutlined /> */}
            </button>
            {/* )
            } */}
          </>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  const [pageIndex, setpageIndex] = useState(1);
  useEffect(() => {
    const pageDetails = {
      limit: pageSize,
      offset: pageSize * (pageIndex - 1) < 0 ? 0 : pageSize * (pageIndex - 1),
    };
    pagination(pageDetails);
  }, [pageIndex, pageSize, previousPage, nextPage]);

  return (
    <div
      className="container mx-auto float-left h-[100%]"
      style={{ maxWidth: "100%" }}
    >
      <div className="flex flex-col h-full items-center">
        {tabledata?.length == 0 && flag == false ? (
          <>
            <ShimmerLoader />
            {/* {setTimeout(() => {
              setflag(true);
            }, 3000)} */}
          </>
        ) : (
          <table
            {...getTableProps()}
            className="mt-7 font-normal max-w-[100%] sm:w-[97.6%] md:w-[97.6%] lg:w-[97.6%] xl:w-[97.6%] min-h-auto h-[100%] shadow-xl rounded-lg overflow-hidden"
          >
            <thead>
              {headerGroups.map((headerGroup, headerIndex) => {
                const { key: headerKey, ...headerGroupProps } =
                  headerGroup.getHeaderGroupProps();
                return (
                  <tr
                    key={headerKey}
                    {...headerGroupProps}
                    className="bg-[#78CFEC66] text-sm font-semibold"
                  >
                    {headerGroup.headers.map((column, colIndex) => {
                      const { key: columnKey, ...columnProps } =
                        column.getHeaderProps(column.getSortByToggleProps());
                      return (
                        <th
                          key={columnKey}
                          {...columnProps}
                          className="py-3 px-2 whitespace-nowrap "
                        >
                          <div className="flex items-center justify-center">
                            {column.render("Header")}
                            <span className="">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <img
                                    src={Vectorsorting}
                                    style={{ minHeight: 10, minWidth: 10 }}
                                  />
                                ) : (
                                  <img
                                    className="rotate-180"
                                    src={Vectorsorting}
                                    style={{ minHeight: 10, minWidth: 10 }}
                                  />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className="bg-[#F7F9FD] text-center"
            >
              {page.map((row, rowIndex) => {
                prepareRow(row);
                const { key: rowKey, ...rowProps } = row.getRowProps();
                return (
                  <React.Fragment key={rowKey}>
                    <tr
                      {...rowProps}
                      style={{ maxHeight: 10 }}
                      className="relative hover:bg-white  transition duration-300 border-b border-gray-300 fixed-row-height"
                    >
                      {row.cells.map((cell, cellIndex) => {
                        const { key: cellKey, ...cellProps } =
                          cell.getCellProps();
                        return (
                          <td
                            key={cellKey}
                            style={{ maxHeight: "10px" }}
                            {...cellProps}
                            className={`p-2 text-[#272728] text-sm font-normal`}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              })}
              {/* Empty state row */}
              {page?.length === 0 && (
                <tr>
                  <td colSpan={columns.length}>
                    <Empty description="No data available" />
                  </td>
                </tr>
              )}
              <tr>
                <td className=" " colSpan={columns.length}>
                  <CustomPagination
                    data={tabledata?.length}
                    pageIndex={pageIndex}
                    pageCount={page?.length}
                    canPreviousPage={pageIndex !== 1}
                    canNextPage={tabledata?.length >= 10}
                    gotoPage={(page) => nextPage(page)}
                    previousPage={() => setpageIndex(pageIndex - 1)}
                    nextPage={() => setpageIndex(pageIndex + 1)}
                    pageSize={pageSize}
                    setPageSize={(pageSize) => setPageSize(pageSize)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default AllOrders;
