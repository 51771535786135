import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import SelectBox from "../CustomWidgets/SelectBox";
import upload from "../../assets/Images/Uploadminimalistic.svg";
import UploadMediaonly from "../upload-imageonly";
import "../../styles.css";
import { Button, Image, Input, Select, TimePicker } from "antd";
import { ProfileShopDetailSchema } from "../validationSchema";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";
import UpdatedProfile from "../../modals/UpdatedProfile";
import { InputBox } from "../CustomWidgets/InputBox";
import { useTranslation } from "react-i18next";
// import DatePicker from "react-datepicker";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const format = "hh:mm";

const ShopDetails = ({ payload }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const user_id = useSelector((state) => state.login?.user?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);

  const [selectedDate, setSelectedDate] = useState(null);
  const [showUpdatedProfile, setShowUpdatedProfile] = useState(false);
  const [restricted, setrestricted] = useState(true);
  const [offdayserror, setoffdayserror] = useState(false);
  const [Opentimeoptions, setOpentimeoptions] = useState([
    { value: "", label: t("select"), disabled: true },
    { value: "0.0", label: "12:00 AM" },
    { value: "0.3", label: "12:30 AM" },
    { value: "1.0", label: "01:00 AM" },
    { value: "1.3", label: "01:30 AM" },
    { value: "2.0", label: "02:00 AM" },
    { value: "2.3", label: "02:30 AM" },
    { value: "3.0", label: "03:00 AM" },
    { value: "3.3", label: "03:30 AM" },
    { value: "4.0", label: "04:00 AM" },
    { value: "4.3", label: "04:30 AM" },
    { value: "5.0", label: "05:00 AM" },
    { value: "5.3", label: "05:30 AM" },
    { value: "6.0", label: "06:00 AM" },
    { value: "6.3", label: "06:30 AM" },
    { value: "7.0", label: "07:00 AM" },
    { value: "7.3", label: "07:30 AM" },
    { value: "8.0", label: "08:00 AM" },
    { value: "8.3", label: "08:30 AM" },
    { value: "9.0", label: "09:00 AM" },
    { value: "9.3", label: "09:30 AM" },
    { value: "10.0", label: "10:00 AM" },
    { value: "10.3", label: "10:30 AM" },
    { value: "11.0", label: "11:00 AM" },
    { value: "11.3", label: "11:30 AM" },
    { value: "12.0", label: "12:00 PM" },
    { value: "12.3", label: "12:30 PM" },
    { value: "13.3", label: "01:30 PM" },
    { value: "13.0", label: "01:00 PM" },
    { value: "14.0", label: "02:00 PM" },
    { value: "14.3", label: "02:30 PM" },
    { value: "15.0", label: "03:00 PM" },
    { value: "15.3", label: "03:30 PM" },
    { value: "16.0", label: "04:00 PM" },
    { value: "16.3", label: "04:30 PM" },
    { value: "17.0", label: "05:00 PM" },
    { value: "17.3", label: "05:30 PM" },
    { value: "18.0", label: "06:00 PM" },
    { value: "18.3", label: "06:30 PM" },
    { value: "19.0", label: "07:00 PM" },
    { value: "19.3", label: "07:30 PM" },
    { value: "20.0", label: "08:00 PM" },
    { value: "20.3", label: "08:30 PM" },
    { value: "21.0", label: "09:00 PM" },
    { value: "21.3", label: "09:30 PM" },
    { value: "22.0", label: "10:00 PM" },
    { value: "22.3", label: "10:30 PM" },
    { value: "23.0", label: "11:00 PM" },
    { value: "23.3", label: "11:30 PM" },
  ]);
  const [Closingtimeoptions, setClosingtimeoptions] = useState([
    { value: "", label: t("select"), disabled: true },
    { value: "0.0", label: "12:00 AM" },
    { value: "0.3", label: "12:30 AM" },
    { value: "1.0", label: "01:00 AM" },
    { value: "1.3", label: "01:30 AM" },
    { value: "2.0", label: "02:00 AM" },
    { value: "2.3", label: "02:30 AM" },
    { value: "3.0", label: "03:00 AM" },
    { value: "3.3", label: "03:30 AM" },
    { value: "4.0", label: "04:00 AM" },
    { value: "4.3", label: "04:30 AM" },
    { value: "5.0", label: "05:00 AM" },
    { value: "5.3", label: "05:30 AM" },
    { value: "6.0", label: "06:00 AM" },
    { value: "6.3", label: "06:30 AM" },
    { value: "7.0", label: "07:00 AM" },
    { value: "7.3", label: "07:30 AM" },
    { value: "8.0", label: "08:00 AM" },
    { value: "8.3", label: "08:30 AM" },
    { value: "9.0", label: "09:00 AM" },
    { value: "9.3", label: "09:30 AM" },
    { value: "10.0", label: "10:00 AM" },
    { value: "10.3", label: "10:30 AM" },
    { value: "11.0", label: "11:00 AM" },
    { value: "11.3", label: "11:30 AM" },
    { value: "12.0", label: "12:00 PM" },
    { value: "12.3", label: "12:30 PM" },
    { value: "13.0", label: "01:00 PM" },
    { value: "13.3", label: "01:30 PM" },
    { value: "14.0", label: "02:00 PM" },
    { value: "14.3", label: "02:30 PM" },
    { value: "15.0", label: "03:00 PM" },
    { value: "15.3", label: "03:30 PM" },
    { value: "16.0", label: "04:00 PM" },
    { value: "16.3", label: "04:30 PM" },
    { value: "17.0", label: "05:00 PM" },
    { value: "17.3", label: "05:30 PM" },
    { value: "18.0", label: "06:00 PM" },
    { value: "18.3", label: "06:30 PM" },
    { value: "19.0", label: "07:00 PM" },
    { value: "19.3", label: "07:30 PM" },
    { value: "20.0", label: "08:00 PM" },
    { value: "20.3", label: "08:30 PM" },
    { value: "21.3", label: "09:30 PM" },
    { value: "21.0", label: "09:00 PM" },
    { value: "22.0", label: "10:00 PM" },
    { value: "22.3", label: "10:30 PM" },
    { value: "23.0", label: "11:00 PM" },
    { value: "23.3", label: "11:30 PM" },
  ]);
  const [nextday, setnextday] = useState(false);

  useEffect(() => {
    if (payload) getshopdetaildata();
  }, [payload]);

  const getshopdetaildata = async () => {
    try {
      // console.log("payload", payload);
      axiosInstance
        .post(URLS.GET_STORE_SHOP_DETAIL, payload)
        .then((response) => {
          if (response?.message == "Invalid Token") {
            localStorage.clear();
            navigate("/");
          }
          const shopdetail = response?.data?.basic_details?.[0];
          // setdata(shopdetail);
          formik?.setValues({
            // Use formik.setValues to update form values
            address_line_1: shopdetail?.address_line_1 || "", // Provide default values or empty string if data is null
            address_line_2: shopdetail?.address_line_2 || "",
            street: shopdetail?.locality || "",
            zipcode: shopdetail?.zip_code || "",
            area: shopdetail?.area || "",
            city: shopdetail?.city || "",
            minimum_order_value: shopdetail?.minimum_order_value || 0,
            shop_close: shopdetail?.shop_close == false ? 0 : 1 || 0,
            prepration_time:
              `00:${shopdetail?.prepration_time * 100}` || "00:00",
            owner_photo:
              shopdetail?.profile_details?.find(
                (item) => item.type == "owner_photo"
              )?.url || "",
            shop_logo:
              shopdetail?.profile_details?.find(
                (item) => item.type == "store_profile"
              )?.url || "",
            shop_photo_1:
              shopdetail?.profile_details?.find(
                (item) => item.type == "shop_photo_1"
              )?.url || "",
            shop_photo_2:
              shopdetail?.profile_details?.find(
                (item) => item.type == "shop_photo_2"
              )?.url || "",
            shop_photo_3:
              shopdetail?.profile_details?.find(
                (item) => item.type == "shop_photo_3"
              )?.url || "",
            closing_time:
              shopdetail?.close_time?.length != 0 &&
              shopdetail?.close_time != null
                ? `${shopdetail?.close_time?.substring(0,2)}:${shopdetail?.close_time?.substring(3,5)?.length==1?`${shopdetail?.close_time?.substring(3,5)}0`:shopdetail?.close_time?.substring(3,5)}`
              
                : "00:00",
            opening_time:
              shopdetail?.open_time?.length != 0 &&
              shopdetail?.open_time != null
                ? `${shopdetail?.open_time?.substring(0,2)}:${shopdetail?.open_time?.substring(3,5)?.length==1?`${shopdetail?.open_time?.substring(3,5)}0`:shopdetail?.open_time?.substring(3,5)}`
                : "00:00",
            off_day_ids:
              shopdetail?.off_day_ids?.length != 0
                ? shopdetail?.off_day_ids
                : [0],
            approval_status: shopdetail?.approval_status,
            country: "Egypt",
          });
          setrestricted(true);

          console.log(`${shopdetail?.open_time?.substring(0,2)}:${shopdetail?.open_time?.substring(3,5)?.length==1?`${shopdetail?.open_time?.substring(3,5)}0`:shopdetail?.open_time?.substring(3,5)}`
          )
        });
    } catch (error) {}
  };

  const formik = useFormik({
    initialValues: {
      address_line_1: "",
      address_line_2: "",
      street: "",
      shop_logo: "",
      owner_photo: "",
      shop_photo_1: "",
      shop_photo_2: "",
      shop_photo_3: "",
      opening_time: "00:00",
      closing_time: "00:00",
      city: "",
      area: "",
      zipcode: "",
      operatingdays: "7",
      minimum_order_value: 0,
      shop_close: 0,
      prepration_time: "00:00",
      approval_status: "Pending",
      country: "Egypt",
    },

    validationSchema: ProfileShopDetailSchema,
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: (values, { resetForm }) => {
      // if (
      //   formik?.values?.off_day_ids?.length == 1 &&
      //   formik?.values?.off_day_ids[0] == 0
      // )
      //   setoffdayserror(true);
      // else {
      const apipayload = {
        user_id: user_id,
        store_id: store_id,
        address_line_1: formik.values.address_line_1,
        address_line_2: formik.values.address_line_2,
        street: formik.values.street,
        shop_logo: formik.values.shop_logo,
        owner_photo: formik.values.owner_photo,
        shop_photo_1: formik.values.shop_photo_1,
        shop_photo_2: formik.values.shop_photo_2,
        shop_photo_3: formik.values.shop_photo_3,
        opening_time: (parseFloat(formik.values.opening_time?.substring(0, 2))+parseFloat(formik.values.opening_time?.substring(3, 5)/100)),
        closing_time: (parseFloat(formik.values.closing_time?.substring(0, 2))+parseFloat(formik.values.closing_time?.substring(3, 5)/100)),
        min_order_val: formik.values.minimum_order_value,
        order_prep_time:
          parseFloat(formik.values.prepration_time?.substring(3, 5)) / 100,
        enable_online_sale: formik.values.shop_close,
        week_day_id: formik.values.off_day_ids,
        next_day: nextday,
      };

      axiosInstance
        .post(URLS.SET_STORE_SHOP_DETAIL, apipayload)
        .then((response) => {
          if (response?.data?.status == 200) {
            setShowUpdatedProfile(true);
            setrestricted(true);
          }
        });
    },
    // resetForm();
    // },
  });
  const handleSubmit = (e) => {
    // e.preventDefault(); // Prevent default form submission behavior
    formik.handleSubmit(e); // Call Formik's submit handler
  };
  const handleurlgenerated = (values, keyname) => {
    setrestricted(false);
    formik.setFieldValue(keyname, values?.url);
  };

  const options = [
    { value: 0, label: t("select"), disabled: true },
    { value: 1, label: "Mon" },
    { value: 2, label: "Tue" },
    { value: 3, label: "Wed" },
    { value: 4, label: "Thu" },
    { value: 5, label: "Fri" },
    { value: 6, label: "Sat" },
    { value: 7, label: "Sun" },
  ];

  const handleOptionChange = (selectedValues) => {
    if (selectedValues?.length > 1)
      selectedValues = selectedValues.filter((value) => value !== 0);

    setoffdayserror(false);
    setrestricted(false);
    // selectedValues.length <= 4 &&
    formik.setFieldValue("off_day_ids", selectedValues);
  };

  const handletimingChange = (name, selectedValues) => {
    formik.setFieldValue(name, selectedValues);
    setrestricted(false);
    name == "opening_time" &&
      !nextday &&
      setClosingtimeoptions(
        Opentimeoptions.slice(
          Opentimeoptions.findIndex(
            (option) => option.value === selectedValues
          ) + 1,
          Opentimeoptions.length
        )
      );
    name == "opening_time" &&
      formik.setFieldValue(
        "closing_time",
        Opentimeoptions[
          Opentimeoptions.findIndex(
            (option) => option.value === selectedValues
          ) + 1
        ]?.value
      );
  };

  const handleorderpreparationtimeChange = (time) => {
    formik.setFieldValue("prepration_time", JSON.stringify(time));
  };

  return (
    <>
      <div className=" font-medium text-[20px] text-[#272728]  mx-7 mt-4">
        {t("shop_location")}
      </div>
      <form onSubmit={handleSubmit} className="h-120vh overflow-x-hidden">
        <div className="font-normal text-[14px] text-[#5C5C5C] grid grid-cols-2 gap-x-9 mx-7 mt-7">
          <div className=" w-[363px] md:w-full  leading-[0px]">
            <label className="font-normal text-[14px] text-[#5C5C5C]">
              {t("approval_status")}
            </label>
            <Input
              style={{ color: "#5C5C5C" }}
              className="text-[#5C5C5C] mb-8 mt-4 h-10"
              name="approval_status"
              placeholder="Address 2"
              onBlur={formik.handleBlur}
              value={formik.values.approval_status}
              error={formik.errors.approval_status}
              touched={formik.touched.approval_status}
              disabled
            />
          </div>
          <div></div>
          <div className="w-[363px] md:w-full  leading-[0px]">
            <label>{t("address1")}</label>
            <InputBox
              name="address_line_1"
              placeholder="Address"
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.address_line_1}
              error={formik.errors.address_line_1}
              touched={formik.touched.address_line_1}
            />
          </div>
          <div className="flex gap-x-4">
            <div className="w-[170px] md:w-full leading-[0px]">
              <label> {t("area")}</label>
              <Input
                name="area"
                placeholder="Area"
                className="text-[#5C5C5C] mt-4 h-10"
                onChange={(val) => {
                  formik.handleChange(val);
                  setrestricted(false);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.area}
                error={formik.errors.area}
                touched={formik.touched.area}
                disabled
              />
            </div>
            <div className="w-[180px] md:w-full h-[89px] leading-[0px]">
              <label>{t("city")}</label>
              <Input
                name="City"
                className="text-[#5C5C5C] mt-4 h-10"
                placeholder="City"
                onChange={(val) => {
                  formik.handleChange(val);
                  setrestricted(false);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                error={formik.errors.city}
                touched={formik.touched.city}
                disabled
              />
            </div>
          </div>
          <div className="w-[363px] md:w-full leading-[0px]">
            <label>{t("address2")}</label>
            <InputBox
              name="address_line_2"
              placeholder="Address 2"
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.address_line_2}
              error={formik.errors.address_line_2}
              touched={formik.touched.address_line_2}
            />
          </div>

          <div className="w-[363px] md:w-full leading-[0px] ">
            <label>{t("zip")}</label>
            <Input
              className="text-[#5C5C5C] mt-4 h-10"
              name="zipcode"
              placeholder="409797"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.zipcode}
              error={formik.errors.zipcode}
              touched={formik.touched.zipcode}
              disabled
            />
          </div>
          <div className="w-[363px] md:w-full leading-[0px] mt-7">
            <label>{t("locality")}</label>
            <Input
              className="text-[#5C5C5C] mt-4 h-10"
              name="street"
              placeholder="Enter Locality"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.street}
              error={formik.errors.street}
              touched={formik.touched.street}
              disabled
            />
          </div>
          <div className=" w-[363px] md:w-full leading-[0px] mt-7">
            <label className="font-normal text-[14px] text-[#5C5C5C]">
              {t("country")}
            </label>
            <Input
              className="text-[#5C5C5C] mt-4 h-10"
              name="country"
              placeholder="Country"
              onBlur={formik.handleBlur}
              value={formik.values.country}
              error={formik.errors.country}
              touched={formik.touched.country}
              disabled
            />
          </div>
        </div>
        <div className="font-medium text-xl mx-7 mt-10">{t("shop_photos")}</div>
        <div className="grid grid-cols-2 gap-5 mx-7 mt-4 font-normal text-sm">
          <div className="w-[363px] md:w-full ">
            <label className="text-[#5C5C5C]">{t("shop_logo")}</label>

            <div className=" rounded-lg border border-inset border-gray-900/25 px-2 mt-3 py-3 flex justify-start">
              <div
                className="flex flex-row"
                style={{ minHeight: 100, maxHeight: 100 }}
              >
                {formik.values?.shop_logo != "" ? (
                  <Image
                    className=" object-cover"
                    src={formik.values?.shop_logo}
                    height={100}
                    width={100}
                    alt="Image"
                  />
                ) : (
                  <svg
                    className=" float-left h-25 w-20 text-gray-300 mt-3"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                      // clip-rule="evenodd"
                    />
                  </svg>
                )}
                <div class="flex flex-row text-sm leading-6 pl-10 ml-10 text-gray-600 ">
                  <label
                    for="file-upload"
                    class="relative flex items-center cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                  >
                    {/* <span>
                     <Image
   className=" object-cover" src={upload} width={18} />
                    </span>
                    <span className="ml-2">Change </span> */}
                    <UploadMediaonly
                      foldername={"store_image"}
                      onfileAdd={"Shop_logo"}
                      urlgenerated={handleurlgenerated}
                      keyname={"shop_logo"}
                      imageurl={formik.values.shop_logo}

                      // Adjust field name as needed
                    />
                  </label>
                </div>
                {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
              </div>
            </div>
          </div>

          <div className="w-[363px] md:w-full">
            <label className="text-[#5C5C5C]">{t("owner_photo")}</label>

            <div className=" rounded-lg border border-inset border-gray-900/25 px-2 mt-3 py-3 flex justify-start">
              <div
                className="flex flex-row"
                style={{ minHeight: 100, maxHeight: 100 }}
              >
                {formik.values?.owner_photo.length != 0 ? (
                  <Image
                    className=" object-cover"
                    src={formik.values?.owner_photo}
                    height={100}
                    width={100}
                    alt="image"
                  />
                ) : (
                  <svg
                    className=" float-left h-25 w-20 text-gray-300 mt-3"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                      // clip-rule="evenodd"
                    />
                  </svg>
                )}
                <div class="flex flex-row text-sm leading-6 pl-10 ml-10 text-gray-600">
                  <label
                    for="file-upload"
                    class="relative flex items-center cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                  >
                    {/* <span>
                     <Image
   className=" object-cover" src={upload} width={18} />
                    </span> */}
                    {/* <span className="ml-2">Change </span> */}
                    <UploadMediaonly
                      foldername={"owner"}
                      onfileAdd={"Owner_Photo"}
                      urlgenerated={handleurlgenerated}
                      keyname={"owner_photo"}
                      imageurl={formik.values.owner_photo}
                      // Adjust field name as needed
                    />
                  </label>
                </div>
                {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
              </div>
            </div>
          </div>

          <div className="w-[363px] md:w-full">
            <label className="text-[#5C5C5C]">{t("shopphoto1")}</label>

            <div className=" rounded-lg border border-inset border-gray-900/25 px-2 mt-3 py-3 flex justify-start">
              <div
                className="flex flex-row"
                style={{ minHeight: 100, maxHeight: 100 }}
              >
                {formik.values?.shop_photo_1 != "" ? (
                  <Image
                    className=" object-cover"
                    src={formik.values?.shop_photo_1}
                    height={100}
                    width={100}
                    alt="Image"
                  />
                ) : (
                  <svg
                    className=" float-left h-25 w-20 text-gray-300 mt-3"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                      // clip-rule="evenodd"
                    />
                  </svg>
                )}
                <div class="flex flex-row text-sm leading-6 pl-10 ml-10 text-gray-600">
                  <label
                    for="file-upload"
                    class="relative flex items-center cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                  >
                    {/* <span>
                     <Image
   className=" object-cover" src={upload} width={18} />
                    </span> */}
                    {/* <span className="ml-2">Change </span> */}
                    <UploadMediaonly
                      foldername={"store_image"}
                      onfileAdd={"Shop_1"}
                      urlgenerated={handleurlgenerated}
                      keyname={"shop_photo_1"}
                      imageurl={formik.values.shop_photo_1}
                      // Adjust field name as needed
                    />
                  </label>
                </div>
                {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
              </div>
            </div>
          </div>
          <div className="w-[363px] md:w-full">
            <label className="text-[#5C5C5C]">{t("shopphoto2")}</label>

            <div className=" rounded-lg border border-inset border-gray-900/25 px-2 mt-3 py-3 flex justify-start">
              <div
                className="flex flex-row"
                style={{ minHeight: 100, maxHeight: 100 }}
              >
                {formik.values?.shop_photo_2 != "" ? (
                  <Image
                    className=" object-cover"
                    src={formik.values?.shop_photo_2}
                    height={100}
                    width={100}
                    alt="Image"
                  />
                ) : (
                  <svg
                    className=" float-left h-25 w-20 text-gray-300 mt-3"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                      // clip-rule="evenodd"
                    />
                  </svg>
                )}
                <div class="flex flex-row text-sm leading-6 pl-10 ml-10 text-gray-600">
                  <label
                    for="file-upload"
                    class="relative flex items-center cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                  >
                    {/* <span>
                     <Image
   className=" object-cover" src={upload} width={18} />
                    </span>
                    <span className="ml-2">Change </span> */}
                    <UploadMediaonly
                      foldername={"store_image"}
                      onfileAdd={"Shop_2"}
                      urlgenerated={handleurlgenerated}
                      keyname={"shop_photo_2"}
                      imageurl={formik.values.shop_photo_2}
                      // Adjust field name as needed
                    />
                  </label>
                </div>
                {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
              </div>
            </div>
          </div>
          <div className="w-[363px] md:w-full">
            <label className="text-[#5C5C5C]">{t("shopphoto3")}</label>

            <div className=" rounded-lg border border-inset border-gray-900/25 px-2 mt-3 py-3 flex justify-start">
              <div
                className="flex flex-row"
                style={{ minHeight: 100, maxHeight: 100 }}
              >
                {formik.values?.shop_photo_3 != "" ? (
                  <Image
                    className=" object-cover"
                    src={formik.values?.shop_photo_3}
                    height={100}
                    width={100}
                    alt="Image"
                  />
                ) : (
                  <svg
                    className=" float-left h-25 w-20 text-gray-300 mt-3"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                      // clip-rule="evenodd"
                    />
                  </svg>
                )}
                <div class="flex flex-row text-sm leading-6 pl-10 ml-10 text-gray-600">
                  <label
                    for="file-upload"
                    class="relative flex items-center cursor-pointer rounded-md bg-white font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-white focus-within:ring-offset-2 hover:text-primaryBlue"
                  >
                    {/* <span>
                     <Image
   className=" object-cover" src={upload} width={18} />
                    </span>
                    <span className="ml-2">Change </span> */}
                    <UploadMediaonly
                      foldername={"store_image"}
                      onfileAdd={"Shop_3"}
                      urlgenerated={handleurlgenerated}
                      keyname={"shop_photo_3"}
                      imageurl={formik.values.shop_photo_3}
                      // Adjust field name as needed
                    />
                  </label>
                </div>
                {/* <p className="text-xs text-gray-600 pl-24">
                  PNG, JPG, GIF up to 10MB
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className=" text-[20px] font-medium mt-10 mx-7">
          {t("shop_timings")}
        </div>
        <div className="w-[363px] ml-7 mt-4  md:w-full leading-[0px] ">
          <input
            type="checkbox"
            onChange={() => {
              setnextday(!nextday);
              setTimeout(() => {
                !nextday
                  ? setClosingtimeoptions(Opentimeoptions)
                  : setClosingtimeoptions(
                      Opentimeoptions.slice(
                        Opentimeoptions.findIndex(
                          (option) =>
                            option.value === formik.values.opening_time
                        ) + 1,
                        Opentimeoptions.length
                      )
                    );
              }, 500);
            }}
          />
          <label className="ml-2 text-[14px] text-[#5C5C5C]">
            {t("next_day")}
          </label>
        </div>
        <div className="grid grid-cols-3 gap-x-4 mt-6 mx-7 ">
          {/* <div className="w-[300px] md:w-full leading-[0px] ">
            <label>Operating days</label>
            <Select
              defaultValue="7 days a week"
              name="days"
              onChange={formik.handleChange}
              options={[
                { value: "Sunday to Friday", label: "Sunday to Friday" },
                {
                  value: "Saturday to Thursday",
                  label: "Saturday to Thursday",
                },
                { value: "7 days a week", label: "7 days a week" },
              ]}
              onBlur={formik.handleBlur}
              value={formik.values.days}
              error={formik.errors.days}
              touched={formik.touched.days}
            />
          </div> */}
          <div className="w-[300px] md:w-full leading-[0px] ">
            <label className="text-[14px] text-[#5C5C5C]">
              {t("open_time")}
            </label>
            <Input
              type="time"
              name="opening_time"
              className={` mt-4 border leading-none text-[#5C5C5C] text-sm rounded-md block w-full p-2  py-2.1${
                formik.touched.opening_time && formik.errors.opening_time
                  ? "border-red"
                  : "border-[#ccc]"
              }`}
              onChange={(e) => {
                console.log(e);
                formik.handleChange(e);
                setrestricted(false);
              }}
              value={formik.values.opening_time}
              required
            />
            {/* <Select
              className="h-10 mt-4 w-full text-md text-body  !border-[#CCCCCC]  !text-[#aeaeae] "
              name="opening_time"
              defaultValue={formik.values.opening_time}
              onChange={(val) => handletimingChange("opening_time", val)}
              options={Opentimeoptions}
              onBlur={formik.handleBlur}
              value={formik.values.opening_time}
              error={formik.errors.opening_time}
              touched={formik.touched.opening_time}
            /> */}
            {formik.errors.opening_time && (
              <div className=" text-red text-sm h-2 mt-0 ml-1">
                {formik.errors.opening_time}
              </div>
            )}
          </div>

          <div className="w-[300px] md:w-full leading-[0px] ">
            <label className="text-[14px] text-[#5C5C5C]">
              {t("close_time")}
            </label>
            <Input
              type="time"
              name="closing_time"
              className={` mt-4 border leading-none text-[#5C5C5C] text-sm rounded-md block w-full p-2  py-2.1${
                formik.touched.closing_time && formik.errors.closing_time
                  ? "border-red"
                  : "border-[#ccc]"
              }`}
              onChange={(e) => {
                console.log(e);
                formik.handleChange(e);
                setrestricted(false);
              }}
              value={formik.values.closing_time}
              required
            />
            {/* <Select
              className="h-10 mt-4 w-full text-md text-body  !border-[#CCCCCC]  !text-[#5C5C5C]  "
              name="closing_time"
              defaultValue={formik.values.closing_time}
              onChange={(val) => handletimingChange("closing_time", val)}
              options={Closingtimeoptions}
              onBlur={formik.handleBlur}
              value={formik.values.closing_time}
              error={formik.errors.closing_time}
              touched={formik.touched.closing_time}
            /> */}
            {formik.errors.closing_time && (
              <div className=" text-red text-sm h-2 mt-0 ml-1">
                {formik.errors.closing_time}
              </div>
            )}
          </div>

          <div className="w-[300px] md:w-full leading-[0px] ">
            <label className="text-[14px] text-[#5C5C5C]">{t("offdays")}</label>
            <Select
              style={{ width: "100%", overflowY: "hidden" }}
              mode="multiple"
              className="h-10 mt-4 w-full text-md text-body  !border-[#CCCCCC]  !text-[#5C5C5C]  "
              defaultValue={formik.values.off_day_ids}
              name="off_day_ids"
              onChange={handleOptionChange}
              options={options}
              onBlur={formik.handleBlur}
              value={formik.values.off_day_ids}
              error={formik.errors.off_day_ids}
              touched={formik.touched.off_day_ids}
            />
            {formik.errors.off_day_ids && (
              <div className=" text-red text-sm h-2 mt-0 ml-1">
                {/* Select Off Days */}
                {formik.errors.off_day_ids}
              </div>
            )}
            {/* {formik.errors.off_day_ids && <div className=" text-red text-sm h-2 mt-0 ml-1">{formik.errors.off_day_ids}</div>} */}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mt-10 mx-7">
          <div className="w-[360px] md:w-full leading-[0px] ">
            <label className="text-[14px] text-[#5C5C5C]">
              {t("prepartion_time")}{" "}
            </label>
            <div className=" mt-4">
              {/* <div class="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                <svg
                  class="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div> */}
              <Input
                type="time"
                name="prepration_time"
                className={`  border leading-none text-[#5C5C5C] text-sm rounded-md block w-full p-2  py-2.1${
                  formik.touched.prepration_time &&
                  formik.errors.prepration_time
                    ? "border-red"
                    : "border-[#ccc]"
                }`}
                min="00:00"
                max="01:00"
                onChange={(e) => {
                  console.log(e);
                  formik.handleChange(e);
                  setrestricted(false);
                }}
                value={formik.values.prepration_time}
                required
              />
            </div>
            {/* <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              showTimeInput
              // showTimeSelect
              showTimeSelectOnly
              timeIntervals={5}
              dateFormat="mm"
              placeholderText="Select time"
            /> */}

            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                views={["hours","minutes"]}
                label="Controlled picker"
                // value={formik.values.prepration_time}
                onChange={(val) => {
                  const temp = JSON.stringify(val);
                  console.log(temp, "val", val);
                  formik.handleChange(JSON.stringify(val));
                  setrestricted(false);
                }}
              />
            </LocalizationProvider> */}

            {/* <Input
              // label="orderpreparationtime"
              className={`mt-4 h-10 w-full bg-transparent  text-[#5C5C5C] text-sm text-body outline-none focus:outline-none  rounded-lg border p-3 font-[inherit] font-normal ${
                formik.touched.prepration_time && formik.errors.prepration_time
                  ? "border-red" // Apply red border when touched or notification shown
                  : "border-[#ccc]"
              } `}
              type="number"
              name="prepration_time"
              placeholder="Enter in minutes"
              maxLength={3}
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.prepration_time}
              error={formik.errors.prepration_time}
              touched={formik.touched.prepration_time}
            /> */}
            {formik.errors.prepration_time && (
              <div className=" text-red text-sm h-2 mt-0 ml-1">
                {formik.errors.prepration_time}
              </div>
            )}
            {/* </div>

          <div className="w-[300px] md:w-full leading-[0px] ">
            <label>Order Preparation Time (min.)</label> */}

            {/* <TimePicker
              className={`h-10 mt-6 w-full bg-transparent text-sm text-body outline-none focus:outline-none  rounded-lg border p-3 font-[inherit] font-normal`}
              name="prepration_time"
              // defaultValue={dayjs("12:08",format)}
              format={format}
              onChange={(val) => {
                handleorderpreparationtimeChange(val)
                 console.log("val", val);
              }}
              value={dayjs(formik.values.prepration_time, format)}
              placeholder="Select time"
            /> */}
            {/* {formik.errors.prepration_time && (
              <div className="text-red text-sm h-2 mt-0 ml-1">
                {formik.errors.prepration_time}
              </div>
            )} */}
          </div>

          <div className="w-[300px] md:w-full leading-[0px] ">
            <label className="text-[14px] text-[#5C5C5C]">
              {t("min_order_value")}
            </label>

            <Input
              // label="orderpreparationtime"
              style={{
                WebkitAppearance: "none", // Remove default spinner appearance (Chrome/Safari)
                MozAppearance: "textfield", // Firefox spinner removal
                paddingRight: "1rem",
              }}
              type="number"
              className={`mt-4 h-10 w-full bg-transparent text-[#5C5C5C]  text-sm text-body outline-none focus:outline-none  rounded-lg border p-3 font-[inherit] font-normal ${
                formik.errors.minimum_order_value
                  ? ""
                  : // "border-red" // Apply red border when touched or notification shown
                    ""
              } `}
              name="minimum_order_value"
              placeholder="Enter value in EGP"
              onChange={(val) => {
                formik.handleChange(val);
                setrestricted(false);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.minimum_order_value}
              // error={formik.errors.minimum_order_value}
              // touched={formik.touched.minimum_order_value}
            />
            {formik.errors.minimum_order_value && (
              <div className=" text-red text-sm h-2 mt-0 ml-1">
                {formik.errors.minimum_order_value}
              </div>
            )}
          </div>

          <div className="w-[30px] md:w-full leading-[0px] ">
            <label className="text-[14px] text-[#5C5C5C]">
              {t("enable_online")}
            </label>
            <Select
              defaultValue=""
              className="h-10 mt-4 w-full text-md text-body  !border-[#CCCCCC]  !text-[#5C5C5C] "
              name="shop_close"
              onChange={(val) => {
                formik.setFieldValue("shop_close", val);
                setrestricted(false);
              }}
              options={[
                { value: 0, label: "Enable" },
                {
                  value: 1,
                  label: "Disable",
                },
              ]}
              onBlur={formik.handleBlur}
              value={formik.values.shop_close}
              error={formik.errors.shop_close}
              touched={formik.touched.shop_close}
            />
            {formik.errors.shop_close && (
              <div className=" text-red text-sm h-2 mt-0 ml-1">
                {formik.errors.shop_close}
              </div>
            )}
          </div>
        </div>

        <div className="mt-12 mb-10 mx-7 flex gap-5">
          <button
            type="submit"
            className={`w-[180px] h-[45px] ${
              restricted ? "bg-primaryBluedisabled" : "bg-primaryBlue"
            } text-white rounded-lg font-semibold`}
            disabled={restricted}
          >
            {t("update_and_save")}
          </button>
          <Button
            className="w-[180px] h-[45px] text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
            onClick={getshopdetaildata}
          >
            {t("cancel")}
          </Button>
        </div>
      </form>
      {showUpdatedProfile && (
        <UpdatedProfile
          onClose={() => setShowUpdatedProfile(false)}
          message={"Shop Details"}
        />
      )}
    </>
  );
};

export default ShopDetails;
