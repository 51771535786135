import { store } from "./Redux/store";
import axios from "axios";

//Defaults will be combined with the instance
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
console.log("axios.defaults.baseURL", process.env.REACT_APP_API_URL);
let lang;

// Check if localStorage is available (for browser environments)
if (typeof localStorage !== 'undefined') {
  lang = localStorage.getItem("selectedLanguage");
}

const axiosInstance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    // 'api-key': 'b63bd82b-4a83-4d28-909d-30ce29614589',
    lang: lang == "ar" ? "ar_001":"en_US",
  },
});

//Add interceptors to add header
axiosInstance.interceptors.request.use(function (config) {
  const { login } = store.getState();

  //const token = auth?.accessToken;
  const token = login?.user?.access_token;
  const lang = login?.user?.lang;
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers.lang = lang === "ar" ? "ar_001" : "en_US";
  return config;
});
//Add interceptors to instance
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // showShortSnackbar(error?.message);
    return error;
  }
);

export default axiosInstance;
