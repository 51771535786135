import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";


const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 999,
  },
  container: {
    width: "65rem",
    padding: "20px 60px",
    borderRadius: "20px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    height:"30rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    marginBottom: "20px", 
    // Example maximum height for the scrollable area
    overflowY: "scroll", // Enable vertical scrolling
    scrollbarWidth: "none", // Hide the scrollbar for Firefox
  },
  inside: {
    width: "70rem",
    marginTop:"1.5rem",
    paddingLeft: "5rem ",
    paddingRight: "5rem ",
    borderRadius: "20px",
       
    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    position: "fixed",
    height:"25rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1000,
    marginBottom: "20px", 
    // Example maximum height for the scrollable area
    overflow: "scroll", // Enable vertical scrolling
    scrollbarWidth: "none", // Hide the scrollbar for Firefox
  },
  title: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    // textAlign: "left",
    marginBottom: "20px",
    // marginTop: ".5rem",
    textAlign: "center",
    position: "sticky", 
    top: 0, 
    zIndex: "1001"
  },
  description: {
    textAlign: "justify",
    fontSize: "0.775rem",
    color: "#5C5C5C",
  },
};

const TermsModal = ({ isOpen, onClose, UserId, Details, UserPhone }) => {
  const {t,i18n}=useTranslation();
  const navigate=useNavigate();
  
  const handleAcceptTerms = () => {
    // Perform any necessary actions or API calls here

    // Redirect to the orders page

    localStorage.setItem("UserPhone", UserPhone);
    // localStorage.setItem(
    //   "retailer_info",
    //   JSON.stringify(Details.retailer_info[0])
    // );

 navigate("/register" )

    // Close the modal
    setTimeout(() => {
      onClose();
    }, 1000);
    
  };

  return (
    <>
      {isOpen && (
        <>
          <div style={styles.overlay} onClick={onClose}></div>
          <div style={styles.container}>
            <p style={styles.title}>Terms and Conditions</p>
            </div>
            <div style={styles.inside}>
            <span style={styles.description}>
              <span className=" font-extrabold">Myginne Application for Retailers - Terms & Conditions</span>
              <br />
              <span className=" font-extrabold">ATTENTION:</span> Please read these Myginne for Retailers Terms and
              Conditions (hereinafter referred to as the "Agreement") before
              accessing or using the Myginne Application and services.
              <br />
              This Agreement sets out the terms and conditions applicable to
              Myginne’s application for Retailers as provided by Myginne
              INFOTECH a Limited Liability Company, Commercial register number
              196874 and its address is at First Floor above Ground in Building
              no.14, Plot 14/1164 El Walid Ben Thanian El Saoud Sheraton El
              Nozha-Cairo or any other affiliated or sister company (hereinafter
              referred to as "Myginne").
              <br />
              <span className=" font-extrabold">Article 1: Terms and Definitions</span>
              <br />
              <span className=" font-extrabold">1.1. Platform:</span> is an online platform along with its electronic
              interfaces, owned by Myginne which shall provide receiving,
              supporting Retailer’s data related to the sales, purchases,
              Inventory cycle, Inventory expiry management and accounting on
              periodic basis and connecting the Retailer with the Suppliers,
              Payment Aggregators and Facilitators, Fleet Partners and
              Customers.
              <br />
              <span className=" font-extrabold">1.2. Myginne: </span> Myginne INFOTECH a Limited Liability Company,
              Commercial register number 196874 and its address is at First
              Floor above Ground in Building no.14, Plot 14/1164 El Walid Ben
              Thanian El Saoud Sheraton El Nozha, Cairo or any other affiliated
              or sister company (hereinafter referred to as "Myginne").
              <br />
              <span className=" font-extrabold">1.3. Retailer:</span> The juristic person, the partnership or any other
              entity that is operating a retail store is licensed to perform
              commercial affairs in accordance with the provisions of the laws
              and regulations in Egypt.
              <br />
              <span className=" font-extrabold">1.4. Service:</span> availing a platform to assist the Retailer with
              supporting Retailer’s data related to the sales, purchases,
              Inventory cycle, Inventory expiry management and accounting on
              periodic basis, and providing maintenance support, and updates of
              the Platform and proper training for the Retailers to use the
              Platform and connecting the Retailer with the Suppliers, Payment
              Aggregators or Facilitators, Fleet Partners and Customers and
              collection of the Retailers receivables.
              <br />
              <span className=" font-extrabold">1.5. Service Fee:</span> The fee agreed upon to perform the services
              provided by Myginne to the Retailer, including Value Added Tax and
              all applicable taxes.
              <br />
              <span className=" font-extrabold">1.6. Bank Account:</span> An account created and managed by Myginne at a
              bank licensed by the Central Bank of Egypt, through which the
              Service Fee is settled from Retailers to the benefit of Myginne by
              any means licensed by the Central bank of Egypt.
              <br />
              <span className=" font-extrabold">1.7. Supplier:</span> Any Supplier having a valid agreement with Myginne
              who shall provide its products and services to the Retailers
              having a valid agreement with Myginne through the Platform.
              <br />
              <span className=" font-extrabold">1.8. Fleet Partner:</span> Any entity licensed by the Land Transport
              Regulatory Authority (“LTRA”) to provide delivery services of
              goods in compliance with all the provisions of the Law and safety
              regulations which has a contract with Myginne to provide delivery
              services of goods.
              <br />
              <span className=" font-extrabold">1.9. Payment Aggregators or Facilitators:</span> Any entity having its
              license from the Central Bank of Egypt and which has a contract
              with a locally licensed bank in Egypt to provide payment
              aggregators or facilitators services in Egypt.
              <br />
              <span className=" font-extrabold">1.10. Customers:</span> All the Customers using the Platform for
              Customers and benefiting from the Retailer’s services and products
              displayed on the Platform.
              <br />
              <span className=" font-extrabold">Article 2: Contractual Framework & Termination </span>
              <br />
              <span className=" font-extrabold">2.1</span> This Agreement shall include the basic terms and conditions
              regulating the relationship between Myginne and the Retailer with
              respect to the provision of the Service.
              <br />
              <span className=" font-extrabold">2.2</span> Myginne reserves the right to amend the Terms and Conditions
              published on the Platform at its sole discretion, provided that
              the Retailer shall be electronically notified (via email) or
              through the Platform within 30 days from the date of publication
              of the amendment. The Retailer is entitled to terminate this
              Agreement after serving a prior notice to Myginne of its desire to
              terminate the Agreement (30) days prior to termination, without
              the need for a court ruling.
              <br />
              <span className=" font-extrabold">2.3</span> The Retailer is entitled to terminate the contract immediately
              with Myginne if there are serious reasons (according to the
              customary and common business practices) including but not limited
              to:
              <br />
              Critical defaults that could lead to immediate termination of the
              Service
              <br />
              Unauthorized access, or duplication of the application
              <br />
              Deliberate attempt to corrupt the application by mala fide
              intentions
              <br />
              <span className=" font-extrabold">Article 3: The Scope of the Agreement and Nature of Relationship</span>
              <br />
              <span className=" font-extrabold">3.1</span> This Agreement shall govern the relationship between Myginne
              and the Retailer with respect to availing receiving, supporting
              Retailer’s data related to the sales, purchases, Inventory cycle,
              Inventory expiry management and accounting on a periodic basis,
              and providing maintenance support, and updates of the Platform and
              proper training for the Retailers to use the Platform and
              connecting the Retailer with the Suppliers, Payment Aggregators or
              Facilitators, Fleet Partners and Customers. This Agreement shall
              not, in itself, be deemed as a partnership agreement, agency or
              consulting agreement between the Parties.
              <br />
             <span className=" font-extrabold"> 3.2 </span> The Retailer agrees and acknowledges that Myginne does not
              provide any service of supply, sale or purchase of goods, delivery
              services, or payment services. However, Myginne connects the
              Retailer electronically with warehouses, the Suppliers, Fleet
              Partners, Payment Aggregators or Facilitators contracting with
              Myginne to enable the Retailers to operate their business swiftly.
              The role of Myginne is limited to connecting each party to the
              other party, providing the relevant Platform services and
              obtaining its Service Fee prescribed under this Agreement.
              <br />
              <span className=" font-extrabold">Article 4: Registering and Onboarding the Retailer on the Platform</span>
              <br />
              <span className=" font-extrabold"> 4.1</span> For the purpose of registering the Retailer on the Platform,
              each Retailer should submit a registration request and must be an
              establishment registered in accordance with the laws/regulations
              applicable in the Arab Republic of Egypt, and should upload
              electronically the required documents and data for the completion
              of the registration request on the Platform.
              <br />
              <span className=" font-extrabold">4.2</span> After finishing the registration request and uploading all the
              data and documents on the platform electronically, Myginne shall
              contact the Retailer to set a date for the inspection of the
              Retailer’s goods and warehouses and registering of the Retailer’s
              Products and inventory on the Platform electronically and for
              training the Retailer’s employees regarding the usage of the
              platform.
              <br />
              <span className=" font-extrabold">4.3</span> The main user shall have the authorization to manage the
              Retailer’s account and is entitled to create additional users.
              Therefore, the Retailer shall be responsible for protecting the
              data of the main user and the additional users. Each action
              carried out on the Platform by using the main or additional login
              data is deemed acceptable by the Retailer and shall follow all
              guidelines of data protection, safety, SOPs (standard operating
              procedures) as prescribed by Myginne.
              <br />
              <span className=" font-extrabold">4.4</span> Using the services of Myginne may require using the services
              of other entities such as internet, hosting, data hosting,
              electronic payment service providers. Those services are subject
              to the terms and conditions of those service providers. Myginne
              does not indemnify against any damage resulting from the
              performance or non-availability of third-party services when
              needed.
              <br />
              <span className=" font-extrabold"> Article 6: Myginne’s obligations towards The Retailer</span>
              <br />
              <span className=" font-extrabold"> 6.1 The Retailer shall, upon being registered on the Platform,
              enjoy the following Services of the Platform available to the
              Retailer:</span>
              <br />
              Access to his sales, purchase, inventory data on the platform
              <br />
              View the expiry of products where such details are entered on the
              platform Issuance of purchase order using the platform Avail the
              Myginne choice of wholesaler service to place the periodic
              Purchase order In all cases, the provided Service shall not
              violate public order or morals, the society values or the laws and
              regulations in force in the Arab Republic of Egypt.
              <br />
              <span className=" font-extrabold">6.2</span> Myginne shall provide to the Retailer support and data on
              sales, purchases, Inventory cycle, Inventory expiry management and
              transactions periodically each Month through the Platform against
              a service fee to be agreed upon between Myginne and the Retailer.
              <br />
              <span className=" font-extrabold">6.3</span> Myginne shall send its representatives to the Retailer in
              order to provide the Retailer and its employees with training on
              the use of the Platform.
              <br />
              <span className=" font-extrabold"> 6.4</span> Myginne shall maintain the Platform and provide upgrades and
              support during the validity period of this agreement.
              <br />
              <span className=" font-extrabold">6.5</span> Myginne shall comply with all legal, statutory requirements
              for activities under this Agreement as applicable under Egyptian
              Laws.
              <br />
              <span className=" font-extrabold">Article 7: The Retailer’s obligations towards Myginne</span>
              <br />
              <span className=" font-extrabold">7.1</span> The Retailer shall not delay the payment of the dues payable
              by it as the service fee to Myginne and service providers for
              non-serious reasons or clearly unjustified objections. The
              Retailer shall also be responsible for payment of Myginne’s due
              amounts or Service providers. In case unjustified procrastination
              is proven, Myginne may cancel the Retailers’ registration, suspend
              its account or suspend its right to create a new service request
              on the Platform, without any liability on Myginne. Myginne
              reserves the right to take the legal action that it deems
              appropriate to receive its dues.
              <br />
              <span className=" font-extrabold">7.2</span> The Retailer undertakes to follow all guidelines of data
              protection, safety, SOPs (standard operating procedures) as
              prescribed by Myginne. The Retailer also undertakes to protect the
              Customer’s privacy and the confidentiality of their information
              and not to share it with any third parties, except within the
              limits required by the nature of the contracted duty or with their
              prior written consent.
              <br />
              <span className=" font-extrabold">7.3</span> The Retailer undertakes that it grants Myginne the right to
              use, retain, publish, share the data collected (except for
              Personal Data) from Retailer with others as per the discretion of
              Myginne free of charge or for a paid arrangement with Myginne’s
              clients as per Myginne’s discretion.
              <br />
              <span className=" font-extrabold">7.4</span> The Retailer acknowledges that Myginne has the right to use
              digital payment gateways and payment aggregators or facilitators
              for the sale of the Retailer’s inventory on the “Platform” and
              Myginne to provide collection services for the benefit of the
              Retailer. The Retailer agrees to periodic reimbursement of the
              amounts collected by Myginne to the Retailer after deduction of
              agreed-upon Service Fee.
              <br />
              <span className=" font-extrabold">7.5</span> The Retailer acknowledges that Myginne has the right to use
              Fleet Partners available on the Platform for delivery of the
              Retailer’s inventory products on the “application” to the
              Customers as per the terms and conditions defined by Myginne. The
              Retailer agrees that such delivery Service Fee will be added to
              the invoice value of the Retailer and collected by the Fleet
              Partner and retained by Myginne after deduction of agreed-upon
              Service Fee.
              <br />
              <span className=" font-extrabold">7.6</span>For obtaining this Service, the Retailer is obligated to supply
              the confirmed customers’ orders received on the platform. In case
              of noncompliance by the Retailer, it is agreed between the Parties
              that the Retailer shall pay Myginne a contractual penalty in the
              amount of (*) for each incidence of breach separately.
              Furthermore, in case of recidivism Myginne shall have the right to
              either immediately suspend the Retailer’s account or terminate the
              T&Cs without the need for notification or obtaining a court order.
              <br />
              <span className=" font-extrabold">7.7</span> The Retailer acknowledges that Myginne has the right to give
              the option of “cash on delivery” on the “application” for the
              sales of the Retailer. The Service Fee, if any, for such delivery
              will be added to the invoice of the Retailer and collected by
              Myginne and retained by them as per the Annex 1 of this Agreement.
              <br />
              <span className=" font-extrabold">7.8</span> Retailer agrees that Myginne has the right to run promotional
              activities on the Platform through licensed companies for
              providing marketing (digital / conventional), SMS, e-mail, social
              media messaging. Myginne has all rights to use the data (except
              for Personal Data) collected on the Platform from Retailer to be
              used for such promotional campaigns. Myginne also has the right to
              charge the proportionate cost of such promotions to the Retailer
              based on prior agreement with the Retailer. The Retailer agrees
              that such promotional Service Fee will be added to the invoice
              value of the Retailer and collected by the Promotion companies and
              retained by Myginne after deduction of agreed-upon Service Fee.
              <br />
              <span className=" font-extrabold">Article 8: Costs and Service Fee</span>
              <br />
              <span className=" font-extrabold">8.1</span> Myginne shall receive from the Retailer the Service Fee
              determined through the Platform upon the creation of the service
              request. The Service Fee of Myginne and service providers shall be
              paid via tax invoices including Value Added Tax. The Service Fee
              shall be as mentioned in Annex A.
              <br />
              <span className=" font-extrabold">8.2</span> Myginne confirms that there are no other Service Fees to be
              paid by the Retailer other than the Service Fee mentioned in this
              Agreement.
              <br />
              <span className=" font-extrabold">8.3</span> The Retailer shall bear the Value Added Tax in accordance with
              the laws and regulations in force in this regard in the Arab
              Republic of Egypt. The Service Fee shall be paid in return for the
              performance of the Service in Myginne’s bank account, the details
              of which are as follows (*). Myginne is entitled to deduct its
              Service Fee from the amounts deposited in its bank account by the
              Retailer without the need for the Retailer’s approval.
              <br />
              <span className=" font-extrabold"> Article 9: Intellectual Property</span>
              <br />
              <span className=" font-extrabold"> 9.1</span> All the intellectual property rights relating to the Platform
              shall be solely owned by Myginne, such as designs, logos,
              trademarks, drawings, interfaces, software codes, source codes,
              workflow, templates, content, collected information and data,
              legal documents and all the technical technological and linguistic
              elements of the Platform.
              <br />
              <span className=" font-extrabold">9.2</span> This Agreement shall not grant the Retailer any rights to use
              the intellectual property rights of Myginne except within the
              limits of its use of the Platform. The Retailer shall not, without
              prior written consent of Myginne, copy, imitate or reproduce the
              Platform’s content, or copy it to any paper or electronic media,
              or amend or use any part of the intellectual property elements
              protected under these Terms and Conditions for any purpose other
              than what is stated herein.
              <br />
              <span className=" font-extrabold">9.3</span> Any proposals submitted by the Retailer to develop the
              Platform are deemed the property of Myginne. The Retailer is not
              entitled to claim any damages, dues or intellectual property
              rights relating to the outputs of those proposals.
              <br />
              <span className=" font-extrabold">9.4</span> The Retailer reserves all its intellectual property rights
              such as trademarks and others. Myginne shall not, without the
              prior written approval of the Retailer, use the Retailer’s logo or
              its intellectual property except within the limits necessary for
              providing the service thereto.
              <br />
              <span className=" font-extrabold">Article 10: Confidentiality of Information</span>
              <br />
              <span className=" font-extrabold">10.1</span> The information received by the Retailer due to its use of
              the Platform shall be confidential, including information on
              Myginne, the service providers, their data, experience, and
              appraisals. It shall not disclose or share this information with
              any third parties unless with a written approval of Myginne.
              <br />
              <span className=" font-extrabold">Article 11: Approvals and Notices</span>
              <br />
              <span className=" font-extrabold">11.1</span> The Parties agree to serve any notices relating to this
              Agreement via email. The electronic notice is deemed received by
              the recipient and effective as of the date of sending it,
              regardless of the date of its receipt, unless it is proven that a
              technical defect has led to delayed delivery.
              <br />
              <span className=" font-extrabold">Article 12: The Applicable Law and Settlement of Disputes</span>
              <br />
              The relationship between the Parties, resulting from this
              Agreement and the disputes that may arise therefrom shall be
              governed by the laws applicable in the Arab Republic of Egypt. In
              case there is a dispute between the Parties over this Agreement,
              its interpretation, execution, or termination Myginne shall exert
              its best efforts to settle it amicably. In case no amicable
              settlement is reached within (30) days, either Party may refer the
              matter to arbitration under the Rules of the Cairo Regional Centre
              for International Commercial Arbitration (CRCICA). The number of
              arbitrators shall be one. The arbitration shall be conducted in
              the English language in the city of Cairo. The arbitration award
              shall be final and binding upon the Parties.
              <br />
              <span className=" font-extrabold">Article 13 Language of the Agreement</span>
              <br />
              This Agreement was concluded in Arabic and English languages, and
              in case of discrepancy between both languages, the Arabic language
              shall prevail. The Parties also declare that they have read,
              understood and agreed on the Terms and Conditions of this
              Agreement as stated herein.
              <br />
              <span className=" font-extrabold">ANNEX – A
              <br />
              Annex A Myginne’s Service Fee and the Service Providers Service
              Fee</span>
              <br />
              The Retailer shall pay Myginne a Monthly Service Fees of EGP 100
              (Egyptian Pounds One Hundred only inclusive of vat) for usage of
              the My Ginny platform.
            </span>
            <div className="flex gap-5 mt-7 mb-7 justify-start">
              
              
              <button
                type="button"
                className="w-[180px] h-[45px] bg-white text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
                onClick={()=>onClose()}
              >
                  {t('cancel')}
              </button>
              <button
                type="button"
                className="w-[180px] h-[45px] bg-primaryBlue text-white rounded-lg font-semibold"
                onClick={handleAcceptTerms}
              >
                {t('iaccept')}
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TermsModal;
