import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputBox, Password } from "../../components/CustomWidgets/InputBox";
import { Button, DatePicker, Input } from "antd";
import gallery from "../../assets/Images/gallery.svg";
import { Image } from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import SelectBox from "../CustomWidgets/SelectBox";
import vector from "../../assets/Images/Vector.svg";
import UploadMedia from "../upload-media";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { routes } from "../../Routes";
import FlagIcon from "../../assets/egypt.svg";
import UploadMediaonly from "../upload-imageonly";
import SuccessModal from "../../modals/Sucessmodal";
import { deliveryBoyschema } from "../validationSchema";
import "../../styles.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Deliveryboy = ({ isActive, onEditProfile, DeliveryBoyData, onClose }) => {
  const {t,i18n}=useTranslation()
const user_id = useSelector(    (state) => state.login?.user?.user_id  );
  const store_id = useSelector(    (state) => state.login?.user?.store_id  );

  const [file, setFile] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [showsucesspopup, setshowsucesspopup] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
 const [showemail, setshowemail] = useState(false);

  //To create a new delivery partner
  const createnewdeliverypartner = async (payload) => {
    // console.log("payload", payload);
    try {
      const response = await axiosInstance.post(
        URLS.DELIVERY_PARTNER_CREATE,
        payload
      );
      console.log("responsenew", response);
      if (response?.status == 200) {
        if (response?.data?.delivery_partner_id != 0) setshowsucesspopup(true);
      }
      if (response?.data?.status == 201){
        
        setshowemail(true);
      }

      // if (response?.data?.delivery_partner_id != 0) window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  //To update a delivery Partner
  const updatedeliverypartner = async (data, DeliveryBoyData) => {
    // console.log("update delivery partner",data,DeliveryBoyData)
    const payload = {
      delivery_partner_id: DeliveryBoyData.id,
      user_id: user_id,
      name: data.name,
      mobile: data.mobile,
      image_url: data.image_url,
      store_id: store_id,
      email: data.email,
      password: data.password,
    };
    try {
      const response = await axiosInstance.post(
        URLS.UPDATE_DELIVERY_PARTNER,
        payload
      );
     
           if (response?.data?.delivery_partner_id != 0) setshowsucesspopup(true);
           if (response?.data?.status == 201){
                       setshowemail(true);
          }
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      user_id: user_id,
      name: DeliveryBoyData?.name ?? "",
      mobile: DeliveryBoyData?.mobile ?? "",
      status: DeliveryBoyData?.status ?? "Available",
      image_url: DeliveryBoyData?.image_url ?? "",
      store_id: store_id,
      email: DeliveryBoyData?.email??"",
      password: "",
    },
    validationSchema: deliveryBoyschema,
    // validateOnChange: false,
    // validateOnBlur: false,

    onSubmit: (values) => {
      DeliveryBoyData
        ? updatedeliverypartner(values, DeliveryBoyData)
        : createnewdeliverypartner(values);
      // Your form submission logic here
    },
  });

  useEffect(() => {
    setIsEditing(isActive);
  }, [isActive]);

  const handleurlgenerated = (values, keyname) => {
    console.log("values", values.url, keyname);
    formik.setFieldValue("image_url", values.url);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Modify the value if needed, e.g., format phone number
    e.target.value < 9999999999 && formik.setFieldValue(name, value);
    setShowNotification(false);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="h-auto  dark:bg-black px-4">
          <div className="flex justify-between items-baseline">
            <div className="font-semibold text-xl mt-4">
              {DeliveryBoyData ? t('edit') :t ('add')} {t('delivery_boy')}
            </div>
            <div className="flex gap-5 ">
              <Button
                className="w-[180px] h-[45px] bg-white text-[#1D5C96] font-semibold rounded-lg inline border border-[#1D5C96]"
                onClick={() => onClose()}
              >
               {t('cancel')}
              </Button>
              <button
                type="submit"
                className="w-[180px] h-[45px] bg-primaryBlue text-white rounded-lg font-semibold"
              >
              {t('save')}
              </button>
            </div>
          </div>
          <div className="md:w-full h-auto bg-[#FFFFFF] border rounded-xl mt-5">
            <div className="w-full h-10 bg-[#C9ECF7] px-4 py-3 font-medium text-base rounded-tr-xl rounded-tl-xl text-[#272728]">
            {t('basic_details')}
            </div>
            <div
              className="flex flex-row justify-between  mt-8 gap-8 px-4"
              style={{ minHeight: 200 }}
            >
              <div
                  style={{ maxheight: 70 }}
                  className={`mb-4  sm:w-[20%] w-full flex justify-center items-center  ${formik.values?.image_url == "" ? "rounded-lg border border-gray-900/25 bg-[#F6F7F9]":"rounded-full"} relative ${
                  (formik.errors.image_url && formik.touched.image_url )? " border-red" : ""
                }`}
              >
                <div
                  className={` flex sm:w-[100%] flex-col gap-4 items-center justify-center m-0.5 `}
                >
                  {formik.values?.image_url != "" ? (
                    <img
                      className=" mt-1 w-[58%] h-[8rem] object-cover rounded-full"
                      src={formik.values?.image_url}
                   
                      alt="delivery Boy image"
                    />

                  ) : (
                    // <img
                    //   className=" m-0.5 h-40 w-40 object-contain "
                    //   style={{ width: 187 }}

                    // />
                    // <img
                    //   src={formik.values?.image_url}
                    //   height="100rem"
                    //   width="full"
                    //     className="object-cover"
                    //   alt="ci"
                    // />
                    <svg
                      className=" h-10 w-12 text-gray-300 "
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                        // clip-rule="evenodd"
                      />
                    </svg>
                  )}
                  <div class=" text-sm leading-4 w-full flex items-center justify-center   text-gray-600">
                    <label
                      for="file-upload"
                      class="relative flex items-center cursor-pointer rounded-md  font-semibold text-[#1D5C96] focus-within:outline-none focus-within:ring--1 focus-within:ring-white focus-within:ring-offset-2"
                    >
                      <UploadMediaonly
                        onfileAdd={
                          // formik.values?.image_url
                          //   ? formik.values?.image_url
                          //   : 
                            "delivery partner"
                        }
                        foldername={"owner"}
                        urlgenerated={handleurlgenerated}
                        keyname={"image_url"}
                        imageurl={formik.values.image_url}
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="font-normal mt-4 text-sm text-[#5C5C5C] grid grid-cols-2 gap-6 sm:flex-row sm:items-baseline sm:w-[75%]">
                <div className="w-full leading-[0px]">
                  <label>{t('full_name')}</label>
                  <InputBox
                    name="name"
                    placeholder={t('enter_fullname')}
                    className={` ${
                      formik.errors.name
                        ? "border-red" // Apply red border when touched or there's an error
                        : ""
                    }`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    touched={formik.touched.name}
                    error={formik.errors.name}
                    // required
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label>{t('phone_number')}</label>
                  <div className="flex items-center ">
                    <div className="h-10 w-16  text-md text-body outline-none focus:outline-none rounded-md border border-[#ccc] p-3 flex items-center justify-center">
                      <Image src={FlagIcon} alt="img" />
                    </div>
                    <Input
                      maxLength={11}
                      placeholder="Enter Number"
                      international
                      defaultCountry="EG"
                      onChange={handleChange}
                      value={formik.values.mobile}
                      onBlur={formik.handleBlur}
                      name="mobile"
                      className={` h-10 w-100 ml-2 text-[#5C5C5C] text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-md border p-3 ${
                        (formik.errors.mobile && formik.touched.mobile) || showNotification
                          ? "border-red" // Apply red border when touched or there's an error
                          : ""
                      }`}
                      // required
                    />
                  </div>
                  {/* {formik.touched.mobile && formik.errors.mobile ? (
                    <div className="font-medium italic text-red">
                      {formik.errors.mobile}
                    </div>
                  ) : null} */}
                </div>
                <div className="w-full leading-[0px]">
                  <label>{t('email')}</label>
                  <InputBox
                    name="email"
                    placeholder={t('enter_email')}
                    onChange={(val)=>{
                      formik.handleChange(val)
                    setshowemail(false)}}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    touched={formik.touched.email}
                    error={formik.errors.email}
                    // required
                    autoComplete="new-password"
                  />
                  {showemail && <div className="text-xs text-red">
                        {" "}
                        {t("Email already registered")}
                      </div>}
                </div>
                <div className="w-full leading-[0px] flex flex-col gap-2">
                  <label>{t('password')}</label>
                  {/* <div className="flex items-center mt-1"> */}
                  <Password
                    loc="0"
                    del="0"
                    DeliveryBoyData={DeliveryBoyData}
                    style={{ marginBottom: `${DeliveryBoyData ? 0 : "2rem "}` }}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    name="password"
                    className={`h-10 mt-2  ml-2 text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-sm border p-3 ${
                      formik.touched.password && formik.errors.password
                        ? "border-red" // Apply red border when touched or there's an error
                        : ""
                    }`}
                    touched={formik.touched.password}
                    error={formik.errors.password}
                    // required
                    autoComplete="new-password"
                  />
                  {/* </div> */}
                </div>

                {DeliveryBoyData && (
                  <div className="w-full leading-[0px] flex flex-col gap-2">
                    <label>{t('joined_on')}</label>

                    <Input
                      name="name"
                      className="h-10 mt-2 w-100  text-[#5C5C5C] text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-md border p-3 
                      "
                      // placeholder="Enter Full Name"
                      // onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={DeliveryBoyData?.joined_on.substring(0, 10)?.replace(/-/g, "/")}
                      readOnly
                      // required
                    />
                    {/* <h1>{DeliveryBoyData?.joined_on.substring(0, 10)}</h1> */}
                    {/* <DatePicker
                    // onChange={(date, dateString) =>
                    //   formik.setFieldValue("joinedon", dateString)
                    // }
                    value={formik.values.status}
                    className="w-full mt-4 text-md text-body border-[#CCCCCC] pl-5 pr-5 text-[#929EAE] h-10"
                  /> */}
                  </div>
                )}
                {DeliveryBoyData && (
                  <div className="w-full leading-[0px] flex flex-col gap-2 mb-8">
                    <label className="">{t('status')}</label>
                    <Input
                    name="status"
                    className="h-10 mt-2 w-100 text-[#5C5C5C]  text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-md border p-3 
                    "
                    // placeholder="Enter Full Name"
                    // onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.status}
                    readOnly
                    />
                    {/* <SelectBox
                      // style={{height:"2.42rem", marginBottom: "2rem",marginTop:".5rem" }}
                      name="status"
                      defaultValue="Select"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.status}
                      options={[
                        { value: "Available", label: "Available" },
                        { value: "Executive", label: "Executive" },
                      ]}
                      disabled
                    /> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      {showsucesspopup && (
        <SuccessModal
          onClose={() => {
            setshowsucesspopup(false);
            onClose();
          }}
          state={DeliveryBoyData ? true : false}
          message={` ${
            DeliveryBoyData ? t('deliveryboy_updated'):t('deliveryboy_added')
          }  `}
          route={routes.deliveryPartner}
        />
      )}
    </>
  );
};

export default Deliveryboy;
