// import React from 'react';
// import ReactDOM from "react-dom"
// import { hydrateRoot } from 'react-dom/client';
// // import './index.css';
// import App from './App';
// import { store, persistor } from './Redux/store';
// import { PersistGate } from 'redux-persist/integration/react';
// import { Provider } from 'react-redux';
// import reportWebVitals from './reportWebVitals';
// import './i18n';

// //  const root = hydrateRoot(document.getElementById('root'),<App/>);
// // const root= ReactDOM.createRoot(document.getElementById("root"));
// // export const root=ReactDOM.hydrate(<App />, document.getElementById('root'));

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <App />
//       </PersistGate>
//     </Provider>
//   </React.StrictMode>
// );

// // ReactDOM.hydrate(
// //   <React.StrictMode>
// //     <Provider store={store}>
// //        <PersistGate loading={null} persistor={persistor}>
// //          <App />
// //        </PersistGate>
// //      </Provider>
// //    </React.StrictMode>,
// //   document.getElementById('root')
// // );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store, persistor } from './Redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import './i18n';

const rootElement = document.getElementById('root');

// Hydrate the root element with the React application
ReactDOM.hydrate(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  rootElement
);

// Measure performance using reportWebVitals
reportWebVitals();

