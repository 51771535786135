const initialState = {
    user: null,
    loading: false,
    error: null,
  };
  
  const logoutReducer = (state = initialState, action) => {
    switch (action.type) {
     
      case 'LOGOUT_USER_SUCCESS':
        return { ...state, loading: false, user: null };
      
      default:
        return state;
    }
  };
  
  export default logoutReducer;
  