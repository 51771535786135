import React, { useEffect, useState } from "react";
import { InputBox, Password } from "../../components/CustomWidgets/InputBox";
import { useFormik } from "formik";
import SelectBox from "../CustomWidgets/SelectBox";
import gallery from "../../assets/Images/gallery.svg";
import { Image, Input } from "antd";
import UploadMediaonly from "../upload-imageonly";
import FlagIcon from "../../assets/egypt.svg";
import {
  createStoreUser,
  getusertypes,
  updateStoreUser,
} from "../../API  Functions/UserMangement";
import UpdatedProfile from "../../modals/UpdatedProfile";
import moment from "moment";
import "../../styles.css";
import UsermanagementPopup from "../../Popups/usermanagementPopup";
import { useSelector } from "react-redux";
import { addUserSchema } from "../validationSchema";
import { useTranslation } from "react-i18next";
import { red } from "@mui/material/colors";
import image from "../../assets/Images/roundimage.png";

const AddUser = ({ onClose, isEdit }) => {
  const { t, i18n } = useTranslation();

  const user_id = useSelector((state) => state.login?.user?.user_id);
  const store_id = useSelector((state) => state.login?.user?.store_id);

  const [file, setFile] = useState();
  const [userTypes, setuserTypes] = useState([]);
  const [showUpdatedProfile, setshowUpdatedProfile] = useState(false);
  const [popup, setpopup] = useState(false);
  const [message, setmessage] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [Error, setError] = useState(0);

 
  const formik = useFormik({
    initialValues: {
      password: "",
      first_name: isEdit?.first_name ?? "",
      last_name: isEdit?.last_name ?? "",
      store_user_type_id: 1,
      mobile: isEdit?.mobile ?? "",
      email: isEdit?.email ?? "",
      url: isEdit?.url ?? "",
    },
    validationSchema: addUserSchema,
    onSubmit: (values, { resetForm }) => {
      if (isEdit)
        updateStoreUser(
          user_id,
          store_id,
          values,
          isEdit.user_id,
          isEdit.user_record_link_id
        ).then((res) => {
          if (res?.data?.message == "User Updated Successfully") {
            setmessage("User Updated Successfully");
            setpopup(true);
            // setshowUpdatedProfile(true);
          }
        });
      else
        createStoreUser(user_id, store_id, values).then((res) => {
          if (res?.data?.message == "User Created Successfully") {
            setmessage("User Created Successfully");
            setpopup(true);
            resetForm();
            // setshowUpdatedProfile(true);
          } else if (res?.data?.status == 500) {
            setError(1);
          }
        });
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Modify the value if needed, e.g., format phone number
    e.target.value < 9999999999 && formik.setFieldValue(name, value);
    setShowNotification(false);
  };

  useEffect(() => {
    const getuserTypes = () =>
      getusertypes().then((res) => {
        res?.data?.message === "Sucess" && setuserTypes(res.data.data);
      });

    getuserTypes();
  }, []);



  useEffect(() => {
    let initialStoreUserTypeId = "";
  if (isEdit && userTypes) {
    const userType = userTypes.find((item) => isEdit.user_type === item.name);
    console.log("userTYpe", userType);
    if (userType) {
      formik.setFieldValue("store_user_type_id",userType.id)
    }
  }
  }, []);
  

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className=" ">
          {console.log("user type", formik.values.store_user_type_id)}
          <div className="flex justify-between items-baseline">
            <div className="font-semibold text-2xl mt-5">
              {isEdit ? t("edit_user") : t("add_user")}{" "}
            </div>
            <div className="flex gap-5 ">
              <button
                onClick={() => onClose()}
                className="w-36 h-10 bg-white text-primaryBlue font-semibold rounded-lg inline border border-primaryBlue"
              >
                {t("cancel")}
              </button>
              <button
                type="submit"
                className="w-36 h-10 bg-primaryBlue text-white rounded-lg font-semibold"
              >
                {t("add")}
              </button>
            </div>
          </div>
          <div className="md:w-full min-h-auto bg-[#FFFFFF] border rounded-xl mt-5">
            <div className="w-full h-10 bg-[#C9ECF7] px-4  flex items-center font-medium text-base rounded-tr-xl rounded-tl-xl text-[#272728]">
              {t("basic_details")}
            </div>
            <div className="flex justify-center mt-4 pb-4 gap-x-8 px-6">
              <div
                className={`w-44 h-50 flex flex-col  sm:w-[20%] ${formik.values?.url == "" ? "rounded-lg border border-gray-900/25 bg-[#ffffff]":"rounded-full"} relative ${
                  formik.errors.url && formik.touched.url ? "border-red" : ""
                }`}
              >
               
                  <label
                    for="file-upload"
                    class=" items-center relative cursor-pointer rounded-md bg-white font-semibold text-primaryBlue  hover:text-primaryBlue"
                  >
                    {console.log("formik.values.url", formik.values.url)}

                    <div className={`flex h-24 item-center justify-center`}>
                      {formik?.values?.url != "" ? (
                        <>
                          <img
                            className=" w-[12rem] h-[12rem] object-cover rounded-full"
                            src={formik.values.url}
                            width={100}
                            height={100}
                            alt="store user photo"
                          />
                        </>
                      ) 
                      : (
                        <img
                            className=" w-[8rem] h-[7rem] object-cover mt-10"
                            src={image}
                            width={60}
                            height={60}
                            alt="store user photo"
                          />
                        
                      )}
                      {/* <Image src={} width={"11rem"} height={"11rem"} /> */}
                    </div>
                    {/* <span className="ml-2">Change </span> */}
                    <div className="mt-[5.9rem] bottom-0">
                      <UploadMediaonly
                        foldername={"store_app_UAT"}
                        // ${moment()}
                        onfileAdd={`storeuserurl`}
                        urlgenerated={(val) => {
                          formik.setFieldValue("url", val.url);
                        }}
                        imageurl={formik.values.url}
                        // Adjust field name as needed
                      />
                    </div>
                  </label>
                  </div>
                {/* // ) : (
                //   <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-400">
                //     <img
                //       src={gallery}
                //       alt="Placeholder"
                //       className="object-cover"
                //     />
                //     <label
                //       htmlFor="fileinput"
                //       className="cursor-pointer font-medium mt-2 text-[#000000] invisible"
                //     >
                //       {t("upload_photo")}
                //     </label>
                //     <UploadMediaonly
                //       foldername={"legal"}
                //       onfileAdd={"storeuserurl"}
                //       urlgenerated={(val) => formik.setFieldValue("url", val.url)}
                //       imageurl={formik.values.url}
                //       // Adjust field name as needed
                //     />
                   
                //   </div>
                // )}
              </div> */}
              <div className="font-normal text-sm text-[#5C5C5C] flex flex-col gap-6 sm:flex-row sm:gap-7 sm:items-baseline sm:w-[75%]">
                <div className="w-full flex flex-col gap-4 leading-[0px]">
                  <label style={{ marginBottom: -15, marginTop: 15 }}>
                    {t("first_name")}
                  </label>
                  <InputBox
                    name="first_name"
                    placeholder={t("Enter First Name")}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name}
                    touched={formik.touched.first_name}
                    error={formik.errors.first_name}
                  />
                  <div>
                    <label style={{ marginBottom: -15, marginTop: 15 }}>
                      {t("last_name")}
                    </label>
                    <InputBox
                      name="last_name"
                      placeholder={t("Enter Last Name")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name}
                      touched={formik.touched.last_name}
                      error={formik.errors.last_name}
                    />
                  </div>
                  <div className="flex flex-col text-[#5C5C5C] gap-2">
                    <label>{t("permission")}</label>

                    <SelectBox
                      name="store_user_type_id"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.store_user_type_id}
                      options={[
                        // { value: "", label: "Select" },
                        ...userTypes?.map((item) => ({
                          value: item?.id,
                          label: item?.name,
                        })),
                        // { value: "Admin", label: "Admin" },
                        // { value: "Manager", label: "Manager" },
                        // { value: "Executive", label: "Executive" },
                      ]}
                    />
                  </div>
                </div>

                <div className="w-full flex flex-col gap-4 leading-[0px]">
                  <div>
                    <label>{t("phone_number")}</label>
                    <div className="flex items-center mt-2">
                      <div className="h-10 w-16 mt-2 text-md text-body outline-none focus:outline-none  rounded-md border border-[#ccc] p-3 flex items-center justify-center">
                        <img src={FlagIcon} alt="img" />
                      </div>
                      <Input
                        maxLength={11}
                        placeholder={t("Enter Your Number")}
                        international
                        defaultCountry="EG"
                        onChange={handleChange}
                        value={formik.values.mobile}
                        onBlur={formik.handleBlur}
                        name="mobile"
                        className={` h-10 mt-2 w-100 ml-2 text-[#5C5C5C] text-md text-body outline-none focus:outline-none ltr:pl-5 rtl:pr-5 rounded-md border p-3 ${
                          (formik.errors.mobile && formik.touched.mobile) ||
                          showNotification
                            ? "border-red" // Apply red border when touched or there's an error
                            : ""
                        }`}
                        // required
                      />
                    </div>
                  </div>
                  <div>
                    <label className="">{t("email")}</label>
                    <InputBox
                      // style={{marginTop:}}
                      name="email"
                      placeholder={t("enter_email")}
                      onChange={(val) => {
                        formik.handleChange(val);
                        setError(0);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      touched={formik.touched.email}
                      error={formik.errors.email}
                      autoComplete="new-email"
                    />
                    {Error == 1 && (
                      <div className="text-xs text-red">
                        {" "}
                        {t("Email already registered")}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-2">
                    <label>{t("password")}</label>
                    <Password
                      loc="0"
                      // label="Enter Password"
                      name="password"
                      placeholder={t("password_place")}
                      maxLength={20}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      error={formik.errors.password}
                      touched={formik.touched.password}
                      autoComplete="new-email"
                    />
                  </div>
                  {/* <InputBox 
                  name="password"
                  placeholder="Enter Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {showUpdatedProfile && (
        <UpdatedProfile
          onClose={() => {
            setshowUpdatedProfile(false);
          }}
          message={"Store Users"}
        />
      )} */}
      </form>
      {popup && (
        <UsermanagementPopup
          message={message}
          close={() => {
            setpopup(false);
            if (message == "User Updated Successfully") onClose();
          }}
        />
      )}
    </div>
  );
};

export default AddUser;
