import React, { useEffect, useRef, useState } from "react";
import InventoryTableComponent from "./InventoryTableComponent";
import SearchBox from "../CustomWidgets/SearchBox";
import { PlusOutlined } from "@ant-design/icons";
import { Image } from "antd";
import category from "../../assets/Inventory/spinner.svg";
import {
  GenerateExcelReport,
  InventoryCategoryFilter,
  InventorySearch,
  InventoryUpdateDetails,
  getInventoryallcategories,
  getInventoryallproducts,
  getInventoryincompleteproducts,
} from "../../API  Functions/InventoryFunctions";
import { debounce } from "@mui/material";
import { Link } from "react-router-dom";
import { Select } from "antd";
import "../../styles.css";
import InCompletetable from "./InCompletetable";
import { useTranslation } from "react-i18next";

const InventoryTableHeader = ({ user_id, store_id }) => {
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const [TableData, setTableData] = useState([]);
  const [IncompleteData, setIncompleteData] = useState([]);

  const [CategoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [Showcategories, setShowcategories] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [SelectedsubcategoryOptions, setSelectedsubcategoryOptions] = useState(
    []
  );
  const [subcategory, setsubcategory] = useState([]);
  const [query, setquery] = useState("");

  //To get categories for filter and get
  t("all_products");
  useEffect(() => {
    if (store_id) {
      // getInventoryKPI();
      getInventoryCategories();
    }
  }, [store_id]);

  const [data] = useState([{ id: "11" }]);
  const [activeHeading, setActiveHeading] = useState(1);
  const [headingWidth, setHeadingWidth] = useState(0);
  const activeHeadingRef = useRef(null);

  useEffect(() => {
    setDynamicWidth();
  }, [activeHeading, data]);
  const setDynamicWidth = () => {
    if (activeHeadingRef.current) {
      setHeadingWidth(activeHeadingRef.current.offsetWidth);
    }
  };

  const handleOptionChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    setsubcategory([]);
    if (selectedValues?.length > 0) {
      searchFilter(selectedValues, [], query);
      selectedValues.map((item) => {
        console.log(
          "subcategory matching",
          CategoryData.filter((res) => res.main_category_id === item)[0]
            ?.sub_category_details
        );
        const cat = CategoryData.filter(
          (res) => res.main_category_id === item
        )[0]?.sub_category_details;
        setsubcategory((prevdata) => [...prevdata, ...cat]);
      });
    } else {
      getInventoryKPI();
    }
  };

  const handlesubcategorySelection = (selectedValues) => {
    setSelectedsubcategoryOptions(selectedValues);
    if (selectedValues) searchFilter(selectedOptions, selectedValues, query);
  };

  //Functions to get
  //Allproducts
  const getInventoryKPI = (limit = 10, offset = 0) => {
    getInventoryallproducts(user_id, store_id, limit, offset).then((res) => {
      setTableData(res);
    });
  };
  //Incomplete products
  const getInventoryIncompleteProducts = (limit = 10, offset = 0) => {
    getInventoryincompleteproducts(user_id, store_id, limit, offset).then(
      (res) => {
        setIncompleteData(res.data);
      }
    );
  };
  //Inventory categories

  const getInventoryCategories = () => {
    getInventoryallcategories(user_id, store_id).then((res) => {
      setCategoryData(res);
    });
  };

  const handleHeadingClick = (headingNumber) => {
    setSelectedOptions([]);
    setSelectedsubcategoryOptions([]);
    switch (headingNumber) {
      case 1:
        getInventoryKPI();
        break; // Add break statement to prevent falling through to the next case
      case 2:
        getInventoryIncompleteProducts();
        break; // Add break statement to prevent falling through to the default case
      default:
        // Handle default case if needed
        break;
    }
    setActiveHeading(headingNumber);

    // getData(1);
    // getData(id[headingNumber - 1].id);
  };
  const debouncedSearch = debounce((value) => {
    setquery(value);
    if (selectedOptions?.length > 0)
      searchFilter(selectedOptions, SelectedsubcategoryOptions, value);
    // Call your search function or API endpoint here
    else {
      value === ""
        ? getInventoryKPI()
        : InventorySearch(user_id, store_id, value).then((response) => {
            response.message == "Success" && activeHeading == 1
              ? setTableData(response.data)
              : setIncompleteData(response.data);
          });
    }
  }, 1000);

  const searchFilter = (selectedValues, subcat = [], value = "") => {
    InventoryCategoryFilter(
      user_id,
      store_id,
      selectedValues,
      subcat,
      value,
      activeHeading
    ).then((response) => {
      response?.message == "Success" && activeHeading == 1
        ? setTableData(response?.data)
        : setIncompleteData(response?.data);
    });
  };

  const handledetailUpdate = (val) => {
    InventoryUpdateDetails(user_id, store_id, val).then((res) => {
      console.log("res", res, activeHeading);
      res?.message == "Update Successfully" &&
        (activeHeading == 1
          ? getInventoryKPI()
          : getInventoryIncompleteProducts());
    });
  };

  const handleinventoryreportdownload = (val) => {
    console.log(val);
    GenerateExcelReport(user_id, store_id, val).then((res) => console.log(res));
  };
  useEffect(() => {
    setDynamicWidth();
  }, [activeHeading, data]);

  const underlineStyle = {
    width: `${headingWidth}px`,
    height: "6px",
    borderRadius: "10px",
    backgroundColor: "#78CFEC",
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null); // Reset subcategory when a new category is selected
    // setShowSubcategories(true);
  };

  const handleSubcategoryClick = (subcategory) => {
    setSelectedSubcategory(subcategory);
    // Perform any action based on the selected subcategory
  };

  const components = [
    <InventoryTableComponent
      additionalData={TableData}
      setAdditionalData={(val) => {
        handledetailUpdate(val);
      }}
      pagination={(values) => getInventoryKPI(values.limit, values.offset)}
    />,
    // <InProcesstable inprocess={inprocess} />,
    // <Onthewaytable ontheway={ontheway} />,
    // <Delivereddata delivered={delivered} />,

    <InCompletetable
      additionalData={IncompleteData}
      setAdditionalData={(val) => {
        handledetailUpdate(val);
      }}
      pagination={(values) =>
        getInventoryIncompleteProducts(values.limit, values.offset)
      }
    />,
    // <DamagedTable
    //   additionalData={additionalData}
    //   setAdditionalData={setAdditionalData}
    // />,

    // <ExpiredTable
    //   additionalData={additionalData}
    //   setAdditionalData={setAdditionalData}
    // />,
  ];

  return (
    <>
      <div className="flex justify-between items-start mt-2 mx-3">
        {/* Left section with SearchBox and Category Button */}
        <div className="flex gap-3  items-center">
          <SearchBox
            showBarcode={true}
            searchResult={(values) => debouncedSearch(values)}
          />

          {/* <button className="w-32 h-10 flex items-center justify-center border-[#CCCCCC] border text-[#5C5C5C] text-sm font-medium rounded-lg">
            <Image src={category} className="mr-2" />
            Category
          </button> */}
          {/* <button
            className="w-32 h-10 flex items-center justify-center border-[#CCCCCC] border text-[#5C5C5C] text-sm font-medium rounded-lg"
            onClick={() => setShowcategories(!Showcategories)}
          >
            <Image src={category} className="mr-2" />
            {selectedCategory ? selectedCategory.name : "Category"}
          </button>
          {Showcategories && (
            <div
              className="absolute   w-50 h-30 overflow-scroll ml--20 mt-5 bg-white border border-gray-300 rounded-lg shadow-lg z-10"
              style={{ height: 200 ,
                left:500,
              }
              
            }
            >
              {/* <ul className="py-1"> */}
          {/* {Object.values(CategoryData).map((category) => (
                <li key={category.id}>
                  {console.log("category", category)}
                  <button
                    className="block w-full px-4  text-sm text-left text-gray-800 hover:bg-gray-100"
                    onClick={() => handleCategoryClick(category)}
                  >
                    {category.name}
                  </button>
                  {selectedCategory && selectedCategory.id === category.id && (
                    <ul>
                      {category.sub_category_details.map((subcategory) => (
                        <li key={subcategory.id}>
                          <button
                            className="block w-full px-4  text-sm text-left text-gray-800 hover:bg-gray-100"
                            onClick={() => handleSubcategoryClick(subcategory)}
                          >
                            {subcategory.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))} */}
          {/* </ul> */}
          {/* </div>
          )} */}

          <Select
            mode="multiple" // Allow multiple selections
            className=" overflow-hidden"
            style={{
              width: 180,
              minWidth: 180,
              // left: -120,
              height: 40,
            }}
            placeholder={t("select_categories")}
            value={selectedOptions}
            onChange={handleOptionChange}
            maxTagCount={1}
            // maxTagPlaceholder={(values) => `+${values.length - 2} more`}
          >
            {Object.values(CategoryData)?.map((option, index) => (
              <Option
                key={option.main_category_id}
                value={option.main_category_id}
              >
                {option.name}
              </Option>
            ))}
          </Select>

          {selectedOptions?.length !== 0 && (
            <Select
              mode="multiple" // Allow multiple selections
              className=" overflow-hidden"
              style={{
                width: 180,
                minWidth: 180,
                height: 40,
              }}
              placeholder={t("select_sub_categories")}
              value={SelectedsubcategoryOptions}
              onChange={handlesubcategorySelection}
              maxTagCount={1}
            >
              {subcategory?.map((option, index) => (
                <Option key={index} value={option.id}>
                  {option.name}
                </Option>
              ))}
            </Select>
          )}
        </div>
        {/* Right section with Inventory Report and Add Product Buttons */}
        <div className="flex   gap-3">
          <button
            className="w-24 h-10 border-primaryBlue border text-primaryBlue text-sm font-semibold rounded-lg "
            onClick={() => handleinventoryreportdownload(activeHeading)}
          >
            {t("report")}
          </button>
          {/* onClick={onAddProductClick} */}
          <Link to="/inventory/InventoryOnboard">
            <button className="w-36 h-10 bg-primaryBlue text-white text-sm font-semibold rounded-lg flex items-center justify-center gap-2">
              <PlusOutlined style={{ fontSize: "1.5em", marginLeft: -10 }} />
              <span> {t("add_product")}</span>
            </button>
          </Link>
        </div>
      </div>

      {data.map((val, index) => (
        <div
          key={val.id}
          className="flex gap-7 mt-4 mx-3 mb-[0.62rem] font-semibold text-[#999999]"
        >
          {[1, 2].map((headingNumber) => {
            const badgeColor =
              headingNumber === activeHeading ? "#FCC71D" : "#E7E7E7";
            return (
              <h1
                key={headingNumber}
                ref={headingNumber === activeHeading ? activeHeadingRef : null}
                className={`flex relative cursor-pointer heading-${headingNumber}`}
                onClick={() => handleHeadingClick(headingNumber)} // Add this line
                style={{
                  fontWeight:
                    headingNumber === activeHeading ? "600" : "#999999",
                }}
              >
                {headingNumber === activeHeading && (
                  <div
                    style={underlineStyle}
                    className="absolute top-7 left-0"
                  />
                )}
                <span
                  style={{
                    color: headingNumber === activeHeading ? "#000" : "#999999",
                  }}
                >
                  {headingNumber === 1 && t("all_products")}
                  {/* {headingNumber === 2 && "Active"}
                  {headingNumber === 3 && "Sold"}
                  {headingNumber === 4 && "Inactive"} */}
                  {headingNumber === 2 && t("incomplete")}
                  {/* {headingNumber === 6 && "Damaged"}
                  {headingNumber === 7 && "Expired"} */}
                </span>
                <div
                  style={{
                    borderRadius: "10px",
                    maxHeight: "23px",
                    marginLeft: "5px",
                    backgroundColor: badgeColor,
                    padding: "0px 7px",
                  }}
                >
                  {/* <span style={{color:"#000000"}}>{val.id}</span> */}
                  <span style={{ color: "#000000" }}>
                    {headingNumber === 2
                      ? IncompleteData?.length
                      : TableData?.allProduct_len ?? TableData?.length}
                  </span>
                </div>
              </h1>
            );
          })}
        </div>
      ))}
      <hr className=" lg:w-[97.6%] relative  left-[13px]" />

      <div className="flex gap-x-2 items-center justify-center">
        {components[activeHeading - 1]}
      </div>
      {/* <div>{components[0]}</div> */}
    </>
  );
};

export default InventoryTableHeader;
